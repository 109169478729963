export type Feedback = {
    id: number;
    username: string;
    email: string;
    phone: string;
    types: string;
    message: string;
    city: number;
    status: TypeOfStatus;
    managerMessage: string | null;
    account: AccountInfo | null;
    createdAt: Date;
    updatedAt: Date;
};

export type AccountInfo = {
    id: number;
    firstName: string;
    lastName: string;
    avatar: null | string;
};

export enum TypeOfStatus {
    'new' = 'new',
    'processed' = 'processed',
    'completed' = 'completed',
    'notResponding' = 'notResponding',
}

export const StatusText = {
    new: 'Новая заявка',
    processed: 'В работе',
    completed: 'Завершена',
    notResponding: 'Не отвечает',
};

export interface IStatusInfo {
    [key: string]: {
        key: string;
        text: string;
        color: string;
    };
}

export const StatusInfo: IStatusInfo = {
    new: {
        key: 'new',
        text: 'Новая заявка',
        color: '#e96055',
    },
    processed: {
        key: 'processed',
        text: 'В работе',
        color: '#5fc5ea',
    },
    completed: {
        key: 'completed',
        text: 'Завершена',
        color: '#1DA57A',
    },
    notResponding: {
        key: 'notResponding',
        text: 'Не отвечает',
        color: '#ffc800',
    },
};

export type AddFeedback = {
    username: string;
    email: string;
    phone: string;
    types: string;
    message: string;
    city: string;
};
