import { put, call } from 'redux-saga/effects';
import {
    acceptTender,
    acceptTenderError,
    acceptTenderFinish,
} from 'ducks/tenders/actions';

import { fetchers } from 'api';

export function* acceptTenderRequest(action: ReturnType<typeof acceptTender>) {
    try {
        const response = yield call(fetchers.acceptTender, action.payload);

        yield put(acceptTenderFinish(response.data));

        if (action.cb) {
            action.cb();
        }
    } catch (error) {
        yield put(acceptTenderError());
    }
}
