import * as Api from 'types/api';
import {
    ScoresActionTypes,
} from './action-types';

export const getScores = (payload: Api.GetScoresPayload) => ({
    type: ScoresActionTypes.GET_SCORES_START,
    payload,
});

export const getScoresError = () => ({
    type: ScoresActionTypes.GET_SCORES_ERROR,
});

export const getScoresFinish = (response: Api.GetScoresResponse) => ({
    type: ScoresActionTypes.GET_SCORES_FINISH,
    response,
});

export const clearScores = () => ({
    type: ScoresActionTypes.CLEAR_SCORES,
});

export const getScore = (payload: Api.GetScorePayload) => ({
    type: ScoresActionTypes.GET_SCORE_START,
    payload,
});

export const getScoreError = () => ({
    type: ScoresActionTypes.GET_SCORE_ERROR,
});

export const getScoreFinish = (response: Api.GetScoreResponse) => ({
    type: ScoresActionTypes.GET_SCORE_FINISH,
    response,
});

export const clearScore = () => ({
    type: ScoresActionTypes.CLEAR_SCORE,
});

export const createScore = (payload: Api.CreateScorePayload, cb?: () => void) => ({
    type: ScoresActionTypes.CREATE_SCORE_START,
    payload,
    cb,
});

export const createScoreError = () => ({
    type: ScoresActionTypes.CREATE_SCORE_ERROR,
});

export const createScoreFinish = () => ({
    type: ScoresActionTypes.CREATE_SCORE_FINISH,
});

export const refundScore = (payload: Api.RefundScorePayload, cb?: () => void) => ({
    type: ScoresActionTypes.REFUND_SCORE_START,
    payload,
    cb,
});

export const refundScoreError = () => ({
    type: ScoresActionTypes.REFUND_SCORE_ERROR,
});

export const refundScoreFinish = (id: number | undefined) => ({
    type: ScoresActionTypes.REFUND_SCORE_FINISH,
    id,
});
