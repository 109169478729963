import { Company, CompanyType, CompanyTypeText } from 'types/company';

export const columns = [
    {
        title: 'ID компании',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Тип компании',
        dataIndex: 'typeOfOrgan',
        key: 'typeOfOrgan',
        render: (value: CompanyType) => CompanyTypeText[value],
    },
    {
        title: 'Наименование/ФИО',
        key: 'name',
        render: (company: Company) => (company.typeOfOrgan === CompanyType.company
            ? company.organization
            : company.fio),
    },
];
