import { InferValueTypes } from 'types/common';
import { Subscription } from 'types/subscription';
import { SubscriptionActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type SubscriptionState = {
    loading: boolean;
    list?: Subscription;
};

export const initialState: SubscriptionState = {
    loading: false,
    list: undefined,
};

export function subscriptionReducer(state = initialState, action: ActionTypes): SubscriptionState {
    switch (action.type) {
        case SubscriptionActionTypes.LOAD_SUBSCRIPTION_START:
            return {
                ...state,
                loading: true,
            };

        case SubscriptionActionTypes.LOAD_SUBSCRIPTION_FINISH:
            return {
                ...state,
                loading: false,
                list: action.response,
            };

        case SubscriptionActionTypes.LOAD_SUBSCRIPTION_ERROR:
            return {
                ...state,
                loading: false,
            };

        case SubscriptionActionTypes.SET_SUBSCRIPTION_START:
            return {
                ...state,
                loading: true,
            };

        case SubscriptionActionTypes.SET_SUBSCRIPTION_ERROR:
            return {
                ...state,
                loading: false,
            };

        case SubscriptionActionTypes.SET_SUBSCRIPTION_FINISH:
            return {
                ...state,
                loading: false,
                list: action.response,
            };

        default: {
            return state;
        }
    }
}
