import { ApplicationState } from 'ducks/application-state';

export const selectDialog = (state: ApplicationState) => state.dialog.dialog.dialog;
export const selectDialogLoading = (state: ApplicationState) => state.dialog.dialog.loading.get;

export const selectDialogMesages = (state: ApplicationState) => state.dialog.message.list;
export const selectDialogMesagesLoading = (state: ApplicationState) => state.dialog.message.loading;
export const selectDialogMesagesPagination = (state: ApplicationState) => state.dialog.message.pagination;
export const selectDialogMesagesSort = (state: ApplicationState) => state.dialog.message.sort;
export const selectDialogMesagesNext = (state: ApplicationState) => state.dialog.message.pagination.next;

export const selectDialogAll = (state: ApplicationState) => state.dialog.dialog.dialogList;
export const selectDialogAllLoading = (state: ApplicationState) => state.dialog.dialog.loading.gets;
export const selectDialogAllPagination = (state: ApplicationState) => state.dialog.dialog.pagination;
export const selectDialogAllSort = (state: ApplicationState) => state.dialog.dialog.sort;
