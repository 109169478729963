import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message as antMessage } from 'antd';
import useNotification from 'components/ui/hooks/useNotification';
import { SocketContext } from 'context/socket';
import { COMMENT } from 'constants/soccet';

import {
    selectDialog,
    selectDialogLoading,
    selectDialogMesages,
    selectDialogMesagesLoading,
    selectDialogMesagesNext,
    selectDialogMesagesPagination,
} from 'ducks/dialog/selectors';
import {
    clearDialog,
    getDialogByHash,
    getMessages,
    clearDialogMessages,
    delteMessage,
    readMessage,
    editMessage,
    sendMessage,
    socketAddMessage,
    socketEditMessage,
    socketRemoveMessage,
} from 'ducks/dialog/actions';

import { Message as MessageType } from 'types/dialog';

import { forbiddenWords } from 'utils/formatters';
import { Header } from './header';
import { Footer } from './footer';
import { ListMessage } from './list';
import { EmptyChat } from '../emptyChat';
import { LoadingDialog } from '../loading';

import cl from './styles.module.scss';

type UseRouteMatchProps = {
    hash: string;
};

export const ChatWindow = React.memo(() => {
    const put = useDispatch();
    const { sendNotification } = useNotification();
    const socket = useContext(SocketContext);
    const dialog = useSelector(selectDialog);
    const loading = useSelector(selectDialogLoading);
    const messages = useSelector(selectDialogMesages);
    const pagination = useSelector(selectDialogMesagesPagination);
    const loadings = useSelector(selectDialogMesagesLoading);
    const nextMoreLoading = useSelector(selectDialogMesagesNext);

    const [errMessage, setErrMessage] = useState<null | string>(null);
    const [page, setPage] = useState(2);
    const [message, setMessage] = useState('');
    const [editedMessage, setEditedMessage] = useState<MessageType | null>(null);

    const { params: { hash } } = useRouteMatch<UseRouteMatchProps>();

    const socketAddMess = (data: MessageType) => put(socketAddMessage(data));
    const socketEditMess = (data: MessageType) => put(socketEditMessage(data));
    const socketRemoveMess = (id: number) => put(socketRemoveMessage(id));

    useEffect(() => {
        put(getDialogByHash({ hash }));
        put(getMessages({ hash, pagination: { page: 1 } }));

        return () => {
            put(clearDialogMessages());
            put(clearDialog());
        };
    }, [hash]);

    useEffect(() => {
        if (dialog) {
            socket.emit(COMMENT.JOIN, { room: dialog?.id });
            socket.on(COMMENT.SEND, socketAddMess);
            socket.on(COMMENT.EDIT, socketEditMess);
            socket.on(COMMENT.REMOVE, socketRemoveMess);
        }

        return () => {
            if (socket) {
                socket.emit(COMMENT.UNSUBSCRIBE, { room: dialog?.id });
                socket.removeListener(COMMENT.SEND, socketAddMess);
                socket.removeListener(COMMENT.EDIT, socketEditMess);
                socket.removeListener(COMMENT.REMOVE, socketRemoveMess);
            }
        };
    }, [socket, dialog]);

    const handleDelete = useCallback((id: number) => () => put(delteMessage(id)), []);
    const handleRead = useCallback((id: number) => () => put(readMessage(id)), []);

    const handleChangeMessage = (txt: any) => {
        setMessage(txt);
    };

    const handleSendMessage = useCallback(() => {
        if (forbiddenWords(message)) {
            setErrMessage('Запрещено использовать нецензурную брань, сылки, телефоны, e-mail адреса');
        } else if (message.length > 0) {
            const callback = () => setMessage('');

            if (hash) {
                put(sendMessage({ hash }, { message }, callback));
                sendNotification({ hash, message });
                setErrMessage(null);
            }
        } else {
            antMessage.error('Нельзя отправить пустое сообщение');
        }
    }, [message]);

    if (!hash) {
        return <EmptyChat />;
    }

    if (hash && loading) {
        return <LoadingDialog />;
    }

    const handleEdit = (mess: MessageType) => {
        setEditedMessage(mess);
        setMessage(mess.message);
    };

    const handleEditClose = () => {
        setEditedMessage(null);
        setMessage('');
        setErrMessage(null);
    };
    const nextMoreData = () => {
        setPage((num) => num + 1);
        setTimeout(() => {
            put(getMessages({ hash, pagination: { page } }));
        }, 1);
    };

    const handleSubmitEdited = () => {
        if (forbiddenWords(message)) {
            setErrMessage('Запрещено использовать нецензурную брань, сылки, телефоны, e-mail адреса');
        } else if (message?.length > 0) {
            const callback = () => {
                setEditedMessage(null);
                setMessage('');
                setErrMessage(null);
            };

            put(editMessage({ id: editedMessage!.id, message }, callback));
        } else {
            antMessage.error('Нельзя отправить пустое сообщение');
        }
    };

    return (
        <div className={ cl.wrap }>
            <Header dialog={ dialog } />
            <ListMessage
                data={ messages }
                pageSize={ pagination.pageSize }
                nextMoreData={ nextMoreData }
                nextMoreLoading={ nextMoreLoading }
                handleEdit={ handleEdit }
                handleDelete={ handleDelete }
                handleRead={ handleRead }
            />
            <Footer
                errMessage={ errMessage }
                loading={ loadings }
                message={ message }
                handleChangeMessage={ handleChangeMessage }
                handleSendMessage={ editedMessage ? handleSubmitEdited : handleSendMessage }
                refund={ editedMessage }
                handleChangeMessageRefund={ handleEditClose }
            />
        </div>
    );
});
