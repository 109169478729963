import React from 'react';
import { Skeleton } from 'antd';
import cn from 'classnames';
import { ClockCircleOutlined } from '@ant-design/icons';

import styles from '../../../order/guest/styles.module.scss';

export const OrderItemBlockSkeleton = () => (
    <div className={ styles.item }>
        <div className={ styles.header }>
            <div className={ styles.header__title }>
                <Skeleton active={ true } style={ { width: '100%' } } paragraph={ { rows: 1 } } />
            </div>
            <div className={ styles.header__tags }>
                <Skeleton paragraph={ { rows: 0 } } />
            </div>
        </div>
        <div className={ styles.body }>
            <Skeleton active={ true } paragraph={ { rows: 1 } } />
        </div>
        <div className={ styles.footer }>
            <div className={ cn(styles.footer__left, styles.clock) }>
                <ClockCircleOutlined className={ styles.icon } />
                <Skeleton active={ true } paragraph={ { rows: 0 } } />
            </div>
        </div>
    </div>
);
