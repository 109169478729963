import React, { Suspense, useEffect } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LHeader } from 'components/header';
import { Container } from 'components/ui/container';
import { Footer } from 'components/footer';
import { EventInit } from 'components/ui/yandex-metrika';
import { TitleLine } from 'components/ui/title-line';
import { selectBlogAll } from 'ducks/blog/selectors';
import { Item } from 'components/blog/item';
import { clearBlog, getBlog } from 'ducks/blog/actions';
import cls from './style.module.scss';

export const PageBlog = () => {
    const put = useDispatch();
    const list = useSelector(selectBlogAll);

    useEffect(() => {
        put(getBlog({ pagination: { pageSize: 9, page: 1 } }));

        return () => {
            put(clearBlog());
        };
    }, []);

    return (
        <Suspense fallback={ <Spin /> }>
            <LHeader />
            <Container className={ cls.containerTop }>
                <TitleLine title="Блог" />

                <div className={ cls.items }>
                    { list.map((item, i) => (
                        <Item { ...item } key={ i } />
                    )) }
                </div>
            </Container>
            <Footer />
            <EventInit />
        </Suspense>
    );
};
