import { REQUIRED_FIELD } from 'form-helpers/message';
import { CompanyType } from 'types/company';
import * as yup from 'yup';

// import { REQUIRED_FIELD } from 'form-helpers/message';

// const yurRequired = yup.string().test('isRequired', REQUIRED_FIELD, function validate(value) {
//     if (this.parent.inn) {
//         return Boolean(value);
//     }

//     return true;
// });

// const fizRequired = yup.string().test('isRequired', REQUIRED_FIELD, function validate(value) {
//     if (this.parent.inn) {
//         return true;
//     }

//     return Boolean(value);
// });

const yurField = yup.string().nullable(true).default(null);
const fizField = yup.string().nullable(true).default(null);

const fileRequired = yup.mixed();

export const schema = yup.object().shape({
    typeOfOrgan: yup.mixed<CompanyType>().required(REQUIRED_FIELD).default(null),
    // юрики
    inn: yurField,
    organization: yurField,
    kpp: yurField,
    address: yurField,
    bicBank: yurField,
    scoreNumber: yurField,
    certificateNumber: yurField,
    decisionCreate: fileRequired,
    pageCharter: fileRequired,
    appOrderDir: fileRequired,
    // физ
    fio: fizField,
    passportNumber: fizField,
    residenceAddress: fizField,
});
