import { put, call, select } from 'redux-saga/effects';

import {
    getMessages,
    getMessagesFinish,
    getMessagesError,
} from 'ducks/dialog/actions';

import {
    selectDialogMesagesPagination,
    selectDialogMesagesSort,
} from 'ducks/dialog/selectors';

import { fetchers } from 'api';

export function* getMessagesRequest({ payload }: ReturnType<typeof getMessages>) {
    try {
        const { pageSize } = yield select(selectDialogMesagesPagination);
        const sort = yield select(selectDialogMesagesSort);

        const response = yield call(fetchers.getDialogMessages, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        yield put(getMessagesFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                next: response.pagination.next,
                page: response.pagination.page,
            },
            sort: response.sort,
        }));
    } catch (error) {
        yield put(getMessagesError());
    }
}
