export const ScoresActionTypes = {
    GET_SCORES_START: 'GET_SCORES_START',
    GET_SCORES_FINISH: 'GET_SCORES_FINISH',
    GET_SCORES_ERROR: 'GET_SCORES_ERROR',

    CLEAR_SCORES: 'CLEAR_SCORES',

    GET_SCORE_START: 'GET_SCORE_START',
    GET_SCORE_FINISH: 'GET_SCORE_FINISH',
    GET_SCORE_ERROR: 'GET_SCORE_ERROR',

    CLEAR_SCORE: 'CLEAR_SCORE',

    CREATE_SCORE_START: 'CREATE_SCORE_START',
    CREATE_SCORE_FINISH: 'CREATE_SCORE_FINISH',
    CREATE_SCORE_ERROR: 'CREATE_SCORE_ERROR',

    REFUND_SCORE_START: 'REFUND_SCORE_START',
    REFUND_SCORE_FINISH: 'REFUND_SCORE_FINISH',
    REFUND_SCORE_ERROR: 'REFUND_SCORE_ERROR',
} as const;
