// import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    createTempOrderGuest,
    createTempOrderGuestError,
    createTempOrderGuestFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* createTempOrderGuestRequest({ data, cb }: ReturnType<typeof createTempOrderGuest>) {
    try {
        const order = yield call(fetchers.createTempOrderGuest, data);

        localStorage.setItem('tempOrderId', order.id);

        yield put(createTempOrderGuestFinish(order));
        if (cb) {
            yield cb();
        }
    } catch (error) {
        yield put(createTempOrderGuestError());
    }
}
