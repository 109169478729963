import React from 'react';
import { Layout } from 'components/ui/layout';
import { DialogList } from 'components/dialogs/list-dialog';
import { ChatWindow } from 'components/dialogs/chat';

import styles from './styles.module.scss';

export const Dialogs = () => (
    <Layout>
        <div className={ styles.row }>
            <DialogList />
            <ChatWindow />
        </div>
    </Layout>
);
