/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Switch } from 'antd';
import { useSelector } from 'react-redux';
import { selectProfile } from 'ducks/user/selectors';
import { RegistrationGuest } from './register';
import { AuthGuest } from './auth';
import styles from '../styles.module.scss';

type Props = {
    onNextStep: () => void;
};

export const LoginAndRegisterGuest = ({ onNextStep }: Props) => {
    const profile = useSelector(selectProfile);
    const [check, setCheck] = useState(false);

    const onChecked = () => setCheck(!check);

    if (profile) {
        onNextStep();
    }

    return (
        <div className={ styles.authContainer }>
            <div className={ styles.switch }>
                <Switch
                    defaultChecked={ !check }
                    checked={ !check }
                    onChange={ onChecked }
                />
                <span
                    onClick={ () => onChecked() }
                    className={ styles.switch__title }
                >
                    Я уже зарегистрирован
                </span>
            </div>
            { check ? (
                <RegistrationGuest onNextStep={ onNextStep } />
            ) : (
                <AuthGuest onNextStep={ onNextStep } />
            ) }
        </div>
    );
};
