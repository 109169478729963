import React from 'react';
import { ROUTES } from 'constants/routes';
import { Alert, Button } from 'antd';
import { history } from 'App';
import styles from './styles.module.scss';

const telegramBot = 'Мы разработали <b>Телеграм бота</b>, который будет информировать Вас о вашем созданном заказе. Он будет отправлять Вам уведомления о новых обновлениях вашего заказа, присылать уведомления о текущем статусе заказа и о процессе его исполнения. Вы также можете получать обновления о ваших предыдущих заказах, а также о новых предложениях.';

export const NotificationBot = () => {
    const onClick = () => history.push(ROUTES.EDIT_PROFILE.path);

    return (
        <Alert
            message={ <div dangerouslySetInnerHTML={ { __html: telegramBot } } /> }
            type="success"
            className={ styles.alertBot }
            showIcon={ true }
            closable={ true }
            action={ (
                <Button size="small" type="primary" onClick={ onClick }>
                    Подключить
                </Button>
            ) }
        />
    );
};
