/* eslint-disable no-case-declarations */
import { InferValueTypes } from 'types/common';
import { Notification } from 'types/notification';
import { NotificationActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type NotificationState = {
    loading: {
        get: boolean;
    };
    list: Notification[];
    newSize: number;
    pagination: {
        pageSize: number;
        total: number;
        page: number;
        next: boolean;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: NotificationState = {
    loading: {
        get: false,
    },
    list: [],
    newSize: 10,
    pagination: {
        pageSize: 6,
        total: 0,
        page: 1,
        next: true,
    },
    sort: {
        sortField: 'id',
        sortOrder: 'desc',
    },
};

export function notificationReducer(state = initialState, action: ActionTypes): NotificationState {
    switch (action.type) {
        case NotificationActionTypes.GET_NOTIFICATION_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case NotificationActionTypes.GET_NOTIFICATION_FINISH:
            const sizeNew1 = action.response.data.filter(({ visible }) => !visible);

            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: [...state.list, ...action.response.data],
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
                newSize: sizeNew1.length,
            };

        case NotificationActionTypes.GET_NOTIFICATION_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case NotificationActionTypes.LOAD_NOTIFICATION_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case NotificationActionTypes.LOAD_NOTIFICATION_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case NotificationActionTypes.LOAD_NOTIFICATION_FINISH:
            const sizeNew = action.response.data.filter(({ visible }) => !visible);

            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                newSize: sizeNew.length,
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case NotificationActionTypes.GET_NOTIFICATION_COUNT_FINISH:
            return {
                ...state,
                newSize: action.size,
            };

        case NotificationActionTypes.SET_NOTIFICATION_FINISH:
            return {
                ...state,
                newSize: state.newSize > 0 ? state.newSize - 1 : 0,
                list: state.list.map((item) => (item.id === action.response.id ? { ...item, ...action.response } : item)),
            };

        default: {
            return state;
        }
    }
}
