import React from 'react';
import moment from 'moment';
import { IconAt } from '@tabler/icons';
import { Notification as NotificationType } from 'types/notification';
import cn from 'classnames';

import styles from '../styles.module.scss';

type Props = {
    item: NotificationType;
    onHandleVisible: (visible: boolean, id: number) => void;
    goToAuthorOrder: (id: number) => void;
};

export const SendReview = ({ item, onHandleVisible, goToAuthorOrder }: Props) => {
    const visibleIco = <div className={ styles.newBagle } />;
    const created = moment(item.createdAt).fromNow();

    return (
        <div
            className={ styles.ls }
            onMouseEnter={ () => onHandleVisible(item.visible, item.id) }
            onClick={ () => goToAuthorOrder(item.orderId) }
        >
            <div className={ cn(styles.ls_img, styles.sendReview) }>
                <IconAt />
                { !item.visible && (visibleIco) }
            </div>
            <div className={ styles.ls_body }>
                <div className={ styles.ls_txt }>
                    Пожалуйста оцените заказ и оставитьте отзыв
                </div>
                <div className={ styles.ls_time }>{ created }</div>
            </div>
        </div>
    );
};
