import React, { FC, ImgHTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import noavatar from 'assets/noavatar.jpg';
import styles from './styles.module.scss';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    src: string;
    type?: 'circle' | 'round';
}

export const Avatar: FC<Props> = ({ src, type = 'circle', className, ...rest }) => {
    const [ava, setAva] = useState<null|string>(null);
    const rootClass = classNames(styles.root, styles[type], className);

    const handleError = () => {
        setAva(noavatar);
    };

    if(ava || !src) {
        return <img { ...rest } src={ noavatar } className={ rootClass } alt="" />;
    }

    return <img { ...rest } src={ src } className={ rootClass } onError={ handleError } alt="" />;
};