import React, {
    useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import classNames from 'classnames';
import { IconBellRinging } from '@tabler/icons';
import { history } from 'App';
import { ROUTES } from 'constants/routes';
import InfiniteScroll from 'react-infinite-scroll-component';
import useNotification from 'components/ui/hooks/useNotification';
import { getNotifications, setNotification } from 'ducks/notification/actions';
import { selectNotification, selectNotificationNewSize, selectNotificationPagination } from 'ducks/notification/selectors';
import { NotificationEmpty } from './empty';
import { Messsage } from './message';

import styles from './styles.module.scss';

export const Notification = React.memo(() => {
    const put = useDispatch();
    const notification = useSelector(selectNotification);
    const pagination = useSelector(selectNotificationPagination);
    const newSize = useSelector(selectNotificationNewSize);
    const { getNotification } = useNotification();
    const event = getNotification();

    const ref = useRef<HTMLDivElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const endMessageTxt = <div className={ styles.nomessage }>Уведомлений больше нет</div>;
    const loadingMessage = <div className={ styles.loading }><Spin /></div>;

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isMenuOpen && ref.current && !ref.current?.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    useEffect(() => {
        const timer = setInterval(() => put(getNotifications({ pagination: { page: 1 } })), 15000);

        if (isMenuOpen) {
            clearInterval(timer);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isMenuOpen]);

    useEffect(() => {
        put(getNotifications({ pagination: { page: 1 } }));
    }, [event]);

    const nextMoreData = () => {
        if (pagination.next) {
            put(getNotifications({ pagination: { page: pagination.page + 1 } }));
        }
    };

    const goToAuthorOrder = (id?: number) => history.push(ROUTES.VIEW_ORDER.getPath({ id }));
    const goToUrl = (link: string) => history.push(link);
    const onHandleVisible = (visible: boolean, id: number) => {
        if (!visible) {
            put(setNotification({ id, visible: true }));
        }
    };

    const container = classNames({
        [styles.notification_wrap]: true,
        [styles.active]: isMenuOpen,
    });

    return (
        <div className={ styles.notification }>
            <div
                className={ styles.notification_ico }
                onClick={ () => setIsMenuOpen((oldState) => !oldState) }
            >
                { newSize > 0 && (<div className={ styles.notification_bagle } />) }
                <IconBellRinging size={ 20 } />
            </div>
            <div
                ref={ ref }
                className={ container }
            >
                { notification.length ? (
                    <div
                        id="scrollableNotification"
                        className={ styles.body }
                    >
                        <InfiniteScroll
                            dataLength={ notification.length }
                            next={ nextMoreData }
                            hasMore={ pagination.next }
                            endMessage={ !pagination.next && endMessageTxt }
                            loader={ loadingMessage }
                            scrollableTarget="scrollableNotification"
                        >
                            { notification && notification.map((item, key) => (
                                <Messsage
                                    item={ item }
                                    goToAuthorOrder={ goToAuthorOrder }
                                    goToUrl={ goToUrl }
                                    onHandleVisible={ onHandleVisible }
                                    key={ key }
                                />
                            )) }
                        </InfiniteScroll>
                    </div>
                ) : (<NotificationEmpty />) }
            </div>
        </div>
    );
});
