/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import {
    Empty, Menu, Spin,
} from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

import { selectProfile } from 'ducks/user/selectors';
import { getFio } from 'utils/formatters';
import { DATE_FORMAT } from 'constants/date';
import { ROUTES } from 'constants/routes';
import { history } from 'App';
import { Message as MessageType } from 'types/dialog';

import { Avatar } from 'components/ui/avatar';
import { Answer } from './answer';
import { Responder } from './responder';

import styles from './styles.module.scss';

type Props = {
    data: MessageType[];
    handleEditComment: (comment: MessageType) => void;
    handleDeleteComment: (number: number) => () => void;
    handleReadComment: (id: number) => void;
    nextMoreData: () => void;
    nextMoreLoading: boolean;
};

export const ListMessage = React.memo(({
    data, handleEditComment, handleDeleteComment, handleReadComment, nextMoreData, nextMoreLoading,
}: Props) => {
    const profile = useSelector(selectProfile);

    const goToAuthorComment = (id: number) => () => history.push(ROUTES.USER.getPath({ id }));

    const endMessageTxt = <div className={ styles.nomessage }>Сообщений больше нет</div>;
    const loadingMessage = <div className={ styles.loading }><Spin /></div>;

    if (data.length === 0) {
        return (<Empty className={ styles.empty } description="Сообщений нет" image={ Empty.PRESENTED_IMAGE_SIMPLE } />);
    }

    const list = data.map((item, i) => {
        const isEditAllow = moment().diff(item.created) < 300000;

        const actions = item.account.id === profile?.id && isEditAllow
            ? (
                <Menu key={ item.id }>
                    <Menu.Item key="edit" onClick={ () => handleEditComment(item) }>Изменить</Menu.Item>
                    <Menu.Item key="delete" onClick={ handleDeleteComment(item.id) }>Удалить</Menu.Item>
                </Menu>
            )
            : undefined;

        if (item.account.id === profile?.id) {
            return (
                <div key={ i }>
                    <Answer
                        avatar={ (
                            <div title={ getFio(item.account.lastName, item.account.firstName) } className={ styles.avaWrapper }onClick={ goToAuthorComment(item.account.id) }>
                                <Avatar src={ item.account.avatar }  className={ styles.ava } />
                            </div>
                        ) }
                        content={ item.message }
                        datetime={ <span className={ styles.commentDate }>{ moment(item.created).format(DATE_FORMAT.DATE_TIME) }</span> }
                        actions={ actions }
                    />
                </div>
            );
        }
        if (item.account.id !== profile?.id) {
            return (
                <div key={ i }>
                    <Responder
                        avatar={ (
                            <div title={ getFio(item.account.lastName, item.account.firstName) } className={ styles.avaWrapper }onClick={ goToAuthorComment(item.account.id) }>
                                <Avatar src={ item.account.avatar }  className={ styles.ava } />
                            </div>
                        ) }
                        content={ item.message }
                        read={ item.read }
                        handleRead={ handleReadComment(item.id) }
                        datetime={ <span className={ styles.commentDate }>{ moment(item.created).format(DATE_FORMAT.DATE_TIME) }</span> }
                    />
                </div>
            );
        }
    });

    return (
        <div
            id="scrollableDiv"
            className={ styles.messageBodyList }
            style={ { height: '100%', overflowY: 'scroll' } }
        >
            <InfiniteScroll
                dataLength={ data.length }
                next={ nextMoreData }
                style={ { display: 'flex', flexDirection: 'column-reverse' } }
                inverse={ true }
                hasMore={ nextMoreLoading }
                endMessage={ data.length > 8 && endMessageTxt }
                loader={ loadingMessage }
                scrollableTarget="scrollableDiv"
            >
                { list }
            </InfiniteScroll>
        </div>
    );
});
