import * as Api from 'types/api';

import {
    PermissionActionTypes,
} from './action-types';

export const getPermission = () => ({
    type: PermissionActionTypes.GET_PERMISSION_START,
});

export const getPermissionError = () => ({
    type: PermissionActionTypes.GET_PERMISSION_ERROR,
});

export const getPermissionFinish = (response: any) => ({
    type: PermissionActionTypes.GET_PERMISSION_FINISH,
    response,
});

export const updatePermission = (response: Api.UpdatePermissionResponse) => ({
    type: PermissionActionTypes.UPDATE_PERMISSION_START,
    response,
});
