import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { clearUser, getUser } from 'ducks/user/actions';
import { clearReviews, getReviews } from 'ducks/reviews/actions';
import { getOrders, clearOrders } from 'ducks/orders/actions';

import { selectUser, selectProfile } from 'ducks/user/selectors';
import {
    selectReviews,
    selectReviewsLoading,
    selectReviewsPagination,
} from 'ducks/reviews/selectors';

import {
    selectOrders,
    selectOrdersPagination,
    selectOrderLoading,
} from 'ducks/orders/selectors';

import { Layout } from 'components/ui/layout';
import { ProfileSkeleton } from 'components/ui/skeleton';
import { MProfile } from 'components/profile';

import { Role } from 'types/account';

type MatchParams = {
    id: string;
};

export const User = React.memo(() => {
    const put = useDispatch();
    const profile = useSelector(selectProfile);
    const user = useSelector(selectUser);
    const reviews = useSelector(selectReviews);
    const reviewsLoading = useSelector(selectReviewsLoading);
    const reviewsPagination = useSelector(selectReviewsPagination);
    const orders = useSelector(selectOrders);
    const ordersLoading = useSelector(selectOrderLoading);
    const ordersPagination = useSelector(selectOrdersPagination);
    const { params: { id: userId } } = useRouteMatch<MatchParams>();

    const isMe = user?.id === profile?.id;

    useEffect(() => {
        put(getUser({ userId }));

        return () => {
            put(clearUser());
        };
    }, []);

    const field = user?.role === Role.Executor ? 'executorId' : 'accountId';

    useEffect(() => {
        put(getReviews({ id: userId, pagination: { page: 1 } }));
        put(getOrders({ [field]: userId, status: 'done', pagination: { page: 1 } }));

        return () => {
            put(clearReviews());
            put(clearOrders());
        };
    }, [user]);

    if (!user) {
        return (
            <Layout>
                <ProfileSkeleton />
            </Layout>
        );
    }

    const onChangeReviewsPagination = (page: number) => {
        put(getReviews({ id: userId, pagination: { page } }));
    };

    const onChangeOrdersPagination = (page: number) => {
        put(getOrders({ [field]: userId, status: 'done', pagination: { page } }));
    };

    return (
        <Layout>
            <MProfile
                auth={ isMe }
                profile={ user }
                onChangeReviewsPagination={ onChangeReviewsPagination }
                onChangeOrdersPagination={ onChangeOrdersPagination }
                reviews={ reviews }
                reviewsLoading={ reviewsLoading.get }
                reviewsPagination={ reviewsPagination }
                orders={ orders }
                ordersLoading={ ordersLoading.getOrders }
                ordersPagination={ ordersPagination }
            />
        </Layout>
    );
});
