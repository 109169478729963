import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconBoltOff, IconBolt } from '@tabler/icons';
import { selectDialog } from 'ducks/dialog/selectors';
import { toggleDialogNotify } from 'ducks/dialog/actions';

import Tooltip from 'antd/es/tooltip';
import styles from './styles.module.scss';

export const Title = React.memo(() => {
    const put = useDispatch();
    const dialog = useSelector(selectDialog);

    const onHandleClick = useCallback((notify: boolean) => {
        if (dialog?.id) {
            put(toggleDialogNotify({ dialogId: dialog.id, notify }));
        }
    }, [dialog?.id]);

    const btn = dialog?.notify ? (
        <Tooltip  placement="bottom" title="Отключить уведомление" trigger="hover">
            <IconBolt className={ styles.ico_on } onClick={ () => onHandleClick(false) } />
        </Tooltip>
    ) : (
        <Tooltip placement="bottom" title="Включить уведомление" trigger="hover">
            <IconBoltOff className={ styles.ico_off } onClick={ () => onHandleClick(true) } />
        </Tooltip>
    );

    return (
        <div className={ styles.chat_head }>
            <div className={ styles.chat_head__title }>Чат для заказа</div>
            <div>
                { btn }
            </div>
        </div>
    );
});
