import React from 'react';
import {
    IconAntennaBars1, IconArrowBarLeft, IconArrowForwardUp, IconBoltOff,
} from '@tabler/icons';
import { Dropdown } from 'antd';
import { history } from 'App';
import { ROUTES } from 'constants/routes';
import { declOfNum } from 'utils/formatters';
import { useDispatch } from 'react-redux';
import { getDialogAll, toggleDialogNotify } from 'ducks/dialog/actions';
import { Dialog } from 'types/dialog';

import cl from './styles.module.scss';

type Props = {
    dialog: Dialog | null;
};

export const Header = React.memo(({ dialog }: Props) => {
    const put = useDispatch();

    const unsubscribeDialog = () => {
        if (dialog) {
            put(toggleDialogNotify({ dialogId: dialog.id, notify: false }, () => {
                history.push(ROUTES.DIALOGS.path);
                put(getDialogAll({ pagination: { page: 1 } }));
            }));
        }
    };

    const onClose = () => history.push(ROUTES.DIALOGS.path);

    const goToOrder = (id?: number | null) => (id ? history.push(ROUTES.VIEW_ORDER.getPath({ id })) : null);

    const items = [{
        label: 'Перейти к заказу',
        icon: <IconArrowForwardUp />,
        onClick: () => goToOrder(dialog?.orderId),
        key: '1',
    }, {
        label: 'Отписаться',
        icon: <IconBoltOff />,
        onClick: () => unsubscribeDialog(),
        key: '2',
    }];

    return (
        <div className={ cl.wrap }>
            <div className={ cl.header }>
                <div className={ cl.back } onClick={ onClose }>
                    <IconArrowBarLeft />
                </div>
                <div className={ cl.info }>
                    <div className={ cl.title } onClick={ () => goToOrder(dialog?.orderId) }>
                        { dialog?.name }
                        { dialog?.order?.name }
                    </div>
                    <div className={ cl.size }>
                        { dialog?.dialogAccountSize ?
                            `${dialog?.dialogAccountSize.size} ${declOfNum(dialog?.dialogAccountSize.size, ['участник', 'участника', 'участников'])}`
                            : '1 участник' }
                    </div>
                </div>
                <div className={ cl.setting }>
                    <Dropdown menu={ { items } } placement="bottomRight">
                        <IconAntennaBars1 size={ 30 } className={ cl.setting__ico } />
                    </Dropdown>
                </div>
            </div>
        </div>
    );
});
