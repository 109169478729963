import * as Api from 'types/api';
// import { Blog } from 'types/blog';
import {
    BlogActionTypes,
} from './action-types';

export const getBlog = (payload: Api.GetBlogAllPayload) => ({
    type: BlogActionTypes.GET_BLOG_START,
    payload,
});

export const getBlogError = () => ({
    type: BlogActionTypes.GET_BLOG_ERROR,
});

export const getBlogFinish = (response: Api.GetBlogAllResponse) => ({
    type: BlogActionTypes.GET_BLOG_FINISH,
    response,
});

export const getBlogById = (payload: Api.GetBlogByIdPayload) => ({
    type: BlogActionTypes.GET_BLOG_BY_ID_START,
    payload,
});

export const getBlogByIdError = () => ({
    type: BlogActionTypes.GET_BLOG_BY_ID_ERROR,
});

export const getBlogByIdFinish = (response: Api.GetBlogByIdResponse) => ({
    type: BlogActionTypes.GET_BLOG_BY_ID_FINISH,
    response,
});

export const clearBlog = () => ({
    type: BlogActionTypes.CLEAR_BLOG,
});
export const clearBlogDetail = () => ({
    type: BlogActionTypes.CLEAR_BLOG_DETAIL,
});

export const createBlog = (payload: Api.BlogCreatePayload, cb: () => void) => ({
    type: BlogActionTypes.CREATE_BLOG_START,
    payload,
    cb,
});

export const createBlogError = () => ({
    type: BlogActionTypes.CREATE_BLOG_ERROR,
});

export const createBlogFinish = (response: Api.GetBlogResponse) => ({
    type: BlogActionTypes.CREATE_BLOG_FINISH,
    response,
});

export const editBlog = (payload: Api.BlogEditPayload, cb: () => void) => ({
    type: BlogActionTypes.EDIT_BLOG,
    payload,
    cb,
});

export const editBlogError = () => ({
    type: BlogActionTypes.EDIT_BLOG_ERROR,
});

export const editBlogFinish = (response: Api.GetBlogResponse) => ({
    type: BlogActionTypes.EDIT_BLOG_FINISH,
    response,
});
