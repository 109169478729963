/* eslint-disable prefer-promise-reject-errors */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form,
    Input,
    InputNumber,
    Modal,
    Spin,
} from 'antd';
import { Link } from 'react-router-dom';

import useNotification from 'components/ui/hooks/useNotification';
import { sendTender } from 'ducks/tenders/actions';
import { selectTendersLoading } from 'ducks/tenders/selectors';

import { schema } from 'form-helpers/tender/schema';
import { TenderFormType } from 'form-helpers/tender/types';
import { useErrorProfile } from 'components/ui/hooks/useError';
import { selectProfile } from 'ducks/user/selectors';
import { ROUTES } from 'pages/edit-profile/routes';

type Props = {
    visible: boolean;
    setVisibilityModal: (value: boolean) => void;
    orderId: number | string;
};

export const TenderModal = React.memo(({ visible, setVisibilityModal, orderId }: Props) => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const loading = useSelector(selectTendersLoading);
    const [form] = Form.useForm();
    const { sendNewTender } = useNotification();
    const { isAuthConfirmed, IsAuthConfirmedTxt } = useErrorProfile();
    const [commission, setCommission] = useState(1000);

    const onSubmit = () => {
        form.validateFields()
            .then((values: TenderFormType) => {
                form.resetFields();
                put(sendTender(
                    {
                        ...values,
                        price: +values.price,
                        orderId,
                    },
                    () => setVisibilityModal(false),
                ));
                sendNewTender(orderId);
            }).catch((e) => e);
    };

    const onChangeScore = (price: number | null) => {
        if (price) {
            // eslint-disable-next-line no-mixed-operators
            const proc: number = (price / 100) * 5;

            if (proc < 1000) {
                setCommission(1000);
            } else {
                setCommission(proc);
            }
        }
    };

    const validatePrice = () => {
        const price = form.getFieldValue('price');
        const proc: number = (price / 100) * 5;
        const companyPrice = user?.company?.price || 0;
        const priceUser = companyPrice < (companyPrice - proc) || (companyPrice - proc) < 0;
        const payLink = (<Link to={ ROUTES.BALANS.path }>пополнить счет</Link>);

        if (!companyPrice) {
            return Promise.reject(<div>На вашем счету: 0 руб. Вам необходимо { payLink } </div>);
        }
        if (companyPrice < 1000) {
            return Promise.reject(<div>На вашем счету: { companyPrice } руб. Вам необходимо { payLink }</div>);
        }
        if (price < 1000 && companyPrice < 1000) {
            return Promise.reject(<div>На вашем счету не достаточно денег чтобы указать такую сумму. На вашем счете: { companyPrice } руб. ({ payLink })</div>);
        }
        if (priceUser) {
            return Promise.reject(<div>На вашем счету не достаточно денег чтобы указать такую сумму. На вашем счете: { companyPrice } руб. ({ payLink })</div>);
        }

        return Promise.resolve();
    };

    return (

        <Modal
            confirmLoading={ loading.send }
            onCancel={ () => setVisibilityModal(false) }
            onOk={ onSubmit }
            okText="Предложить"
            open={ visible }
            okButtonProps={ isAuthConfirmed ? { style: { display: 'none' } } : {} }
        >
            <Spin spinning={ loading.send }>
                <Form
                    form={ form }
                    layout="vertical"
                    name="TenderModal"
                    initialValues={ { price: 1000 } }
                >

                    <Form.Item
                        label="Предложите цену"
                        name="price"
                        extra={ true }
                        rules={ [
                            ...schema.price,
                            { validator: validatePrice },
                        ] }
                    >
                        <InputNumber
                            min={ 0 }
                            addonAfter={ `Коммисия ${commission.toLocaleString('ru-RU')} Руб.` }
                            onChange={ onChangeScore }
                            stringMode={ true }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Укажите планируемый срок оказания услуг"
                        name="termOfService"
                        extra={ true }
                        style={ { marginBottom: 0 } }
                        rules={ schema.termOfService }
                        help="Вы можете указать свой вариант оплаты"
                    >
                        <Input placeholder="Укажите планируемый срок оказания услуг" />
                    </Form.Item>

                    <Form.Item
                        label="Порядок оплаты"
                        name="payment"
                        extra={ true }
                        style={ { marginBottom: 0 } }
                        rules={ schema.payment }
                        help="Вы можете указать свой вариант оплаты"
                    >
                        <Input placeholder="Порядок оплаты" />
                    </Form.Item>

                    <Form.Item
                        label="Комментарий к предложению"
                        name="message"
                        extra={ true }
                        style={ { marginBottom: 0 } }
                        rules={ schema.message }
                        help="В данном поле укажите дополнительную информацию, например: аттестаты ОС и ИЛ, количество ПИ в СС, количество образцов необходимых для предоставления"
                    >
                        <Input.TextArea
                            placeholder="Комментарий к предложению"
                            rows={ 4 }
                            className="textarea"
                        />
                    </Form.Item>
                </Form>
            </Spin>
            <IsAuthConfirmedTxt />
        </Modal>
    );
});
