import React from 'react';
import { Button, Rate } from 'antd';
import cn from 'classnames';
import { IconEdit } from '@tabler/icons';
import { TypeOfRole, TypeOfRoleText } from 'types/account';
import Tooltip from 'antd/lib/tooltip';
import imgCrowns from '../../assets/crowns.png';

import styles from './styles.module.scss';

type Props = {
    active?: boolean;
    see?: boolean;
    typeOfRole?: TypeOfRole;
    author: React.ReactChild;
    avatar: React.ReactChild;
    message?: string | null;
    termOfService?: string | null;
    payment?: string | null;
    orderSize?: number;
    rating?: number;
    price?: React.ReactChild | null;
    btn: React.ReactChild | null;
    datetime: React.ReactChild;
    onHandleSee?: any;
    edit?: {
        isEdit: boolean;
        fEdit: () => void;
    };
};

export const Comment = React.memo(({
    active = false,
    see = true,
    author,
    avatar,
    message,
    termOfService,
    payment,
    orderSize,
    rating,
    typeOfRole,
    price,
    btn,
    datetime,
    onHandleSee,
    edit,
}: Props) => (
    <div
        className={ cn({
            [styles.comment]: true,
            [styles.comment__first]: active,
        }) }
    >
        { active && (
            <Tooltip title="Самое выгодное предложение по цене">
                <img src={ imgCrowns } className={ styles.crowns } alt="" />
            </Tooltip>
        ) }
        <div className={ styles.comment_wrap } onMouseEnter={ onHandleSee }>
            { !see && <div className={ styles.newCom } /> }
            <div className={ styles.profile }>
                <div className={ styles.profile_wrap }>
                    <div className={ styles.profile_ava }>
                        <div className={ styles.avatar }>{ avatar }</div>
                    </div>
                    <div className={ styles.profile_info }>
                        <div className={ styles.profile_name }>{ author }</div>
                        { typeOfRole && (<div className={ styles.profile_type }>({ TypeOfRoleText[typeOfRole] })</div>) }
                        <div className={ styles.profile_sizeorder }>Заказов: { orderSize }</div>
                        { rating && (
                            <div className={ styles.profile_rate }>
                                <Rate disabled={ true } allowHalf={ true } defaultValue={ rating } style={ { fontSize: 16 } } />
                            </div>
                        ) }
                    </div>
                </div>
            </div>
            <div className={ styles.comment_info }>
                { message && (<div className={ styles.comment_message }>{ message }</div>) }
                <div className={ styles.comment_block }>
                    <div className={ styles.comment_item }>
                        <div className={ styles.comment__txt }>{ termOfService || 'Не указан' }</div>
                        <div className={ styles.comment__desc }>Срок выпонения</div>
                    </div>
                    <div className={ styles.comment_item }>
                        <div className={ styles.comment__txt }>{ payment || 'Не указан' }</div>
                        <div className={ styles.comment__desc }>Способ оплаты</div>
                    </div>
                </div>
                <div
                    className={ cn({ [styles.comment_block]: true, [styles.comment_block__btn]: Boolean(btn) }) }
                >
                    <div className={ styles.comment_item }>
                        <div className={ styles.comment__txt }>{ price || 0 } Р.</div>
                        <div className={ styles.comment__desc }>Цена</div>
                    </div>
                    <div className={ styles.comment_item }>
                        <div className={ styles.comment__txt }>{ datetime }</div>
                        <div className={ styles.comment__desc }>Опубликовано</div>
                    </div>
                    { btn && (<div className={ styles.comment_btn }>{ btn }</div>) }
                </div>
            </div>
            { edit?.isEdit && (
                <div className={ styles.comment_footer }>
                    <Button
                        type="text"
                        onClick={ edit.fEdit }
                        icon={ <IconEdit size={ 16 } /> }
                    >
                        Изменить
                    </Button>
                </div>
            ) }
        </div>
    </div>
));
