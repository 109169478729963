import React from 'react';
import { LayoutStatic } from 'components/ui/layoutStatic';

export const Payment = () => (
    <LayoutStatic>
        <h2 className="sp-title">Оплата</h2>
        <div className="sp-text">
            <p>Сервис СертТорг абсолютно бесплатный, это означает, что мы не взымаем платы за его использование с заказчиков.</p>
        </div>
    </LayoutStatic>
);
