import React from 'react';
import {  Tabs, Tooltip } from 'antd';
import {
    IconEdit, IconBriefcase, IconWaveSawTool, IconFileCertificate,
} from '@tabler/icons';
import cn from 'classnames';

import moment from 'moment';
import { round } from 'lodash';
import { User } from 'types/user';
import { Review, ReviewPagination } from 'types/reviews';
import { Order, OrdersPagination } from 'types/order';

import { getFio } from 'utils/formatters';
import { DATE_FORMAT } from 'constants/date';
import { Role, RoleText } from 'types/account';
import { ROUTES } from 'constants/routes';
import { history } from 'App';
import { Avatar } from 'components/ui/avatar';
import { Orders } from './order';
import { Reviews } from './reviews';

import styles from './styles.module.scss';

import uActive from '../../assets/shield.png';
import uActiveDisable from '../../assets/shield_disable.png';

type Props = {
    auth?: boolean;
    profile: User;
    onChangeReviewsPagination?: (page: number) => void | null;
    onChangeOrdersPagination?: (page: number) => void | null;
    reviews?: Review[];
    reviewsLoading?: any;
    reviewsPagination?: ReviewPagination;
    orders?: Order[];
    ordersLoading?: any | null;
    ordersPagination?: OrdersPagination;
};

export const MProfile = React.memo(({
    auth = true,
    profile,
    onChangeReviewsPagination = undefined,
    onChangeOrdersPagination = undefined,
    reviews = [],
    reviewsLoading = undefined,
    reviewsPagination = undefined,
    orders = [],
    ordersLoading = false,
    ordersPagination = undefined,
}: Props) => {
    const uActiveTitle = 'Компания профиля прошла проверку сайта';
    const uActiveDisableTitle = 'Компания профиля не прошла еще проверку администратором';

    const pRole = [Role.Customer, Role.Executor].includes(profile.role);

    const goToEditProfile = () => history.push(ROUTES.EDIT_PROFILE.path);

    const avgRating = round(+profile.avgRating, 1);

    const online = moment().diff(profile.onlineTime) < 15 * 60 * 1000;

    const tabs = [
        {
            label: 'Выполненые заказы',
            key: 'orders',
            children: (<Orders
                data={ orders || [] }
                loading={ ordersLoading }
                pagination={ ordersPagination }
                onChange={ onChangeOrdersPagination }
            />),
        },
        {
            label: 'Отзывы',
            key: 'reviews',
            children: (<Reviews
                data={ reviews }
                loading={ reviewsLoading }
                pagination={ reviewsPagination }
                onChange={ onChangeReviewsPagination }
            />),
        },
    ];

    return (
        <div className={ styles.cart }>
            <div className={ styles.profile }>
                <div className={ styles.profile__ava }>
                    <Avatar type='round' width={ 200 } src={ profile.avatar } />
                    <div className={ styles.profile__status }>
                        { online ? (<div className={ styles.status_on }>В сети</div>) : <div>заходил(а) { moment(profile.onlineTime).fromNow() }</div> }
                    </div>
                </div>
                <div className={ styles.profile__content }>
                    <div className={ styles.profile__contact }>
                        <div className={ styles.profile__name }>
                            { getFio(profile.lastName, profile.firstName) }
                        </div>
                        <div className={ styles.profile__valid }>
                            <Tooltip placement="bottom" title={ profile.error?.company ? uActiveDisableTitle : uActiveTitle }>
                                <img src={ profile.error?.company ? uActiveDisable : uActive } alt="" />
                            </Tooltip>
                        </div>
                    </div>
                    <div className={ styles.info }>
                        <span className={ styles.info_item }>
                            <b>Роль</b>: { RoleText[profile.role] }
                        </span>
                        <span className={ styles.info_item }>
                            <b>На сайте</b>: c { moment(profile.created).format(DATE_FORMAT.DATE) }
                        </span>
                    </div>
                    { profile.aboutMe && (
                        <div className={ styles.profile__desc }>
                            { profile.aboutMe }
                        </div>
                    ) }
                </div>
                { auth && (
                    <div className={ styles.setting }>
                        <div className={ styles.setting__item }>
                            <Tooltip placement="bottom" title="Редактировать профиль">
                                <IconEdit onClick={ goToEditProfile } />
                            </Tooltip>
                        </div>
                    </div>
                ) }
            </div>
            { pRole && (
                <div className={ styles.process }>
                    { ordersPagination && (
                        <div className={ styles.process__item }>
                            <div className={ cn(styles.process__img, styles.color1) }>
                                <IconBriefcase />
                            </div>
                            <div className={ styles.process__body }>
                                <div className={ styles.process__count }>{ ordersPagination?.total }</div>
                                <div className={ styles.process__title }>Выполненных заказов</div>
                            </div>
                        </div>
                    ) }
                    { reviewsPagination && (
                        <div className={ styles.process__item }>
                            <div className={ cn(styles.process__img, styles.color2) }>
                                <IconFileCertificate />
                            </div>
                            <div className={ styles.process__body }>
                                <div className={ styles.process__count }>{ reviewsPagination.total }</div>
                                <div className={ styles.process__title }>Кол-во отзывов</div>
                            </div>
                        </div>
                    ) }
                    <div className={ styles.process__item }>
                        <div className={ cn(styles.process__img, styles.color3) }>
                            <IconWaveSawTool />
                        </div>
                        <div className={ styles.process__body }>
                            <div className={ styles.process__count }>{ avgRating } / 5</div>
                            <div className={ styles.process__title }>Общий рейтинг</div>
                        </div>
                    </div>
                </div>
            ) }
            <div className={ styles.details }>
                <div className={ styles.title }>Информация</div>
                <Tabs defaultActiveKey="orders" items={ tabs } />
            </div>
        </div>
    );
});
