import { put, call } from 'redux-saga/effects';
import {
    signUp,
    signUpError,
    signUpFinish,
} from 'ducks/app/actions';

import { fetchers } from 'api';

export function* signUpRequest({ values, cb }: ReturnType<typeof signUp>) {
    try {
        yield call(fetchers.signUp, values);
        yield put(signUpFinish());
        if (cb) {
            cb();
        }
    } catch (error) {
        yield put(signUpError());
    }
}
