import { put, call, select } from 'redux-saga/effects';

import {
    getOrders,
    getOrdersError,
    getOrdersFinish,
} from 'ducks/orders/actions';

import {
    selectOrdersPagination,
    selectOrdersSort,
} from 'ducks/orders/selectors';

import { fetchers } from 'api';
import { Order } from 'types/order';

export function* getOrdersRequest({ payload }: ReturnType<typeof getOrders>) {
    try {
        const { pageSize } = yield select(selectOrdersPagination);
        const sort = yield select(selectOrdersSort);
        const response = yield call(fetchers.getOrders, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        yield put(getOrdersFinish({
            data: response.data.map((item: Order) => ({
                ...item,
                orderCategories: item.orderCategories.map((category: any) => category.id),
            })),
            pagination: {
                total: response.pagination.total,
                page: payload.pagination.page,
            },
            sort: payload.sort || sort,
        }));
    } catch (error) {
        yield put(getOrdersError());
    }
}
