import axios from 'axios';
import * as Api from 'types/api';

import { getBody, ContentTypes } from 'form-helpers/utils';

export const fetchers = {
    signUp: (data: Api.SignUpPayload) => axios.post('/api/accounts/register', data),
    signIn: (data: Api.SignInPayload) => axios.post('/api/accounts/authenticate', data),
    refreshToken: () => axios.post('/api/accounts/refresh-token'),
    passwordRecovery: (data: Api.PasswordRecoveryPayload) => axios.post('/api/accounts/forgot-password', data),
    verifyEmail: (data: Api.VerifyEmailPayload) => axios.post('/api/accounts/verify-email', data),
    resetPassword: (data: Api.ResetPasswordPayload) => axios.post('/api/accounts/reset-password', data),
    exit: (data: any) => axios.post('/api/accounts/exit', data),

    // ПОЛЬЗОВАТЕЛИ
    sendСonfirmation: (data: Api.SendСonfirmationPayload) => axios.post('/api/accounts/send-confirmation', data),
    getUser: ({ userId }: Api.GetUserPayload) => axios.get(`/api/accounts/${userId}`),
    getProfilePrice: () => axios.get('/api/accounts/getPrice'),
    getUsers: (params: Api.GetUsersPayload) => axios.get('/api/accounts',
        {
            params: {
                ...params.pagination,
                ...params.sort,
            },
        }),
    saveUser: ({ id, ...restProps }: Api.SaveUserPayload) => axios.put(`/api/accounts/${id}`, getBody(
        {
            ...restProps,
        },
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    saveUserSocialNetwork: (data: Api.SaveUserPayload) => axios.put('/api/accounts/social-network', data),
    removeSocialNetwork: (data: Api.RemoveSocialNerworkPayload) => axios.delete('/api/accounts/social-network', { data }),

    blockedUser: ({ userId, access }: Api.BlockedUserPayload) => axios.put(`/api/accounts/${userId}`, { access }),

    // ЗАКАЗЫ
    getOrdersBidding: (params: Api.GetOrderGuestPayload) => axios.get('/api/order/tenderswon',
        {
            params: {
                ...params.pagination,
                ...params.sort,
            },
        }),
    getOrdersGuest: (params: Api.GetOrderGuestPayload) => axios.get('/api/order/guestOrders',
        {
            params: {
                ...params.pagination,
                ...params.sort,
            },
        }),
    getOrders: (params: Api.GetOrdersPayload) => axios.get('/api/order',
        {
            params: {
                executorId: params.executorId,
                accountId: params.accountId,
                status: params.status,
                orderCategories: params.orderCategories,
                addTender: params.addTender,
                created: params.created,
                ...params.pagination,
                ...params.sort,
            },
        }),
    createOrder: (props: Api.CreateOrderPayload) => axios.post('/api/order', getBody(
        props,
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    getTempOrderGuest: (orderId: any) => axios.get(`/api/order/temporary/${orderId}`),
    createTempOrderGuest: (props: Api.CreateTempOrderPayload) => axios.post('/api/order/temporary', getBody(
        props,
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),

    editOrder: ({ id, ...restProps }: Api.CreateOrderPayload) => axios.put(`/api/order/${id}`, getBody(
        restProps,
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),

    getOrder: ({ orderId }: Api.GetOrderPayload) => axios.get(`/api/order/${orderId}`),
    changeOrderStatus: (data: Api.ChangeOrderStatusPayload) => axios.post('/api/order/status', data),

    // КАТЕГОРИИ
    getCategories: () => axios.get('/api/order/categories'),
    getCategoriesSubscribed: ({ userId }: Api.GetCategoriesSubscribedPayload) => axios.get(`/api/order/categories/${userId}`),
    subscribeOnCategory: (data: Api.SubscribeOnCategoryPayload) => axios.post('/api/order/categories', data),

    // ОТЗЫВЫ
    getReviews: (params: Api.GetReviewsPayload) => axios.get(`/api/reviews/${params.id}`,
        {
            params: {
                ...params.pagination,
                ...params.sort,
            },
        }),

    sendReview: (data: Api.SendReviewPayload) => axios.post('/api/reviews/', data),

    // КОММЕНТАРИИ
    getComments: ({ orderId, ...restProps }: Api.GetCommentsPayload) => axios.get(`/api/order/comments/${orderId}`,
        {
            params: {
                ...restProps.pagination,
                ...restProps.sort,
            },
        }),

    sendComment: ({ orderId, ...restProps }: Api.SendCommentPayload) => axios.post(`/api/order/comments/${orderId}`, restProps),
    editComment: ({ commentId, ...restProps }: Api.EditCommentPayload) => axios.put(`/api/order/comments/${commentId}`, restProps),
    deleteComment: (commentId: number) => axios.delete(`/api/order/comments/${commentId}`),

    // уведомления
    getNotificationCount: () => axios.get('/api/notification/count'),

    //  предложения к заказу
    getTendersOffer: (orderId: number) => axios.get(`/api/order/tenders/offer/${orderId}`),
    getTenders: ({ orderId, ...restProps }: Api.GetTendersPayload) => axios.get(`/api/order/tenders/${orderId}`,
        {
            params: {
                ...restProps.pagination,
                ...restProps.sort,
            },
        }),
    revokeTender: (data: Api.RevokeTenderPayload) => axios.put('/api/order/tenders/revoke', data),
    sendTender: ({ orderId, ...restProps }: Api.SendTenderPayload) => axios.post(`/api/order/tenders/${orderId}`, restProps),
    acceptTender: (data: Api.AcceptTenderPayload) => axios.post('/api/order/tenders', data),
    seeTender: ({ tenderId, ...restProps }: Api.EditTenderPayload) => axios.put(`/api/order/tenders/see/${tenderId}`, restProps),
    editTender: ({ id, ...restProps }: Api.EditTendersPayload) => axios.put(`/api/order/tenders/${id}`, restProps),

    // компания
    searchCompany: (params: Api.GetCompanyPayload) => axios.get('/api/company', { params }),
    getCompanyInfoByInn: (params: Api.GetCompanyInfoByInnPayload) => axios.get('/api/companyInn', { params }),
    saveCompany: (userId: number | string, data: Api.SaveCompanyPayload) => axios.post(`/api/company/${userId}`, getBody(
        {
            ...data,
        },
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    saveCompanyForManager: (userId: number | string, data: Api.SaveCompanyForManagerPayload) => axios.put(`/api/company/${userId}`, data),
    getCompany: (userId: number | string) => axios.get(`/api/company/${userId}`),

    // модерация
    getCompaniesForModeration: (params: Api.GetCompaniesForModerationPayload) => axios.get('/api/company/manager/notconfirmed',
        {
            params: {
                ...params.pagination,
                ...params.sort,
            },
        }),
    getPermission: () => axios.get('/api/info/admin'),
    savePermission: (data: any) => axios.post('/api/info/admin', data),

    getDialogAll: ({ ...restProps }: Api.GetDialogAllPayload) => axios.get('/api/dialog', {
        params: {
            ...restProps.pagination,
            ...restProps.sort,
        },
    }),
    getDialog: ({ orderId }: Api.GetDialogPayload) => axios.get('/api/dialog', { params: { orderId } }),
    getDialogByHash: ({ hash }: Api.GetDialogPayload) => axios.get(`/api/dialog/${hash}`),
    setDialogNotify: (data: Api.GetDialogNottifPayload) => axios.post('/api/dialog/notification', data),
    sendDialogMessage: (params: any, data: any) => axios.post('/api/dialog/message', data, { params }),
    getDialogMessages: (resp: Api.GetDiagloMessagesPayload) => axios.get('/api/dialog/message', {
        params: {
            orderId: resp.orderId,
            hash: resp.hash,
            dialogId: resp.dialogId,
            ...resp.pagination,
            ...resp.sort,
        },
    }),
    editDialogMessage: ({ id, ...restProps }: Api.EditDiagloMessagePayload) => axios.put('/api/dialog/message', restProps, {
        params: { id },
    }),
    readDialogMessage: (id: number) => axios.put('/api/dialog/message/read', { id }),
    deleteDialogMessage: (id: number) => axios.delete('/api/dialog/message', { params: { id } }),

    getNotification: (params: Api.GetNotificationsPayload) => axios.get('/api/notification', {
        params: {
            ...params.pagination,
            ...params.sort,
        },
    }),
    setNotification: ({ id, ...restProps }: Api.SetNotificationPayload) => axios.put('/api/notification', restProps, {
        params: { id },
    }),
    loadNotification: (params: Api.GetNotificationsPayload) => axios.get('/api/notification', {
        params: {
            ...params.pagination,
            ...params.sort,
        },
    }),

    anonymousSubscription: (data: Api.AnonymousSubscriptionPayload) => axios.post('/api/accounts/anonymous-subscription', data),

    loadSubscription: () => axios.put('/api/accounts/subscription'),
    setSubscription: (payload: Api.SetSubscriptionPayload) => axios.put('/api/accounts/subscription', payload),

    getFeedbacks: (params: Api.GetFeedbackPayload) => axios.get('/api/feedback', {
        params: {
            ...params.pagination,
            ...params.sort,
        },
    }),
    sendFeedback: (payload: Api.SendFeedbackPayload) => axios.post('/api/feedback', payload),
    editFeedback: ({ id, ...restProps }: Api.EditFeedbackPayload) => axios.put(`/api/feedback/${id}`, restProps),
    // счета
    getScores: ({
        userId, typePayment, refundStatus, ...restProps
    }: Api.GetScoresPayload) => axios.get('/api/score',
        {
            params: {
                userId,
                typePayment,
                refundStatus,
                ...restProps.pagination,
                ...restProps.sort,
            },
        }),
    getScore: ({ id }: Api.GetScorePayload) => axios.get(`/api/score/detail/${id}`),
    createScore: (data: Api.CreateScorePayload) => axios.post('/api/score', data),
    refundScore: (data: Api.RefundScorePayload) => axios.post('/api/score/refund', data),
    getBlogById: ({ id }: Api.GetBlogByIdPayload) => axios.get(`/api/blog/${id}`),
    createBlog: (props: Api.BlogCreatePayload) => axios.post('/api/blog', getBody(
        props,
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    editBlog: (props: Api.BlogCreatePayload) => axios.put('/api/blog', getBody(
        props,
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    getBlogAll: (params: Api.GetBlogAllPayload) => axios.get('/api/blog',
        {
            params: {
                ...params.pagination,
                ...params.sort,
            },
        }),
};
