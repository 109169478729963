export enum ACCESS {
    USER_VIEWS = 'USER_VIEWS',
    USER_BLOCKING = 'USER_BLOCKING',
    ADMIN_MENU = 'ADMIN_MENU',
    // модерация
    EXPERT_VIEW = 'EXPERT_VIEW',
    EXPERT_UPDATE = 'EXPERT_UPDATE',
    PERMISION = 'PERMISION',
    COMPANY_VIEWS_EDIT_MY = 'COMPANY_VIEWS_EDIT_MY',
    SUBSCRIPTION_ACCESS = 'SUBSCRIPTION_ACCESS',
    SCORE_VIEWS = 'SCORE_VIEWS',
}
