import {
    all,
    takeEvery,
} from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { AppActionTypes } from 'ducks/app/action-types';
import { UserActionTypes } from 'ducks/user/action-types';
import { OrderActionTypes } from 'ducks/orders/action-types';
import { ReviewsActionTypes } from 'ducks/reviews/action-types';
import { CommentsActionTypes } from 'ducks/comments/action-types';
import { TendersActionTypes } from 'ducks/tenders/action-types';
import { CompanyActionTypes } from 'ducks/company/action-types';
import { ModerationActionTypes } from 'ducks/moderation/action-types';
import { PermissionActionTypes } from 'ducks/permission/action-types';
import { DialogActionTypes } from 'ducks/dialog/action-types';
import { NotificationActionTypes } from 'ducks/notification/action-types';
import { SubscriptionActionTypes } from 'ducks/subscription/action-types';
import { FeedbacksActionTypes } from 'ducks/feedback/action-types';
import { ScoresActionTypes } from 'ducks/score/action-types';

import { BlogActionTypes } from 'ducks/blog/action-types';
import { signUpRequest } from './app/sign-up';
import { signInRequest } from './app/sign-in';
import { rigisterAutoSignInRequest } from './app/register-auto-sign-in';
import { passwordRecoveryRequest } from './app/password-recovery';

import { getUserRequest } from './user/get-user';
import { getUserPriceRequest } from './user/get-price';
import { getUsersRequest } from './user/get-users';
import { saveUserRequest } from './user/save-user';
import { saveUserSocialNetworkRequest } from './user/save-user-social-network';
import { removeSocialNetworkRequest } from './user/remove-social-network';
import { getProfileRequest } from './user/get-profile';
import { blockedUserRequest } from './user/blocked-user';
import { sendConfirmationRequest } from './user/send-confirmation';

import { getCategoriesRequest } from './categories/get-categories';
import { subscribeOnCategoryRequest } from './categories/subscribe-on-categories';

import { getOrdersBiddingRequest } from './orders/get-orders-bidding';
import { getOrdersGuestRequest } from './orders/get-orders-guest';
import { getOrdersRequest } from './orders/get-orders';
import { createOrderRequest } from './orders/create-order';
import { createTempOrderGuestRequest } from './orders/create-order-guest';
import { getTempOrderGuestRequest } from './orders/get-temp-order-guest';
import { getOrderRequest } from './orders/get-order';
import { editOrderRequest } from './orders/edit-order';
import { changeOrderStatusRequest } from './orders/change-status';

import { getCommentsRequest } from './comments/get-comments';
import { sendCommentRequest } from './comments/send-comment';
import { editCommentRequest } from './comments/edit-comment';
import { deleteCommentRequest } from './comments/delete-comment';

import { getReviewsRequest } from './reviews/get-reviews';
import { sendReviewRequest } from './reviews/send-review';

import { getTendersRequest } from './tenders/get-tenders';
import { getTendersOfferRequest } from './tenders/get-offer';
import { sendTenderRequest } from './tenders/send-tender';
import { acceptTenderRequest } from './tenders/accept-tender';
import { seeTenderRequest } from './tenders/see-tender';
import { editTenderRequest } from './tenders/edit-tender';
import { revokeTenderRequest } from './tenders/revoke-tender';

import { saveCompanyRequest } from './company/save-company';
import { getCompanyRequest } from './company/get-company';
import { searchCompanyRequest } from './company/search-company';

import { getCompaniesForModeration } from './moderation/get-companies-for-moderation';
import { getPermission } from './permission/get-permission';
import { editPermission } from './permission/edit-permission';

import { getDialogRequest } from './dialog/get-dialog';
import { getDialogByHashRequest } from './dialog/get-dialog-by-hash';
import { getDialogAllRequest } from './dialog/get-dialog-all';
import { setDialogNotifyRequest } from './dialog/set-dialog-notify';
import { sendDialogMessageRequest } from './dialog/send-dialog-message';
import { getMessagesRequest } from './dialog/get-dialog-message';
import { editMessageRequest } from './dialog/edit-message';
import { deleteMessageRequest } from './dialog/delete-message';
import { readDialogMessageRequest } from './dialog/read-dialog-message';

import { getNotificationRequest } from './notification/get-notification';
import { setNotificationRequest } from './notification/set-notification';
import { loadNotificationRequest } from './notification/load-notification';

import { loadSubscriptionRequest } from './subscription/load-subscription';
import { setSubscriptionRequest } from './subscription/set-subscription';

import { getFeedbacksRequest } from './feedback/get-feedback';
import { sendFeedbackRequest } from './feedback/send-feedback';
import { editFeedbackRequest } from './feedback/edit-feedback';

import { getScoresRequest } from './score/get-scores';
import { getScoreRequest } from './score/get-score';
import { createScoreRequest } from './score/create-score';
import { refundScoreRequest } from './score/refund-score';

import { createBlogRequest } from './blog/create-blog';
import { getsBlogAllRequest } from './blog/gets-blog';
import { getsBlogByIdRequest } from './blog/get-by-id-blog';
import { editBlogRequest } from './blog/edit-blog';

export default function* sagas() {
    yield all([
        takeEvery(UserActionTypes.GET_PROFILE_START, getProfileRequest),
        takeEvery(UserActionTypes.GET_PRICE_START, getUserPriceRequest),
        takeEvery(UserActionTypes.GET_USERS_START, getUsersRequest),
        takeEvery(UserActionTypes.GET_USER_START, getUserRequest),
        takeEvery(UserActionTypes.SAVE_USER_START, saveUserRequest),
        takeEvery(UserActionTypes.SAVE_USER_SOCIAL_NETWORK_START, saveUserSocialNetworkRequest),
        takeEvery(UserActionTypes.REMOVE_SOCIAL_NETWORK_START, removeSocialNetworkRequest),
        takeEvery(UserActionTypes.BLOCKED_USER_START, blockedUserRequest),
        takeEvery(UserActionTypes.SEND_СONFIRMATION_START, sendConfirmationRequest),
        takeEvery(AppActionTypes.REGISTER_AUTO_SIGN_IN_START, rigisterAutoSignInRequest),
        takeEvery(AppActionTypes.SIGN_UP_START, signUpRequest),
        takeEvery(AppActionTypes.SIGN_IN_START, signInRequest),
        takeEvery(AppActionTypes.PASSWORD_RECOVERY_START, passwordRecoveryRequest),

        takeEvery(OrderActionTypes.GET_ORDERS_GUEST_START, getOrdersGuestRequest),
        takeEvery(OrderActionTypes.GET_ORDERS_BIDDING_START, getOrdersBiddingRequest),
        takeEvery(OrderActionTypes.GET_ORDERS_START, getOrdersRequest),
        takeEvery(OrderActionTypes.GET_ORDER_START, getOrderRequest),
        takeEvery(OrderActionTypes.CREATE_ORDER_START, createOrderRequest),
        takeEvery(OrderActionTypes.GET_TEMP_ORDER_GUEST_START, getTempOrderGuestRequest),
        takeEvery(OrderActionTypes.CREATE_TEMP_ORDER_GUEST_START, createTempOrderGuestRequest),
        takeEvery(OrderActionTypes.EDIT_ORDER_START, editOrderRequest),
        takeEvery(OrderActionTypes.CHANGE_STATUS_START, changeOrderStatusRequest),

        takeEvery(OrderActionTypes.GET_CATEGORIES_START, getCategoriesRequest),
        takeEvery(OrderActionTypes.SUBSCRIBE_ON_CATEGORY_START, subscribeOnCategoryRequest),

        takeEvery(CommentsActionTypes.GET_COMMENTS_START, getCommentsRequest),
        takeEvery(CommentsActionTypes.SEND_COMMENT_START, sendCommentRequest),
        takeEvery(CommentsActionTypes.EDIT_COMMENT_START, editCommentRequest),
        takeEvery(CommentsActionTypes.DELETE_COMMENT_START, deleteCommentRequest),

        takeEvery(ReviewsActionTypes.GET_REVIEWS_START, getReviewsRequest),
        takeEvery(ReviewsActionTypes.SEND_REVIEW_START, sendReviewRequest),

        takeEvery(TendersActionTypes.REVOKE_TENDER, revokeTenderRequest),
        takeEvery(TendersActionTypes.GET_TENDERS_START, getTendersRequest),
        takeEvery(TendersActionTypes.OFFER_TENDER_START, getTendersOfferRequest),
        takeEvery(TendersActionTypes.SEND_TENDER_START, sendTenderRequest),
        takeEvery(TendersActionTypes.ACCEPT_TENDER_START, acceptTenderRequest),
        takeEvery(TendersActionTypes.SEE_TENDER_START, seeTenderRequest),
        takeEvery(TendersActionTypes.EDIT_TENDER, editTenderRequest),

        takeEvery(CompanyActionTypes.SAVE_COMPANY_START, saveCompanyRequest),
        takeEvery(CompanyActionTypes.GET_COMPANY_START, getCompanyRequest),
        takeEvery(CompanyActionTypes.SEARCH_COMPANY_START, searchCompanyRequest),

        takeEvery(ModerationActionTypes.GET_COMPANY_FOR_MODERATION_START, getCompaniesForModeration),

        takeEvery(LOCATION_CHANGE, loadNotificationRequest),
        takeEvery(PermissionActionTypes.GET_PERMISSION_START, getPermission),
        takeEvery(PermissionActionTypes.UPDATE_PERMISSION_START, editPermission),

        takeEvery(DialogActionTypes.GET_DIALOG_START, getDialogRequest),
        takeEvery(DialogActionTypes.GET_DIALOG_BY_HASH_START, getDialogByHashRequest),
        takeEvery(DialogActionTypes.GET_DIALOG_ALL_START, getDialogAllRequest),
        takeEvery(DialogActionTypes.TOGGLE_SELECT_START, setDialogNotifyRequest),
        takeEvery(DialogActionTypes.SEND_DIALOG_MESSAGE_START, sendDialogMessageRequest),
        takeEvery(DialogActionTypes.GET_MESSAGES_START, getMessagesRequest),
        takeEvery(DialogActionTypes.EDIT_MESSAGES_START, editMessageRequest),
        takeEvery(DialogActionTypes.DELETE_MESSAGES_START, deleteMessageRequest),
        takeEvery(DialogActionTypes.READ_DIALOG_MESSAGE, readDialogMessageRequest),

        takeEvery(NotificationActionTypes.GET_NOTIFICATION_START, getNotificationRequest),
        takeEvery(NotificationActionTypes.SET_NOTIFICATION_START, setNotificationRequest),

        takeEvery(SubscriptionActionTypes.LOAD_SUBSCRIPTION_START, loadSubscriptionRequest),
        takeEvery(SubscriptionActionTypes.SET_SUBSCRIPTION_START, setSubscriptionRequest),

        takeEvery(FeedbacksActionTypes.GET_FEEDBACK_START, getFeedbacksRequest),
        takeEvery(FeedbacksActionTypes.SEND_FEEDBACK_START, sendFeedbackRequest),
        takeEvery(FeedbacksActionTypes.EDIT_FEEDBACK_START, editFeedbackRequest),

        takeEvery(ScoresActionTypes.GET_SCORES_START, getScoresRequest),
        takeEvery(ScoresActionTypes.GET_SCORE_START, getScoreRequest),
        takeEvery(ScoresActionTypes.CREATE_SCORE_START, createScoreRequest),
        takeEvery(ScoresActionTypes.REFUND_SCORE_START, refundScoreRequest),

        takeEvery(BlogActionTypes.CREATE_BLOG_START, createBlogRequest),
        takeEvery(BlogActionTypes.GET_BLOG_START, getsBlogAllRequest),
        takeEvery(BlogActionTypes.GET_BLOG_BY_ID_START, getsBlogByIdRequest),
        takeEvery(BlogActionTypes.EDIT_BLOG, editBlogRequest),
    ]);
}
