import React from 'react';
import { FrownTwoTone } from '@ant-design/icons';
import { history } from 'App';
import styles from './styles.module.scss';

export const PageNotFound = () => {
    const goBack = () => {
        history.go(-1);
    };

    return (
        <div className={ styles.pageNotFound }>
            <FrownTwoTone twoToneColor="#e64c4c" style={ { fontSize: 70 } } />
            <div className={ styles.text }>Страница не найдена <br /> или у вас нет доступа к ней</div>
            <button
                type="button"
                className={ styles.btn }
                onClick={ goBack }
            >
                Вернуться назад
            </button>
        </div>
    );
};
