/* eslint-disable no-console */
import { put, call } from 'redux-saga/effects';

import {
    readMessage,
    readMessageFinish,
} from 'ducks/dialog/actions';

import { fetchers } from 'api';

export function* readDialogMessageRequest({ id }: ReturnType<typeof readMessage>) {
    try {
        yield call(fetchers.readDialogMessage, id);

        yield put(readMessageFinish(id));
    } catch (error) {
        console.log('Ошибка прочтения сообщений');
    }
}
