import React, { useEffect } from 'react';
import { BotHeader } from 'components/bot';
import { useTelegram } from 'components/ui/hooks/useTelegram';
import {
    Route,
    Switch,
} from 'react-router';
import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile, selectUserLoading } from 'ducks/user/selectors';
import { getCategories } from 'ducks/orders/actions';
import { getProfile } from 'ducks/user/actions';
import { Order } from './order';
import { ROUTES } from './routes';
import { Tender } from './tender';
import { PageNotFound } from './pageNotFound';
import { Orders } from './orders';
import { PrivateRoute } from './private-router';
import { Auth } from './auth';
import { CreateTender } from './tender/create';
import styles from './styles.module.scss';
import './tg-styles.scss';

export const BotPage = () => {
    const { tg, theme } = useTelegram();
    const put = useDispatch();
    const profile = useSelector(selectProfile);
    const loading = useSelector(selectUserLoading);
    const token = localStorage.getItem('token');

    const loadingProfile = loading.profile;

    useEffect(() => {
        tg.onEvent('themeChanged', () => {
            window.location.reload();
        });

        put(getCategories());
        document.body.classList.add(`theme-${theme}`);
        if (token) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { id } = jwt_decode(token);

            if (!profile) {
                put(getProfile({ userId: id }));
            }
        }
    }, []);

    // ngrok http 3000 --host-header="localhost:3000"

    return (
        <div className={ styles.app }>
            <BotHeader />
            <Switch>
                <Route { ...ROUTES.MAIN } component={ Orders } />
                <Route { ...ROUTES.AUTH } component={ Auth } />
                <PrivateRoute { ...ROUTES.ORDER } user={ profile } loading={ loadingProfile } component={ Order } />
                <PrivateRoute { ...ROUTES.TENDER } user={ profile } loading={ loadingProfile } component={ Tender } />
                <PrivateRoute { ...ROUTES.ADD_TENDER } user={ profile } loading={ loadingProfile } component={ CreateTender } />
                <Route component={ PageNotFound } />
            </Switch>
        </div>
    );
};
