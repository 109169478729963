import React from 'react';
import { ErrorMessage } from 'components/ui/error-message';

export const getError = (
    errors: { [key: string]: any },
    touched: { [key: string]: any },
    isSubmitTry: boolean,
    path: string,
) => {
    if (errors[path] && (touched[path] || isSubmitTry)) {
        return <ErrorMessage text={ errors[path] } />;
    }

    return null;
};

export const getErrorStatus = (error: boolean) => {
    if (error) return 'error';

    return undefined;
};
