import { InferValueTypes } from 'types/common';
import { OrderBidding } from 'types/order';
import * as actions from '../actions';
import { OrderActionTypes } from '../action-types';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type StateType = {
    loading: boolean;
    hide: boolean;
    list: OrderBidding[];
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: StateType = {
    loading: false,
    hide: false,
    list: [],
    pagination: {
        pageSize: 6,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'created',
        sortOrder: 'desc',
    },
};

export function ordersBiddingReducer(state = initialState, action: ActionTypes): StateType {
    switch (action.type) {
        case OrderActionTypes.GET_ORDERS_BIDDING_START:
            return {
                ...state,
                loading: true,
            };
        case OrderActionTypes.GET_ORDERS_BIDDING_ERROR:
            return {
                ...state,
                loading: false,
            };

        case OrderActionTypes.GET_ORDERS_BIDDING_FINISH:
            return {
                ...state,
                loading: false,
                list: [...state.list, ...action.response.data],
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case OrderActionTypes.CLEAR_ORDERS_BIDDING:
            return {
                ...state,
                ...initialState,
            };

        case OrderActionTypes.HIDE_BTN_ORDERS_BIDDING:
            return {
                ...state,
                loading: false,
                hide: true,
            };

        default: return state;
    }
}
