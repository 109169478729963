import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TRoute } from 'types/route';
import {
    Link, Route, Switch, useLocation,
} from 'react-router-dom';
import cn from 'classnames';
import { ACCESS } from 'constants/access';
import { selectProfile } from 'ducks/user/selectors';

import cl from './styles.module.scss';
import { NotFound } from '../not-found';

type Props = {
    menu: TRoute;
    title: string;
};

export const LayoutLeftMenu = ({
    menu: ROUTES, title,
}: Props) => {
    const user = useSelector(selectProfile);
    const location = useLocation();

    const routes = useMemo(() => {
        const ROUTES_KEYS = Object.keys(ROUTES);
        const routeKey = ROUTES_KEYS.find((key) => ROUTES[key].path === location.pathname);

        const route = ROUTES_KEYS.map((key: string) => {
            if (ROUTES[key].showInMenu && ROUTES[key].private && user && (ROUTES[key].access ? user?.rights.includes(ROUTES[key].access as ACCESS) : true)) {
                return (<li key={ key }><Link className={ cn({ [cl.menu__active]: key === routeKey }) } to={ ROUTES[key].path }><span>{ ROUTES[key].title }</span></Link></li>);
            }

            if (ROUTES[key].showInMenu && !ROUTES[key].private) {
                return (<li key={ key }> <Link className={ cn({ [cl.menu__active]: key === routeKey }) } to={ ROUTES[key].path }><span>{ ROUTES[key].title }</span></Link></li>);
            }

            return null;
        });

        return route;
    }, [user, location]);

    const switchRoute = useMemo(() => {
        const ROUTES_KEYS = Object.keys(ROUTES);

        const route = ROUTES_KEYS.map((key: string) => {
            if (ROUTES[key].component && ROUTES[key].private && user && (ROUTES[key].access ? user?.rights.includes(ROUTES[key].access as ACCESS) : true)) {
                return (
                    <Route
                        key={ ROUTES[key].path }
                        path={ ROUTES[key].path }
                        exact={ ROUTES[key].exact }
                        component={ ROUTES[key].component }
                    />
                );
            }

            if (ROUTES[key].component && !ROUTES[key].private) {
                return (
                    <Route
                        key={ ROUTES[key].path }
                        path={ ROUTES[key].path }
                        exact={ ROUTES[key].exact }
                        component={ ROUTES[key].component }
                    />
                );
            }

            return null;
        });

        return route;
    }, [user, location]);

    return (
        <div className={ cl.wrap }>
            <div className={ cl.left }>
                { title && <div className={ cl.menu__title }>{ title }</div> }
                <ul className={ cl.menu }>
                    { routes }
                </ul>
            </div>
            <div className={ cl.content }>
                <Switch>
                    { switchRoute }
                    <Route component={ NotFound } />
                </Switch>
            </div>
        </div>
    );
};
