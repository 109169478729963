export const DialogActionTypes = {
    GET_DIALOG_START: 'GET_DIALOG_START',
    GET_DIALOG_ERROR: 'GET_DIALOG_ERROR',
    GET_DIALOG_FINISH: 'GET_DIALOG_FINISH',

    GET_DIALOG_ALL_START: 'GET_DIALOG_ALL_START',
    GET_DIALOG_ALL_ERROR: 'GET_DIALOG_ALL_ERROR',
    GET_DIALOG_ALL_FINISH: 'GET_DIALOG_ALL_FINISH',

    CLEAR_DIALOG_ALL: 'CLEAR_DIALOG_ALL',

    GET_DIALOG_BY_HASH_START: 'GET_DIALOG_BY_HASH_START',
    GET_DIALOG_BY_HASH_ERROR: 'GET_DIALOG_BY_HASH_ERROR',
    GET_DIALOG_BY_HASH_FINISH: 'GET_DIALOG_BY_HASH_FINISH',

    TOGGLE_SELECT_START: 'TOGGLE_SELECT_START',
    TOGGLE_SELECT_FINISH: 'TOGGLE_SELECT_FINISH',

    SEND_DIALOG_MESSAGE_START: 'SEND_DIALOG_MESSAGE_START',
    SEND_DIALOG_MESSAGE_ERROR: 'SEND_DIALOG_MESSAGE_ERROR',
    SEND_DIALOG_MESSAGE_FINISH: 'SEND_DIALOG_MESSAGE_FINISH',

    CLEAR_DIALOG: 'CLEAR_DIALOG',

    GET_MESSAGES_START: 'GET_MESSAGES_START',
    GET_MESSAGES_ERROR: 'GET_MESSAGES_ERROR',
    GET_MESSAGES_FINISH: 'GET_MESSAGES_FINISH',

    DELETE_MESSAGES_START: 'DELETE_MESSAGES_START',
    DELETE_MESSAGES_ERROR: 'DELETE_MESSAGES_ERROR',
    DELETE_MESSAGES_FINISH: 'DELETE_MESSAGES_FINISH',

    EDIT_MESSAGES_START: 'EDIT_MESSAGES_START',
    EDIT_MESSAGES_ERROR: 'EDIT_MESSAGES_ERROR',
    EDIT_MESSAGES_FINISH: 'EDIT_MESSAGES_FINISH',

    READ_DIALOG_MESSAGE: 'READ_DIALOG_MESSAGE',
    READ_DIALOG_MESSAGE_FINISH: 'READ_DIALOG_MESSAGE_FINISH',

    READ_DIALOG_MESSAGE_ALL: 'READ_DIALOG_MESSAGE_ALL',

    CLEAR_DIALOG_MESSAGES: 'CLEAR_DIALOG_MESSAGES',

    SOCKET_ADD_MESSAGE: 'SOCKET_ADD_MESSAGE',
    SOCKET_ADD_MESSAGE_FINISH: 'SOCKET_ADD_MESSAGE_FINISH',
    SOCKET_EDIT_MESSAGE: 'SOCKET_EDIT_MESSAGE',
    SOCKET_REMOVE_MESSAGE: 'SOCKET_REMOVE_MESSAGE',

} as const;
