import React, { memo, Suspense } from 'react';
import { Spin } from 'antd';
import { Footer } from 'components/footer';
import { Reviews } from 'components/reviews';
import { Subscribe } from 'components/subscribe';
import { Container } from 'components/ui/container';
import { EventInit } from 'components/ui/yandex-metrika';
import { LHeader } from 'components/header';
import { TitleLine } from 'components/ui/title-line';
import ColorBlock from 'components/colorBlock';
import imgContractor from 'assets/contractor/contractor.png';
import colorBlock1 from 'assets/contractor/colorBlock1.jpg';
import colorBlock2 from 'assets/contractor/colorBlock2.png';
import styles from './styles.module.scss';

const Banner = React.lazy(() => import('components/banner'));
const OrderGuest = React.lazy(() => import('components/order/guest'));
const OrderBidding = React.lazy(() => import('components/order/bidding'));

export const Сontractor = memo(() => (
    <Suspense fallback={ <Spin /> }>
        <LHeader />
        <Banner
            register={ true }
            title="Находите новых клиентов на сертификацию без затрат на рекламу"
            description="Принимайте заказы напрямую от заказчиков, предлагая свое ценовое предложение"
            img={ imgContractor }
        />
        <Container>
            <TitleLine
                title="О сервисе"
                sub="Кто мы такие - команда энтузиастов, проработавших более 15 лет на рынке сертификации, изучивших проблемы участников рынка <br><br>Основная цель сервиса – повышение эффективности процесса осуществления заказа услуг по сертификации."
            />
            <ColorBlock
                color="default"
                title="Отслеживайте новые заказы и предлагайте свои условия"
                desc="Отслживайте заявки на сертификацию от сотней заказчиков. Предложите свою цену, укажите планируемый срок оказания услуг и выберите удобный для вас порядок оплаты"
                img={ colorBlock1 }
            />
            <ColorBlock
                color="violet"
                title="Общайтесь с выбранным заказчиком напрямую"
                desc="Связываетесь с заказчиком, заключайте договор на выполнение заказа. Решайте все вопросы в онлайн чате"
                img={ colorBlock2 }
            />
        </Container>
        <Container>
            <TitleLine
                title="Кому подойдет СертТорг"
                extra={
                    <div className={ styles.textInformation }>
                        <p>
                            Наш сервис сертификации подойдет для органов по
                            сертификации и испытательным лабораториям, а также
                            посредникам, которые ищут клиентов
                        </p>
                        <p className={ styles.ulTitle }>
                            Исполнители (органы по сертификации и лаборатории)
                            смогут:
                        </p>
                        <ul>
                            <li>
                                получить возможность находить прямых Заказчиками
                                без затрат на рекламу
                            </li>
                            <li>
                                снизить зависимость от крупных посредников,
                                которые диктуют свои условия располагая объемам
                                заказов
                            </li>
                            <li>
                                повысить качество работ за счет улучшения
                                коммуникаций с прямым Заказчиком
                            </li>
                        </ul>
                        <p className={ styles.ulTitle }>Посредники смогут:</p>
                        <ul>
                            <li>
                                сократить бюджеты на рекламу, огромные отделы
                                продаж, кол-центры за счет прямого бесплатного
                                доступа к заказам
                            </li>
                            <li>
                                перевести конкуренцию из плоскости бюджетов на
                                рекламу в плоскость качества обслуживания
                                клиентов и рейтингов, это стоит дешевле и
                                способствует выстраиванию долгосрочных отношений
                                с Заказчиками и конечными Исполнителями
                            </li>
                        </ul>
                    </div>
                }
            />
        </Container>
        <Container>
            <OrderGuest />
        </Container>
        <Container>
            <OrderBidding />
        </Container>
        <Container>
            <Reviews />
        </Container>
        <Container>
            <Subscribe />
        </Container>
        <Footer />
        <EventInit />
    </Suspense>
));
