import { put, call } from 'redux-saga/effects';
import {
    delteMessage,
    delteMessageError,
    delteMessageFinish,
} from 'ducks/dialog/actions';

import { fetchers } from 'api';

export function* deleteMessageRequest(action: ReturnType<typeof delteMessage>) {
    try {
        yield call(fetchers.deleteDialogMessage, action.id);
        yield put(delteMessageFinish(action.id));
    } catch (error) {
        yield put(delteMessageError());
    }
}
