/* eslint-disable complexity */
import { InferValueTypes } from 'types/common';
import * as actions from '../actions';
import { OrderActionTypes } from '../action-types';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type StateType = {
    getOrders: boolean;
    getCategories: boolean;
    subscribeOnCategories: boolean;
    create: boolean;
    get: boolean;
    getOrderIdsWithNewComments: boolean;
    changeStatus: boolean;
};

export const initialState: StateType = {
    getOrders: false,
    getCategories: false,
    subscribeOnCategories: false,
    create: false,
    get: false,
    getOrderIdsWithNewComments: false,
    changeStatus: false,
};

export function loadingReducer(state = initialState, action: ActionTypes): StateType {
    switch (action.type) {
        case OrderActionTypes.GET_ORDERS_START:
            return {
                ...state,
                getOrders: true,
            };

        case OrderActionTypes.GET_ORDERS_FINISH:
        case OrderActionTypes.GET_ORDERS_ERROR:
            return {
                ...state,
                getOrders: false,
            };

        case OrderActionTypes.GET_CATEGORIES_START:
            return {
                ...state,
                getCategories: true,
            };

        case OrderActionTypes.GET_CATEGORIES_FINISH:
        case OrderActionTypes.GET_CATEGORIES_ERROR:
            return {
                ...state,
                getCategories: false,
            };

        case OrderActionTypes.SUBSCRIBE_ON_CATEGORY_START:
            return {
                ...state,
                subscribeOnCategories: true,
            };

        case OrderActionTypes.SUBSCRIBE_ON_CATEGORY_FINISH:
        case OrderActionTypes.SUBSCRIBE_ON_CATEGORY_ERROR:
            return {
                ...state,
                subscribeOnCategories: false,
            };

        case OrderActionTypes.CREATE_ORDER_START:
        case OrderActionTypes.EDIT_ORDER_START:
            return {
                ...state,
                create: true,
            };

        case OrderActionTypes.CREATE_ORDER_FINISH:
        case OrderActionTypes.CREATE_ORDER_ERROR:
        case OrderActionTypes.EDIT_ORDER_FINISH:
        case OrderActionTypes.EDIT_ORDER_ERROR:
            return {
                ...state,
                create: false,
            };

        case OrderActionTypes.GET_ORDER_START:
            return {
                ...state,
                get: true,
            };

        case OrderActionTypes.GET_ORDER_FINISH:
        case OrderActionTypes.GET_ORDER_ERROR:
            return {
                ...state,
                get: false,
            };

            return {
                ...state,
                getOrderIdsWithNewComments: false,
            };

        case OrderActionTypes.CHANGE_STATUS_START:
            return {
                ...state,
                changeStatus: true,
            };

        case OrderActionTypes.CHANGE_STATUS_FINISH:
        case OrderActionTypes.CHANGE_STATUS_ERROR:
            return {
                ...state,
                changeStatus: false,
            };

        default: return state;
    }
}
