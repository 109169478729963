import React from 'react';
import Spin, { SpinProps } from 'antd/lib/spin';

import styles from './styles.module.scss';

export const Loading = (props: SpinProps) => (
    <div className={ styles.loading }>
        <Spin { ...props } />
    </div>
);
