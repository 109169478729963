import { InferValueTypes } from 'types/common';
import { OrderGuest, TempOrderGuest } from 'types/order';
import * as actions from '../actions';
import { OrderActionTypes } from '../action-types';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type StateType = {
    loading: {
        get: boolean;
        create: boolean;
    };
    hide: boolean;
    list: OrderGuest[];
    order: TempOrderGuest | null;
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: StateType = {
    loading: {
        get: false,
        create: false,
    },
    hide: false,
    list: [
        {
            id: 1,
            name: 'трикотажные головные уборы (повязки, шапки), детские от 1г',
            message: 'Нужна декларация на 5лет, производитель РФ, производитель Респуьлика Татарстан. Шапки, повязки, косынки детские от 1г , десять цветов (или разноцветные с принтом), 7 составов',
            created: '2023-07-07T11:59:44.000Z',
            account: {
                id: 175,
                firstName: 'Светлана',
                lastName: 'Рахматуллина',
                avatar: '',
            },
            orderCategories: [],
            orderTenders: [],
            test: true,
        },
        {
            id: 2,
            name: 'Декларация соответствия ',
            message: 'Для производимой бытовой химии нужен документ, в котором будет указано, что продукция сделана на Арктической воде, поскольку, производство находится в г.Мурманск, который входит в Арктическую Зону...',
            created: '2023-07-05T10:28:31.000Z',
            account: {
                id: 173,
                firstName: 'Ирина',
                lastName: 'Суслова ',
                avatar: '',
            },
            orderCategories: [],
            orderTenders: [],
            test: true,
        },
        {
            id: 3,
            name: 'Сертификат соответствия на товары для маникюра',
            message: 'Вкладываю фото документа который мне необходим на мое имя и с моими названиями товаров...',
            created: '2023-07-04T10:28:31.000Z',
            account: {
                id: 173,
                firstName: 'Ирина',
                lastName: 'Суслова ',
                avatar: '',
            },
            orderCategories: [],
            orderTenders: [],
            test: true,
        },
        {
            id: 4,
            name: 'Сертификат соответствия по ТР ТС 019/2011',
            message: 'Одежда специальная защитная от общих производственных загрязнений и механических воздействий...',
            created: '2023-07-04T10:28:31.000Z',
            account: {
                id: 173,
                firstName: 'Ирина',
                lastName: 'Суслова ',
                avatar: '',
            },
            orderCategories: [],
            orderTenders: [],
            test: true,
        },
        {
            id: 5,
            name: 'Необходима декларация для Озон',
            message: 'Из хлопчатобумажной пряжи, из смешанной пряжи. Также нужны испытания в лаборатории...',
            created: '2023-07-04T10:28:31.000Z',
            account: {
                id: 173,
                firstName: 'Ирина',
                lastName: 'Суслова ',
                avatar: '',
            },
            orderCategories: [],
            orderTenders: [],
            test: true,
        },
        {
            id: 6,
            name: 'УСТРОЙСТВА ЗАЩИТЫ ОТ ИМПУЛЬСНЫХ...',
            message: 'Необходимо оформить СС ТР ТС. Серия на 5 лет. Производитель РФ. Продукция: УСТРОЙСТВА ЗАЩИТЫ ОТ ИМПУЛЬСНЫХ ....',
            created: '2023-07-04T10:28:31.000Z',
            account: {
                id: 173,
                firstName: 'Ирина',
                lastName: 'Суслова ',
                avatar: '',
            },
            orderCategories: [],
            orderTenders: [],
            test: true,
        },
    ],
    order: null,
    pagination: {
        pageSize: 6,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'created',
        sortOrder: 'desc',
    },
};

export function ordersGuestReducer(state = initialState, action: ActionTypes): StateType {
    switch (action.type) {
        case OrderActionTypes.GET_ORDERS_GUEST_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };
        case OrderActionTypes.GET_ORDERS_GUEST_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case OrderActionTypes.GET_ORDERS_GUEST_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: action.response.test && action.response.data.length <= 6 ? [...action.response.data, ...state.list.slice(action.response.data.length)] : [...state.list, ...action.response.data],
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case OrderActionTypes.CREATE_TEMP_ORDER_GUEST_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: true,
                },
            };
        case OrderActionTypes.CREATE_TEMP_ORDER_GUEST_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
            };

        case OrderActionTypes.CREATE_TEMP_ORDER_GUEST_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
                order: action.order,
            };

        case OrderActionTypes.GET_TEMP_ORDER_GUEST_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };
        case OrderActionTypes.GET_TEMP_ORDER_GUEST_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case OrderActionTypes.GET_TEMP_ORDER_GUEST_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                order: action.order,
            };

        case OrderActionTypes.DELETE_TEMP_ORDER_GUEST:
            return {
                ...state,
                order: null,
            };

        case OrderActionTypes.CLEAR_ORDERS_GUEST:
            return {
                ...state,
                ...initialState,
            };

        case OrderActionTypes.HIDE_BTN_ORDERS_GUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                hide: true,
            };

        default: return state;
    }
}
