import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './styles/index.css';
import { OneClickRedirect } from './utils/oneclik';

const container = document.getElementById('root');
const root = createRoot(container!);

OneClickRedirect();

root.render(<App />);

if (process.env.REACT_APP_PRODUCTION === 'true') {
    const js = document.createElement('script');

    js.type = 'text/javascript';
    js.append(`
        (function(d, w, c) {
            w.ChatraID = 'aiaoFtu9HMB3zENWA';
            var s = d.createElement('script');
            w[c] = w[c] || function() {
                (w[c].q = w[c].q || []).push(arguments);
            };
            s.async = true;
            s.src = 'https://call.chatra.io/chatra.js';
            if (d.head) d.head.appendChild(s);
        })(document, window, 'Chatra')
    `);

    document.body.appendChild(js);
}
