import React from 'react';
import { Skeleton } from 'antd';
import styles from '../../profile/styles.module.scss';

export const ProfileSkeleton = () => (
    <div className={ styles.cart }>
        <div className={ styles.profile }>
            <div className={ styles.profile__ava }>
                <Skeleton.Avatar
                    shape="circle"
                    style={ { width: '150px', height: '150px' } }
                    active={ true }
                />
                <div className={ styles.profile__status }>
                    <Skeleton active={ true } paragraph={ { rows: 0 } } />
                </div>
            </div>
            <div className={ styles.profile__content }>
                <div className={ styles.profile__contact }>
                    <Skeleton active={ true } paragraph={ { rows: 0 } } />
                </div>
                <div className={ styles.info }>
                    <Skeleton active={ true } paragraph={ { rows: 0 } } />
                </div>
                <div className={ styles.profile__desc }>
                    <Skeleton active={ true } />
                </div>
            </div>
        </div>
        <div className={ styles.details }>
            <div className={ styles.title }>Информация</div>
            <br /><br /><br />
            <Skeleton active={ true } />
        </div>
    </div>
);
