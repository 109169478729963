export enum RefundStatus {
    'empty' = 'empty',
    'waiting' = 'waiting',
    'reserved' = 'reserved',
    'completed' = 'completed',
    'error' = 'error',
}

export const RefundStatusText = {
    [RefundStatus.empty]: 'Нет операций',
    [RefundStatus.waiting]: 'Ожидание',
    [RefundStatus.completed]: 'Готово',
    [RefundStatus.error]: 'Ошибка',
    [RefundStatus.reserved]: 'Зарезервировано',
};

export const RefundStatusOptions = [
    {
        value: RefundStatus.completed,
        name: RefundStatusText[RefundStatus.completed],
    },
    {
        value: RefundStatus.error,
        name: RefundStatusText[RefundStatus.error],
    },
];

export enum TypePayment {
    'add' = 'add',
    'writeDowns' = 'writeDowns',
    'reserved' = 'reserved',
    'refund' = 'refund',
}

export const TypePaymentText = {
    [TypePayment.add]: 'Пополнение',
    [TypePayment.writeDowns]: 'Списание',
    [TypePayment.reserved]: 'Зарезервировано',
    [TypePayment.refund]: 'Возврат',
};

export const TypeOfPaymentOptions = [
    {
        value: TypePayment.add,
        name: TypePaymentText[TypePayment.add],
    },
    {
        value: TypePayment.refund,
        name: TypePaymentText[TypePayment.refund],
    },
    {
        value: TypePayment.writeDowns,
        name: TypePaymentText[TypePayment.writeDowns],
    },
];

export interface IStatus {
    [key: string]: {
        key: string;
        text: string;
        color: string;
    };
}

export const StatusInfo: IStatus = {
    add: {
        key: 'add',
        text: 'Пополнение',
        color: '#10b981',
    },
    writeDowns: {
        key: 'writeDowns',
        text: 'Списание',
        color: '#f43f5e',
    },
    refund: {
        key: 'refund',
        text: 'Возврат',
        color: '#f59e0b',
    },
    reserved: {
        key: 'reserved',
        text: 'Зарезервировано',
        color: '#0d63dc',
    },
};

export type Status = keyof typeof StatusInfo;

export type TypePaymentItem = {
    value: string;
    name: string;
};

export type AccountInfo = {
    id: number;
    firstName: string;
    lastName: string;
    avatar: null | string;
};
export type ScoreHistories = {
    id: number;
    created: Date;
    update: Date;
    message: string;
};

export type CompanyInfo = {
    id: number;
    organization: string;
    fio: string;
};

export type Score = {
    id: number;
    inn: string;
    pp: string;
    message: string;
    typePayment: TypePayment;
    refundStatus: RefundStatus;
    price: number;
    refund: boolean;
    refundMessage: string;
    created: Date;
    datePp: Date;
    account?: AccountInfo;
    company?: CompanyInfo;
    orderId?: number | null;
    scoreHistories?: ScoreHistories[];
};

export type Pagination = {
    sortField: string;
    sortOrder: string;
    page: number;
    pageSize: number;
    total: number;
};

export type ReturnScore = {
    data: Score[];
    pagination: Pagination;
};
