import { put, call } from 'redux-saga/effects';

import {
    getPermissionError,
    getPermissionFinish,
} from 'ducks/permission/actions';

import { fetchers } from 'api';

export function* getPermission() {
    try {
        const response = yield call(fetchers.getPermission);

        yield put(getPermissionFinish(response));
    } catch (error) {
        yield put(getPermissionError());
    }
}
