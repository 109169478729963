import { InferValueTypes } from 'types/common';
import { FullOrder, Order } from 'types/order';
import { TendersActionTypes } from '../../tenders/action-types';
import * as tenderActions from '../../tenders/actions';
import * as actions from '../actions';
import { OrderActionTypes } from '../action-types';

type ActionTypes = ReturnType<InferValueTypes<typeof actions | typeof tenderActions>>;

export type StateType = {
    order: FullOrder | null;
    list: Order[];
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: StateType = {
    order: null,
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'created',
        sortOrder: 'desc',
    },
};

export function ordersReducer(state = initialState, action: ActionTypes): StateType {
    switch (action.type) {
        case OrderActionTypes.GET_ORDERS_FINISH:
            return {
                ...state,
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case OrderActionTypes.GET_ORDER_FINISH:
            return {
                ...state,
                order: action.order,
            };

        case OrderActionTypes.CREATE_ORDER_FINISH:
            return {
                ...state,
                order: action.order,
            };

        case TendersActionTypes.ACCEPT_TENDER_FINISH:
            return {
                ...state,
                order: {
                    ...state.order as FullOrder,
                    orderTender: action.info,
                },
            };

        case OrderActionTypes.CHANGE_STATUS_FINISH:
            return {
                ...state,
                order: {
                    ...state.order as FullOrder,
                    status: action.status,
                },
            };

        case OrderActionTypes.CLEAR_ORDERS:
            return {
                ...initialState,
            };

        default: return state;
    }
}
