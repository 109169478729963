export const FeedbacksActionTypes = {
    GET_FEEDBACK_START: 'GET_FEEDBACK_START',
    GET_FEEDBACK_ERROR: 'GET_FEEDBACK_ERROR',
    GET_FEEDBACK_FINISH: 'GET_FEEDBACK_FINISH',

    SEND_FEEDBACK_START: 'SEND_FEEDBACK_START',
    SEND_FEEDBACK_ERROR: 'SEND_FEEDBACK_ERROR',
    SEND_FEEDBACK_FINISH: 'SEND_FEEDBACK_FINISH',

    CLEAR_FEEDBACKS: 'CLEAR_FEEDBACKS',

    EDIT_FEEDBACK_START: 'EDIT_FEEDBACK_START',
    EDIT_FEEDBACK_ERROR: 'EDIT_FEEDBACK_ERROR',
    EDIT_FEEDBACK_FINISH: 'EDIT_FEEDBACK_FINISH',
} as const;
