import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    Pagination, Radio, Table,
} from 'antd';
import { ScoreInfo } from 'components/score/info';
import { getPrice, getStatus } from 'components/score/helper';

import { clearScore, getScore, getScores } from 'ducks/score/actions';
import { selectScores, selectScoresLoading, selectScoresPagination } from 'ducks/score/selectors';
import {
    Score as TypeScore,
    RefundStatus as TypeRefundStatus,
    Status, StatusInfo, TypePayment,
} from 'types/score';
import { DATE_FORMAT } from 'constants/date';
import styles from './styles.module.scss';

type Props = {
    userId: number | string;
};

export const BalansList = ({ userId }: Props) => {
    const put = useDispatch();
    const scores = useSelector(selectScores);
    const pagination = useSelector(selectScoresPagination);
    const loading = useSelector(selectScoresLoading);
    const [openInfo, setopenInfo] = useState(false);
    const [typePayment, setTypePayment] = useState<TypePayment | null>(null);

    useEffect(() => {
        put(getScores({ userId, pagination: { page: 1 } }));
    }, []);

    const onChangePagination = useCallback((page: number) => {
        // eslint-disable-next-line no-negated-condition
        if (!typePayment) {
            put(getScores({ userId, pagination: { page }, sort: { sortField: 'id', sortOrder: 'desc' } }));
        } else {
            put(getScores({
                userId,
                typePayment,
                pagination: { page },
                sort: {
                    sortField: 'id', sortOrder: 'desc',
                },
            }));
        }
    }, []);

    const onClickRow = useCallback((id: number) => {
        setopenInfo(true);
        put(getScore({ id }));
    }, []);

    const onClickClose = () => {
        put(clearScore());
        setopenInfo(false);
    };

    const onToggleStatus = (type: any) => {
        if (type === null) {
            setTypePayment(type);
            put(getScores({
                userId,
                sort: {
                    sortField: 'id', sortOrder: 'desc',
                },
                pagination: {
                    page: 1,
                    pageSize: 10,
                },
            }));
        } else {
            setTypePayment(type);
            put(getScores({
                userId,
                typePayment: type,
                sort: {
                    sortField: 'id', sortOrder: 'desc',
                },
                pagination: {
                    page: 1,
                    pageSize: 10,
                },
            }));
        }
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Сумма',
            dataIndex: 'price',
            key: 'price',
            render: (t: string, rec: TypeScore) => getPrice(rec.typePayment, rec.price),
        },
        {
            title: 'Тип операции',
            dataIndex: 'typePayment',
            key: 'typePayment',
            render: (status: Status) => <div className={ styles[status] }>{ StatusInfo[status].text }</div>,
        },
        {
            title: 'Статус',
            dataIndex: 'refundStatus',
            key: 'refundStatus',
            render: (value: TypeRefundStatus) => getStatus(value),
        },
        {
            title: 'Заказ',
            dataIndex: 'orderId',
            key: 'orderId',
            render: (value: null | string) => (value ? `Заказ №${value}` : '-'),
        },
        {
            title: 'Дата',
            dataIndex: 'created',
            key: 'created',
            render: (t: string) => moment(t).format(DATE_FORMAT.DATE_TIME),
        },
        {
            title: 'Обновлен',
            dataIndex: 'update',
            key: 'update',
            render: (t: string) => moment(t).format(DATE_FORMAT.DATE_TIME),
        },
    ];

    return (
        <React.Fragment>
            <div className={ styles.filter }>
                <Radio.Group defaultValue="all" buttonStyle="solid">
                    <Radio.Button value="all" onClick={ () => onToggleStatus(null) }>История операций</Radio.Button>
                    <Radio.Button value="add" onClick={ () => onToggleStatus(TypePayment.add) }>Пополнение</Radio.Button>
                    <Radio.Button value="writeDowns" onClick={ () => onToggleStatus(TypePayment.writeDowns) }>Списание</Radio.Button>
                    <Radio.Button value="reserved" onClick={ () => onToggleStatus(TypePayment.reserved) }>Зарезервировано</Radio.Button>
                    <Radio.Button value="refund" onClick={ () => onToggleStatus(TypePayment.refund) }>Возврат</Radio.Button>
                </Radio.Group>
            </div>
            <Table
                size="small"
                rowKey="id"
                rowClassName={ styles.tableRow }
                pagination={ false }
                loading={ loading.gets }
                dataSource={ scores }
                columns={ columns }
                onRow={ (record) => ({
                    onClick: () => onClickRow(record.id),
                }) }
                locale={ {
                    emptyText: 'У вас пока что нет ни одного счета',
                } }
            />
            {
                Boolean(pagination.total) && (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total }
                            pageSize={ pagination.pageSize }
                            current={ pagination.page }
                            onChange={ onChangePagination }
                        />
                    </div>
                )
            }
            {
                openInfo && (
                    <ScoreInfo
                        open={ openInfo }
                        onClose={ onClickClose }
                    />
                )
            }
        </React.Fragment>
    );
};
