import { InferValueTypes } from 'types/common';
import { Score } from 'types/score';
import { ScoresActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type ScoresState = {
    loading: {
        gets: boolean;
        get: boolean;
        create: boolean;
        refund: boolean;
    };
    list: Score[];
    item: Score | null;
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: ScoresState = {
    loading: {
        gets: false,
        get: false,
        create: false,
        refund: false,
    },
    list: [],
    item: null,
    pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'created',
        sortOrder: 'desc',
    },
};

export function scoresReducer(state = initialState, action: ActionTypes): ScoresState {
    switch (action.type) {
        case ScoresActionTypes.GET_SCORES_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    gets: true,
                },
            };

        case ScoresActionTypes.GET_SCORES_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    gets: false,
                },
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case ScoresActionTypes.GET_SCORES_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    gets: false,
                },
            };

        case ScoresActionTypes.CREATE_SCORE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: true,
                },
            };

        case ScoresActionTypes.CREATE_SCORE_FINISH:
        case ScoresActionTypes.CREATE_SCORE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
            };

        case ScoresActionTypes.REFUND_SCORE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    refund: true,
                },
            };

        case ScoresActionTypes.REFUND_SCORE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    refund: false,
                },
                list: state.list.filter((value) => (value.id !== action.id)),
            };

        case ScoresActionTypes.REFUND_SCORE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    refund: false,
                },
            };

        case ScoresActionTypes.GET_SCORE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case ScoresActionTypes.GET_SCORE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                item: action.response,
            };

        case ScoresActionTypes.GET_SCORE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case ScoresActionTypes.CLEAR_SCORE:
            return {
                ...state,
                item: null,
            };

        case ScoresActionTypes.CLEAR_SCORES:
            return {
                ...state,
                list: [],
                item: null,
            };

        default: {
            return state;
        }
    }
}
