import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={ { fontSize: 28 } } spin={ true } />;

type Props = {
    loading: boolean;
    children: React.ReactNode;
};

export const ComponentLoading = ({ loading, children }: Props) => (
    <React.Fragment>
        {
            loading ? (
                <Spin indicator={ antIcon } />
            ) : children
        }
    </React.Fragment>
);
