import * as Api from 'types/api';
import { Status } from 'types/order';
import { OrderActionTypes } from './action-types';

export const getOrders = (payload: Api.GetOrdersPayload) => ({
    type: OrderActionTypes.GET_ORDERS_START,
    payload,
});

export const getOrdersError = () => ({
    type: OrderActionTypes.GET_ORDERS_ERROR,
});

export const getOrdersFinish = (response: Api.GetOrdersResponse) => ({
    type: OrderActionTypes.GET_ORDERS_FINISH,
    response,
});

export const clearOrdersGuest = () => ({
    type: OrderActionTypes.CLEAR_ORDERS_GUEST,
});

export const getOrdersGuest = (payload: Api.GetOrderGuestPayload) => ({
    type: OrderActionTypes.GET_ORDERS_GUEST_START,
    payload,
});

export const getOrdersGuestError = () => ({
    type: OrderActionTypes.GET_ORDERS_GUEST_ERROR,
});

export const getOrdersGuestFinish = (response: Api.GetOrderGuestResponse) => ({
    type: OrderActionTypes.GET_ORDERS_GUEST_FINISH,
    response,
});

export const clearOrdersBidding = () => ({
    type: OrderActionTypes.CLEAR_ORDERS_BIDDING,
});

export const getOrdersBidding = (payload: Api.GetOrderBiddingPayload) => ({
    type: OrderActionTypes.GET_ORDERS_BIDDING_START,
    payload,
});

export const getOrdersBiddingError = () => ({
    type: OrderActionTypes.GET_ORDERS_BIDDING_ERROR,
});

export const getOrdersBiddingFinish = (
    response: Api.GetOrderBiddingResponse,
) => ({
    type: OrderActionTypes.GET_ORDERS_BIDDING_FINISH,
    response,
});

export const getCategories = () => ({
    type: OrderActionTypes.GET_CATEGORIES_START,
});

export const getCategoriesError = () => ({
    type: OrderActionTypes.GET_CATEGORIES_ERROR,
});

export const getCategoriesFinish = (
    response: Api.GetCategoriesResponse,
    mappedObj: { [key: number]: string },
    options: any,
) => ({
    type: OrderActionTypes.GET_CATEGORIES_FINISH,
    response,
    mappedObj,
    options,
});

export const saveUserCategories = (
    response: Api.GetUserCategoriesResponse,
) => ({
    type: OrderActionTypes.SAVE_USER_CATEGORIES,
    response,
});

export const subscribeOnCategory = (data: Api.SubscribeOnCategoryPayload) => ({
    type: OrderActionTypes.SUBSCRIBE_ON_CATEGORY_START,
    data,
});

export const subscribeOnCategoryError = () => ({
    type: OrderActionTypes.SUBSCRIBE_ON_CATEGORY_ERROR,
});

export const subscribeOnCategoryFinish = () => ({
    type: OrderActionTypes.SUBSCRIBE_ON_CATEGORY_FINISH,
});

export const clearOrders = () => ({
    type: OrderActionTypes.CLEAR_ORDERS,
});

export const createOrder = (data: Api.CreateOrderPayload, goToPage = true) => ({
    type: OrderActionTypes.CREATE_ORDER_START,
    data,
    goToPage,
});

export const createOrderError = () => ({
    type: OrderActionTypes.CREATE_ORDER_ERROR,
});

export const createOrderFinish = (order: Api.GetOrderResponse) => ({
    type: OrderActionTypes.CREATE_ORDER_FINISH,
    order,
});

export const clearTempOrderGuest = () => ({
    type: OrderActionTypes.DELETE_TEMP_ORDER_GUEST,
});

export const getTempOrderGuest = (orderId: string) => ({
    type: OrderActionTypes.GET_TEMP_ORDER_GUEST_START,
    orderId,
});

export const getTempOrderGuestError = () => ({
    type: OrderActionTypes.GET_TEMP_ORDER_GUEST_ERROR,
});

export const getTempOrderGuestFinish = (
    order: Api.CreateTempOrderResponse,
) => ({
    type: OrderActionTypes.GET_TEMP_ORDER_GUEST_FINISH,
    order,
});

export const createTempOrderGuest = (
    data: Api.CreateTempOrderPayload,
    cb?: () => void,
) => ({
    type: OrderActionTypes.CREATE_TEMP_ORDER_GUEST_START,
    data,
    cb,
});

export const createTempOrderGuestError = () => ({
    type: OrderActionTypes.CREATE_TEMP_ORDER_GUEST_ERROR,
});

export const createTempOrderGuestFinish = (
    order: Api.CreateTempOrderResponse,
) => ({
    type: OrderActionTypes.CREATE_TEMP_ORDER_GUEST_FINISH,
    order,
});

export const getOrder = (data: Api.GetOrderPayload) => ({
    type: OrderActionTypes.GET_ORDER_START,
    data,
});

export const getOrderError = () => ({
    type: OrderActionTypes.GET_ORDER_ERROR,
});

export const getOrderFinish = (order: Api.GetOrderResponse) => ({
    type: OrderActionTypes.GET_ORDER_FINISH,
    order,
});

export const editOrder = (data: Api.CreateOrderPayload) => ({
    type: OrderActionTypes.EDIT_ORDER_START,
    data,
});

export const editOrderError = () => ({
    type: OrderActionTypes.EDIT_ORDER_ERROR,
});

export const editOrderFinish = () => ({
    type: OrderActionTypes.EDIT_ORDER_FINISH,
});

export const changeStatus = (
    payload: Api.ChangeOrderStatusPayload,
    callback?: () => void,
) => ({
    type: OrderActionTypes.CHANGE_STATUS_START,
    payload,
    callback,
});

export const changeStatusError = () => ({
    type: OrderActionTypes.CHANGE_STATUS_ERROR,
});

export const changeStatusFinish = (status: Status) => ({
    type: OrderActionTypes.CHANGE_STATUS_FINISH,
    status,
});

export const hideBtn = () => ({
    type: OrderActionTypes.HIDE_BTN_ORDERS_GUEST,
});

export const hideBtnBidding = () => ({
    type: OrderActionTypes.HIDE_BTN_ORDERS_BIDDING,
});
