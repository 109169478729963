import React from 'react';
import {
    Route,
    Redirect,
    RouteProps,
} from 'react-router';

import { ROUTES } from 'constants/routes';
import { AppLoading } from 'components/ui/app-loading';
import { PageNotFound } from 'pages/page-not-found';
import { User } from 'types/user';

type PrivateRouteProps = RouteProps & {
    user: User | null;
    loading: boolean;
    access?: string;
};

export const PrivateRoute = ({
    component: Component,
    user,
    loading,
    access,
    ...rest
}: PrivateRouteProps) => {
    const token = localStorage.getItem('token');

    if (!token) {
        return <Redirect to={ ROUTES.AUTH.path } />;
    }

    // если нужен доступ для страницы, то ждем загрузки профиля
    if (access) {
        if (!user || loading) {
            return (
                <AppLoading />
            );
        }

        if (user && !user.rights.includes(access)) {
            return (
                <PageNotFound />
            );
        }
    }

    if (!Component) return <PageNotFound />;

    if (!user || loading) {
        return (
            <AppLoading />
        );
    }

    return (
        <Route
            { ...rest }
            render={ (props) => <Component { ...props } /> }
        />
    );
};
