import React from 'react';
import { Empty } from 'antd';
import styles from './styles.module.scss';

export const NotificationEmpty = () => (
    <div className={ styles.bodyEmpty }>
        <Empty
            imageStyle={ {
                height: 60,
            } }
            description="У вас нет уведомлений"
        />
    </div>
);
