import React, { useEffect, useState, useCallback } from 'react';
import { Empty, Spin, Typography } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { clearDialogAll, getDialogAll } from 'ducks/dialog/actions';
import { selectDialogAll, selectDialogAllPagination } from 'ducks/dialog/selectors';
import empty from 'assets/empty.svg';
import { Dialog } from './dialog';

import cl from './styles.module.scss';

const { Title } = Typography;

export const DialogList = () => {
    const put = useDispatch();
    const dialogAll = useSelector(selectDialogAll);
    const dialogPagination = useSelector(selectDialogAllPagination);
    const [page, setPage] = useState(2);

    useEffect(() => {
        put(getDialogAll({ pagination: { page: 1 } }));

        return () => {
            put(clearDialogAll());
        };
    }, []);

    const nextLstDialog = useCallback(() => {
        setPage((num) => num + 1);
        setTimeout(() => {
            put(getDialogAll({ pagination: { page } }));
        }, 1);
    }, [page, setPage]);

    const endMessageTxt = <div className={ cl.nomessage }>Диалогов больше нет</div>;
    const loadingMessage = <div className={ cl.loading }><Spin /></div>;

    if (dialogAll.length > 0) {
        return (
            <div className={ cl.wrap }>
                <div className={ cl.header }>
                    <div className={ cl.title }>
                        Диалоги по заказам
                    </div>
                </div>
                <div className={ cl.body }>
                    <div
                        id="scrollableDiv"
                        className={ cl.list }
                        style={ { height: '100%', overflowY: 'scroll' } }
                    >
                        <InfiniteScroll
                            key={ 123 }
                            dataLength={ dialogAll.length }
                            next={ nextLstDialog }
                            style={ { display: 'flex', flexDirection: 'column' } }
                            hasMore={ dialogPagination.next }
                            endMessage={ dialogAll.length > dialogPagination.pageSize && endMessageTxt }
                            loader={ loadingMessage }
                            scrollableTarget="scrollableDiv"
                        >
                            { dialogAll && dialogAll.map((item) => <Dialog { ...item } key={ item.id } />) }
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={ cl.wrap }>
            <div className={ cl.header }>
                <div className={ cl.title }>
                    Диалоги по заказам
                </div>
            </div>
            <div className={ cl.body }>
                <Empty
                    className={ cl.emptyDialog }
                    image={ empty }
                    imageStyle={ {
                        height: 60,
                    } }
                    description={ (
                        <React.Fragment>
                            <Title level={ 5 }>У вас нет диалогов</Title>
                            <p className={ cl.emptyDesc }>Чтобы появились диалоги участвуйте в заказах и нажмите на молнию в чате заказа чтобы подключить диалог заказа</p>
                        </React.Fragment>
                    ) }
                />
            </div>
        </div>
    );
};
