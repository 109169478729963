import { put, call } from 'redux-saga/effects';
import {
    sendFeedback,
    sendFeedbackError,
    sendFeedbackFinish,
} from 'ducks/feedback/actions';

import { fetchers } from 'api';

export function* sendFeedbackRequest(action: ReturnType<typeof sendFeedback>) {
    try {
        yield call(fetchers.sendFeedback, action.payload);

        yield put(sendFeedbackFinish());
        if (action.callback) {
            action.callback();
        }
    } catch (error) {
        yield put(sendFeedbackError());
    }
}
