import { combineReducers } from 'redux';
import { loadingReducer } from './loading';
import { companyReducer } from './company';

export const moderationReducer = combineReducers({
    loading: loadingReducer,
    company: companyReducer,
});

export type ModerationState = ReturnType<typeof moderationReducer>;
