import * as Api from 'types/api';
import {
    ReviewsActionTypes,
} from './action-types';

export const getReviews = (payload: Api.GetReviewsPayload) => ({
    type: ReviewsActionTypes.GET_REVIEWS_START,
    payload,
});

export const getReviewsError = () => ({
    type: ReviewsActionTypes.GET_REVIEWS_ERROR,
});

export const getReviewsFinish = (response: Api.GetReviewsResponse) => ({
    type: ReviewsActionTypes.GET_REVIEWS_FINISH,
    response,
});

export const clearReviews = () => ({
    type: ReviewsActionTypes.CLEAR_REVIEWS,
});

export const sendReview = (payload: Api.SendReviewPayload, callback: () => void) => ({
    type: ReviewsActionTypes.SEND_REVIEW_START,
    payload,
    callback,
});

export const sendReviewError = () => ({
    type: ReviewsActionTypes.SEND_REVIEW_ERROR,
});

export const sendReviewFinish = () => ({
    type: ReviewsActionTypes.SEND_REVIEW_FINISH,
});
