import { DATE_FORMAT } from 'constants/date';
import moment from 'moment';

export const columns = [
    {
        title: '№',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Заголовок',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Дата',
        dataIndex: 'created',
        key: 'created',
        render: (_: any, record: any) =>
            moment(record.created).format(DATE_FORMAT.DATE_TIME),
    },
];
