import { put, call } from 'redux-saga/effects';
import {
    seeTender,
    seeTenderError,
    seeTenderFinish,
} from 'ducks/tenders/actions';

import { fetchers } from 'api';

export function* seeTenderRequest(action: ReturnType<typeof seeTender>) {
    try {
        const response = yield call(fetchers.seeTender, action.payload);

        yield put(seeTenderFinish(response));
    } catch (error) {
        yield put(seeTenderError());
    }
}
