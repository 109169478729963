import React, { useCallback } from 'react';
import { Button, Form, Input, Modal, Tooltip, Upload } from 'antd';
import { CKEditor } from 'ckeditor4-react';
import { Formik } from 'formik';
import { UploadOutlined } from '@ant-design/icons';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { BlogFormType } from 'form-helpers/blog/types';
import { allowedFileTypes } from 'types/blog';
import { BlogCreatePayload } from 'types/api';
import { schema } from 'form-helpers/blog/schema';
import { getInitialData } from 'form-helpers/blog/mapping';

import { cloneDeep } from 'lodash';
import { createBlog } from 'ducks/blog/actions';
import { useDispatch } from 'react-redux';
import cls from './styles.module.scss';

type Props = {
    open: boolean;
    onOk: () => void;
    onCancel: () => void;
};

const { TextArea } = Input;

const initialValues = schema.cast({});

export const BlogCreate = ({ open, onOk, onCancel }: Props) => {
    const put = useDispatch();
    const onSubmit = useCallback((data: BlogCreatePayload) => {
        const values = cloneDeep(data);

        if (data.image) {
            const image = [];

            for (let i = 0; i < data.image.length; i++) {
                const files = data.image[i].originFileObj;

                image.push(files);
            }

            values.image = image;
        }

        put(createBlog(values, onOk));
    }, []);

    return (
        <Formik<BlogFormType>
            onSubmit={ onSubmit }
            initialValues={ getInitialData(initialValues, null) }
            validationSchema={ schema }
            key="createScore"
            enableReinitialize={ true }>
            { ({
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                values,
                errors,
                touched,
            }) => {
                const errorList = (name: string) =>
                    getError(errors, touched, true, name);

                const handleChangeText = (e: any) => {
                    const event = {
                        target: {
                            name: e.target.name,
                            value: e.target.value,
                            id: e.target.id,
                        },
                    };

                    handleChange(event);
                };
                const handleChangeTextCKEditor = (e: any) => {
                    const event = {
                        target: {
                            name: e.editor.name,
                            value: e.editor.getData(),
                            id: e.editor.id,
                        },
                    };

                    handleChange(event);
                };

                const onChangeFile = (name: string) => (file: any) => {
                    setFieldValue(name, file.fileList || null, true);
                    setFieldTouched(name, true);
                };

                return (
                    <Modal
                        title="Добавить статью"
                        open={ open }
                        onOk={ () => handleSubmit() }
                        onCancel={ onCancel }
                        okText="Создать"
                        className={ cls.modal }>
                        <Form layout="vertical" autoComplete="off">
                            <Form.Item
                                label="Название"
                                name="title"
                                extra={ errorList('title') }
                                validateStatus={ getErrorStatus(
                                    !!errorList('title'),
                                ) }>
                                <Input
                                    placeholder="Название"
                                    onChange={ handleChangeText }
                                    onBlur={ handleChangeText }
                                    value={ values.title }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Картинка превью"
                                name="image"
                                extra={ errorList('image') }
                                validateStatus={ getErrorStatus(
                                    !!errorList('image'),
                                ) }
                                tooltip={ `Допустимые форматы файлов: ${allowedFileTypes.join(
                                    ', ',
                                )}` }>
                                <Upload
                                    accept="image/*"
                                    beforeUpload={ () => false }
                                    maxCount={ 1 }
                                    multiple={ false }
                                    onChange={ onChangeFile('image') }
                                    fileList={ values.image }>
                                    <Tooltip title="Загрузите картинку">
                                        <Button icon={ <UploadOutlined /> }>
                                            Загрузить
                                        </Button>
                                    </Tooltip>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                label="Краткое описание"
                                name="desc"
                                extra={ errorList('desc') }
                                validateStatus={ getErrorStatus(
                                    !!errorList('desc'),
                                ) }>
                                <TextArea
                                    autoSize={ true }
                                    placeholder="Краткое описание"
                                    onChange={ handleChangeText }
                                    onBlur={ handleChangeText }
                                    value={ values.desc }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Полное описание"
                                name="fullDesc"
                                extra={ errorList('fullDesc') }
                                validateStatus={ getErrorStatus(
                                    !!errorList('fullDesc'),
                                ) }>
                                <CKEditor
                                    name="fullDesc"
                                    onChange={ handleChangeTextCKEditor }
                                    onBlur={ handleChangeTextCKEditor }
                                    // onChange={ (evt) => setDescription(evt.editor.getData()) }                                />
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                );
            } }
        </Formik>
    );
};
