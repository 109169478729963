import { TRoute } from 'types/route';
import { Companies } from './companies';
import { Feedback } from './feedback';
import { Scores } from './score';
import { Blog } from './blog';

export const ROUTES: TRoute = {
    SCORES: {
        path: '/moderation/score',
        title: 'Счета клиентов',
        exact: true,
        showInMenu: true,
        component: Scores,
    },
    COMPANY: {
        path: '/moderation/company',
        title: 'Компании',
        exact: true,
        showInMenu: true,
        component: Companies,
    },
    FEEDBACK: {
        path: '/moderation/feedback',
        title: 'Обратная связь',
        exact: true,
        showInMenu: true,
        component: Feedback,
    },
    BLOG: {
        path: '/moderation/blog',
        title: 'Блог',
        exact: true,
        showInMenu: true,
        component: Blog,
    },
};

export const ROUTES_KEYS = Object.keys(ROUTES);
