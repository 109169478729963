import { put, call } from 'redux-saga/effects';
import {
    sendComment,
    sendCommentError,
    sendCommentFinish,
    // getComments,
} from 'ducks/comments/actions';

import { fetchers } from 'api';

export function* sendCommentRequest(action: ReturnType<typeof sendComment>) {
    try {
        yield call(fetchers.sendComment, action.payload);

        action.callback();
        // yield put(getComments({ orderId: action.payload.orderId, pagination: { page: 1 } }));
        yield put(sendCommentFinish());
    } catch (error) {
        yield put(sendCommentError());
    }
}
