import { put, call } from 'redux-saga/effects';

import {
    getUser,
    getUserError,
    getUserFinish,
} from 'ducks/user/actions';

import { fetchers } from 'api';

export function* getUserRequest({ userId }: ReturnType<typeof getUser>) {
    try {
        const response = yield call(fetchers.getUser, { userId });

        yield put(getUserFinish(response));
    } catch (error) {
        yield put(getUserError());
    }
}
