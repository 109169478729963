import React from 'react';
import { Avatar } from 'components/ui/avatar';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Blog } from 'types/blog';
import { ROUTES } from 'constants/routes';
import { DATE_FORMAT } from 'constants/date';
import cls from './style.module.scss';

export const Item = ({ id, title, image, created, account }: Blog) => (
    <div className={ cls.item } key={ id }>
        <div className={ cls.images }>
            <img src={ image } className={ cls.img } alt="" />
        </div>
        <div className={ cls.author }>
            <div className={ cls.author_image }>
                <Avatar src={ account.avatar } className={ cls.author__img } />
            </div>
            <div className={ cls.author_content }>
                <div className={ cls.author__name }>
                    { account.firstName } { account.lastName }
                </div>
                <div className={ cls.author__title }>
                    Автор статей для СертТорг
                </div>
                <div className={ cls.author__date }>{ moment(created).format(DATE_FORMAT.DATE_TIME) }</div>
            </div>
        </div>
        <Link to={ ROUTES.BLOGS.getPath({id}) } className={ cls.title }>{ title }</Link>
    </div>
);
