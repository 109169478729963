export const NotificationActionTypes = {
    LOAD_NOTIFICATION_START: 'LOAD_NOTIFICATION_START',
    LOAD_NOTIFICATION_ERROR: 'LOAD_NOTIFICATION_ERROR',
    LOAD_NOTIFICATION_FINISH: 'LOAD_NOTIFICATION_FINISH',

    GET_NOTIFICATION_START: 'GET_NOTIFICATION_START',
    GET_NOTIFICATION_ERROR: 'GET_NOTIFICATION_ERROR',
    GET_NOTIFICATION_FINISH: 'GET_NOTIFICATION_FINISH',

    SET_NOTIFICATION_START: 'SET_NOTIFICATION_START',
    SET_NOTIFICATION_ERROR: 'SET_NOTIFICATION_ERROR',
    SET_NOTIFICATION_FINISH: 'SET_NOTIFICATION_FINISH',

    GET_NOTIFICATION_COUNT_START: 'GET_NOTIFICATION_COUNT_START',
    GET_NOTIFICATION_COUNT_FINISH: 'GET_NOTIFICATION_COUNT_FINISH',
} as const;
