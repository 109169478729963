export const schema = {
    price: [
        { required: true, message: 'Обязательно нужно заполнить' },
        {
            pattern: RegExp('[0-9]'),
            message: 'Разрешены только  цифры',
        },
    ],
    message: [
        { whitespace: true, message: 'Пустые пробелы запрешены' },
        {
            pattern: RegExp('[A-zА-я0-9]'),
            message: 'Разрешены только англ. и рус. буквы',
        },
    ],
    termOfService: [
        { whitespace: true, message: 'Пустые пробелы запрешены' },
        { required: true, message: 'Обязательно нужно заполнить' },
    ],
    payment: [
        { whitespace: true, message: 'Пустые пробелы запрешены' },
        { required: true, message: 'Обязательно нужно заполнить' },
    ],
};
