import { put, call } from 'redux-saga/effects';
import {
    changeStatus,
    changeStatusError,
    changeStatusFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* changeOrderStatusRequest({ payload, callback }: ReturnType<typeof changeStatus>) {
    try {
        yield call(fetchers.changeOrderStatus, payload);

        yield put(changeStatusFinish(payload.status));

        if (callback) {
            callback();
        }
    } catch (error) {
        yield put(changeStatusError());
    }
}
