import { InferValueTypes } from 'types/common';
import { User } from 'types/user';
import { AppActionTypes } from '../app/action-types';
import * as appActions from '../app/actions';
import { UserActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions |
    typeof appActions
>>;

export type UserState = {
    loading: {
        profile: boolean;
        user: boolean;
        save: boolean;
        getList: boolean;
        blocked: boolean;
        email: boolean;
        price: boolean;
        telegram: boolean;
        skype: boolean;
    };
    profile: User | any;
    user: User | null;
    list: any;
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: UserState = {
    loading: {
        profile: false,
        user: false,
        save: false,
        getList: false,
        blocked: false,
        email: false,
        price: false,
        telegram: false,
        skype: false,
    },
    profile: null,
    user: null,
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'created',
        sortOrder: 'desc',
    },
};

// eslint-disable-next-line complexity
export function userReducer(state = initialState, action: ActionTypes): UserState {
    switch (action.type) {
        case AppActionTypes.SIGN_IN_FINISH:
            return {
                ...state,
                profile: action.data,
            };

        // ПОЛУЧЕНИЕ ПРОФИЛЯ
        case AppActionTypes.REGISTER_AUTO_SIGN_IN_START:
        case UserActionTypes.GET_PROFILE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    profile: true,
                },
            };

        case AppActionTypes.REGISTER_AUTO_SIGN_IN_FINISH:
        case UserActionTypes.GET_PROFILE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    profile: false,
                },
                profile: action.response,
            };

        case AppActionTypes.REGISTER_AUTO_SIGN_IN_ERROR:
        case UserActionTypes.GET_PROFILE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    profile: false,
                },
            };

        // ПОЛУЧЕНИЕ ПОЛЬЗОВАТЕЛЯ
        case UserActionTypes.GET_USER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    user: true,
                },
            };
        case UserActionTypes.GET_USER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    user: false,
                },
                user: action.response,
            };

        case UserActionTypes.GET_USER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    user: false,
                },
            };

        // СОХРАНЕНИЕ ПОЛЬЗОВАТЕЛЯ
        case UserActionTypes.SAVE_USER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    save: true,
                },
            };
        case UserActionTypes.SAVE_USER_FINISH:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...action.user,
                },
                loading: {
                    ...state.loading,
                    save: false,
                },
            };

        case UserActionTypes.SAVE_USER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    save: false,
                },
            };

        // СОХРАНЕНИЕ/ИЗМЕНЕНИЕ СОЦИАЛЬНЫЕ СЕТИ ПОЛЬЗОВАТЕЛЯ
        case UserActionTypes.SAVE_USER_SOCIAL_NETWORK_START:
        case UserActionTypes.REMOVE_SOCIAL_NETWORK_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.loading]: true,
                },
            };
        case UserActionTypes.SAVE_USER_SOCIAL_NETWORK_FINISH:
        case UserActionTypes.REMOVE_SOCIAL_NETWORK_FINISH:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...action.user,
                },
                loading: {
                    ...state.loading,
                    [action.loading]: false,
                },
            };

        case UserActionTypes.SAVE_USER_SOCIAL_NETWORK_ERROR:
        case UserActionTypes.REMOVE_SOCIAL_NETWORK_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.loading]: false,
                },
            };

        // СПИСОК ПОЛЬЗОВАТЕЛЕЙ
        case UserActionTypes.GET_USERS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getList: true,
                },
            };

        case UserActionTypes.GET_USERS_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getList: false,
                },
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case UserActionTypes.GET_USERS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getList: false,
                },
            };

        // БЛОКИРОКА ПОЛЬЗОВАТЕЛЯ
        case UserActionTypes.BLOCKED_USER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    blocked: true,
                },
            };

        case UserActionTypes.BLOCKED_USER_FINISH:
            return {
                ...state,
                list: state.list.map((user: User) => ({
                    ...user,
                    access: user.id === action.userId ? action.access : user.access,
                })),
                loading: {
                    ...state.loading,
                    blocked: false,
                },
            };

        case UserActionTypes.BLOCKED_USER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    blocked: false,
                },
            };

        case UserActionTypes.SEND_СONFIRMATION_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    email: true,
                },
            };

        case UserActionTypes.SEND_СONFIRMATION_FINISH:
        case UserActionTypes.SEND_СONFIRMATION_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    email: false,
                },
            };

        case UserActionTypes.GET_PRICE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    price: true,
                },
            };

        case UserActionTypes.GET_PRICE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    price: false,
                },
                profile: state.profile ? {
                    ...state.profile,
                    company: {
                        ...state.profile.company,
                        price: action.response.company.price,
                    },
                } : null,
            };

        case UserActionTypes.GET_PRICE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    price: false,
                },
            };

        // ОЧИСТКА ДАННЫХ
        case UserActionTypes.CLEAR_PROFILE:
            return {
                ...state,
                profile: null,
            };

        case UserActionTypes.CLEAR_USER:
            return {
                ...state,
                user: null,
            };

        case UserActionTypes.CLEAR_USERS:
            return {
                ...state,
                list: [],
                pagination: initialState.pagination,
            };

        default: {
            return state;
        }
    }
}
