/* eslint-disable no-console */
import React from 'react';
import { Button, Input } from 'antd';

import { Message as MessageType } from 'types/dialog';

import styles from './styles.module.scss';

type Props = {
    errMessage: null | string;
    message: string;
    loadings: {
        get: boolean;
        send: boolean;
        edit: boolean;
        delete: boolean;
    };
    handleChangeMessage: (e: any) => void;
    handleSendMessage: (e: any) => void;
    refund?: MessageType | null;
    handleChangeMessageRefund: (e: any) => void;
};

export const Send = React.memo(({
    errMessage,
    loadings,
    message,
    handleChangeMessage,
    handleSendMessage,
    refund,
    handleChangeMessageRefund,
}: Props) => (
    <div className={ styles.message }>
        { errMessage && (<div className={ styles.errMessage }>{ errMessage }</div>) }
        <div className={ styles.messageInput }>
            <Input.TextArea
                autoSize={ { minRows: 3, maxRows: 6 } }
                placeholder="Здесь вы можете уточнить информацию по заказу. Не предлагайте здесь цену, это можно сделать в предложениях"
                disabled={ loadings.send }
                onChange={ handleChangeMessage }
                value={ message }
            />
        </div>
        <div className={ styles.messageButtom }>
            { refund && (
                <Button
                    type="primary"
                    ghost={ true }
                    onClick={ handleChangeMessageRefund }
                >
                    Отмена
                </Button>
            ) }
            <Button
                type="primary"
                ghost={ true }
                onClick={ handleSendMessage }
                loading={ loadings.send }
            >
                Отправить
            </Button>
        </div>
    </div>
));
