import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Picker, { IEmojiData } from 'emoji-picker-react';
import { IconMoodHappy } from '@tabler/icons';

import cl from './styles.module.scss';

type Props = {
    picker: (message: string) => void;
};

export const EmojiPicker = React.memo(({ picker }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isMenuOpen && ref.current && !ref.current?.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    const onEmojiClick = (event: React.MouseEvent<Element, MouseEvent>, ico: IEmojiData) => {
        picker(ico?.emoji);
    };

    const container = classNames({
        [cl.popop_wrap]: true,
        [cl.active]: isMenuOpen,
    });

    return (
        <div className={ cl.popop } ref={ ref }>
            <div
                className={ cl.icon }
                onClick={ () => setIsMenuOpen((oldState) => !oldState) }
            >
                <IconMoodHappy />
            </div>
            <div className={ container }>
                <Picker
                    onEmojiClick={ onEmojiClick }
                    disableSearchBar={ true }
                />
            </div>
        </div>
    );
});
