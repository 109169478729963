import { put, call } from 'redux-saga/effects';

import {
    signIn,
    signInError,
    signInFinish,
} from 'ducks/app/actions';

import { SignInResponse } from 'types/api';

import { fetchers } from 'api';
import { history } from 'App';
import { ROUTES } from 'constants/routes';

export function* signInRequest({ values, cb }: ReturnType<typeof signIn>) {
    try {
        const { jwtToken, ...restProps }: SignInResponse = yield call(fetchers.signIn, values);

        localStorage.setItem('token', jwtToken);
        yield put(signInFinish(restProps));

        if (cb) {
            cb();
        } else {
            history.push(ROUTES.MAIN.path);
        }
    } catch (error) {
        yield put(signInError());
    }
}
