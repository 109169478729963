import * as Api from 'types/api';
import { User } from 'types/user';
import {
    UserActionTypes,
} from './action-types';

export const getPrice = () => ({
    type: UserActionTypes.GET_PRICE_START,
});

export const getPriceError = () => ({
    type: UserActionTypes.GET_PRICE_ERROR,
});

export const getPriceFinish = (response: Api.GetFieldsResponder) => ({
    type: UserActionTypes.GET_PRICE_FINISH,
    response,
});

export const getProfile = ({ userId }: Api.GetUserPayload) => ({
    type: UserActionTypes.GET_PROFILE_START,
    userId,
});

export const getProfileError = () => ({
    type: UserActionTypes.GET_PROFILE_ERROR,
});

export const getProfileFinish = (response: Api.GetUserResponse) => ({
    type: UserActionTypes.GET_PROFILE_FINISH,
    response,
});

export const getUser = ({ userId }: Api.GetUserPayload) => ({
    type: UserActionTypes.GET_USER_START,
    userId,
});

export const getUserError = () => ({
    type: UserActionTypes.GET_USER_ERROR,
});

export const getUserFinish = (response: Api.GetUserResponse) => ({
    type: UserActionTypes.GET_USER_FINISH,
    response,
});

export const saveUserSocialNetwork = (payload: Api.SaveSocialNerworkPayload, loading: Api.SocialNerworkType) => ({
    type: UserActionTypes.SAVE_USER_SOCIAL_NETWORK_START,
    payload,
    loading,
});

export const saveUserSocialNetworkError = (loading: Api.SocialNerworkType) => ({
    type: UserActionTypes.SAVE_USER_SOCIAL_NETWORK_ERROR,
    loading,
});

export const saveUserSocialNetworkFinish = (user: User, loading: Api.SocialNerworkType) => ({
    type: UserActionTypes.SAVE_USER_SOCIAL_NETWORK_FINISH,
    user,
    loading,
});

export const removeSocialNetwork = (payload: Api.RemoveSocialNerworkPayload, loading: Api.SocialNerworkType) => ({
    type: UserActionTypes.REMOVE_SOCIAL_NETWORK_START,
    payload,
    loading,
});

export const removeSocialNetworkError = (loading: Api.SocialNerworkType) => ({
    type: UserActionTypes.REMOVE_SOCIAL_NETWORK_ERROR,
    loading,
});

export const removeSocialNetworkFinish = (user: User, loading: Api.SocialNerworkType) => ({
    type: UserActionTypes.REMOVE_SOCIAL_NETWORK_FINISH,
    user,
    loading,
});

export const saveUser = (payload: Api.SaveUserPayload, reload = true) => ({
    type: UserActionTypes.SAVE_USER_START,
    payload,
    reload,
});

export const saveUserError = () => ({
    type: UserActionTypes.SAVE_USER_ERROR,
});

export const saveUserFinish = (user: User) => ({
    type: UserActionTypes.SAVE_USER_FINISH,
    user,
});

export const getUsers = (payload: Api.GetUsersPayload) => ({
    type: UserActionTypes.GET_USERS_START,
    payload,
});

export const getUsersError = () => ({
    type: UserActionTypes.GET_USERS_ERROR,
});

export const getUsersFinish = (response: Api.GetUsersResponse) => ({
    type: UserActionTypes.GET_USERS_FINISH,
    response,
});

export const blockedUser = (access: number, userId: number | string) => ({
    type: UserActionTypes.BLOCKED_USER_START,
    access,
    userId,
});

export const blockedUserError = () => ({
    type: UserActionTypes.BLOCKED_USER_ERROR,
});

export const blockedUserFinish = (access: number, userId: number | string) => ({
    type: UserActionTypes.BLOCKED_USER_FINISH,
    access,
    userId,
});

export const clearUser = () => ({
    type: UserActionTypes.CLEAR_USER,
});
export const clearProfile = () => ({
    type: UserActionTypes.CLEAR_PROFILE,
});

export const clearUsers = () => ({
    type: UserActionTypes.CLEAR_USERS,
});

export const sendСonfirmation = (payload: Api.SendСonfirmationPayload) => ({
    type: UserActionTypes.SEND_СONFIRMATION_START,
    payload,
});

export const sendСonfirmationError = () => ({
    type: UserActionTypes.SEND_СONFIRMATION_ERROR,
});

export const sendСonfirmationFinish = () => ({
    type: UserActionTypes.SEND_СONFIRMATION_FINISH,
});
