import { put, call } from 'redux-saga/effects';

import {
    blockedUser,
    blockedUserError,
    blockedUserFinish,
} from 'ducks/user/actions';

import { fetchers } from 'api';

export function* blockedUserRequest(params: ReturnType<typeof blockedUser>) {
    try {
        yield call(fetchers.blockedUser, params);

        yield put(blockedUserFinish(params.access, params.userId));
    } catch (error) {
        yield put(blockedUserError());
    }
}
