import { put, call, select } from 'redux-saga/effects';
import {
    getCategoriesError,
    getCategoriesFinish,
} from 'ducks/orders/actions';

import { selectCategories, selectMappedCategories, selectCategoriesOptions } from 'ducks/orders/selectors';

import { fetchers } from 'api';
import { Category } from 'types/order';

const mapToObj = (categories: Category[], obj: { [key: number]: string }) => {
    categories.forEach((category: Category) => {
        // eslint-disable-next-line no-param-reassign
        obj[category.id] = category.name;

        if (category.child) {
            mapToObj(category.child, obj);
        }
    });
};

const mapToOptions = (categories: Category[]): any =>
    categories.map((item: Category) => ({
        value: item.id,
        title: item.name,
        children: item.child ? mapToOptions(item.child) : [],
    }));

export function* getCategoriesRequest() {
    try {
        const categories = yield select(selectCategories);
        const mapped = yield select(selectMappedCategories);
        const formatted = yield select(selectCategoriesOptions);

        if (categories.length) {
            yield put(getCategoriesFinish(categories, mapped, formatted));
        } else {
            const response = yield call(fetchers.getCategories);

            const result: { [key: number]: string } = {};
            const options = mapToOptions(response);

            mapToObj(response, result);

            yield put(getCategoriesFinish(response, result, options));
        }
    } catch (error) {
        yield put(getCategoriesError());
    }
}
