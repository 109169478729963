import React, { useState } from 'react';
import { LayoutStatic } from 'components/ui/layoutStatic';
import {Modal} from 'antd';
import srcImage from 'assets/static_page/certificate.jpg';

export const Certificate = () => {
    const [previewOpen, setPreviewOpen] = useState(false);

    return (
        <LayoutStatic>
            <h2 className="sp-title">Орган по сертификации продукции &quot;Эксперт-С&quot; RA.RU.11НЕ06</h2>
            <div className="sp-image-wrap max-w500" onClick={ ()=>setPreviewOpen(true) }>
                <img src={ srcImage }  alt="Орган по сертификации продукции Эксперт-С RA.RU.11НЕ06" />
            </div>
            <Modal width={ 1200 } open={ previewOpen } footer={ null } onCancel={ ()=>setPreviewOpen(false) }>
                <img alt="Орган по сертификации продукции Эксперт-С RA.RU.11НЕ06" style={ { width: '100%' } } src={ srcImage } />
            </Modal>
        </LayoutStatic>
    );
};
