/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-negated-condition */
import React, {
    useEffect, useRef, useState,
} from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';
import cn from 'classnames';
import { history } from 'App';
import styles from './styles.module.scss';

type TStateContainer = {
    container: number;
    menu: number;
};
type THideAngle = 'right' | 'left' | 'all';

type ItemMenu = {
    path: string;
    title: string;
};

type Props = {
    items: ItemMenu[];
};

export const MenuScroll = React.memo(({ items }: Props) => {
    const [angleLeft, setAngleLeft] = useState(false);
    const [angleRight, setAngleRight] = useState(false);
    const [container, setContainer] = useState<TStateContainer>({
        container: 0,
        menu: 0,
    });
    const containerRef = useRef<any>();
    const wrapperRef = useRef<any>();

    const clickLink = (url: string) => () => history.push(url);

    const hideAngle = (type: THideAngle) => {
        const totalSize = Number(wrapperRef.current.style.left?.replace('px', ''));
        const fullSize = container.container - container.menu;

        if (totalSize === fullSize && type === 'right') {
            setAngleRight(false);
            setAngleLeft(true);
        }

        if (totalSize === 0 && type === 'left') {
            setAngleLeft(false);
            setAngleRight(true);
        }

        if (type === 'all') {
            setAngleLeft(false);
            setAngleRight(false);
            wrapperRef.current.style.left = '0px';
        }
    };

    const updateDimensions = () => {
        if (containerRef.current && wrapperRef.current) {
            const c = containerRef.current.clientWidth;
            const m = wrapperRef.current.clientWidth + 20;

            setContainer((prev) => ({
                ...prev,
                container: c,
                menu: m,
            }));

            if (m > c) {
                setAngleRight(true);
            } else {
                hideAngle('all');
            }
        }
    };

    const handleSliderLeft = () => {
        const totalSize = Number(wrapperRef.current.style.left?.replace('px', ''));
        const fullSize = container.container - container.menu;
        const size = fullSize / 2;

        wrapperRef.current.style.left = totalSize !== 0 ? `${totalSize - size}px` : wrapperRef.current.style.left = `${fullSize}px`;

        hideAngle('left');
    };

    const handleSliderRight = () => {
        const totalSize = Number(wrapperRef.current.style.left?.replace('px', ''));
        const fullSize = container.container - container.menu;
        const size = fullSize / 2;

        wrapperRef.current.style.left = totalSize !== fullSize ? `${totalSize + size}px` : wrapperRef.current.style.left = `${fullSize}px`;

        hideAngle('right');
    };

    useEffect(() => {
        setTimeout(updateDimensions, 500);
        window.addEventListener('resize', updateDimensions);
        setContainer((prev) => ({
            ...prev,
            container: containerRef.current.clientWidth,
            menu: wrapperRef.current.clientWidth + 20,
        }));

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <div
            ref={ containerRef }
            className={ styles.menu }
        >
            { angleLeft && (
                <div
                    className={ cn(styles.angle, styles.angle_left) }
                    onClick={ handleSliderLeft }
                >
                    <IconChevronLeft size={ 25 } />
                </div>
            ) }
            <div className={ styles.overflow_scroller }>
                <div
                    ref={ wrapperRef }
                    className={ styles.overflow_wrapper }
                >
                    <nav>
                        <ul className={ styles.navigation }>
                            { items.map((item, i) => (<li key={ i }><div onClick={ clickLink(item.path) }>{ item.title }</div></li>)) }
                        </ul>
                    </nav>
                </div>
            </div>
            { angleRight && (
                <div
                    className={ cn(styles.angle, styles.angle_right) }
                    onClick={ handleSliderRight }
                >
                    <IconChevronRight size={ 25 } />
                </div>
            ) }
        </div>
    );
});
