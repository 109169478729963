import React from 'react';
import { LHeader } from 'components/header';
import { Footer } from 'components/footer';
import { Notification } from 'components/notification';
import { CookieNotify } from 'components/cookieNotify';

import styles from './styles.module.scss';

type Props = {
    children: React.ReactNode;
};

export const LayoutStatic = ({ children }: Props) => (
    <div className={ styles.container }>
        <LHeader />
        <Notification />
        <div className={ styles.body }>
            <div className={ styles.content }> { children }</div>
        </div>
        <Footer />
        <CookieNotify/>
    </div>
);
