import React from 'react';
import { Typography } from 'antd';
import { Permission as ModulePermission } from 'components/permission';

import { Layout } from 'components/ui/layout';
import { Cart } from 'components/ui/cart';
import { Title } from 'components/ui/title';

const { Title: AntTitle } = Typography;

export const Permission = React.memo(() => (
    <Layout>
        <Title
            title={ <AntTitle level={ 2 }>Права доступа</AntTitle> }
        />
        <Cart>
            <ModulePermission />
        </Cart>
    </Layout>
));
