import React from 'react';
import moment from 'moment';
import { IconCertificateOff } from '@tabler/icons';
import { Notification as NotificationType } from 'types/notification';
import cn from 'classnames';
import { ROUTES } from 'pages/edit-profile/routes';

import styles from '../styles.module.scss';

type Props = {
    item: NotificationType;
    onHandleVisible: (visible: boolean, id: number) => void;
    goToUrl: (link: string) => void;
};

export const SendErrorCompany = ({ item, onHandleVisible, goToUrl }: Props) => {
    const visibleIco = <div className={ styles.newBagle } />;
    const created = moment(item.createdAt).fromNow();

    return (
        <div
            className={ styles.ls }
            onMouseEnter={ () => onHandleVisible(item.visible, item.id) }
            onClick={ () => goToUrl(ROUTES.COMPANY.path) }
        >
            <div className={ cn(styles.ls_img, styles.sendErrorCompany) }>
                <IconCertificateOff />
                { !item.visible && (visibleIco) }
            </div>
            <div className={ styles.ls_body }>
                <div className={ styles.ls_txt }>
                    В <b>вашей компании</b> были допущены ошибки. Посмотрите результат на детальной странице вашей компании.
                </div>
                <div className={ styles.ls_time }>{ created }</div>
            </div>
        </div>
    );
};
