import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Modal } from 'antd';

import { passwordRecovery } from 'ducks/app/actions';

import { selectPasswordRecovery } from 'ducks/app/selectors';

import { PasswordRecoveryPayload } from 'types/api';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { ROUTES } from 'constants/routes';
import { history } from 'App';

import { schema } from './schema';

const initialValues = schema.cast({});

type Props = {
    open: boolean;
    onOk: () => void;
    onCancel: () => void;
};

export const ModalPasswordRecovery = ({ open, onOk, onCancel }: Props) => {
    const { loading } = useSelector(selectPasswordRecovery);
    const put = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push(ROUTES.MAIN.path);
        }
    }, []);

    const itemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const onSubmit = (values: PasswordRecoveryPayload) => {
        put(passwordRecovery(values, () => {
            onOk();
            Modal.success({
                content: 'На вашу почту отправлено письмо',
            });
        }));
    };

    return (
        <Formik
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ schema }
        >
            { ({
                handleSubmit,
                errors,
                touched,
                handleChange,
                handleBlur,
            }) => {
                const errorList = {
                    email: getError(errors, touched, true, 'email'),
                };

                return (
                    <Modal
                        title="Восстановление пароля"
                        confirmLoading={ loading }
                        open={ open }
                        onOk={ () => handleSubmit() }
                        okText="Отправить"
                        onCancel={ onCancel }
                        cancelButtonProps={ { style: { display: 'none' } } }
                    >
                        <Form
                            onFinish={ handleSubmit }
                            labelAlign="left"
                            { ...itemLayout }
                        >

                            <Form.Item
                                label="Ваш E-mail"
                                extra={ errorList.email }
                                validateStatus={ getErrorStatus(!!errorList.email) }
                            >
                                <Input
                                    name="email"
                                    placeholder="Ваш E-mail"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                );
            } }
        </Formik>
    );
};
