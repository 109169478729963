import React from 'react';
import {
    Typography,
} from 'antd';
import { Layout } from 'components/ui/layout';
import { LayoutLeftMenu } from 'components/ui/layoutLeftMenu';
import { ROUTES } from './routes';

const { Title } = Typography;

export const Moderation = React.memo(() => (
    <Layout>
        <Title level={ 2 }>Модерация</Title>
        <LayoutLeftMenu
            title="Модерация"
            menu={ ROUTES }
        />
    </Layout>
));
