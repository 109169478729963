import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import {
    Form,
    Input,
    Button,
    Typography,
    Row,
    Col,
    Alert,
} from 'antd';
import { Link } from 'react-router-dom';

import { AppLoading } from 'components/ui/app-loading';
import { UploadFile } from 'components/upload-file';

import { saveUser } from 'ducks/user/actions';
import { selectProfile, selectUserLoading } from 'ducks/user/selectors';

import { getError, getErrorStatus } from 'form-helpers/validation';
import { ProfileForm } from 'form-helpers/profile/types';
import { schema } from 'form-helpers/profile/schema';
import { getInitialValues } from 'form-helpers/profile/mapping';

import { formatStringToUploadObj } from 'utils/formatters';

import { ROUTES } from 'constants/routes';
import { history } from 'App';

import { ProfileEditTelegram } from 'components/profile/edit';
import styles from './styles.module.scss';

const itemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const { Title } = Typography;

const initialValues = schema.cast({});

const layoutFormItem = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

export const Profile = React.memo(() => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const loading = useSelector(selectUserLoading);

    if (!user || loading.profile) {
        return <AppLoading />;
    }

    const onSubmit = (values: ProfileForm) => {
        put(saveUser(values));
    };

    const returnToProfile = () => history.push(ROUTES.USER.getPath({ id: user.id }));

    return (
        <React.Fragment>
            <Title className={ styles.title } level={ 4 }>Личные данные</Title>
            <div className={ styles.content }>
                { !user.isVerified && (
                    <Alert
                        message={ (<div>Для участия в торгах, необходимо <Link to="/edit-profile/security">подтвердить e-mail.</Link> Для этого нужно перейти по ссылке, которую мы вам отправили на e-mail указанный при регистрации</div>) }
                        type="info"
                        showIcon={ true }
                        className={ styles.alertNotConfirmed }
                    />
                ) }
                <Row>
                    <Col lg={ 18 } span={ 24 }>
                        <div className={ styles.form }>
                            <Formik<ProfileForm>
                                initialValues={ getInitialValues(initialValues, user) }
                                onSubmit={ onSubmit }
                                validationSchema={ schema }
                            >
                                { ({
                                    setFieldValue,
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    errors,
                                    touched,
                                    values,
                                }) => {
                                    const errorList = {
                                        avatar: getError(errors, touched, true, 'avatar'),
                                        firstName: getError(errors, touched, true, 'firstName'),
                                        lastName: getError(errors, touched, true, 'lastName'),
                                        aboutMe: getError(errors, touched, true, 'aboutMe'),
                                        phone: getError(errors, touched, true, 'phone'),
                                        skype: getError(errors, touched, true, 'skype'),
                                        viber: getError(errors, touched, true, 'viber'),
                                        whatsapp: getError(errors, touched, true, 'whatsapp'),
                                    };

                                    const onChangeFile = (name: string) => (file: any) => {
                                        setFieldValue(name, file || null, true);
                                    };

                                    return (
                                        <Form
                                            onFinish={ handleSubmit }
                                            labelAlign="left"
                                            { ...itemLayout }
                                        >
                                            <Form.Item
                                                label="Аватар"
                                                extra={ errorList.avatar }
                                                validateStatus={ getErrorStatus(!!errorList.avatar) }
                                            >
                                                <UploadFile
                                                    onChange={ onChangeFile('avatar') }
                                                    defaultValue={ [formatStringToUploadObj(values.avatar)] }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Ваше имя"
                                                extra={ errorList.firstName }
                                                validateStatus={ getErrorStatus(!!errorList.firstName) }
                                            >
                                                <Input
                                                    name="firstName"
                                                    placeholder="Ваше имя"
                                                    onChange={ handleChange }
                                                    onBlur={ handleBlur }
                                                    value={ values.firstName }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Ваша фамилия"
                                                extra={ errorList.lastName }
                                                validateStatus={ getErrorStatus(!!errorList.lastName) }
                                            >
                                                <Input
                                                    name="lastName"
                                                    placeholder="Ваша фамилия"
                                                    onChange={ handleChange }
                                                    onBlur={ handleBlur }
                                                    value={ values.lastName }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Обо мне"
                                                extra={ errorList.aboutMe }
                                                validateStatus={ getErrorStatus(!!errorList.aboutMe) }
                                            >
                                                <Input.TextArea
                                                    allowClear={ true }
                                                    autoSize={ { minRows: 2 } }
                                                    name="aboutMe"
                                                    placeholder="Краткая информация о вас, о вашей деятельности"
                                                    onChange={ handleChange }
                                                    onBlur={ handleBlur }
                                                    value={ values.aboutMe }
                                                />
                                            </Form.Item>
                                            <div className={ styles.flexItems }>
                                                <Form.Item
                                                    label="Telegram"
                                                    { ...layoutFormItem }
                                                >
                                                    <ProfileEditTelegram />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Skype"
                                                    extra={ errorList.skype }
                                                    validateStatus={ getErrorStatus(!!errorList.skype) }
                                                    { ...layoutFormItem }
                                                >
                                                    <p className={ styles.info }>Ипользуется для связи с вами</p>
                                                    <Input
                                                        name="skype"
                                                        placeholder="live:.cid.db4d0a213a796ea9"
                                                        onChange={ handleChange }
                                                        onBlur={ handleBlur }
                                                        value={ values.skype }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Viber"
                                                    extra={ errorList.viber }
                                                    validateStatus={ getErrorStatus(!!errorList.viber) }
                                                    { ...layoutFormItem }
                                                >
                                                    <p className={ styles.info }>Ипользуется для связи с вами</p>
                                                    <Input
                                                        name="viber"
                                                        placeholder="79000000000"
                                                        onChange={ handleChange }
                                                        onBlur={ handleBlur }
                                                        value={ values.viber }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Whatsapp"
                                                    extra={ errorList.whatsapp }
                                                    validateStatus={ getErrorStatus(!!errorList.whatsapp) }
                                                    { ...layoutFormItem }
                                                >
                                                    <p className={ styles.info }>Ипользуется для связи с вами</p>
                                                    <Input
                                                        name="whatsapp"
                                                        placeholder="79000000000"
                                                        onChange={ handleChange }
                                                        onBlur={ handleBlur }
                                                        value={ values.whatsapp }
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div>
                                                <Button
                                                    style={ { marginRight: 16 } }
                                                    onClick={ returnToProfile }
                                                >
                                                    Отменить
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    loading={ loading.save }
                                                >
                                                    Сохранить личные данные
                                                </Button>
                                            </div>
                                        </Form>
                                    );
                                } }
                            </Formik>
                        </div>
                    </Col>
                    <Col lg={ 8 } span={ 24 } />
                </Row>
            </div>
        </React.Fragment>
    );
});
