import React, { memo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography,
    Table,
    Pagination,
    Button,
} from 'antd';

import { Layout } from 'components/ui/layout';
import { Title } from 'components/ui/title';
import { Cart } from 'components/ui/cart';

import { clearOrders, getOrders } from 'ducks/orders/actions';

import {
    selectOrders,
    selectOrdersPagination,
    selectOrderLoading,
    selectMappedCategories,
} from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { ROUTES } from 'constants/routes';
import { history } from 'App';

import { Role } from 'types/account';
import { Order } from 'types/order';
import imgNoOrders from 'assets/page/my-orders/no-orders.png';
import { getColumns } from './columns';
import styles from './styles.module.scss';

const { Title: AntTitle } = Typography;

export const MyOrders = memo(() => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const orders = useSelector(selectOrders);
    const ordersLoading = useSelector(selectOrderLoading);
    const pagination = useSelector(selectOrdersPagination);
    const mappedCategories = useSelector(selectMappedCategories);
    const isCustomer = user?.role === Role.Customer;
    const queryField = isCustomer ? 'accountId' : 'executorId';

    useEffect(() => {
        put(getOrders({ [queryField]: user?.id, pagination: { page: 1 }, sort: { sortField: 'status', sortOrder: 'asc' } }));

        return () => {
            put(clearOrders());
        };
    }, []);

    const onChangePagination = useCallback((page: number) => {
        put(getOrders({ [queryField]: user?.id, pagination: { page }, sort: { sortField: 'status', sortOrder: 'asc' } }));
    }, []);

    const goGoCreateOrder = useCallback(() => {
        history.push(ROUTES.CREATE_ORDER.path);
    }, []);

    const onClickRow = useCallback((data: Order) => () => {
        history.push(ROUTES.VIEW_ORDER.getPath({ id: data.id }));
    }, []);

    return (
        <Layout>
            <Title
                title={ <AntTitle level={ 3 }>Мои заказы</AntTitle> }
                event={ isCustomer && <Button type="primary" onClick={ goGoCreateOrder }>Создать заказ</Button> }
            />
            { orders.length ? (
                <Cart
                    title={ `Заказы <span>${pagination.total}</span>` }
                >
                    <Table
                        rowKey="id"
                        className={ styles.table }
                        columns={ getColumns(mappedCategories, isCustomer) }
                        dataSource={ orders }
                        loading={ ordersLoading.getOrders }
                        pagination={ false }
                        onRow={ (record: any) => ({
                            onClick: onClickRow(record),
                        }) }
                        locale={ {
                            emptyText: isCustomer ? 'Пока вы не разместили ни одного заказа, ваши заказы будут отображаться на данной странице'
                                : 'После выигранного тендера, ваш заказ будет отображаться здесь',
                        } }
                    />
                </Cart>
            ) : (
                <div className={ styles.noOrder }>
                    <img src={ imgNoOrders } alt=""  className={ styles.noOrder__img } />
                    <div  className={ styles.noOrder__title }>{ isCustomer ? 'Пока вы не разместили ни одного заказа, ваши заказы будут отображаться на данной странице'
                        : 'После выигранного тендера, ваш заказ будет отображаться здесь' }</div>
                </div>
            ) }
            { Boolean(pagination.total) && (
                <div className={ styles.pagination }>
                    <Pagination
                        total={ pagination.total }
                        pageSize={ pagination.pageSize }
                        current={ pagination.page }
                        onChange={ onChangePagination }
                        showSizeChanger={ false }
                    />
                </div>
            ) }
        </Layout>
    );
});
