import { User } from 'types/user';

export const getInitialValues = (
    initialValues: any,
    user: User,
) => ({
    ...initialValues,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    avatar: user.avatar,
    aboutMe: user.aboutMe,
    skype: user.skype,
    viber: user.viber,
    whatsapp: user.whatsapp,
});

export const getInitialValuesEmail = (
    initialValues: any,
    user: User,
) => ({
    ...initialValues,
    id: user.id,
    email: user.email,
});

export const getInitialValuesPhone = (
    initialValues: any,
    user: User,
) => ({
    ...initialValues,
    id: user.id,
    phone: user.phone,
});

export const getInitialValuesPassword = (
    initialValues: any,
    user: User,
) => ({
    ...initialValues,
    id: user.id,
});
