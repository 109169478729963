import React from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TLoginButton, TLoginButtonSize } from 'react-telegram-auth';
import { ProfileFormTelegram } from 'form-helpers/profile/types';
import { removeSocialNetwork, saveUserSocialNetwork } from 'ducks/user/actions';
import { selectProfile, selectUserLoading } from 'ducks/user/selectors';
import styles from './styles.module.scss';

export const ProfileEditTelegram = React.memo(() => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const loading = useSelector(selectUserLoading);

    const onAuthCallback = (data: ProfileFormTelegram) => {
        put(saveUserSocialNetwork({ type: 'telegram', ...data }, 'telegram'));
    };
    const onRemoveTelegram = () => {
        put(removeSocialNetwork({
            type: 'telegram',
            tg: true,
            tgUid: true,
        }, 'telegram'));
    };

    return (
        <div className={ styles.container }>
            <p className={ styles.info }>Привяжите свой телеграм к своей учетной записи. Чтобы авторизоваться в 1 клик.</p>
            { user?.tg ? (
                <div className={ styles.tg_flex }>
                    <div className={ styles.tg_tied }>Привязан</div>
                    <div className={ styles.tg_link } onClick={ onRemoveTelegram }>Отключить</div>
                </div>
            ) : (
                <div className={ styles.tg_flex }>
                    <div className={ styles.tg_notTied }>Не привязан</div>
                    <TLoginButton
                        botName={ `${process.env.REACT_APP_TG_BOT}` }
                        buttonSize={ TLoginButtonSize.Medium }
                        lang="ru"
                        usePic={ false }
                        cornerRadius={ 20 }
                        onAuthCallback={ onAuthCallback }
                        requestAccess="write"
                    />
                </div>
            ) }
            <p className={ styles.info }>
                Подключить к нашему телеграм боту, чтобы получать свежую информацию.
                <a href={ `https://t.me/${process.env.REACT_APP_TG_BOT}` } target="_blank" rel="noreferrer"> Подключиться</a>
            </p>
            { loading.telegram && (<div className={ styles.loading }><Spin /></div>) }
        </div>
    );
});
