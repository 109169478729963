import { history } from 'App';
import { ButtonBack } from 'components/bot/button';
import { Buttons } from 'components/bot/buttons';
import { OrderDetail } from 'components/bot/order/detail';
import { clearOrders, getOrder } from 'ducks/orders/actions';
import { selectOrder } from 'ducks/orders/selectors';
import { clearTenders, getTenders } from 'ducks/tenders/actions';
import { selectTendersPagination } from 'ducks/tenders/selectors';
import { selectProfile } from 'ducks/user/selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { StatusInfo } from 'types/order';
import { declOfNum } from 'utils/formatters';
import { ROUTES } from '../routes';

type MatchParams = {
    id: string;
};

export const Order = () => {
    const put = useDispatch();
    const order = useSelector(selectOrder);
    const profile = useSelector(selectProfile);
    const tenderPagination = useSelector(selectTendersPagination);
    const [isHiddeTender, setHiddeTender] = useState(true);
    const { params: { id: orderId } } = useRouteMatch<MatchParams>();

    const isMe = Number(profile?.id) === Number(order?.account.id);
    const isExecutor = Number(profile?.id) === Number(order?.orderTender?.account.id);

    const goTenders = () => {
        history.push(ROUTES.TENDER.getPath({ id: orderId }));
    };

    useEffect(() => {
        put(getOrder({ orderId }));

        return () => {
            put(clearOrders());
        };
    }, [orderId]);

    useEffect(() => {
        if (order?.status === StatusInfo.new.key) {
            setHiddeTender(false);
        }

        if (!order?.orderTender && order?.status !== StatusInfo.draft.key) {
            put(getTenders({ pagination: { page: 1 }, orderId }));
        }

        return () => {
            put(clearTenders());
        };
    }, [order, isMe, isExecutor]);

    return (
        <React.Fragment>
            { order ? (<OrderDetail order={ order } />) : null }
            { !isHiddeTender && (
                <Buttons>
                    <ButtonBack />
                    <button
                        type="button"
                        onClick={ goTenders }
                    >
                        { tenderPagination.total > 0 ? `${tenderPagination.total} ${declOfNum(tenderPagination.total, ['предложениe', 'предложений', 'предложений'])}` : 'Предложения к заказу' }
                    </button>
                </Buttons>
            ) }
        </React.Fragment>
    );
};
