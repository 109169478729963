export enum COMMENT {
    JOIN = 'COMMENT_JOIN',
    SEND = 'COMMENT_SEND',
    EDIT = 'COMMENT__EDIT',
    REMOVE = 'COMMENT__REMOVE',
    UNSUBSCRIBE = 'COMMENT__UNSUBSCRIBE',
}

export enum ACCOUNT {
    READ = 'ACCOUNT_READ',
    JOIN = 'ACCOUNT_JOIN',
    SEND = 'ACCOUNT_SEND',
    EDIT = 'ACCOUNT__EDIT',
    REMOVE = 'ACCOUNT__REMOVE',
    NEW_TENDER = 'ACCOUNT__NEW_TENDER',
    UNSUBSCRIBE = 'ACCOUNT__UNSUBSCRIBE',
}

export enum TENDER {
    JOIN = 'TENDER_JOIN',
    UNSUBSCRIBE = 'TENDER__UNSUBSCRIBE',
    READ = 'TENDER_READ',
    SEND = 'TENDER_SEND',
    CHOOSE = 'TENDER_CHOOSE',
}
