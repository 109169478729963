import { put, call } from 'redux-saga/effects';
import {
    setNotification,
    setNotificationError,
    setNotificationFinish,
} from 'ducks/notification/actions';

import { fetchers } from 'api';

export function* setNotificationRequest(action: ReturnType<typeof setNotification>) {
    try {
        const response = yield call(fetchers.setNotification, action.payload);

        yield put(setNotificationFinish(response));
    } catch (error) {
        yield put(setNotificationError());
    }
}
