import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    createOrder,
    createOrderError,
    createOrderFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

import { history } from 'App';

import { ROUTES } from 'constants/routes';

export function* createOrderRequest({ data, goToPage }: ReturnType<typeof createOrder>) {
    try {
        const response = yield call(fetchers.createOrder, data);

        message.success('Заказ успешно создан');

        if (goToPage) {
            history.push(ROUTES.VIEW_ORDER.getPath({ id: response.data.id }));
        }

        yield put(createOrderFinish(response.data));
    } catch (error) {
        yield put(createOrderError());
    }
}
