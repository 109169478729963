import React, { memo, Suspense, useState } from 'react';
import { Spin, Checkbox, Form, Input, Segmented, Modal } from 'antd';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { MaskedInput } from 'antd-mask-input';
import { Footer } from 'components/footer';
import { Container } from 'components/ui/container';
import { EventInit } from 'components/ui/yandex-metrika';
import { LHeader } from 'components/header';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { schema } from 'form-helpers/footer/schema';
import { sendFeedback } from 'ducks/feedback/actions';
import { AddFeedback as FeedbackFormType } from 'types/feedback';
import cn from 'classnames';
import styles from './styles.module.scss';

const optionSegmented = [
    {
        label: (
            <div>
                Отзывы <br /> и предложения
            </div>
        ),
        value: 'Отзывы и предложения',
    },
    {
        label: (
            <div>
                Вопрос <br /> по контенту
            </div>
        ),
        value: 'Вопрос по контенту',
    },
    {
        label: <div>Жалоба</div>,
        value: 'Жалоба',
    },
    {
        label: <div>Другое</div>,
        value: 'Другое',
    },
];

export const AboutCompany = memo(() => {
    const put = useDispatch();

    const [isSubmitTry, setIsSubmitTry] = useState(false);
    const [checked, setChecked] = useState(true);

    const success = () => {
        Modal.success({
            title: 'Ваше обращение успешно отправлено',
        });
    };

    const onSendSubmit = (values: FeedbackFormType) => {
        const newData = values;

        newData.phone = values.phone.replaceAll(' ', '');
        put(sendFeedback(newData, success));
    };

    return (
        <Suspense fallback={ <Spin /> }>
            <LHeader />
            <Container size='m' className={ styles.flex }>
                <div className={ styles.company }>
                    <div className={ styles.company__item }>
                        <div className={ styles.company_title }>ООО &ldquo;СЕРТТОРГ&rdquo;</div>
                        <div className={ styles.company_val }>
                            <span>ОГРН</span>
                            <span>1227700340053</span>
                        </div>
                        <div className={ styles.company_val }>
                            <span>ИНН</span>
                            <span>9721166922</span>
                        </div>
                    </div>
                    <div className={ cn(styles.company__item, styles.row, styles.gap) }>
                        <div className={ cn(styles.company_val, styles.col) }>
                            <span>Телефон:</span>
                            <a href='tel:88007772908'>8-800-777-2908</a>
                        </div>
                        <div className={ cn(styles.company_val, styles.col) }>
                            <span>Почта:</span>
                            <a href='mailto:info@cert-torg.ru'>info@cert-torg.ru</a>
                        </div>
                    </div>
                    <div className={ styles.company__item }>
                        <div className={ cn(styles.company_val, styles.col) }>
                            <span>Часы работы:</span>
                            <span className={ styles.fz16 }>Пн. - Вс.: Круглосуточно</span>
                        </div>
                    </div>
                    <div className={ styles.company__item }>
                        <div className={ cn(styles.company_val, styles.col) }>
                            <span>Адрес:</span>
                            <span className={ styles.fz16 }>
                                111677, город Москва, улица Маршала Ерёменко, дом 5, корпус 2,
                                помещение 3н, комната 2
                            </span>
                        </div>
                    </div>
                </div>
                <div className={ styles.contact }>
                    <Formik<FeedbackFormType>
                        initialValues={ schema.cast({ types: 'Отзывы и предложения' }) }
                        onSubmit={ onSendSubmit }
                        validationSchema={ schema }
                    >
                        { ({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            errors,
                            touched,
                        }) => {
                            const errorList = {
                                username: getError(errors, touched, isSubmitTry, 'username'),
                                phone: getError(errors, touched, isSubmitTry, 'phone'),
                                email: getError(errors, touched, isSubmitTry, 'email'),
                                city: getError(errors, touched, isSubmitTry, 'city'),
                                message: getError(errors, touched, isSubmitTry, 'message'),
                            };

                            const onSubmit = () => {
                                setIsSubmitTry(true);
                                handleSubmit();
                            };

                            const handleChangeSegmented = (value: string | number) => {
                                setFieldValue('types', value, true);
                            };

                            return (
                                <div>
                                    <div className={ styles.contact__title }>Обратная связь</div>
                                    <p className={ styles.contact__info }>
                                        Мы всегда готовы ответить на любой вопрос, а также услышать
                                        ваши отзывы и предложения по работе нашего сервиса.
                                    </p>
                                    <Form
                                        name='Feedback'
                                        autoComplete='off'
                                        layout='vertical'
                                        onFinish={ handleSubmit }
                                    >
                                        <div className={ styles.contact__row }>
                                            <div className={ styles.contact__col }>
                                                <Form.Item
                                                    extra={ errorList.username }
                                                    validateStatus={ getErrorStatus(
                                                        !!errorList.username,
                                                    ) }
                                                >
                                                    <Input
                                                        placeholder='Ваше Имя'
                                                        size='large'
                                                        name='username'
                                                        onChange={ handleChange }
                                                        onBlur={ handleBlur }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    extra={ errorList.phone }
                                                    validateStatus={ getErrorStatus(
                                                        !!errorList.phone,
                                                    ) }
                                                >
                                                    <MaskedInput
                                                        mask='+7 000 000 00 00'
                                                        name='phone'
                                                        placeholder='Телефон'
                                                        size='large'
                                                        onChange={ handleChange }
                                                        onBlur={ handleBlur }
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className={ styles.contact__col }>
                                                <Form.Item
                                                    extra={ errorList.email }
                                                    validateStatus={ getErrorStatus(
                                                        !!errorList.email,
                                                    ) }
                                                >
                                                    <Input
                                                        name='email'
                                                        placeholder='Адрес электронной почты'
                                                        size='large'
                                                        onChange={ handleChange }
                                                        onBlur={ handleBlur }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    extra={ errorList.city }
                                                    validateStatus={ getErrorStatus(
                                                        !!errorList.city,
                                                    ) }
                                                    hidden={ true }
                                                >
                                                    <Input
                                                        name='city'
                                                        placeholder='Ваш город'
                                                        size='large'
                                                        onChange={ handleChange }
                                                        onBlur={ handleBlur }
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <Form.Item>
                                            <Segmented
                                                name='types'
                                                block={ true }
                                                className='segmented'
                                                options={ optionSegmented }
                                                onChange={ handleChangeSegmented }
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            extra={ errorList.message }
                                            validateStatus={ getErrorStatus(!!errorList.message) }
                                        >
                                            <Input.TextArea
                                                name='message'
                                                placeholder='Опишите ваш вопрос'
                                                autoSize={ { minRows: 3, maxRows: 6 } }
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                            />
                                        </Form.Item>
                                        <div className={ styles.contact__row }>
                                            <div className={ styles.contact__col }>
                                                <Checkbox
                                                    checked={ checked }
                                                    onChange={ () => setChecked(!checked) }
                                                >
                                                    Даю согласие на обработку и хранение
                                                    персональных данных
                                                </Checkbox>
                                            </div>
                                            <div className={ styles.contact__col }>
                                                <button
                                                    type='submit'
                                                    className={ styles.contact__button }
                                                    disabled={ !checked }
                                                    onClick={ onSubmit }
                                                >
                                                    Отправить
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            );
                        } }
                    </Formik>
                </div>
            </Container>
            <Footer />
            <EventInit />
        </Suspense>
    );
});
