import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table,
    Pagination,
    Modal,
    Checkbox,
    Form,
    message,
    Typography,
    Button,
} from 'antd';

import { saveCompany } from 'ducks/company/actions';
import { getCompanyForModeration } from 'ducks/moderation/actions';

import { selectProfile } from 'ducks/user/selectors';
import { selectCompanyLoading } from 'ducks/company/selectors';
import {
    selectCompanies,
    selectCompaniesPagination,
    selectModerationLoading,
} from 'ducks/moderation/selectors';

import { fields } from 'pages/edit-profile/company/fields';
import { Title } from 'components/ui/title';

import { Company, CompanyType, CompanyTypeText } from 'types/company';
import { checkAccess } from 'utils/access';
import { ACCESS } from 'constants/access';
import { columns } from './columns';

import styles from './styles.module.scss';

const { Title: AntTitle } = Typography;

const FormItem = Form.Item;

export const Companies = React.memo(() => {
    const put = useDispatch();
    const companyLoading = useSelector(selectCompanyLoading);
    const loading = useSelector(selectModerationLoading);
    const pagination = useSelector(selectCompaniesPagination);
    const companies = useSelector(selectCompanies);
    const profile = useSelector(selectProfile);

    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [data, setData] = React.useState<Company | null>(null);
    const [verified, setVerified] = React.useState(false);
    const [comment, setComment] = React.useState<string | null>(null);

    const isEditAllow = checkAccess(profile?.rights, ACCESS.EXPERT_UPDATE);

    const getLoad = (page = 1) => put(getCompanyForModeration({ pagination: { page } }));

    useEffect(() => {
        getLoad();
    }, []);

    const onChangePagination = (page: number) => {
        getLoad(page);
    };

    const onCloseModal = () => {
        setIsModalVisible(false);
        setData(null);
    };

    const onShowModal = (record: Company) => () => {
        setIsModalVisible(true);
        setData(record);
        setComment(record.comment as string);
    };

    const onSubmit = () => {
        if (!comment && !verified) {
            message.error('Подтвердите компанию, либо заполните комментарий');

            return;
        }
        put(saveCompany(
            {
                comment: comment || null,
                verified,
                sendForverif: verified ? 2 : 0,
            },
            data!.accounts![0].id as number,
            onCloseModal,
        ));
    };

    return (
        <React.Fragment>
            <Title
                className={ styles.title }
                title={ <AntTitle level={ 3 }>Компании</AntTitle> }
                event={ <Button onClick={ () => getLoad() } type="ghost">Обновить</Button> }
            />
            <Table
                columns={ columns }
                rowKey="id"
                loading={ loading.getCompanies }
                className={ styles.table }
                dataSource={ companies }
                pagination={ false }
                onRow={ (record: Company) => ({
                    onClick: isEditAllow ? onShowModal(record) : () => {},
                }) }

            />
            {
                Boolean(pagination.total > 1) && (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total }
                            pageSize={ pagination.pageSize }
                            current={ pagination.page }
                            onChange={ onChangePagination }
                        />
                    </div>
                )
            }
            {
                data && (
                    <Modal
                        open={ isModalVisible }
                        title="Проверка данных"
                        onCancel={ onCloseModal }
                        okText="Сохранить"
                        onOk={ onSubmit }
                        confirmLoading={ companyLoading.save }
                    >
                        <div>
                            <b>Тип: </b> { CompanyTypeText[data.typeOfOrgan as CompanyType] }
                        </div>
                        {
                            fields[data.typeOfOrgan as CompanyType].map((item) => (
                                item.type === 'file' ? (
                                    <div key={ item.key }>
                                        <b>{ item.text }: </b>
                                        {
                                            data[item.key as keyof Company]
                                                ? (
                                                    <a
                                                        href={ data[item.key as keyof Company] }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        посмотреть
                                                    </a>
                                                ) : 'Не заполнено'
                                        }
                                    </div>
                                ) : (
                                    <div key={ item.key }>
                                        <b>{ item.text }: </b> { data[item.key as keyof Company] || 'Не заполнено' }
                                    </div>
                                )
                            ))
                        }
                        <Checkbox
                            style={ { margin: '16px 0 8px' } }
                            onChange={ (e) => setVerified(e.target.checked) }
                        >
                            Подтвердить компанию
                        </Checkbox>
                        <FormItem
                            label="Оставьте комментарий, если что-то не так"
                        >
                            <textarea
                                name="comment"
                                placeholder="Оставьте комментарий, если что-то не так"
                                rows={ 4 }
                                className="textarea"
                                defaultValue={ data.comment || '' }
                                onChange={ (e) => setComment(e.target.value) }
                            />
                        </FormItem>
                    </Modal>
                )
            }
        </React.Fragment>
    );
});
