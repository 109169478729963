import { InferValueTypes } from 'types/common';
import { Tender } from 'types/tender';
import { TendersActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type TendersState = {
    loading: {
        get: boolean;
        send: boolean;
        accept: boolean;
        edit: boolean;
        revoke: boolean;
    };
    list: Tender[];
    isRevoke: boolean;
    offer: Tender | null;
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: TendersState = {
    loading: {
        get: false,
        send: false,
        accept: false,
        edit: false,
        revoke: false,
    },
    list: [],
    isRevoke: false,
    offer: null,
    pagination: {
        pageSize: 8,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'id',
        sortOrder: 'desc',
    },
};

export function tendersReducer(state = initialState, action: ActionTypes): TendersState {
    switch (action.type) {
        // СПИСОК ПРЕДЛОЖЕНИЙ
        case TendersActionTypes.GET_TENDERS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case TendersActionTypes.GET_TENDERS_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: action.response.data,
                isRevoke: action.response.isRevoke,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case TendersActionTypes.SOCKET_ADD_NEW_TENDER:
            return {
                ...state,
                list: [action.response, ...state.list],
            };

        case TendersActionTypes.GET_TENDERS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case TendersActionTypes.SEND_TENDER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    send: true,
                },
            };

        case TendersActionTypes.SEND_TENDER_ERROR:
        case TendersActionTypes.SEND_TENDER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    send: false,
                },
            };

        case TendersActionTypes.ACCEPT_TENDER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    accept: true,
                },
            };

        case TendersActionTypes.ACCEPT_TENDER_FINISH:
        case TendersActionTypes.ACCEPT_TENDER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    accept: false,
                },
            };

        case TendersActionTypes.OFFER_TENDER_FINISH:
            return {
                ...state,
                offer: action.tender,
            };

        case TendersActionTypes.CLEAR_TENDERS:
            return {
                ...initialState,
            };

        case TendersActionTypes.SEE_TENDER_FINISH:
            return {
                ...state,
                list: state.list.map((item) => (item.id === action.response.id ? { ...item, ...action.response } : item)),
            };

        case TendersActionTypes.EDIT_TENDER:
            return {
                ...state,
                list: state.list.map((item) => (item.id === action.response.id ? { ...item, ...action.response } : item)),
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };

        case TendersActionTypes.EDIT_TENDER_ERROR:
        case TendersActionTypes.EDIT_TENDER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };
        case TendersActionTypes.REVOKE_TENDER:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    revoke: true,
                },
            };

        case TendersActionTypes.REVOKE_TENDER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    revoke: true,
                },
            };

        case TendersActionTypes.REVOKE_TENDER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    revoke: false,
                },
                isRevoke: false,
                list: state.list.filter((item) => item.id !== action.response.id),
            };

        default: {
            return state;
        }
    }
}
