import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography,
    Pagination,
    Button,
} from 'antd';
import { eventYm } from 'components/ui/yandex-metrika';
import { useLocation } from 'react-router';
import qs from 'qs';
import { history } from 'App';
import { ROUTES } from 'constants/routes';

import { OrderShort } from 'components/order/short';
import { Title } from 'components/ui/title';
import { Layout } from 'components/ui/layout';
import { SubscribeCategories } from 'components/subscribe-categories';
import { OrderItemSkeleton } from 'components/ui/skeleton';
import { OrdersFilter } from 'components/orders-filter';

import { selectProfile } from 'ducks/user/selectors';
import { getOrders, clearOrders } from 'ducks/orders/actions';

import {
    selectOrders,
    selectOrdersPagination,
    selectOrderLoading,
} from 'ducks/orders/selectors';

import { Status, StatusInfo } from 'types/order';
import { Role } from 'types/account';

import styles from './styles.module.scss';

const { Title: AntTitle } = Typography;

export const Orders = memo(() => {
    const { search } = useLocation();
    const put = useDispatch();
    const orders = useSelector(selectOrders);
    const profil = useSelector(selectProfile);
    const ordersLoading = useSelector(selectOrderLoading);
    const pagination = useSelector(selectOrdersPagination);

    const onChangePagination = (page: number) => {
        put(getOrders({ pagination: { page }, status: StatusInfo.new.key as Status }));
    };

    useEffect(() => {
        const filter = qs.parse(search, { ignoreQueryPrefix: true, interpretNumericEntities: true, comma: true }) || {};

        put(getOrders({ pagination: { page: 1 }, ...filter, status: StatusInfo.new.key as Status }));

        return () => {
            put(clearOrders());
        };
    }, []);

    const createOrder = () => {
        history.push(ROUTES.CREATE_ORDER.path);
        eventYm('reachGoal', 'create');
    };

    return (
        <Layout>
            <Title
                title={ <AntTitle level={ 3 }>Заказы</AntTitle> }
                event={ profil?.role === Role.Customer && <Button type="primary" onClick={ createOrder }>Разместить свой заказ</Button> }
            />
            <div className={ styles.row }>
                <div className={ styles.left }>
                    { !orders.length && !ordersLoading.getOrders && (<div>Заказы отсутствуют</div>) }
                    {
                        ordersLoading.getOrders
                            ? [...Array(pagination.pageSize)].map((e, i) => <OrderItemSkeleton key={ `skeleton-${i}` } />)
                            : orders.map((order) => <OrderShort key={ `order-${order.id}` } { ...order } />)
                    }
                </div>
                <div className={ styles.right }>
                    <OrdersFilter />
                    <SubscribeCategories />
                </div>
            </div>
            {
                Boolean(pagination.total) && (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total }
                            pageSize={ pagination.pageSize }
                            current={ pagination.page }
                            showSizeChanger={ false }
                            onChange={ onChangePagination }
                        />
                    </div>
                )
            }
        </Layout>
    );
});
