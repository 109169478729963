import * as Api from 'types/api';
import {
    ModerationActionTypes,
} from './action-types';

export const getCompanyForModeration = (payload: Api.GetCompaniesForModerationPayload) => ({
    type: ModerationActionTypes.GET_COMPANY_FOR_MODERATION_START,
    payload,
});

export const getCompanyForModerationError = () => ({
    type: ModerationActionTypes.GET_COMPANY_FOR_MODERATION_ERROR,
});

export const getCompanyForModerationFinish = (response: Api.GetCompaniesForModerationResponse) => ({
    type: ModerationActionTypes.GET_COMPANY_FOR_MODERATION_FINISH,
    response,
});
