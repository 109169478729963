export type Company = {
    accounts?: Array<{
        id: number;
        avatar: string;
        firstName: string;
        lastName: string;
    }>;
    organization: string | null;
    inn: number | string | null;
    kpp: number | string | null;
    address: string | null;
    bicBank: number | string | null;
    scoreNumber: number | string | null;
    certificateNumber: number | string | null;
    appOrderDir: any;
    decisionCreate: any;
    pageCharter: any;
    fio: string | null;
    passportNumber: number | string | null;
    residenceAddress: string | null;
    typeOfOrgan: CompanyType | null;
    verified?: number;
    created?: string | null;
    comment?: string | null;
    id?: number;
    sendForverif?: number;
};

export enum CompanyType {
    company = 'company',
    individual = 'individual',
}

export enum CompanyTypeText {
    company = 'Юридическое лицо',
    individual = 'Физическое лицо',
}

export type Management = {
    name: string;
    post: string;
    disqualified?: any;
};

export type State = {
    status: string;
    code?: any;
    actuality_date: number;
    registration_date: number;
    liquidation_date?: any;
};

export type Opf = {
    type: string;
    code: string;
    full: string;
    short: string;
};

export type Name = {
    full_with_opf: string;
    short_with_opf: string;
    latin?: any;
    full: string;
    short: string;
};

export type Finance = {
    tax_system?: any;
    income?: any;
    expense?: any;
    debt?: any;
    penalty?: any;
    year?: any;
};

export type Data2 = {
    postal_code: string;
    country: string;
    country_iso_code: string;
    federal_district: string;
    region_fias_id: string;
    region_kladr_id: string;
    region_iso_code: string;
    region_with_type: string;
    region_type: string;
    region_type_full: string;
    region: string;
    area_fias_id?: any;
    area_kladr_id?: any;
    area_with_type?: any;
    area_type?: any;
    area_type_full?: any;
    area?: any;
    city_fias_id: string;
    city_kladr_id: string;
    city_with_type: string;
    city_type: string;
    city_type_full: string;
    city: string;
    city_area?: any;
    city_district_fias_id?: any;
    city_district_kladr_id?: any;
    city_district_with_type: string;
    city_district_type: string;
    city_district_type_full: string;
    city_district: string;
    settlement_fias_id?: any;
    settlement_kladr_id?: any;
    settlement_with_type?: any;
    settlement_type?: any;
    settlement_type_full?: any;
    settlement?: any;
    street_fias_id: string;
    street_kladr_id: string;
    street_with_type: string;
    street_type: string;
    street_type_full: string;
    street: string;
    stead_fias_id?: any;
    stead_cadnum?: any;
    stead_type?: any;
    stead_type_full?: any;
    stead?: any;
    house_fias_id: string;
    house_kladr_id: string;
    house_cadnum?: any;
    house_type: string;
    house_type_full: string;
    house: string;
    block_type?: any;
    block_type_full?: any;
    block?: any;
    entrance?: any;
    floor?: any;
    flat_fias_id?: any;
    flat_cadnum?: any;
    flat_type: string;
    flat_type_full: string;
    flat: string;
    flat_area?: any;
    square_meter_price?: any;
    flat_price?: any;
    postal_box?: any;
    fias_id: string;
    fias_code: string;
    fias_level: string;
    fias_actuality_state: string;
    kladr_id: string;
    geoname_id: string;
    capital_marker: string;
    okato: string;
    oktmo: string;
    tax_office: string;
    tax_office_legal: string;
    timezone: string;
    geo_lat: string;
    geo_lon: string;
    beltway_hit?: any;
    beltway_distance?: any;
    metro?: any;
    qc_geo: string;
    qc_complete?: any;
    qc_house?: any;
    history_values?: any;
    unparsed_parts?: any;
    source: string;
    qc: string;
};

export type Address = {
    value: string;
    unrestricted_value: string;
    data: Data2;
};

export type Data = {
    kpp: string;
    capital?: any;
    management: Management;
    founders?: any;
    managers?: any;
    predecessors?: any;
    successors?: any;
    branch_type: string;
    branch_count: number;
    source?: any;
    qc?: any;
    hid: string;
    type: string;
    state: State;
    opf: Opf;
    name: Name;
    inn: string;
    ogrn: string;
    okpo: string;
    okato: string;
    oktmo: string;
    okogu: string;
    okfs: string;
    okved: string;
    okveds?: any;
    authorities?: any;
    documents?: any;
    licenses?: any;
    finance: Finance;
    address: Address;
    phones?: any;
    emails?: any;
    ogrn_date: number;
    okved_type: string;
    employee_count?: any;
};

export type CompanyByInn = {
    value: string;
    unrestricted_value: string;
    data: Data;
};
