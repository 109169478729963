import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form, Input, Button, Space,
} from 'antd';

import { signIn } from 'ducks/app/actions';

import { selectSignInLoading } from 'ducks/app/selectors';

import { schema } from 'form-helpers/auth/schema';
import { AuthFormType } from 'form-helpers/auth/types';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { history } from 'App';

import { EventInit, eventYm } from 'components/ui/yandex-metrika';
import { useTelegram } from 'components/ui/hooks/useTelegram';
import { IconBrandTelegram } from '@tabler/icons';
import { ROUTES } from 'constants/routes';

import styles from './styles.module.scss';

const initialValues = schema.cast({});

export const Auth = () => {
    const { user } = useTelegram();
    const loading = useSelector(selectSignInLoading);
    const put = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push(ROUTES.BOT.path);
        }
    }, []);

    const itemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const onSubmit = (values: AuthFormType) => {
        put(signIn(values, () => history.push(ROUTES.BOT.path)));
        eventYm('reachGoal', 'sign');
    };

    const onAuth = () => {
        put(signIn({
            type: 'telegram',
            tg: user.username,
            tgUid: user.id,
        }, () => history.push(ROUTES.BOT.path)));
        eventYm('reachGoal', 'sign');
    };

    return (
        <div className={ styles.container }>
            <Formik<AuthFormType>
                initialValues={ initialValues }
                onSubmit={ onSubmit }
                validationSchema={ schema }
            >
                { ({
                    handleSubmit,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                }) => {
                    const errorList = {
                        email: getError(errors, touched, true, 'email'),
                        password: getError(errors, touched, true, 'password'),
                    };

                    return (
                        <Form
                            onFinish={ handleSubmit }
                            labelAlign="left"
                            { ...itemLayout }
                        >
                            <Form.Item
                                label="Ваш E-mail"
                                extra={ errorList.email }
                                validateStatus={ getErrorStatus(!!errorList.email) }
                            >
                                <Input
                                    name="email"
                                    placeholder="Ваш E-mail"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Пароль"
                                extra={ errorList.password }
                                validateStatus={ getErrorStatus(!!errorList.password) }
                            >
                                <Input
                                    name="password"
                                    placeholder="Пароль"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                    type="password"
                                />
                            </Form.Item>

                            <Space align="center" size="middle">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={ loading }
                                >
                                    Войти
                                </Button>
                                { user && (
                                    <React.Fragment>
                                        <span>или</span>
                                        <Button
                                            type="link"
                                            htmlType="submit"
                                            loading={ loading }
                                            onClick={ onAuth }
                                        >
                                            <IconBrandTelegram className={ styles.icon_tg } />
                                        </Button>
                                    </React.Fragment>
                                ) }
                            </Space>
                        </Form>
                    );
                } }
            </Formik>
            <EventInit />
        </div>
    );
};
