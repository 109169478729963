import React from 'react';
import cn from 'classnames';
import { OrderFile } from 'types/order';
import { IconDownload } from '@tabler/icons';
import { getImages, getPath } from 'utils/formatters';

import styles from './styles.module.scss';

type Props = {
    files: OrderFile[] | null;
};

const getName = (path: string) => {
    if (path) {
        // const m = path.toString().match(/.*\/(.+?)\./);
        const m = path.toString().match(/[^_]+w*$/gm);

        if (m && m.length > 0) {
            return m[0];
        }
    }

    return '';
};

export const FilesList = ({ files }: Props) => {
    if (!files?.length) {
        return null;
    }

    return (
        <div className={ cn(styles.blockTop, styles.files) }>
            <div className={ styles.blockTop_top }>Вложения</div>
            <div className={ styles.file }>
                {
                    files?.length ? files.map((file, i) => (
                        <a
                            key={ i }
                            href={ getPath(file.path) }
                            target="_blank"
                            rel="noopener noreferrer"
                            className={ styles.file_item }
                        >
                            <div className={ styles.file_img }>
                                <img src={ getImages(file.path.split('.').pop()) } alt="" />
                            </div>
                            <div className={ styles.file_name }>
                                { getName(file.path) }
                            </div>
                            <IconDownload size={ 15 } className={ styles.file_ico } />
                        </a>
                    )) : null
                }
            </div>
        </div>
    );
};
