
import { put, call } from 'redux-saga/effects';

import {
    getBlogById,
    getBlogByIdError,
    getBlogByIdFinish,
} from 'ducks/blog/actions';


import { fetchers } from 'api';

export function* getsBlogByIdRequest({ payload }: ReturnType<typeof getBlogById>) {
    try {
        const response = yield call(fetchers.getBlogById, payload);

        yield put(getBlogByIdFinish(response));
    } catch (error) {
        yield put(getBlogByIdError());
    }
}
