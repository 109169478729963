import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Table,
    Pagination,
    Select,
    Form,
} from 'antd';
import { VerticalAlignBottomOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';

import { Layout } from 'components/ui/layout';
import { Cart } from 'components/ui/cart';
import { Title } from 'components/ui/title';

import { getUsers, blockedUser } from 'ducks/user/actions';

import {
    selectUsers,
    selectUsersPagination,
    selectUserLoading,
    selectUsersSort,
    selectProfile,
} from 'ducks/user/selectors';

import { ROUTES } from 'constants/routes';
import { history } from 'App';

import { User } from 'types/user';
import { getColumns } from './columns';

import styles from './styles.module.scss';

const { Title: AntTitle } = Typography;
const { Option } = Select;

export const Users = React.memo(() => {
    const put = useDispatch();
    const loading = useSelector(selectUserLoading);
    const pagination = useSelector(selectUsersPagination);
    const sort = useSelector(selectUsersSort);
    const users = useSelector(selectUsers);
    const profile = useSelector(selectProfile);

    useEffect(() => {
        put(getUsers({ pagination: { page: 1 } }));
    }, []);

    const onChangePagination = (page: number) => {
        put(getUsers({ pagination: { page } }));
    };

    const onClickRow = useCallback((data: User) => () => {
        history.push(ROUTES.USER.getPath({ id: data.id }));
    }, []);

    const onChangeSort = (field: string) => (value: string) => {
        put(getUsers({
            pagination: {
                page: 1,
            },
            sort: {
                ...sort,
                [field]: value,
            },
        }));
    };

    const onBlocked = (value: number, userId: number | string, e: MouseEvent) => {
        e.stopPropagation();
        put(blockedUser(value, userId));
    };

    return (
        <Layout>
            <Title
                title={ <AntTitle level={ 2 }>Пользователи</AntTitle> }
                event={ (
                    <div className={ styles.sort }>
                        <div className={ styles.sortItem }>
                            <Form.Item>
                                <Select
                                    className={ styles.select }
                                    placeholder="Выберите..."
                                    onChange={ onChangeSort('sortOrder') }
                                    defaultValue={ sort.sortOrder }
                                >
                                    <Option value="desc"><VerticalAlignTopOutlined /> По убыванию</Option>
                                    <Option value="asc"><VerticalAlignBottomOutlined /> По возрастанию</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                ) }
            />
            <Cart
                title={ `Список пользователей <span>${pagination.total}</span>` }
            >
                <Table
                    rowKey="id"
                    loading={ loading.getList }
                    className={ styles.table }
                    dataSource={ users }
                    columns={ getColumns({ loading, profile, actions: { onBlocked } }) }
                    pagination={ false }
                    onRow={ (record: any) => ({
                        onClick: onClickRow(record),
                    }) }
                />
            </Cart>
            <div className={ styles.pagination }>
                <Pagination
                    total={ pagination.total }
                    pageSize={ pagination.pageSize }
                    current={ pagination.page }
                    onChange={ onChangePagination }
                />
            </div>
        </Layout>
    );
});
