import React from 'react';
import logo from 'assets/logo.svg';
import tg_logo_black from 'assets/tg_logo_black.png';
import cn from 'classnames';
import { useTelegram } from 'components/ui/hooks/useTelegram';
import { history } from 'App';
import { ROUTES } from 'pages/bot/routes';
import styles from './styles.module.scss';

export const BotHeader = () => {
    const { theme } = useTelegram();

    const headStyle = cn({
        [styles.header]: true,
        [styles[theme]]: true,
    });

    const logoSrc = theme === 'light' ? logo : tg_logo_black;

    const goHome = () => history.push(ROUTES.MAIN.path);

    return (
        <div className={ headStyle } onClick={ goHome }>
            <img src={ logoSrc } alt="" className={ styles.logo } />
        </div>
    );
};
