import * as Api from 'types/api';
import { AppActionTypes } from './action-types';

export const signUp = (values: Api.SignUpPayload, cb?: () => void) => ({
    type: AppActionTypes.SIGN_UP_START,
    values,
    cb,
});

export const signUpError = () => ({
    type: AppActionTypes.SIGN_UP_ERROR,
});

export const signUpFinish = () => ({
    type: AppActionTypes.SIGN_UP_FINISH,
});

export const signIn = (values: Api.SignInPayload, cb?: () => void) => ({
    type: AppActionTypes.SIGN_IN_START,
    values,
    cb,
});

export const signInError = () => ({
    type: AppActionTypes.SIGN_IN_ERROR,
});

export const signInFinish = (data: Omit<Api.SignInResponse, 'jwtToken'>) => ({
    type: AppActionTypes.SIGN_IN_FINISH,
    data,
});

export const passwordRecovery = (values: Api.PasswordRecoveryPayload, cb?: () => void) => ({
    type: AppActionTypes.PASSWORD_RECOVERY_START,
    values,
    cb,
});

export const passwordRecoveryError = () => ({
    type: AppActionTypes.PASSWORD_RECOVERY_ERROR,
});

export const passwordRecoveryFinish = () => ({
    type: AppActionTypes.PASSWORD_RECOVERY_FINISH,
});

export const registerAutoSignIn = (values: Api.SignUpPayload, cb?: () => void) => ({
    type: AppActionTypes.REGISTER_AUTO_SIGN_IN_START,
    values,
    cb,
});

export const registerAutoSignInError = () => ({
    type: AppActionTypes.REGISTER_AUTO_SIGN_IN_ERROR,
});

export const registerAutoSignInFinish = (response: Omit<Api.SignInResponse, 'jwtToken'>) => ({
    type: AppActionTypes.REGISTER_AUTO_SIGN_IN_FINISH,
    response,
});
