import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { appReducer } from './app/reducer';
import { userReducer } from './user/reducer';
import { orderReducer } from './orders/reducer';
import { reviewsReducer } from './reviews/reducer';
import { commentsReducer } from './comments/reducer';
import { tendersReducer } from './tenders/reducer';
import { companyReducer } from './company/reducer';
import { moderationReducer } from './moderation/reducer';
import { permissionReducer } from './permission/reducer';
import { dialogMessageReducer } from './dialog/reducer';
import { notificationReducer } from './notification/reducer';
import { subscriptionReducer } from './subscription/reducer';
import { feedbackReducer } from './feedback/reducer';
import { scoresReducer } from './score/reducer';
import { blogReducer } from './blog/reducer';

import { ApplicationState } from './application-state';

export default (history: History) => combineReducers<ApplicationState>({
    app: appReducer,
    router: connectRouter(history),
    user: userReducer,
    blog: blogReducer,
    orders: orderReducer,
    reviews: reviewsReducer,
    comments: commentsReducer,
    tenders: tendersReducer,
    company: companyReducer,
    moderation: moderationReducer,
    permission: permissionReducer,
    dialog: dialogMessageReducer,
    notification: notificationReducer,
    subscription: subscriptionReducer,
    feedback: feedbackReducer,
    score: scoresReducer,
});
