import { Feedback } from 'types/feedback';
import { InferValueTypes } from 'types/common';
import { FeedbacksActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type FeedbackState = {
    loading: {
        get: boolean;
        send: boolean;
        edit: boolean;
        delete: boolean;
    };
    list: Feedback[];
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: FeedbackState = {
    loading: {
        get: false,
        send: false,
        edit: false,
        delete: false,
    },
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'status',
        sortOrder: 'asc',
    },
};

export function feedbackReducer(state = initialState, action: ActionTypes): FeedbackState {
    switch (action.type) {
        case FeedbacksActionTypes.GET_FEEDBACK_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case FeedbacksActionTypes.GET_FEEDBACK_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case FeedbacksActionTypes.GET_FEEDBACK_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case FeedbacksActionTypes.SEND_FEEDBACK_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    send: true,
                },
            };

        case FeedbacksActionTypes.SEND_FEEDBACK_ERROR:
        case FeedbacksActionTypes.SEND_FEEDBACK_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    send: false,
                },
            };

        case FeedbacksActionTypes.EDIT_FEEDBACK_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };

        case FeedbacksActionTypes.EDIT_FEEDBACK_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };

        case FeedbacksActionTypes.EDIT_FEEDBACK_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
                list: state.list.map(
                    (item) => (item.id === action.response.id ?
                        { ...action.response }
                        : item),
                ),
            };

        default: {
            return state;
        }
    }
}
