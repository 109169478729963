import React, { FC, useState } from 'react';
import cn from 'classnames';
import { useTelegram } from 'components/ui/hooks/useTelegram';
import styles from './styles.module.scss';

type Props = {
    see?: boolean;
    onSee?: any;
    btn: any;
};

export const TenderItemSelected: FC<Props> = ({
    children,
    see = true,
    btn,
    onSee,
}) => {
    const { theme } = useTelegram();
    const [visible, setVisible] = useState(false);

    const toggle = () => {
        setVisible(!visible);
        onSee();
    };

    const tenderStyle = cn({
        [styles.bg]: true,
        [styles[theme]]: true,
    });

    return (
        <div className={ tenderStyle } onClick={ toggle }>
            { !see && (<div className={ styles.see } />) }
            { (visible && btn) && (
                <div className={ styles.checked }>
                    { btn }
                </div>
            ) }
            { children }
        </div>
    );
};
