import React, { useEffect } from 'react';
import { Avatar, notification as antdNotification } from 'antd';
import { getAvatar, getFio } from 'utils/formatters';
import { ROUTES } from 'constants/routes';
import { history } from 'App';
import useNotification from 'components/ui/hooks/useNotification';

import cl from './styles.module.scss';

export const Notification = React.memo(() => {
    const { getNotification } = useNotification();

    const goToAuthor = (id: number) => () => history.push(ROUTES.USER.getPath({ id }));
    const goToOrder = (id: number) => () => history.push(ROUTES.VIEW_ORDER.getPath({ id }));

    const notif = ({
        title, message, account, orderLink,
    }: any) => {
        const key = `open${Date.now()}`;

        antdNotification.open({
            key,
            className: 'notification-notice-msg',
            duration: 5,
            placement: 'bottomRight',
            message: <div className={ cl.header }> { title } </div>,
            description: (
                <div className={ cl.description }>
                    <Avatar
                        className={ cl.description_avatar }
                        size={ 40 }
                        src={ getAvatar(account.avatar) }
                    />
                    <div className={ cl.description_info }>
                        <div className={ cl.author } onClick={ goToAuthor(account.id) }> { getFio(account.lastName, account.firstName) } </div>
                        { orderLink ? (<div className={ cl.msg } onClick={ goToOrder(orderLink) }> { message } </div>) :
                            (<div className={ cl.msg }> { message } </div>) }
                    </div>
                </div>
            ),
        });
    };

    useEffect(() => {
        const getData = getNotification();

        if (getData) {
            notif(getData);
        }
    }, [getNotification]);

    return <div />;
});
