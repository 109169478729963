import { put, call, select } from 'redux-saga/effects';

import {
    getOrdersGuest,
    getOrdersGuestError,
    getOrdersGuestFinish,
    hideBtn,
} from 'ducks/orders/actions';

import {
    selectOrdersGuestPagination,
    selectOrdersGuestSort,
} from 'ducks/orders/selectors';

import { fetchers } from 'api';

export function* getOrdersGuestRequest({ payload }: ReturnType<typeof getOrdersGuest>) {
    try {
        const { pageSize } = yield select(selectOrdersGuestPagination);
        const sort = yield select(selectOrdersGuestSort);
        const response = yield call(fetchers.getOrdersGuest, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        if (response.data.length === 0) {
            yield put(hideBtn());
        } else {
            yield put(getOrdersGuestFinish({
                data: response.data,
                pagination: {
                    total: response.pagination.total,
                    page: payload.pagination.page,
                },
                sort: payload.sort || sort,
                test: payload.test,
            }));
        }
    } catch (error) {
        yield put(getOrdersGuestError());
    }
}
