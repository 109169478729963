/* eslint-disable no-nested-ternary */
import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import {
    Form,
    Input,
    Button,
    Select,
    Row,
    Col,
    Typography,
} from 'antd';
import { UploadFile } from 'components/upload-file';
import { ComponentLoading } from 'components/ui/component-loading';
import { eventYm } from 'components/ui/yandex-metrika';
import { createTempOrderGuest } from 'ducks/orders/actions';
import { selectCategoriesOptions, selectOrdersGuestLoading, selectTempOrder } from 'ducks/orders/selectors';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { TempOrderForm } from 'form-helpers/order/types';
import { schema } from 'form-helpers/order/schema';
import { getInitialData } from 'form-helpers/order/mapping';

import { allowedFileTypes } from 'types/order';

import styles from '../styles.module.scss';

const { Title } = Typography;
const initialValues = schema.cast({});

type Props = {
    onNextStep: () => void;
};

const getSelectValue = (value: number | string) => (value ? [`${value}`] : undefined);

export const CreateOrderGuest = memo(({ onNextStep }: Props) => {
    const [isSubmitTry, setIsSubmitTry] = React.useState(false);
    const put = useDispatch();
    const options = useSelector(selectCategoriesOptions);
    const loading = useSelector(selectOrdersGuestLoading);
    const order = useSelector(selectTempOrder);

    const onSubmit = useCallback((values: TempOrderForm) => {
        const data = cloneDeep(values);

        data.files = data.files.map((item: any) => {
            if (!item.type) {
                return JSON.stringify({ id: item.uid, path: item.url });
            }

            return item;
        });

        if (order?.id) {
            data.id = order?.id;

            if (order?.orderFiles) {
                const deleteFiles: number[] = [];

                order?.orderFiles.forEach(({ id }) => {
                    const isFileDeleted = !data.files.some((item: any) => {
                        if (!(typeof item === 'string')) {
                            return false;
                        }
                        const { id: fileId } = JSON.parse(item);

                        return id === fileId;
                    });

                    if (isFileDeleted) {
                        deleteFiles.push(id);
                    }
                });

                data.deleteFiles = deleteFiles;
            }
        }

        put(createTempOrderGuest(data, onNextStep));
        eventYm('reachGoal', 'target');
    }, [order]);

    return (
        <React.Fragment>
            <Title level={ 3 }>Оформление заказа</Title>
            <div className={ styles.body }>
                <ComponentLoading loading={ loading.get }>
                    <Row>
                        <Col xs={ { span: 24 } } lg={ { span: 15 } }>
                            <Formik<TempOrderForm>
                                initialValues={ getInitialData(initialValues, order) }
                                onSubmit={ onSubmit }
                                validationSchema={ schema }
                                key="createFormGuest"
                            >
                                { ({
                                    setFieldValue,
                                    setFieldTouched,
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    errors,
                                    touched,
                                    values,
                                }) => {
                                    const group = values.orderCategories[0]
                                        ? options.find((item: any) => +item.value === +values.orderCategories[0])
                                        : [];

                                    const subGroup = values.orderCategories[1]
                                        ? group?.children.find((item: any) => +item.value === +values.orderCategories[1])
                                        : [];

                                    const selectOptions = {
                                        level1: options.map((item: any) => ({ label: item.title, value: `${item.value}` })),
                                        level2: values.orderCategories[0]
                                            ? group?.children.map((item: any) => ({ label: item.title, value: `${item.value}` }))
                                            : [],
                                        level3: values.orderCategories[1]
                                            ? subGroup?.children.map((item: any) => ({ label: item.title, value: `${item.value}` }))
                                            : [],
                                    };

                                    const trySubmit = () => {
                                        setIsSubmitTry(true);
                                        handleSubmit();
                                    };

                                    const errorList = (field: string) => getError(errors, touched, isSubmitTry, field);

                                    const handleChangeSelect = (name: string) => (value: string[]) => {
                                        if (name === 'orderCategories[0]') {
                                            if (value) {
                                                setFieldValue('orderCategories', [value]);
                                            } else {
                                                setFieldValue('orderCategories', []);
                                            }
                                        }
                                        if (name === 'orderCategories[1]') {
                                            setFieldValue('orderCategories', [values.orderCategories[0], value]);
                                        }
                                        if (name === 'orderCategories[2]') {
                                            setFieldValue('orderCategories', [values.orderCategories[0], values.orderCategories[1], value]);
                                        }
                                    };

                                    const onChangeFile = (name: string) => (file: any) => {
                                        setFieldValue(name, file || null, true);
                                        setFieldTouched(name, true);
                                    };

                                    return (
                                        <Form
                                            onFinish={ trySubmit }
                                            labelAlign="left"
                                            layout="vertical"
                                        >
                                            <Form.Item
                                                label="Наименование заказа"
                                                extra={ errorList('name') }
                                                validateStatus={ getErrorStatus(!!errorList('name')) }
                                                required={ true }
                                            >
                                                <Input
                                                    name="name"
                                                    placeholder="Наименование заказа"
                                                    onChange={ handleChange }
                                                    onBlur={ handleBlur }
                                                    value={ values.name }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Описание заказа"
                                                extra={ errorList('message') }
                                                validateStatus={ getErrorStatus(!!errorList('message')) }
                                                required={ true }
                                            >
                                                <Input.TextArea
                                                    autoSize={ { minRows: 5 } }
                                                    name="message"
                                                    placeholder="Опишите все важные характеристики вашего заказа, которые позволят подрядчикам понять, что конкретно вам нужно. Также напишите ваши пожелания к заполнению заказа если таковые имеются"
                                                    rows={ 4 }
                                                    className="textarea"
                                                    onChange={ handleChange }
                                                    onBlur={ handleBlur }
                                                    defaultValue={ values.message }
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                hidden={ true }
                                                label="Категория заказа"
                                                extra={ errorList('orderCategories') }
                                                validateStatus={ getErrorStatus(!!errorList('orderCategories')) }
                                            >
                                                <Select
                                                    value={ getSelectValue(values.orderCategories[0]) }
                                                    allowClear={ true }
                                                    placeholder="Выберите категорию"
                                                    options={ selectOptions.level1 }
                                                    className={ styles.select }
                                                    onChange={ handleChangeSelect('orderCategories[0]') }
                                                />
                                                { values.orderCategories[0] ? (
                                                    <Select
                                                        value={ getSelectValue(values.orderCategories[1]) }
                                                        placeholder="Выберите подгруппу"
                                                        options={ selectOptions.level2 }
                                                        className={ styles.select }
                                                        onChange={ handleChangeSelect('orderCategories[1]') }
                                                    />
                                                ) : null }
                                                {
                                                    values.orderCategories[1] && selectOptions.level3?.length ? (
                                                        <Select
                                                            value={ getSelectValue(values.orderCategories[2]) }
                                                            placeholder="Выберите регламент"
                                                            options={ selectOptions.level3 }
                                                            onChange={ handleChangeSelect('orderCategories[2]') }
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                            <Form.Item
                                                hidden={ true }
                                                label="Вложения"
                                                extra={ errorList('files') }
                                                validateStatus={ getErrorStatus(!!errorList('files')) }
                                            >
                                                <UploadFile
                                                    onChange={ onChangeFile('files') }
                                                    defaultValue={ values.files }
                                                    multiple={ true }
                                                />
                                                <span className={ styles.gray }>Допустимые форматы файлов: { allowedFileTypes.join(', ') }</span>
                                            </Form.Item>
                                            <div>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Опубликовать
                                                </Button>
                                            </div>
                                        </Form>
                                    );
                                } }
                            </Formik>
                        </Col>
                        <Col xs={ { span: 24 } } lg={ { span: 8, offset: 1 } } className={ styles.txt }>
                            <p><b>Наименование заказа</b>, которое будет отображено в общем списке заказов. Пример заполнения: «Носки детские, 7 составов, серия»</p>
                            <p><b>Категория заказа</b>: выберете нужную категорию, к которой относится ваш заказ, чтобы подрядчикам, специализирующимся на той или иной категории, было проще его найти</p>
                            <p><b>Описание заказа</b>: опишите все важные характеристики вашего заказа, которые позволят подрядчикам понять, что конкретно вам нужно. Также напишите ваши пожелания к выполнению заказа, если таковые имеются. Пример заполнения: «Нужен СС ТРТС серия на 3 года, производитель РФ, регион Московская область. Продукция носки детские 5 цветов 7 разных составов. Нужен надежный ОС и аккредитованная ИЛ. Срок не дольше 3 недель»</p>
                            <p><b>Вложения</b>: прикрепите дополнительную документацию для своей продукции, если считаете, что она необходима подрядчику для оценки вашего заказа. Это могут быть фотографии этикетки, продукции, перечень номенклатуры, паспорт безопасности и т.д. Если вы не приложите дополнительную информацию сейчас, вы сможете направить ее подрядчикам позже, если они запросят</p>
                        </Col>
                    </Row>
                </ComponentLoading>
            </div>
        </React.Fragment>
    );
});
