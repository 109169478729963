/* eslint-disable no-mixed-operators */
import React from 'react';
import { Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';
import moment from 'moment';

import { StatusInfo } from 'types/order';

import { getFio } from 'utils/formatters';

import { ROUTES } from 'constants/routes';
import { history } from 'App';

import styles from './styles.module.scss';

type Props = {
    created: any;
    account: any;
    status: any;
};

const { Text } = Typography;

export const Panel = React.memo(({
    created,
    account,
    status,
}: Props) => {
    const goToAuthor = () => history.push(ROUTES.USER.getPath({ id: account.id }));

    return (
        <div className={ cn(styles.blockTop, styles.panel) }>
            <div className={ styles.panel_info }>
                <div className={ styles.panel_title }>Создано</div>
                <div className={ styles.panel_desc }>
                    <ClockCircleOutlined className={ styles.clock } />
                    { moment(created).fromNow() }
                </div>
            </div>
            <div className={ styles.panel_info }>
                <div className={ styles.panel_title }>Статус</div>
                <div className={ styles.panel_desc }>
                    { status && (<span className={ styles.status } style={ { backgroundColor: StatusInfo[status].color } }>{ StatusInfo[status].text }</span>) }
                </div>
            </div>
            <div className={ styles.panel_info }>
                <div className={ styles.panel_title }>Заказчик</div>
                <div className={ styles.panel_desc }>
                    <div className={ styles.author } onClick={ goToAuthor }>
                        <Text>{ getFio(account.lastName, account.firstName) }</Text>
                    </div>
                </div>
            </div>
        </div>
    );
});
