import { put, call } from 'redux-saga/effects';
import {
    editComment,
    editCommentError,
    editCommentFinish,
} from 'ducks/comments/actions';

import { fetchers } from 'api';

export function* editCommentRequest(action: ReturnType<typeof editComment>) {
    try {
        yield call(fetchers.editComment, action.payload);

        yield put(editCommentFinish());
        action.callback();
    } catch (error) {
        yield put(editCommentError());
    }
}
