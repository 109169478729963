import * as Api from 'types/api';
import {
    SubscriptionActionTypes,
} from './action-types';

export const loadSubscription = () => ({
    type: SubscriptionActionTypes.LOAD_SUBSCRIPTION_START,
});

export const loadSubscriptionError = () => ({
    type: SubscriptionActionTypes.LOAD_SUBSCRIPTION_ERROR,
});

export const loadSubscriptionFinish = (response: Api.SubscriptionPayload) => ({
    type: SubscriptionActionTypes.LOAD_SUBSCRIPTION_FINISH,
    response,
});

export const setSubscription = (payload: Api.SetSubscriptionPayload) => ({
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_START,
    payload,
});

export const setSubscriptionError = () => ({
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_ERROR,
});

export const setSubscriptionFinish = (response: Api.SetSubscriptionResponse) => ({
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_FINISH,
    response,
});
