import  React, { Suspense, useEffect } from 'react';
import moment from 'moment';
import { Spin } from 'antd';
import { LHeader } from 'components/header';
import { Container } from 'components/ui/container';
import { Footer } from 'components/footer';
import { EventInit } from 'components/ui/yandex-metrika';
import { Avatar } from 'components/ui/avatar';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectBlogDetail } from 'ducks/blog/selectors';
import { clearBlog, getBlogById } from 'ducks/blog/actions';
import { DATE_FORMAT } from 'constants/date';
import cls from './style.module.scss';

type MatchParams = {
    id: string;
};

export const PageBlogDetail = React.memo(() =>{
    const put = useDispatch();
    const blog = useSelector(selectBlogDetail);
    const {params: { id }} = useRouteMatch<MatchParams>();

    useEffect(()=>{
        put(getBlogById({id}));

        return ()=>{
            put(clearBlog());
        };
    }, []);

    return (
        <Suspense fallback={ <Spin /> }>
            <LHeader />
            <Container className={ cls.containerTop }>
                <div className={ cls.detail }>
                    <div className={ cls.date }>
                        { moment(blog?.created).format(DATE_FORMAT.DATE_TIME) }
                    </div>
                    <div className={ cls.header }>{ blog?.title }</div>
                    <div className={ cls.content }>
                        <div className={ cls.description } dangerouslySetInnerHTML={ {__html: blog?.fullDesc || ''} }/>
                        <div className={ cls.authors }>
                            <div className={ cls.author }>
                                <div className={ cls.author_ava }>
                                    <Avatar src={ blog?.account.avatar || '' } className={ cls.author__src }/>
                                </div>
                                <div className={ cls.author_content }>
                                    <div className={ cls.author__name }>{ blog?.account.firstName } { blog?.account.lastName }</div>
                                    <div className={ cls.author__title }>Автор статей для СертТорг</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
            <EventInit />
        </Suspense>
    );
});