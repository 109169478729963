/* eslint-disable react/no-danger */
import React from 'react';

import styles from './styles.module.scss';

type Props = {
    title?: string;
    event?: React.ReactNode;
    children: React.ReactNode;
};

export const Cart = ({ title, event, children }: Props) => (
    <div className={ styles.cart }>
        { title && (
            <div className={ styles.cart__header }>
                { title && (<div className={ styles.cart__title } dangerouslySetInnerHTML={ { __html: title } } />) }
                { event && (<div className={ styles.cart__event }>{ event }</div>) }
            </div>
        ) }
        { children }
    </div>
);
