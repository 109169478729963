import React from 'react';
import { Drawer, Spin } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { DATE_FORMAT } from 'constants/date';
import { selectScore, selectScoresLoading } from 'ducks/score/selectors';
import { StatusInfo, RefundStatus } from 'types/score';
import { getPrice, getStatus } from '../helper';

import styles from './styles.module.scss';

type Props = {
    onClose: () => void;
    open: boolean;
};

export const ScoreInfo = ({ onClose, open }: Props) => {
    const info = useSelector(selectScore);
    const loading = useSelector(selectScoresLoading);

    if (!info || loading.get) {
        return (
            <Drawer
                placement="right"
                closable={ false }
                onClose={ onClose }
                open={ open }
                drawerStyle={ { background: '#f8fafc' } }
            >
                <div className={ styles.drawer }>
                    <Spin className={ styles.drawer_spin } />
                </div>
            </Drawer>
        );
    }

    return (
        <Drawer
            placement="right"
            closable={ false }
            onClose={ onClose }
            open={ open }
            drawerStyle={ { background: '#f8fafc' } }
        >
            <div className={ styles.drawer }>
                <div className={ styles.drawer_title }>Информация о счете №{ info.id }</div>
                <div className={ styles.drawer_time }>{ moment(info.created).format(DATE_FORMAT.DATE_TIME) }</div>
                <div className={ styles.score }>
                    <div className={ styles.score_top }>
                        <div className={ styles.score_img }>
                            { getStatus(info.refundStatus) }
                        </div>
                        <div className={ cn(styles.score_price) }>
                            { getPrice(info.typePayment, info.price) }
                        </div>
                        <div className={ styles.score_status }>
                            <div className={ styles[info.typePayment] }>{ StatusInfo[info.typePayment].text }</div>
                        </div>
                    </div>
                    <div className={ styles.score_middle }>
                        <svg className={ styles.score_circle } xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 20c5.523 0 10-4.477 10-10S5.523 0 0 0h20v20H0Z" />
                        </svg>
                        <div className={ styles.score_line } />
                        <svg className={ cn(styles.score_circle, styles.score_circle__reserve) } xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 20c5.523 0 10-4.477 10-10S5.523 0 0 0h20v20H0Z" />
                        </svg>
                    </div>
                    <div className={ styles.score_footer }>
                        <div className={ styles.info }>
                            { info.orderId && (
                                <div className={ styles.info_icol }>
                                    <div className={ styles.info_name }>{ info.refundStatus === RefundStatus.reserved ? 'Зарезервированно для заказа' : 'Заказ' }</div>
                                    <div className={ styles.info_value }>№{ info.orderId }</div>
                                </div>
                            ) }
                            <div className={ styles.info_icol }>
                                <div className={ styles.info_name }>№ ПП</div>
                                <div className={ styles.info_value }>{ info.pp || '-' }</div>
                            </div>
                            <div className={ styles.info_icol }>
                                <div className={ styles.info_name }>Дата ПП</div>
                                <div className={ styles.info_value }>{ info.datePp ? moment(info.datePp).format(DATE_FORMAT.DATE_TIME) : '-' }</div>
                            </div>
                            <div className={ styles.info_icol }>
                                <div className={ styles.info_name }>ИНН</div>
                                <div className={ styles.info_value }>{ info.inn }</div>
                            </div>
                            { info.company?.organization && (
                                <div className={ styles.info_icol }>
                                    <div className={ styles.info_name }>Компания</div>
                                    <div className={ styles.info_value }>{ info.company?.organization }</div>
                                </div>
                            ) }
                            { info.company?.fio && (
                                <div className={ styles.info_icol }>
                                    <div className={ styles.info_name }>Физ.лицо</div>
                                    <div className={ styles.info_value }>{ info.company?.fio }</div>
                                </div>
                            ) }
                            { info.message && (
                                <div className={ styles.info_icol }>
                                    <div className={ styles.info_name }>Комментарий</div>
                                    <div className={ styles.info_value }>{ info.message }</div>
                                </div>
                            ) }
                            { info.refundMessage && (
                                <div className={ styles.info_icol }>
                                    <div className={ styles.info_name }>Комментарий</div>
                                    <div className={ styles.info_value }>{ info.refundMessage }</div>
                                </div>
                            ) }
                            { info?.scoreHistories && (
                                <div className={ styles.info_irow }>
                                    <div className={ styles.info_history }>История:</div>
                                    <ul className={ styles.info_history_list }>
                                        { info.scoreHistories.map((item) => (<li>{ item.message }</li>)) }
                                    </ul>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};
