import React from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import {
    Form,
    Input,
    Button,
    notification,
} from 'antd';
import { NavLink } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { EventInit, eventYm } from 'components/ui/yandex-metrika';
import { registerAutoSignIn } from 'ducks/app/actions';
import { selectUserLoading } from 'ducks/user/selectors';

import { schema } from 'form-helpers/registration/schema';
import { RegistrationFormType } from 'form-helpers/registration/types';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { Role, TypeOfRole } from 'types/account';

const initialValues = schema.cast({});

type Props = {
    onNextStep: () => void;
};

export const RegistrationGuest = ({ onNextStep }: Props) => {
    const loading = useSelector(selectUserLoading);
    const put = useDispatch();

    const itemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
    };

    const onSubmit = (values: RegistrationFormType) => {
        put(registerAutoSignIn(values, () => {
            notification.success({
                message: 'Авторизация',
                description: 'Вы успешно зарегистрированы и автоматически авторизованы',
                duration: 3000,
                placement: 'topRight',
            });
            notification.success({
                message: 'Подтвердите Email',
                description: 'Мы отправили вам сообщение на почту для ее подтверждения перейдите по ссылке в письме для подтвержения после этого заказ будет опубликован',
                duration: 10000,
                placement: 'topRight',
            });
            onNextStep();
        }));
        eventYm('reachGoal', 'registration');
    };

    const genPass = String(_.random(11111111, 99999999));

    return (
        <React.Fragment>
            <Formik<RegistrationFormType>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                initialValues={ {
                    ...initialValues,
                    password: genPass,
                    confirmPassword: genPass,
                    role: Role.Customer,
                    typeOfRole: TypeOfRole.broker,
                } }
                onSubmit={ onSubmit }
                validationSchema={ schema }
            >
                { ({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    setSubmitting,
                    validateForm,
                }) => {
                    const errorList = {
                        firstName: getError(errors, touched, isSubmitting, 'firstName'),
                        lastName: getError(errors, touched, isSubmitting, 'lastName'),
                        email: getError(errors, touched, isSubmitting, 'email'),
                    };

                    const handleSubmit = async () => {
                        setSubmitting(true);
                        const result = await validateForm();

                        if (isEmpty(result)) {
                            onSubmit(values);
                        }
                    };

                    return (
                        <Form
                            onFinish={ handleSubmit }
                            labelAlign="left"
                            { ...itemLayout }
                        >
                            <Form.Item
                                label="Ваше имя"
                                extra={ errorList.firstName }
                                validateStatus={ getErrorStatus(!!errorList.firstName) }
                            >
                                <Input
                                    name="firstName"
                                    placeholder="Ваше имя"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Ваша фамилия"
                                extra={ errorList.lastName }
                                validateStatus={ getErrorStatus(!!errorList.lastName) }
                            >
                                <Input
                                    name="lastName"
                                    placeholder="Ваша фамилия"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Ваш E-mail"
                                extra={ errorList.email }
                                validateStatus={ getErrorStatus(!!errorList.email) }
                            >
                                <Input
                                    name="email"
                                    placeholder="Ваш E-mail"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>
                            <Button
                                type="primary"
                                loading={ loading.profile }
                                onClick={ handleSubmit }
                            >
                                Продолжить
                            </Button>
                            <p style={ { marginTop: 24, fontSize: 11 } }>
                                Регистрируясь на сайте вы соглашаетесь с <NavLink target="_blank" to="/Политика конфиденциальности.pdf">Политикой конфиденциальности</NavLink>
                            </p>
                        </Form>
                    );
                } }
            </Formik>
            <EventInit />
        </React.Fragment>
    );
};
