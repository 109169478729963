import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
    color: 'default' | 'orange' | 'violet';
    title: string;
    desc: string;
    img: string;
};

const ColorBlock = ({
    color = 'default', title, desc, img,
}: Props) => {
    const styleContent = cn({
        [styles.colorBlock]: true,
        [styles[`${color}Color`]]: true,
    });

    return (
        <div className={ styleContent }>
            <div className={ styles.textContent }>
                <div className={ styles.title } dangerouslySetInnerHTML={ { __html: title } } />
                <div className={ styles.desc } dangerouslySetInnerHTML={ { __html: desc } } />
            </div>
            <div className={ styles.imageContent }>
                <img src={ img } alt={ title } />
            </div>
        </div>
    );
};

export default ColorBlock;
