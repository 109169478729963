import React from 'react';
import moment from 'moment';
import { IconMailForward } from '@tabler/icons';
import { Notification as NotificationType } from 'types/notification';
import cn from 'classnames';
import { declOfNum } from 'utils/formatters';

import styles from '../styles.module.scss';

type Props = {
    item: NotificationType;
    onHandleVisible: (visible: boolean, id: number) => void;
    goToAuthorOrder: (id: number) => void;
};

export const NewMessageOrder = ({ item, onHandleVisible, goToAuthorOrder }: Props) => {
    const visibleIco = <div className={ styles.newBagle } />;
    const created = moment(item.createdAt).fromNow();
    const messArr = ['непрочитанное сообщение', 'непрочитанных сообщений', 'непрочитанных сообщений'];

    return (
        <div
            className={ styles.ls }
            onMouseEnter={ () => onHandleVisible(item.visible, item.id) }
            onClick={ () => goToAuthorOrder(item.orderId) }
        >
            <div className={ cn(styles.ls_img, styles.newMessageOrder) }>
                <IconMailForward />
                { !item.visible && (visibleIco) }
            </div>
            <div className={ styles.ls_body }>
                <div className={ styles.ls_txt }>
                    У Вас <b>{ item.unreadMesssage }</b> { declOfNum(item.unreadMesssage, messArr) } по заказу №<b>{ item.orderId } | { item.order?.name }</b>
                </div>
                <div className={ styles.ls_time }>{ created }</div>
            </div>
        </div>
    );
};
