import { InferValueTypes } from 'types/common';
import * as actions from './actions';
import { AppActionTypes } from './action-types';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type AppState = {
    loading: {
        signUp: boolean;
        signIn: boolean;
    };
    passwordRecovery: {
        loading: boolean;
        complete: boolean;
    };
    isRegistrationFinish: boolean;
};

export const initialState: AppState = {
    loading: {
        signUp: false,
        signIn: false,
    },
    passwordRecovery: {
        loading: false,
        complete: false,
    },
    isRegistrationFinish: false,
};

export function appReducer(state = initialState, action: ActionTypes): AppState {
    switch (action.type) {
        /* SIGN UP */
        case AppActionTypes.SIGN_UP_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    signUp: true,
                },
            };
        case AppActionTypes.SIGN_UP_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    signUp: false,
                },
                isRegistrationFinish: true,
            };

        case AppActionTypes.SIGN_UP_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    signUp: false,
                },
            };

        /* SIGN IN */
        case AppActionTypes.SIGN_IN_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    signIn: true,
                },
            };
        case AppActionTypes.SIGN_IN_ERROR:
        case AppActionTypes.SIGN_IN_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    signIn: false,
                },
            };
        /* PASSWORD RECOVERY */
        case AppActionTypes.PASSWORD_RECOVERY_START:
            return {
                ...state,
                passwordRecovery: {
                    loading: true,
                    complete: false,
                },
            };
        case AppActionTypes.PASSWORD_RECOVERY_FINISH:
            return {
                ...state,
                passwordRecovery: {
                    loading: false,
                    complete: true,
                },
            };
        case AppActionTypes.PASSWORD_RECOVERY_ERROR:
            return {
                ...state,
                passwordRecovery: {
                    loading: false,
                    complete: false,
                },
            };
        default: {
            return state;
        }
    }
}
