import React from 'react';
import { OrderGuest } from 'types/order';
import { history } from 'App';
import { ROUTES } from 'pages/bot/routes';
import styles from './styles.module.scss';

type Props = {
    order: OrderGuest;
};

export const OrderItem = ({ order }: Props) => {
    const goToUrl = (id: number) => history.push(ROUTES.ORDER.getPath({ id }));

    return (
        <React.Fragment>
            <div className={ styles.order } onClick={ () => goToUrl(order.id) }>
                <div className={ styles.order_title }>#{ order.id } | { order.name }</div>
                <div className={ styles.order_categories }>
                    <b>Категории:</b>
                    { order.orderCategories.length ? order.orderCategories.map(({ id, name }, i, arr) => ((i + 1) < arr.length ? (
                        <span className={ styles.tag } key={ id }>{ name },</span>
                    ) : (
                        <span className={ styles.tag } key={ id }>{ name }</span>
                    ))) : 'без категории' }
                </div>
                <div className={ styles.order_desc }>{ order.message }</div>
                <div className={ styles.order_footer } />

            </div>
        </React.Fragment>
    );
};
