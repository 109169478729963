import { ACCESS } from 'constants/access';
import { TRoute } from 'types/route';
import { Balans } from './balans';
import { Company } from './company';
import { Profile } from './profile';
import { Security } from './security';
import { Subscription } from './subscription';
import { SubscriptionSite } from './subscriptionSite';
import { TermsAuction } from './terms-auction';

export const ROUTES: TRoute = {
    PROFILE: {
        path: '/edit-profile',
        title: 'Личные данные',
        exact: true,
        showInMenu: true,
        component: Profile,
    },
    SECURITY: {
        path: '/edit-profile/security',
        title: 'Безопасность и вход',
        exact: true,
        showInMenu: true,
        component: Security,
    },
    COMPANY: {
        path: '/edit-profile/company',
        title: 'О компании',
        showInMenu: true,
        exact: true,
        private: true,
        access: ACCESS.COMPANY_VIEWS_EDIT_MY,
        component: Company,
    },
    BALANS: {
        path: '/edit-profile/balans',
        title: 'Лицевой счет',
        showInMenu: true,
        exact: true,
        private: true,
        access: ACCESS.SCORE_VIEWS,
        component: Balans,
    },
    TERMS_AUCTION: {
        path: '/edit-profile/terms-auction',
        title: 'Условия участия в торгах',
        showInMenu: true,
        exact: true,
        private: true,
        component: TermsAuction,
    },
    SUBSCRIPTION: {
        path: '/edit-profile/subscription',
        title: 'Подписка на категории',
        showInMenu: true,
        exact: true,
        private: true,
        access: ACCESS.SUBSCRIPTION_ACCESS,
        component: Subscription,
    },
    SUBSCRIPTION_SITE: {
        path: '/edit-profile/subscription-site',
        title: 'Подписки на уведомления',
        showInMenu: true,
        exact: true,
        private: true,
        component: SubscriptionSite,
    },
};

export const ROUTES_KEYS = Object.keys(ROUTES);
