import React from 'react';
import { MenuMobile, MenuScroll } from 'components/ui/menu';
import { useSelector } from 'react-redux';
import { logout } from 'utils/app';
import { ROUTES } from 'constants/routes';
import { selectProfile } from 'ducks/user/selectors';
import { Role } from 'types/account';
import logo from 'assets/logo.svg';
import { history } from 'App';
import styles from './styles.module.scss';

const menuItems = [
    {
        path: ROUTES.MAIN.path,
        title: 'Что получают заказчики',
    },
    {
        path: ROUTES.СONTRACTOR.path,
        title: 'Что получают исполнители',
    },
    {
        path: ROUTES.STATIC_PAGE_VIDEO.path,
        title: 'Видео по работе сервиса',
    },
    {
        path: ROUTES.ABOUT_THE_COMPANY.path,
        title: 'О компании',
    },
    // {
    //     path: ROUTES.STATIC_CERTIFICATE.path,
    //     title: 'Аттестаты',
    // },
];

export const LHeader = React.memo(() => {
    const profil = useSelector(selectProfile);
    const isDesctop = window.matchMedia('(min-width: 1001px)').matches;

    const clickLink = (url: string) => () => history.push(url);

    const getLinkAuth = () => {
        const isCustomer = profil?.role === Role.Customer;

        if (isCustomer) {
            return ROUTES.CREATE_ORDER.path;
        }

        if (profil) {
            return ROUTES.ORDERS.path;
        }

        return ROUTES.CREATE_ORDER_GUEST.path;
    };


    return (
        <header className={ styles.header }>
            <div className={ styles.header__wrapper }>

                { !isDesctop && (
                    <MenuMobile items={ menuItems } />
                ) }

                <img className={ styles.logo } src={ logo } onClick={ clickLink(ROUTES.MAIN.path) } role="presentation" alt="logo" />

                { isDesctop && (
                    <MenuScroll items={ menuItems } />
                ) }

                <div className={ styles.techsupport__flex } />
                <div className={ styles.techsupport }>
                    <div className={ styles.techsupport__title }>Техподдержка:</div>
                    <a className={ styles.techsupport__phone } href="tel:8-800-777-2908">8-800-777-2908</a>
                </div>

                <div className={ styles.authNav }>
                    <a href={ ROUTES.ORDERS.path } onClick={ clickLink(ROUTES.ORDERS.path) } className={ styles.authNav__a }>Заказы</a>
                    { profil ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a href="#" onClick={ logout } className={ styles.authNav__a }>Выйти</a>
                    ) : (
                        <a href={ ROUTES.AUTH.path } onClick={ clickLink(ROUTES.AUTH.path) } className={ styles.authNav__a }>Войти</a>
                    ) }
                    <a href={ getLinkAuth() } className={ styles.authNav__btn }>Попробовать</a>
                </div>
            </div>
        </header>
    );
});
