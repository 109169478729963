import { InferValueTypes } from 'types/common';
import { Blog, BlogDetail } from 'types/blog';
import { BlogActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type BlogState = {
    loading: {
        get: boolean;
        create: boolean;
        delete: boolean;
        edit: boolean;
    };
    list: Blog[];
    detail: BlogDetail | null;
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: BlogState = {
    loading: {
        get: false,
        create: false,
        delete: false,
        edit: false,
    },
    list: [],
    detail: null,
    pagination: {
        pageSize: 9,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'id',
        sortOrder: 'desc',
    },
};

export function blogReducer(state = initialState, action: ActionTypes): BlogState {
    switch (action.type) {
        case BlogActionTypes.GET_BLOG_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };
        case BlogActionTypes.GET_BLOG_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case BlogActionTypes.GET_BLOG_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case BlogActionTypes.GET_BLOG_BY_ID_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };
        case BlogActionTypes.GET_BLOG_BY_ID_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case BlogActionTypes.GET_BLOG_BY_ID_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                detail: action.response,
            };

        case BlogActionTypes.EDIT_BLOG:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };
        case BlogActionTypes.EDIT_BLOG_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };

        case BlogActionTypes.EDIT_BLOG_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
                detail: null,
                list: state.list.map((item) => item.id === action.response.id ? { ...action.response } : item),
            };

        case BlogActionTypes.CLEAR_BLOG_DETAIL:
            return {
                ...state,
                detail: null,
            };

        case BlogActionTypes.CLEAR_BLOG:
            return initialState;

        default: {
            return state;
        }
    }
}
