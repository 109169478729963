import { put, call } from 'redux-saga/effects';

import {
    saveUserSocialNetwork,
    saveUserSocialNetworkError,
    saveUserSocialNetworkFinish,
} from 'ducks/user/actions';

import { fetchers } from 'api';

export function* saveUserSocialNetworkRequest({ payload, loading }: ReturnType<typeof saveUserSocialNetwork>) {
    try {
        const user = yield call(fetchers.saveUserSocialNetwork, payload);

        yield put(saveUserSocialNetworkFinish(user, loading));
    } catch (error) {
        yield put(saveUserSocialNetworkError(loading));
    }
}
