import * as TApi from 'types/api';
import { FeedbacksActionTypes } from './action-types';

export const getFeedback = (payload: TApi.GetFeedbackPayload) => ({
    type: FeedbacksActionTypes.GET_FEEDBACK_START,
    payload,
});

export const getFeedbackError = () => ({
    type: FeedbacksActionTypes.GET_FEEDBACK_ERROR,
});

export const getFeedbackFinish = (response: TApi.GetFeedbackResponse) => ({
    type: FeedbacksActionTypes.GET_FEEDBACK_FINISH,
    response,
});

export const sendFeedback = (payload: TApi.SendFeedbackPayload, callback?: () => void) => ({
    type: FeedbacksActionTypes.SEND_FEEDBACK_START,
    payload,
    callback,
});

export const sendFeedbackError = () => ({
    type: FeedbacksActionTypes.SEND_FEEDBACK_ERROR,
});

export const sendFeedbackFinish = () => ({
    type: FeedbacksActionTypes.SEND_FEEDBACK_FINISH,
});

export const editFeedback = (payload: TApi.EditFeedbackPayload, callback: () => void) => ({
    type: FeedbacksActionTypes.EDIT_FEEDBACK_START,
    payload,
    callback,
});

export const ediFeedbackError = () => ({
    type: FeedbacksActionTypes.EDIT_FEEDBACK_ERROR,
});

export const editFeedbackFinish = (response: TApi.EditFeedbackResponse) => ({
    type: FeedbacksActionTypes.EDIT_FEEDBACK_FINISH,
    response,
});
