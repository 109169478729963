import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import {
    Form,
    Button,
    Typography,
} from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { saveUser } from 'ducks/user/actions';

import { getError, getErrorStatus } from 'form-helpers/validation';
import { ProfileFormPhone } from 'form-helpers/profile/types';
import { schemaPhone as schema } from 'form-helpers/profile/schema';
import { getInitialValuesPhone } from 'form-helpers/profile/mapping';

import styles from './styles.module.scss';

const { Title } = Typography;

const initialValues = schema.cast({});

type Props = {
    user: any;
    loading: any;
};

export const SecurityPhone = React.memo(({ user, loading }: Props) => {
    const put = useDispatch();

    const onSubmit = (values: ProfileFormPhone) => {
        put(saveUser({
            id: values.id,
            phone: values.phone,
        }, false));
    };

    return (
        <Formik<ProfileFormPhone>
            initialValues={ getInitialValuesPhone(initialValues, user) }
            onSubmit={ onSubmit }
            validationSchema={ schema }
        >
            { ({
                setFieldValue,
                handleSubmit,
                handleBlur,
                errors,
                touched,
                values,
            }) => {
                const errorList = {
                    phone: getError(errors, touched, true, 'phone'),
                };

                const handleChangePhone = (value: string) => {
                    setFieldValue('phone', value || null, true);
                };

                const isSubmit = values.phone !== user.phone;

                return (
                    <Form
                        onFinish={ handleSubmit }
                        layout="vertical"
                        className={ styles.securityPhone }
                    >
                        <Title level={ 4 }>Телефон</Title>
                        <p className={ styles.info }>Телефон используется для быстрой связи с вами.</p>
                        <Form.Item
                            extra={ errorList.phone }
                            validateStatus={ getErrorStatus(!!errorList.phone) }
                            className="r-phone"
                        >
                            <PhoneInput
                                country="ru"
                                inputProps={ {
                                    name: 'phone',
                                    required: true,
                                } }
                                placeholder="+7(000)-000-00-00"
                                onChange={ handleChangePhone }
                                onBlur={ handleBlur }
                                value={ values.phone }
                            />
                        </Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={ loading.save }
                            className={ isSubmit ? styles.visible : styles.hidden }
                        >
                            Сохранить номер телефона
                        </Button>
                    </Form>
                );
            } }
        </Formik>
    );
});
