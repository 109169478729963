import React, { useCallback } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Button } from 'antd';
import { DATE_FORMAT } from 'constants/date';
import { useTelegram } from 'components/ui/hooks/useTelegram';
import { Tender } from 'types/tender';
import { acceptTender, seeTender } from 'ducks/tenders/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectTendersLoading } from 'ducks/tenders/selectors';
import { history } from 'App';
import { ROUTES } from 'pages/bot/routes';
import styles from './styles.module.scss';
import { TenderItemSelected } from '../selected';

type Props = {
    item: Tender;
    orderId: number;
    isMe: boolean;
    isCustomer: boolean;
};

export const TenderItem = React.memo(({
    orderId,
    item,
    isMe,
    isCustomer,
}: Props) => {
    const put = useDispatch();
    const loading = useSelector(selectTendersLoading);
    const { theme } = useTelegram();

    const onTenderAccept = (tenderId: number) => () => {
        put(acceptTender({ tenderId, orderId }, () => {
            history.push(ROUTES.ORDER.getPath({ id: orderId }));
        }));
    };

    const onHandleSee = useCallback((tenderId: number) => {
        put(seeTender({ tenderId, orderId, see: true }));
    }, []);

    const tenderStyle = cn({
        [styles.tender]: true,
        [styles[theme]]: true,
    });

    return (
        <TenderItemSelected
            see={ isMe ? item.see : undefined }
            onSee={ (isMe && isCustomer && !item.see) ? () => onHandleSee(item.id) : () => {} }
            btn={ isMe ? (
                <Button
                    size="small"
                    type="primary"
                    className={ styles.agree }
                    onClick={ onTenderAccept(item.id) }
                    loading={ loading.accept }
                >
                    Принять предложение
                </Button>
            ) : null }
        >
            <div className={ tenderStyle }>
                <div className={ styles.tender_header }>
                    <div className={ styles.tender__author }>{ item.account.firstName } { item.account.lastName }</div>
                    <div className={ styles.tender__price }>{ item.price } ₽</div>
                </div>
                <div className={ styles.tender__message }>{ item.message }</div>
                <div className={ styles.tender_footer }>
                    <div className={ styles.tender_info }>
                        <div className={ styles.tender_info__title }>
                            Срок выпонения
                        </div>
                        <div className={ styles.tender_info__value }>
                            { item.termOfService || 'Не указан' }
                        </div>
                    </div>
                    <div className={ styles.tender_info }>
                        <div className={ styles.tender_info__title }>
                            Способ оплаты
                        </div>
                        <div className={ styles.tender_info__value }>
                            { item.payment || 'Не указан' }
                        </div>
                    </div>
                    <div className={ styles.tender_info }>
                        <div className={ styles.tender_info__title }>
                            Опубликовано
                        </div>
                        <div className={ styles.tender_info__value }>
                            { moment(item.created).format(DATE_FORMAT.DATE_TIME) }
                        </div>
                    </div>
                </div>
            </div>
        </TenderItemSelected>
    );
});
