import { put, call } from 'redux-saga/effects';
import {
    editMessage,
    editMessageError,
    editMessageFinish,
} from 'ducks/dialog/actions';

import { fetchers } from 'api';

export function* editMessageRequest(action: ReturnType<typeof editMessage>) {
    try {
        yield call(fetchers.editDialogMessage, action.payload);

        yield put(editMessageFinish());
        action.callback();
    } catch (error) {
        yield put(editMessageError());
    }
}
