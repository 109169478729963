import { put, call, select } from 'redux-saga/effects';

import {
    getOrdersBidding,
    getOrdersBiddingError,
    getOrdersBiddingFinish,
    hideBtnBidding,
} from 'ducks/orders/actions';

import {
    selectOrdersBiddingPagination,
    selectOrdersBiddingSort,
} from 'ducks/orders/selectors';

import { fetchers } from 'api';

export function* getOrdersBiddingRequest({ payload }: ReturnType<typeof getOrdersBidding>) {
    try {
        const { pageSize } = yield select(selectOrdersBiddingPagination);
        const sort = yield select(selectOrdersBiddingSort);
        const response = yield call(fetchers.getOrdersBidding, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        if (response.data.length === 0) {
            yield put(hideBtnBidding());
        } else {
            yield put(getOrdersBiddingFinish({
                data: response.data,
                pagination: {
                    total: response.pagination.total,
                    page: payload.pagination.page,
                },
                sort: payload.sort || sort,
            }));
        }
    } catch (error) {
        yield put(getOrdersBiddingError());
    }
}
