import React, { useCallback, useEffect, useState } from 'react';
import {
    DatePicker,
    Form, Input, Modal, Select,
} from 'antd';
import { Formik } from 'formik';
import moment from 'moment';
import { ScoreForm } from 'form-helpers/score/types';
import { schema } from 'form-helpers/score/schema';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { getInitialData } from 'form-helpers/score/mapping';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompanyList, selectCompanyLoading } from 'ducks/company/selectors';
import { searchCompany } from 'ducks/company/actions';
import { getNameCompany } from 'form-helpers/score/until';
import { TypeOfPaymentOptions, TypePayment } from 'types/score';
import { createScore } from 'ducks/score/actions';
import { CreateScorePayload } from 'types/api';

const { Option } = Select;

const initialValues = schema.cast({});
// import styles from './styles.module.scss';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const AddScore = ({ open, onClose }: Props) => {
    const put = useDispatch();
    const companyList = useSelector(selectCompanyList);
    const loading = useSelector(selectCompanyLoading);
    const [optionsCompany, setOptionsCompany] = useState<any>([]);

    let timeout: ReturnType<typeof setTimeout> | null;

    const onSearchByCompany = useCallback((textVal: string) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        const strName = textVal;

        if (strName.length > 1) {
            timeout = setTimeout(() => {
                put(searchCompany({
                    id: strName,
                    organization: strName,
                    inn: strName,
                    fio: strName,
                }));
            }, 800);
        }
    }, []);

    const onSubmit = (data: CreateScorePayload) => {
        put(createScore(data, onClose));
    };

    useEffect(() => {
        const newData = companyList?.map((item: any) => ({
            label: getNameCompany(item),
            value: item.id,
        }));

        setOptionsCompany(newData);
    }, [companyList]);

    return (
        <Formik<ScoreForm>
            onSubmit={ onSubmit }
            initialValues={ {
                ...getInitialData(initialValues),
                // refundStatus: RefundStatus.completed,
            } }
            validationSchema={ schema }
            key="createScore"
            enableReinitialize={ true }
        >
            { ({
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                values,
                errors,
                touched,
            }) => {
                const errorList = (name: string) => getError(errors, touched, true, name);

                const handleChangeText = (e: any) => {
                    const event = {
                        target: {
                            name: e.target.name,
                            value: e.target.value,
                            id: e.target.id,
                        },
                    };

                    handleChange(event);
                };
                const handleChangeDate = (v: any) => {
                    const value = moment(v).unix();

                    setFieldValue('datePp', value || null, true);
                    setFieldTouched('datePp', true);
                };

                const onChangeSelect = (name: string) => (value: string) => {
                    setFieldValue(name, value || null, true);
                    setFieldTouched(name, true);
                };

                return (
                    <Modal
                        title="Управление счетом клиента"
                        open={ open }
                        onCancel={ onClose }
                        onOk={ () => handleSubmit() }
                        okText="Отправить"
                        confirmLoading={ loading.save }
                    >
                        <Form
                            labelAlign="left"
                            layout="vertical"
                        >
                            <Form.Item
                                label="Компания"
                                name="companyId"
                                extra={ errorList('companyId') }
                                validateStatus={ getErrorStatus(!!errorList('companyId')) }
                            >
                                <Select
                                    showSearch={ true }
                                    style={ { width: '100%' } }
                                    placeholder="Искать по ИНН или названию компании"
                                    loading={ loading.search }
                                    defaultActiveFirstOption={ false }
                                    options={ optionsCompany }
                                    onSearch={ onSearchByCompany }
                                    onChange={ onChangeSelect('companyId') }
                                    filterOption={ false }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Операция"
                                name="typePayment"
                                extra={ errorList('typePayment') }
                                validateStatus={ getErrorStatus(!!errorList('typePayment')) }
                            >
                                <Select
                                    placeholder="Выберите операцию"
                                    onChange={ onChangeSelect('typePayment') }
                                >
                                    { TypeOfPaymentOptions.map(
                                        (item) => (<Option key={ item.value } value={ item.value }>{ item.name }</Option>),
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="№ ПП"
                                extra={ errorList('pp') }
                                validateStatus={ getErrorStatus(!!errorList('pp')) }
                            >
                                <Input
                                    name="pp"
                                    placeholder="№ ПП"
                                    onChange={ handleChangeText }
                                    onBlur={ handleChangeText }
                                    value={ values.pp }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Дата ПП"
                                extra={ errorList('pp') }
                                validateStatus={ getErrorStatus(!!errorList('pp')) }
                            >
                                <DatePicker
                                    name="datePp"
                                    style={ { width: '100%' } }
                                    placeholder="Дата ПП"
                                    format="YYYY-MM-DD H:mm"
                                    onChange={ handleChangeDate }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Сумма (руб)"
                                extra={ errorList('price') }
                                validateStatus={ getErrorStatus(!!errorList('price')) }
                            >
                                <Input
                                    type="number"
                                    name="price"
                                    placeholder="Сумма"
                                    onChange={ handleChangeText }
                                    onBlur={ handleChangeText }
                                    value={ values.price }
                                />
                            </Form.Item>
                            { values.typePayment === TypePayment.refund ? (
                                <Form.Item
                                    label="Комментарий возврата"
                                    extra={ errorList('refundMessage') }
                                    validateStatus={ getErrorStatus(!!errorList('refundMessage')) }
                                >
                                    <Input.TextArea
                                        name="refundMessage"
                                        placeholder="Комментарий к счету"
                                        onChange={ handleChangeText }
                                        onBlur={ handleChangeText }
                                        value={ values.refundMessage }
                                        autoSize={ { minRows: 2, maxRows: 6 } }
                                    />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    label="Описание"
                                    extra={ errorList('message') }
                                    validateStatus={ getErrorStatus(!!errorList('message')) }
                                >
                                    <Input.TextArea
                                        name="message"
                                        placeholder="Описание к счету"
                                        onChange={ handleChangeText }
                                        onBlur={ handleChangeText }
                                        value={ values.message }
                                        autoSize={ { minRows: 2, maxRows: 6 } }
                                    />
                                </Form.Item>
                            ) }
                        </Form>
                    </Modal>
                );
            } }
        </Formik>
    );
};
