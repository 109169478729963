export const TendersActionTypes = {
    GET_TENDERS_START: 'GET_TENDERS_START',
    GET_TENDERS_ERROR: 'GET_TENDERS_ERROR',
    GET_TENDERS_FINISH: 'GET_TENDERS_FINISH',

    SEND_TENDER_START: 'SEND_TENDER_START',
    SEND_TENDER_ERROR: 'SEND_TENDER_ERROR',
    SEND_TENDER_FINISH: 'SEND_TENDER_FINISH',

    SEE_TENDER_START: 'SEE_TENDER_START',
    SEE_TENDER_ERROR: 'SEE_TENDER_ERROR',
    SEE_TENDER_FINISH: 'SEE_TENDER_FINISH',

    CLEAR_TENDERS: 'CLEAR_TENDERS',

    ACCEPT_TENDER_START: 'ACCEPT_TENDER_START',
    ACCEPT_TENDER_ERROR: 'ACCEPT_TENDER_ERROR',
    ACCEPT_TENDER_FINISH: 'ACCEPT_TENDER_FINISH',

    OFFER_TENDER_START: 'OFFER_TENDER_START',
    OFFER_TENDER_FINISH: 'OFFER_TENDER_FINISH',

    SOCKET_ADD_NEW_TENDER: 'SOCKET_ADD_NEW_TENDER',

    EDIT_TENDER: 'EDIT_TENDER',
    EDIT_TENDER_ERROR: 'EDIT_TENDER_ERROR',
    EDIT_TENDER_FINISH: 'EDIT_TENDER_FINISH',

    REVOKE_TENDER: 'REVOKE_TENDER',
    REVOKE_TENDER_ERROR: 'REVOKE_TENDER_ERROR',
    REVOKE_TENDER_FINISH: 'REVOKE_TENDER_FINISH',
} as const;
