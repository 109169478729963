import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination, Table, Typography } from 'antd';
import { Title } from 'components/ui/title';
import { BlogCreate } from 'components/blog/create';
import { clearBlog, getBlog } from 'ducks/blog/actions';
import {
    selectBlogAll,
    selectBlogLoading,
    selectBlogPagination,
} from 'ducks/blog/selectors';
import { BlogEdit } from 'components/blog/edit';
import { columns } from './columns';
import styles from './styles.module.scss';

const { Title: AntTitle } = Typography;

export const Blog = React.memo(() => {
    const put = useDispatch();
    const blogList = useSelector(selectBlogAll);
    const loading = useSelector(selectBlogLoading);
    const pagination = useSelector(selectBlogPagination);
    const [createModal, setCreateModal] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const toggleCreateModal = () => {
        setCreateModal(!createModal);
    };

    const getLoad = (page = 1) => put(getBlog({ pagination: { page } }));

    useEffect(() => {
        getLoad();

        return () => {
            put(clearBlog());
        };
    }, []);

    return (
        <React.Fragment>
            <Title
                className={ styles.title }
                title={ <AntTitle level={ 3 }>Блог</AntTitle> }
                event={
                    <Button type="text" onClick={ toggleCreateModal }>
                        Добавить статью
                    </Button>
                }
            />
            <Table
                size="small"
                columns={ columns }
                rowKey="id"
                loading={ loading.get }
                className={ styles.table }
                dataSource={ blogList }
                pagination={ false }
                onRow={ (record: any) => ({
                    onClick: () => {
                        setEditModal(record.id);
                    },
                }) }
            />
            { Boolean(pagination.total > 1) && (
                <div className={ styles.pagination }>
                    <Pagination
                        total={ pagination.total }
                        pageSize={ pagination.pageSize }
                        current={ pagination.page }
                        onChange={ getLoad }
                    />
                </div>
            ) }
            { createModal && (
                <BlogCreate
                    open={ createModal }
                    onOk={ toggleCreateModal }
                    onCancel={ toggleCreateModal }
                />
            ) }
            { editModal && (
                <BlogEdit
                    open={ editModal }
                    onCancel={ () => setEditModal(false) }
                />
            ) }
        </React.Fragment>
    );
});
