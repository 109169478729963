import React from 'react';
import { Link } from 'react-router-dom';
import { LayoutStatic } from 'components/ui/layoutStatic';

export const Partner = () => (
    <LayoutStatic>
        <h2 className="sp-title">Стать партнером</h2>
        <div className="sp-text">
            Сервис СертТорг на данный момент не предусматривает специальных партнерских программ. Вы можете стать исполнителем или подрядчиком пройдя авторизацию. <br />
            Любые предложения по сотрудничеству также могут быть направлены на почту <Link to="mailto:info@cert-torg.ru">info@cert-torg.ru</Link>
        </div>
    </LayoutStatic>
);
