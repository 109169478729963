import React from 'react';
import { Tooltip } from 'antd';
import { TypePayment, RefundStatus as TypeRefundStatus } from 'types/score';
import {
    IconAlarm, IconBolt, IconBan, IconChecks,
} from '@tabler/icons';
import styles from './styles.module.scss';

export const getPrice = (type: string, price: number) => {
    switch (type) {
        case TypePayment.add:
            return (
                <div className={ styles.add }>
                    +{ price } &#8381;
                </div>
            );

        case TypePayment.writeDowns:
            return (
                <div className={ styles.writeDowns }>
                    -{ price } &#8381;
                </div>
            );

        case TypePayment.reserved:
            return (
                <div className={ styles.reserved }>
                    -{ price } &#8381;
                </div>
            );
        case TypePayment.refund:
            return (
                <div className={ styles.refund }>
                    -{ price } &#8381;
                </div>
            );

        default:
            return (
                <div>
                    { price } &#8381;
                </div>
            );
    }
};

export const getStatus = (type: TypeRefundStatus) => {
    switch (type) {
        case TypeRefundStatus.empty:
        case TypeRefundStatus.completed:
            return (<Tooltip placement="bottom" title="Операция успешно выполнена"><IconChecks className={ styles.completed } /></Tooltip>);
        case TypeRefundStatus.error:
            return (<Tooltip placement="bottom" title="Ошибка в операции"><IconBan className={ styles.error } /></Tooltip>);
        case TypeRefundStatus.reserved:
            return (<Tooltip placement="bottom" title="Зарезервировано для заказа"><IconBolt className={ styles.reserved } /></Tooltip>);
        default:
            return (<Tooltip placement="bottom" title="Операция еще выполняется"><IconAlarm className={ styles.refund } /></Tooltip>);
    }
};
