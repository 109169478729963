import React from 'react';
import { Empty, Typography } from 'antd';

import empty from 'assets/empty.svg';
import cl from './styles.module.scss';

const { Title } = Typography;

export const EmptyChat = () => (
    <div className={ cl.wrap }>
        <Empty
            image={ empty }
            imageStyle={ {
                height: 60,
            } }
            description={
                <Title level={ 5 }>Выберите диалог</Title>
            }
        />
    </div>
);
