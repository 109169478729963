import { put, call } from 'redux-saga/effects';

import {
    getProfile,
    getProfileError,
    getProfileFinish,
} from 'ducks/user/actions';

import { saveUserCategories } from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* getProfileRequest({ userId }: ReturnType<typeof getProfile>) {
    try {
        const response = yield call(fetchers.getUser, { userId });
        const categories = yield call(fetchers.getCategoriesSubscribed, { userId });

        yield put(saveUserCategories(categories));
        yield put(getProfileFinish(response));
    } catch (error) {
        yield put(getProfileError());
    }
}
