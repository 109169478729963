import React from 'react';
import { history } from 'App';
import { ROUTES } from 'pages/bot/routes';
import { IconArrowNarrowLeft } from '@tabler/icons';
import styles from './styles.module.scss';

type Types = 'tender' | 'order';

type Props = {
    id: number;
    type: Types;
};

export const NavigationToPage = ({ id, type }: Props) => {
    switch (type) {
        case 'order':
            return (
                <div className={ styles.container } onClick={ () => history.push(ROUTES.ORDER.getPath({ id })) }>
                    <div className={ styles.title }><IconArrowNarrowLeft /> Перейти на заказ</div>
                </div>
            );
        case 'tender':
            return (
                <div className={ styles.container } onClick={ () => history.push(ROUTES.TENDER.getPath({ id })) }>
                    <div className={ styles.title }><IconArrowNarrowLeft /> Перейти на список предложений</div>
                </div>
            );
        default:
            throw Error(`Обработайте событие ${type}`);
    }
};
