import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from 'antd/lib/typography';
import Table from 'antd/lib/table';
import Switch from 'antd/lib/switch';

import { AppLoading } from 'components/ui/app-loading';

import {
    selectPermissionRole, selectPermission, selectPermissionLoading, selectPermissionAccess,
} from 'ducks/permission/selectors';
import { getPermission, updatePermission } from 'ducks/permission/actions';

import styles from './styles.module.scss';

const { Title } = Typography;

export const Permission = React.memo(() => {
    const put = useDispatch();
    const role = useSelector(selectPermissionRole);
    const permission = useSelector(selectPermission);
    const access = useSelector(selectPermissionAccess);
    const loading = useSelector(selectPermissionLoading);

    useEffect(() => {
        put(getPermission());
    }, []);

    if (!permission || !role || loading) {
        return <AppLoading />;
    }

    const getAccess = (group: keyof typeof access, rights: string) => {
        const isRight = access[group].includes(rights);

        return {
            role: group,
            code: rights,
            active: isRight,
        };
    };

    const handleSave = (val: any) => {
        put(updatePermission(val));
    };

    const dataSource = permission.map((p) => ({
        code: p.code,
        description: p.description,
        Admin: getAccess('Admin', p.code),
        Manager: getAccess('Manager', p.code),
        Customer: getAccess('Customer', p.code),
        Executor: getAccess('Executor', p.code),
        User: getAccess('User', p.code),
    }));

    const lastColumn = role.map((r: any) => ({
        title: r.description,
        dataIndex: r.role,
        key: r.role,
        render: (val: any) => <Switch defaultChecked={ val.active } onChange={ () => handleSave(val) } />,
    }));

    const columns = [
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code',
            width: 150,
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
            width: 200,
        },
        ...lastColumn,
    ];

    return (
        <React.Fragment>
            <Title className={ styles.title } level={ 4 }>Права доступа</Title>
            <Table
                rowKey={ (record) => record.code }
                dataSource={ dataSource }
                columns={ columns }
                size="small"
                pagination={ { pageSize: 100 } }
            />
        </React.Fragment>
    );
});
