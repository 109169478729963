import { put, call } from 'redux-saga/effects';

import {
    editBlog,
    editBlogError,
    editBlogFinish,
} from 'ducks/blog/actions';

import { fetchers } from 'api';

export function* editBlogRequest({ payload, cb }: ReturnType<typeof editBlog>) {
    try {
        const resp = yield call(fetchers.editBlog, payload);

        yield put(editBlogFinish(resp));
        yield cb();
    } catch (error) {
        yield put(editBlogError());
    }
}
