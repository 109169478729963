import React, { memo, Suspense } from 'react';
import { Spin } from 'antd';
import { Footer } from 'components/footer';
import { Reviews } from 'components/reviews';
import { Subscribe } from 'components/subscribe';
import { Container } from 'components/ui/container';
import { EventInit } from 'components/ui/yandex-metrika';
import { LHeader } from 'components/header';
import banner1 from 'assets/banner.png';
import { PromoBlock } from 'components/widjet';
import { BlogMain } from 'components/blog/main';
import { CookieNotify } from 'components/cookieNotify';

const Process = React.lazy(() => import('components/process'));
const Banner = React.lazy(() => import('components/banner'));
const OrderGuest = React.lazy(() => import('components/order/guest'));
const OrderBidding = React.lazy(() => import('components/order/bidding'));

export const Main = memo(() => (
    <Suspense fallback={ <Spin /> }>
        <LHeader />
        <PromoBlock />
        <Banner
            placeAnOrder={ true }
            title="СертТорг — площадка для поиска минимальных цен на услуги сертификации"
            description="Найдите подрядчика для оформления сертификата, декларации, отказного по минимальной цене"
            img={ banner1 }
        />
        <Container>
            <Process />
        </Container>
        <Container>
            <OrderGuest />
        </Container>
        <Container>
            <OrderBidding />
        </Container>
        <Container>
            <Reviews />
        </Container>
        <Container>
            <BlogMain />
        </Container>
        <Container>
            <Subscribe />
        </Container>
        <Footer />
        <EventInit />
        <CookieNotify />
    </Suspense>
));
