import { put, call } from 'redux-saga/effects';

import {
    loadSubscriptionError,
    loadSubscriptionFinish,
} from 'ducks/subscription/actions';

import { fetchers } from 'api';

export function* loadSubscriptionRequest() {
    try {
        const response = yield call(fetchers.loadSubscription);

        yield put(loadSubscriptionFinish(response));
    } catch (error) {
        yield put(loadSubscriptionError());
    }
}
