import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'pages/edit-profile/routes';
import imgCompany from 'assets/company.png';

import styles from './styles.module.scss';

export const ScoreNoCompany = () => (
    <div className={ styles.body }>
        <div className={ styles.header }>Доступ закрыт</div>
        <div className={ styles.info }>Заполните <Link to={ ROUTES.COMPANY.path }>данные компании</Link> и счет будет доступен</div>
        <img src={ imgCompany } className={ styles.img } alt="" />
    </div>
);
