import { put, call, select } from 'redux-saga/effects';

import {
    getBlog,
    getBlogError,
    getBlogFinish,
} from 'ducks/blog/actions';

import {
    selectBlogPagination,
    selectBlogSort,
} from 'ducks/blog/selectors';

import { fetchers } from 'api';

export function* getsBlogAllRequest({ payload }: ReturnType<typeof getBlog>) {
    try {
        const { pageSize } = yield select(selectBlogPagination);
        const sort = yield select(selectBlogSort);
        const response = yield call(fetchers.getBlogAll, {
            ...payload,
            pagination: {
                page: payload.pagination.page,
                pageSize: payload.pagination.pageSize || pageSize,
            },
            sort: payload.sort || sort,
        });


        yield put(getBlogFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                page: payload.pagination.page,
            },
            sort: payload.sort || sort,
        }));
    } catch (error) {
        yield put(getBlogError());
    }
}
