/* eslint-disable no-nested-ternary */
import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Formik } from 'formik';
import {
    Form,
    Input,
    Button,
    Typography,
    Select,
    Row,
    Col,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';

import { Layout } from 'components/ui/layout';
import { UploadFile } from 'components/upload-file';
import { ComponentLoading } from 'components/ui/component-loading';

import {
    createOrder,
    getOrder,
    editOrder,
    clearOrders,
} from 'ducks/orders/actions';

import {
    selectCategoriesOptions,
    selectOrderLoading,
    selectOrder,
} from 'ducks/orders/selectors';

import { getError, getErrorStatus } from 'form-helpers/validation';
import { OrderForm } from 'form-helpers/order/types';
import { schema } from 'form-helpers/order/schema';
import { getInitialData } from 'form-helpers/order/mapping';

import { history } from 'App';
import { ROUTES } from 'constants/routes';

import { allowedFileTypes, StatusInfo } from 'types/order';
import { Cart } from 'components/ui/cart';
import { useErrorProfile } from 'components/ui/hooks/useError';
import { NotificationBot } from 'components/notification/bot';

import styles from './styles.module.scss';

const { Title } = Typography;

const initialValues = schema.cast({});

type MatchParams = {
    id: string;
};

const getSelectValue = (value: number | string) => (value ? [`${value}`] : undefined);

export const CreateOrder = memo(() => {
    const {
        isAuthConfirmed, IsAuthConfirmedTxt,
    } = useErrorProfile();
    const [isSubmitTry, setIsSubmitTry] = React.useState(false);
    const put = useDispatch();
    const options = useSelector(selectCategoriesOptions);
    const loadings = useSelector(selectOrderLoading);
    const order = useSelector(selectOrder);
    const { params: { id: orderId } } = useRouteMatch<MatchParams>();

    const loadingGetOrder = orderId ? loadings.get : false;

    useEffect(() => {
        if (orderId) {
            put(getOrder({ orderId }));
        }

        return () => {
            put(clearOrders());
        };
    }, []);

    useEffect(() => {
        if (orderId && order && order.status !== StatusInfo.new.key && order.status !== StatusInfo.draft.key) {
            history.push(ROUTES.VIEW_ORDER.getPath({ id: order!.id }));
        }
    }, [order]);

    const onSubmit = (values: OrderForm) => {
        const data = cloneDeep(values);

        data.files = data.files.map((item: any) => {
            if (!item.type) {
                return JSON.stringify({ id: item.uid, path: item.url });
            }

            return item;
        });

        if (isAuthConfirmed) {
            data.status = StatusInfo.draft.key;
        }

        if (orderId) {
            const deleteFiles: number[] = [];

            order?.orderFiles.forEach(({ id }) => {
                const isFileDeleted = !data.files.some((item: any) => {
                    if (!(typeof item === 'string')) {
                        return false;
                    }
                    const { id: fileId } = JSON.parse(item);

                    return id === fileId;
                });

                if (isFileDeleted) {
                    deleteFiles.push(id);
                }
            });
            const result = {
                ...data,
                deleteFiles,
                id: orderId,
                files: data.files.filter((item: any) => typeof item !== 'string'),
            };

            put(editOrder(result));
        } else {
            put(createOrder(data));
        }
    };

    const returnBack = () => history.goBack();

    return (
        <Layout>
            <ComponentLoading loading={ loadingGetOrder }>
                <Title level={ 3 }>{ orderId ? 'Редактирование заказа' : 'Создание заказа' }</Title>
                <NotificationBot />
                <Cart>
                    <div className={ styles.body }>
                        <Row>
                            { isAuthConfirmed && (
                                <Col
                                    xs={ { span: 24 } }
                                    lg={ { span: 24 } }
                                    className={ styles.mbTextError }
                                >
                                    <IsAuthConfirmedTxt />
                                </Col>
                            ) }
                            <Col xs={ { span: 24 } } lg={ { span: 15 } }>
                                <Formik<OrderForm>
                                    initialValues={ getInitialData(initialValues, orderId ? order : null) }
                                    onSubmit={ onSubmit }
                                    validationSchema={ schema }
                                    key={ orderId ? 'editForm' : 'createForm' }
                                >
                                    { ({
                                        setFieldValue,
                                        setFieldTouched,
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        errors,
                                        touched,
                                        values,
                                    }) => {
                                        const group = values.orderCategories[0]
                                            ? options.find((item: any) => +item.value === +values.orderCategories[0])
                                            : [];

                                        const subGroup = values.orderCategories[1]
                                            ? group.children.find((item: any) => +item.value === +values.orderCategories[1])
                                            : [];

                                        const selectOptions = {
                                            level1: options.map((item: any) => ({ label: item.title, value: `${item.value}` })),
                                            level2: values.orderCategories[0]
                                                ? group.children.map((item: any) => ({ label: item.title, value: `${item.value}` }))
                                                : [],
                                            level3: values.orderCategories[1]
                                                ? subGroup.children.map((item: any) => ({ label: item.title, value: `${item.value}` }))
                                                : [],
                                        };

                                        const trySubmit = () => {
                                            setIsSubmitTry(true);
                                            handleSubmit();
                                        };

                                        const errorList = (field: string) => getError(errors, touched, isSubmitTry, field);


                                        const handleChangeSelect = (name: string) => (value: string[]) => {
                                            if (name === 'orderCategories[0]') {
                                                if(value) {
                                                    setFieldValue('orderCategories', [value]);
                                                }else{
                                                    setFieldValue('orderCategories', []);
                                                }
                                            }
                                            if (name === 'orderCategories[1]') {
                                                setFieldValue('orderCategories', [values.orderCategories[0], value]);
                                            }
                                            if (name === 'orderCategories[2]') {
                                                setFieldValue('orderCategories', [values.orderCategories[0], values.orderCategories[1], value]);
                                            }
                                        };


                                        const onChangeFile = (name: string) => (file: any) => {
                                            setFieldValue(name, file || null, true);
                                            setFieldTouched(name, true);
                                        };

                                        return (
                                            <Form
                                                onFinish={ trySubmit }
                                                labelAlign="left"
                                                layout="vertical"
                                            >
                                                <Form.Item
                                                    label="Наименование заказа"
                                                    extra={ errorList('name') }
                                                    validateStatus={ getErrorStatus(!!errorList('name')) }
                                                    required={ true }
                                                >
                                                    <Input
                                                        name="name"
                                                        placeholder="Наименование заказа"
                                                        onChange={ handleChange }
                                                        onBlur={ handleBlur }
                                                        value={ values.name }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Описание заказа"
                                                    extra={ errorList('message') }
                                                    validateStatus={ getErrorStatus(!!errorList('message')) }
                                                    required={ true }
                                                >
                                                    <Input.TextArea
                                                        autoSize={ { minRows: 5 } }
                                                        name="message"
                                                        placeholder="Опишите все важные характеристики вашего заказа, которые позволят подрядчикам понять, что конкретно вам нужно. Также напишите ваши пожелания к заполнению заказа если таковые имеются"
                                                        rows={ 4 }
                                                        className="textarea"
                                                        onChange={ handleChange }
                                                        onBlur={ handleBlur }
                                                        defaultValue={ values.message }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Категория заказа"
                                                    extra={ errorList('orderCategories') }
                                                    validateStatus={ getErrorStatus(!!errorList('orderCategories')) }
                                                >
                                                    <Select
                                                        allowClear={ true }
                                                        onChange={ handleChangeSelect('orderCategories[0]') }
                                                        value={ getSelectValue(values.orderCategories[0]) }
                                                        placeholder="Выберите категорию"
                                                        options={ selectOptions.level1 }
                                                        className={ styles.select }
                                                    />
                                                    { values.orderCategories[0] ? (
                                                        <Select
                                                            value={ getSelectValue(values.orderCategories[1]) }
                                                            placeholder="Выберите подгруппу"
                                                            options={ selectOptions.level2 }
                                                            className={ styles.select }
                                                            onChange={ handleChangeSelect('orderCategories[1]') }
                                                        />
                                                    ) : null }
                                                    {
                                                        values.orderCategories[1] && selectOptions.level3.length ? (
                                                            <Select
                                                                value={ getSelectValue(values.orderCategories[2]) }
                                                                placeholder="Выберите регламент"
                                                                options={ selectOptions.level3 }
                                                                onChange={ handleChangeSelect('orderCategories[2]') }
                                                            />
                                                        ) : null
                                                    }
                                                </Form.Item>
                                                <Form.Item
                                                    label="Вложения"
                                                    extra={ errorList('files') }
                                                    validateStatus={ getErrorStatus(!!errorList('files')) }
                                                >
                                                    <UploadFile
                                                        onChange={ onChangeFile('files') }
                                                        defaultValue={ values.files }
                                                        multiple={ true }
                                                    />
                                                    <span className={ styles.gray }>Допустимые форматы файлов: { allowedFileTypes.join(', ') }</span>
                                                </Form.Item>
                                                <div>
                                                    { orderId && (
                                                        <Button
                                                            style={ { marginRight: 16 } }
                                                            onClick={ returnBack }
                                                            icon={ <ArrowLeftOutlined /> }
                                                        >
                                                            Вернуться назад
                                                        </Button>
                                                    ) }
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        loading={ loadings.create }
                                                    >
                                                        { isAuthConfirmed ? 'Сохранить как черновик' : orderId ? 'Опубликовать' : 'Создать заказ' }
                                                    </Button>
                                                </div>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Col>
                            <Col xs={ { span: 24 } } lg={ { span: 8, offset: 1 } } className={ styles.txt }>
                                <p><b>Наименование заказа</b>, которое будет отображено в общем списке заказов. Пример заполнения: «Носки детские, 7 составов, серия»</p>
                                <p><b>Категория заказа</b>: выберете нужную категорию, к которой относится ваш заказ, чтобы подрядчикам, специализирующимся на той или иной категории, было проще его найти</p>
                                <p><b>Описание заказа</b>: опишите все важные характеристики вашего заказа, которые позволят подрядчикам понять, что конкретно вам нужно. Также напишите ваши пожелания к выполнению заказа, если таковые имеются. Пример заполнения: «Нужен СС ТРТС серия на 3 года, производитель РФ, регион Московская область. Продукция носки детские 5 цветов 7 разных составов. Нужен надежный ОС и аккредитованная ИЛ. Срок не дольше 3 недель»</p>
                                <p><b>Вложения</b>: прикрепите дополнительную документацию для своей продукции, если считаете, что она необходима подрядчику для оценки вашего заказа. Это могут быть фотографии этикетки, продукции, перечень номенклатуры, паспорт безопасности и т.д. Если вы не приложите дополнительную информацию сейчас, вы сможете направить ее подрядчикам позже, если они запросят</p>
                            </Col>
                        </Row>
                    </div>
                </Cart>
            </ComponentLoading>
        </Layout>
    );
});
