/* eslint-disable complexity */
import { InferValueTypes } from 'types/common';
import { Message } from 'types/dialog';
import * as actions from '../actions';
import { DialogActionTypes } from '../action-types';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type StateType = {
    loading: {
        get: boolean;
        send: boolean;
        edit: boolean;
        delete: boolean;
        next: boolean;
    };
    list: Message[];
    pagination: {
        pageSize: number;
        total: number;
        page: number;
        next: boolean;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: StateType = {
    loading: {
        get: false,
        send: false,
        edit: false,
        delete: false,
        next: true,
    },
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
        next: true,
    },
    sort: {
        sortField: 'created',
        sortOrder: 'desc',
    },
};

export function messageReducer(state = initialState, action: ActionTypes): StateType {
    switch (action.type) {
        case DialogActionTypes.GET_MESSAGES_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case DialogActionTypes.GET_MESSAGES_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case DialogActionTypes.GET_MESSAGES_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: [...state.list, ...action.response.data],
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case DialogActionTypes.SEND_DIALOG_MESSAGE_FINISH:
            return {
                ...state,
                list: state.list.map((item) => (item.read === false ? { ...item, read: true } : item)),
            };

        case DialogActionTypes.EDIT_MESSAGES_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };

        case DialogActionTypes.EDIT_MESSAGES_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };

        case DialogActionTypes.EDIT_MESSAGES_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };

        case DialogActionTypes.CLEAR_DIALOG_MESSAGES:
            return {
                ...state,
                list: [],
                pagination: {
                    pageSize: 10,
                    total: 0,
                    page: 1,
                    next: true,
                },
                sort: {
                    sortField: 'created',
                    sortOrder: 'desc',
                },
            };

        case DialogActionTypes.DELETE_MESSAGES_FINISH:
            return {
                ...state,
                list: state.list.filter((item) => item.id !== action.id),
            };

        case DialogActionTypes.SOCKET_ADD_MESSAGE:
            return {
                ...state,
                list: [action.payload, ...state.list],
            };

        case DialogActionTypes.SOCKET_EDIT_MESSAGE:
            return {
                ...state,
                list: state.list.map((item) => (item.id === action.payload.id ? action.payload : item)),
            };

        case DialogActionTypes.SOCKET_REMOVE_MESSAGE:
            return {
                ...state,
                list: state.list.filter((item) => item.id !== Number(action.id)),
            };

        case DialogActionTypes.READ_DIALOG_MESSAGE_FINISH:
            return {
                ...state,
                list: state.list.map((item) => (item.id === action.id ? { ...item, read: true } : item)),
            };

        case DialogActionTypes.READ_DIALOG_MESSAGE_ALL:
            return {
                ...state,
                list: state.list.map((item) => (item.read === false ? { ...item, read: true } : item)),
            };

        default: return state;
    }
}
