import { Dialog, Dialogs } from 'types/dialog';
import { InferValueTypes } from 'types/common';
import { DialogActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type DialogState = {
    loading: {
        get: boolean;
        gets: boolean;
        send: boolean;
        edit: boolean;
        delete: boolean;
    };
    dialog: Dialog | null;
    dialogList: Dialogs[];
    pagination: {
        pageSize: number;
        total: number;
        page: number;
        next: boolean;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: DialogState = {
    loading: {
        get: false,
        gets: false,
        send: false,
        edit: false,
        delete: false,
    },
    dialog: null,
    dialogList: [],
    pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
        next: true,
    },
    sort: {
        sortField: 'id',
        sortOrder: 'desc',
    },
};

export function dialogReducer(state = initialState, action: ActionTypes): DialogState {
    switch (action.type) {
        case DialogActionTypes.GET_DIALOG_BY_HASH_START:
        case DialogActionTypes.GET_DIALOG_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case DialogActionTypes.GET_DIALOG_BY_HASH_FINISH:
        case DialogActionTypes.GET_DIALOG_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                dialog: action.response,
            };

        case DialogActionTypes.GET_DIALOG_BY_HASH_ERROR:
        case DialogActionTypes.GET_DIALOG_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case DialogActionTypes.CLEAR_DIALOG:
            return {
                ...state,
                dialog: null,
            };

        case DialogActionTypes.CLEAR_DIALOG_ALL:
            return {
                ...state,
                dialogList: [],
            };

        case DialogActionTypes.GET_DIALOG_ALL_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    gets: true,
                },
            };

        case DialogActionTypes.GET_DIALOG_ALL_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    gets: false,
                },
                dialogList: [...state.dialogList, ...action.response.data],
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case DialogActionTypes.GET_DIALOG_ALL_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    gets: false,
                },
            };

        case DialogActionTypes.TOGGLE_SELECT_FINISH:
            return {
                ...state,
                dialog: state.dialog && { ...state.dialog, ...action.data },
            };

        default: {
            return state;
        }
    }
}
