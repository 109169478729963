import { put, call } from 'redux-saga/effects';

import {
    getTenderOffer,
    getTendersOfferFinish,
} from 'ducks/tenders/actions';

import { fetchers } from 'api';

export function* getTendersOfferRequest({ orderId }: ReturnType<typeof getTenderOffer>) {
    try {
        const response = yield call(fetchers.getTendersOffer, orderId);

        yield put(getTendersOfferFinish(response));
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
}
