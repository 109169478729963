import React, { useContext, useEffect } from 'react';
import { Pagination, List } from 'antd';
import { useHistory, useRouteMatch } from 'react-router';
import { history } from 'App';
import { Buttons } from 'components/bot/buttons';
import { TenderItem } from 'components/bot';
import { ButtonBack } from 'components/bot/button';
import { useDispatch, useSelector } from 'react-redux';
import { selectTenders, selectTendersLoading, selectTendersPagination } from 'ducks/tenders/selectors';
import { TenderNotFound } from 'components/bot/tender/notFound';
import { addTender, clearTenders, getTenders } from 'ducks/tenders/actions';
import { selectProfile } from 'ducks/user/selectors';
import { selectOrder } from 'ducks/orders/selectors';
import { SocketContext } from 'context/socket';
import { Role } from 'types/account';
import { Tender as TypeTender } from 'types/tender';
import { TENDER } from 'constants/soccet';
import { NavigationToPage } from 'components/bot/navigationToPage';
import { clearOrders, getOrder } from 'ducks/orders/actions';
import { ROUTES } from '../routes';
import styles from './styles.module.scss';

export const Tender = React.memo(() => {
    const { params: { id: orderId } } = useRouteMatch<any>();
    const put = useDispatch();
    const navigate = useHistory();
    const socket = useContext(SocketContext);
    const loading = useSelector(selectTendersLoading);
    const pagination = useSelector(selectTendersPagination);
    const tenders = useSelector(selectTenders);
    const profile = useSelector(selectProfile);
    const order = useSelector(selectOrder);

    const isMe = Number(profile?.id) === Number(order?.account.id);
    const isExecutor = profile?.role === Role.Executor;
    const isCustomer = profile?.role === Role.Customer;

    const onChangePagination = (page: number) => {
        put(getTenders({ orderId, pagination: { page } }));
    };

    const goPageAddTender = () => history.push(ROUTES.ADD_TENDER.getPath({ id: orderId }));

    useEffect(() => {
        put(getOrder({ orderId }));
        put(getTenders({ pagination: { page: 1 }, orderId }));

        return () => {
            put(clearOrders());
            put(clearTenders());
        };
    }, [orderId]);

    useEffect(() => {
        const addSocketTender = (data: TypeTender) => put(addTender(data));
        const reloadPage = () => navigate.go(0);

        if (socket) {
            socket.emit(TENDER.JOIN, { room: orderId });
            socket.on(TENDER.SEND, addSocketTender);
            socket.on(TENDER.CHOOSE, reloadPage);
        }

        return () => {
            if (socket) {
                socket.emit(TENDER.UNSUBSCRIBE, { room: orderId });
                socket.removeListener(TENDER.SEND, addSocketTender);
                socket.removeListener(TENDER.CHOOSE, reloadPage);
            }
        };
    }, [socket, orderId]);

    const tenderButton = !isMe && isExecutor ? (
        <button
            type="button"
            onClick={ goPageAddTender }
        >
            Написать предложение
        </button>
    ) : null;

    if (!tenders.length) {
        return (
            <React.Fragment>
                <NavigationToPage id={ orderId } type="order" />
                <TenderNotFound />
                <Buttons>
                    <ButtonBack />

                    { tenderButton }
                </Buttons>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <NavigationToPage id={ orderId } type="order" />
            <div className={ styles.tg_tender_list }>
                <List
                    loading={ loading.get }
                    className={ styles.list }
                    itemLayout="horizontal"
                    dataSource={ tenders }
                    renderItem={ (item: any) => (
                        <TenderItem
                            key={ item.id }
                            orderId={ orderId }
                            item={ item }
                            isMe={ isMe }
                            isCustomer={ isCustomer }
                        />
                    ) }
                />
            </div>
            { pagination.total > 1 && (
                <div className={ styles.pagination }>
                    <Pagination
                        total={ pagination.total }
                        pageSize={ pagination.pageSize }
                        current={ pagination.page }
                        onChange={ onChangePagination }
                    />
                </div>
            ) }
            <Buttons>
                { tenderButton }
            </Buttons>
        </React.Fragment>
    );
});
