import { put, call } from 'redux-saga/effects';

import {
    getDialog,
    getDialogError,
    getDialogFinish,
} from 'ducks/dialog/actions';

import { fetchers } from 'api';

export function* getDialogRequest({ payload }: ReturnType<typeof getDialog>) {
    try {
        const response = yield call(fetchers.getDialog, payload);

        yield put(getDialogFinish(response));
    } catch (error) {
        yield put(getDialogError());
    }
}
