import { FullOrder, TempOrderGuest } from 'types/order';
import { formatStringToUploadObj } from 'utils/formatters';

export const getInitialData = (initialData: any, order: FullOrder | TempOrderGuest | null) => ({
    files: order?.orderFiles
        ? order.orderFiles.map((item: any) => formatStringToUploadObj(item.path, item.id))
        : [],
    orderCategories: order?.orderCategories || initialData.orderCategories,
    name: order?.name || initialData.name,
    message: order?.message || initialData.message,
});
