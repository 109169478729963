import * as yup from 'yup';

import { REQUIRED_FIELD } from 'form-helpers/message';
import { RefundStatus } from 'types/score';

export const schema = yup.object().shape({
    companyId: yup.number().required(REQUIRED_FIELD),
    pp: yup.string().required(REQUIRED_FIELD),
    price: yup.number()
        .positive()
        .integer()
        .min(10, 'Сумма не может быть меньше 10')
        .required(REQUIRED_FIELD),
    refundMessage: yup.string(),
    refundStatus: yup.string().default(RefundStatus.empty),
    message: yup.string().default(''),
    datePp: yup.number(),
    typePayment: yup.string().required(REQUIRED_FIELD),
});

export const schemaRefund = yup.object().shape({
    price: yup.number()
        .positive()
        .integer()
        .min(10, 'Сумма не может быть меньше 10')
        .required(REQUIRED_FIELD),
});

export const schemaRefundAdmin = yup.object().shape({
    companyId: yup.number(),
    pp: yup.string(),
    refundMessage: yup.string(),
    refundStatus: yup.string().default(RefundStatus.completed),
    datePp: yup.number(),
    price: yup.number()
        .positive()
        .integer()
        .min(10, 'Сумма не может быть меньше 10'),
});
