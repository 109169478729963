import React from 'react';
import { Empty, Typography } from 'antd';
import empty from '../../../../assets/empty.svg';
import styles from './styles.module.scss';

const { Title } = Typography;

export const TenderNotFound = () => (
    <div className={ styles.noTender }>
        <Empty
            image={ empty }
            imageStyle={ {
                height: 80,
            } }
            description={
                <Title className={ styles.title } level={ 5 }>Предложений к заказу не поступило</Title>
            }
        />
    </div>
);
