import React, { useEffect } from 'react';
import { Layout } from 'components/ui/layout';
import { useSelector, useDispatch } from 'react-redux';

import { clearReviews, getReviews } from 'ducks/reviews/actions';
import { getOrders, clearOrders } from 'ducks/orders/actions';

import { selectProfile } from 'ducks/user/selectors';
import {
    selectReviews,
    selectReviewsLoading,
    selectReviewsPagination,
} from 'ducks/reviews/selectors';

import {
    selectOrders,
    selectOrdersPagination,
    selectOrderLoading,
} from 'ducks/orders/selectors';
import { Role } from 'types/account';

import { MProfile } from 'components/profile';
import { ProfileSkeleton } from 'components/ui/skeleton';

export const Profile = () => {
    const put = useDispatch();
    const profile = useSelector(selectProfile);
    const reviews = useSelector(selectReviews);
    const reviewsLoading = useSelector(selectReviewsLoading);
    const reviewsPagination = useSelector(selectReviewsPagination);
    const orders = useSelector(selectOrders);
    const ordersLoading = useSelector(selectOrderLoading);
    const ordersPagination = useSelector(selectOrdersPagination);

    const userId = profile!.id;

    const field = profile?.role === Role.Executor ? 'executorId' : 'accountId';

    useEffect(() => {
        put(getReviews({ id: userId, pagination: { page: 1 } }));
        put(getOrders({ [field]: userId, status: 'done', pagination: { page: 1 } }));

        return () => {
            put(clearReviews());
            put(clearOrders());
        };
    }, []);

    const onChangeReviewsPagination = (page: number) => {
        put(getReviews({ id: userId, pagination: { page } }));
    };

    const onChangeOrdersPagination = (page: number) => {
        put(getOrders({ [field]: userId, status: 'done', pagination: { page } }));
    };

    if (!profile) {
        return (
            <Layout>
                <ProfileSkeleton />
            </Layout>
        );
    }

    return (
        <Layout>
            <MProfile
                profile={ profile }
                onChangeReviewsPagination={ onChangeReviewsPagination }
                onChangeOrdersPagination={ onChangeOrdersPagination }
                reviews={ reviews }
                reviewsLoading={ reviewsLoading.get }
                reviewsPagination={ reviewsPagination }
                orders={ orders }
                ordersLoading={ ordersLoading.getOrders }
                ordersPagination={ ordersPagination }
            />
        </Layout>
    );
};
