import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form, Input, Button,
} from 'antd';

import { passwordRecovery } from 'ducks/app/actions';

import { selectPasswordRecovery } from 'ducks/app/selectors';

import { PasswordRecoveryPayload } from 'types/api';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { ROUTES } from 'constants/routes';
import { history } from 'App';

import { AuthLayout } from 'components/ui/auth-layout';
import { schema } from './schema';
import styles from './styles.module.scss';

const initialValues = schema.cast({});

export const PasswordRecovery = () => {
    const { loading, complete } = useSelector(selectPasswordRecovery);
    const put = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push(ROUTES.MAIN.path);
        }
    }, []);

    const onSubmit = (values: PasswordRecoveryPayload) => {
        put(passwordRecovery(values));
    };

    if (complete) {
        return (
            <AuthLayout title="Письмо с инструкцией направлено на вашу почту">
                <Button
                    type="primary"
                    onClick={ () => history.push(ROUTES.AUTH.path) }
                >
                    Вернуться к авторизации
                </Button>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout title="Восстановление пароля">
            <div className={ styles.formContent }>
                <Formik
                    initialValues={ initialValues }
                    onSubmit={ onSubmit }
                    validationSchema={ schema }
                >
                    { ({
                        handleSubmit,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                    }) => {
                        const errorList = {
                            email: getError(errors, touched, true, 'email'),
                        };

                        return (
                            <Form
                                onFinish={ handleSubmit }
                                labelAlign="left"
                                layout='vertical'
                                className={ styles.form }
                            >

                                <Form.Item
                                    label="Ваш E-mail"
                                    extra={ errorList.email }
                                    validateStatus={ getErrorStatus(!!errorList.email) }
                                >
                                    <Input
                                        name="email"
                                        placeholder="Ваш E-mail"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                    />
                                </Form.Item>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={ loading }
                                    block={ true }
                                >
                                    Отправить сообщение
                                </Button>
                            </Form>
                        );
                    } }
                </Formik>
            </div>
        </AuthLayout>
    );
};
