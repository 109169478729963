import React from 'react';
import { Dropdown } from 'antd';
import { IconDotsVertical } from '@tabler/icons';
import cn from 'classnames';

import styles from './styles.module.scss';

type Props = {
    author: string;
    avatar: React.ReactChild;
    content: React.ReactChild | string;
    datetime: React.ReactChild;
    read?: boolean;
    handleRead?: any;
    actions?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

export const Answer = ({
    author, avatar, content, datetime, read, actions, handleRead,
}: Props) => {
    const styleMsg = cn({
        [styles.msg__body]: true,
        [styles.noRead]: !read,
    });

    return (
        <div className={ styles.chat_body }>
            <div className={ styles.msg }>
                <div className={ styles.msg__avatar }>
                    { avatar }
                </div>
                <div
                    onMouseEnter={ read ? null : handleRead }
                    className={ styleMsg }
                >
                    <div className={ styles.msg__author }>
                        { author }
                        <span className={ styles.msg__time }>
                            { datetime }
                        </span>
                    </div>
                    <div className={ styles.msg__content }>
                        { content }
                    </div>
                </div>
                { actions && (
                    <Dropdown
                        placement="bottomRight"
                        overlay={ actions }
                        trigger={ ['click'] }
                    >
                        <IconDotsVertical
                            className={ styles.msg__setting }
                            size={ 14 }
                        />
                    </Dropdown>
                ) }
            </div>
        </div>
    );
};
