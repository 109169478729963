export const OrderActionTypes = {
    GET_ORDERS_START: 'GET_ORDERS_START',
    GET_ORDERS_FINISH: 'GET_ORDERS_FINISH',
    GET_ORDERS_ERROR: 'GET_ORDERS_ERROR',

    GET_ORDERS_GUEST_START: 'GET_ORDERS_GUEST_START',
    GET_ORDERS_GUEST_ERROR: 'GET_ORDERS_GUEST_ERROR',
    GET_ORDERS_GUEST_FINISH: 'GET_ORDERS_GUEST_FINISH',
    CLEAR_ORDERS_GUEST: 'CLEAR_ORDERS_GUEST',

    HIDE_BTN_ORDERS_GUEST: 'HIDE_BTN_ORDERS_GUEST',

    GET_ORDERS_BIDDING_START: 'GET_ORDERS_BIDDING_START',
    GET_ORDERS_BIDDING_ERROR: 'GET_ORDERS_BIDDING_ERROR',
    GET_ORDERS_BIDDING_FINISH: 'GET_ORDERS_BIDDING_FINISH',
    CLEAR_ORDERS_BIDDING: 'CLEAR_ORDERS_BIDDING',

    HIDE_BTN_ORDERS_BIDDING: 'HIDE_BTN_ORDERS_BIDDING',

    GET_ORDER_START: 'GET_ORDER_START',
    GET_ORDER_FINISH: 'GET_ORDER_FINISH',
    GET_ORDER_ERROR: 'GET_ORDER_ERROR',

    GET_CATEGORIES_START: 'GET_CATEGORIES_START',
    GET_CATEGORIES_FINISH: 'GET_CATEGORIES_FINISH',
    GET_CATEGORIES_ERROR: 'GET_CATEGORIES_ERROR',

    SAVE_USER_CATEGORIES: 'SAVE_USER_CATEGORIES',

    SUBSCRIBE_ON_CATEGORY_START: 'SUBSCRIBE_ON_CATEGORY_START',
    SUBSCRIBE_ON_CATEGORY_FINISH: 'SUBSCRIBE_ON_CATEGORY_FINISH',
    SUBSCRIBE_ON_CATEGORY_ERROR: 'SUBSCRIBE_ON_CATEGORY_ERROR',

    CLEAR_ORDERS: 'CLEAR_ORDERS',

    CREATE_ORDER_START: 'CREATE_ORDER_START',
    CREATE_ORDER_FINISH: 'CREATE_ORDER_FINISH',
    CREATE_ORDER_ERROR: 'CREATE_ORDER_ERROR',

    GET_TEMP_ORDER_GUEST_START: 'GET_TEMP_ORDER_GUEST_START',
    GET_TEMP_ORDER_GUEST_FINISH: 'GET_TEMP_ORDER_GUEST_FINISH',
    GET_TEMP_ORDER_GUEST_ERROR: 'GET_TEMP_ORDER_GUEST_ERROR',

    CREATE_TEMP_ORDER_GUEST_START: 'CREATE_TEMP_ORDER_GUEST_START',
    CREATE_TEMP_ORDER_GUEST_FINISH: 'CREATE_TEMP_ORDER_GUEST_FINISH',
    CREATE_TEMP_ORDER_GUEST_ERROR: 'CREATE_TEMP_ORDER_GUEST_ERROR',

    DELETE_TEMP_ORDER_GUEST: 'DELETE_TEMP_ORDER_GUEST',

    EDIT_ORDER_START: 'EDIT_ORDER_START',
    EDIT_ORDER_FINISH: 'EDIT_ORDER_FINISH',
    EDIT_ORDER_ERROR: 'EDIT_ORDER_ERROR',

    GET_ORDER_IDS_WITH_NEW_COMMENTS_START: 'GET_ORDER_IDS_WITH_NEW_COMMENTS_START',
    GET_ORDER_IDS_WITH_NEW_COMMENTS_ERROR: 'GET_ORDER_IDS_WITH_NEW_COMMENTS_ERROR',
    GET_ORDER_IDS_WITH_NEW_COMMENTS_FINISH: 'GET_ORDER_IDS_WITH_NEW_COMMENTS_FINISH',

    CHANGE_STATUS_START: 'CHANGE_STATUS_START',
    CHANGE_STATUS_ERROR: 'CHANGE_STATUS_ERROR',
    CHANGE_STATUS_FINISH: 'CHANGE_STATUS_FINISH',
} as const;
