/* eslint-disable complexity */
import { InferValueTypes } from 'types/common';
import * as actions from '../actions';
import { ModerationActionTypes } from '../action-types';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type StateType = {
    getCompanies: boolean;
};

export const initialState: StateType = {
    getCompanies: false,
};

export function loadingReducer(state = initialState, action: ActionTypes): StateType {
    switch (action.type) {
        case ModerationActionTypes.GET_COMPANY_FOR_MODERATION_START:
            return {
                ...state,
                getCompanies: true,
            };

        case ModerationActionTypes.GET_COMPANY_FOR_MODERATION_FINISH:
        case ModerationActionTypes.GET_COMPANY_FOR_MODERATION_ERROR:
            return {
                ...state,
                getCompanies: false,
            };

        default: return state;
    }
}
