import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Space, Spin } from 'antd';
import classNames from 'classnames';
import { AppLoading } from 'components/ui/app-loading';
import { ScoreRefill } from 'components/score/refill';
import { ScoreRefund } from 'components/score/refund';
import { ScoreNoCompany } from 'components/score/no-company';
import { selectProfile, selectUserLoading } from 'ducks/user/selectors';
import { getPrice } from 'ducks/user/actions';
import { BalansList } from './list';

import styles from './styles.module.scss';

export const Balans = React.memo(() => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const loading = useSelector(selectUserLoading);
    const [view, setView] = useState('score');

    useEffect(() => {
        put(getPrice());
    }, []);

    if (!user || loading.profile) {
        return <AppLoading />;
    }

    const goView = (val: string) => {
        setView(val);
    };

    const linkStyle = (type: string) => classNames({
        [styles.link]: true,
        [styles.link_active]: type === view,
    });

    if (!user.company?.typeOfOrgan) {
        return <ScoreNoCompany />;
    }

    return (
        <React.Fragment>
            <div className={ styles.price }>
                <div className={ styles.price_val }>
                    { loading.price ? (
                        <Spin spinning={ true } />
                    ) : (
                        <div>На вашем счету<span>{ user.company?.price ? (user.company?.price).toLocaleString('ru') : 0 }</span>руб</div>
                    ) }
                </div>
                <div className={ styles.price_txt }>
                    <Space>
                        <span className={ linkStyle('score') } onClick={ () => goView('score') }>Мои счета</span>
                        <Divider type="vertical" />
                        <span className={ linkStyle('addPrice') } onClick={ () => goView('addPrice') }>Пополнить счет</span>
                        <Divider type="vertical" />
                        <span className={ linkStyle('refundPrice') } onClick={ () => goView('refundPrice') }>Возврат денежных средств</span>
                    </Space>
                </div>
            </div>
            { view === 'score' && (<BalansList userId={ user.id } />) }
            { view === 'addPrice' && (<ScoreRefill />) }
            { view === 'refundPrice' && (<ScoreRefund />) }
        </React.Fragment>
    );
});
