/* eslint-disable prefer-promise-reject-errors */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form,
    Input,
    InputNumber,
    Modal,
    Spin,
} from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from 'pages/edit-profile/routes';
import { Tender } from 'types/tender';

import { editTender } from 'ducks/tenders/actions';
import { selectTendersLoading } from 'ducks/tenders/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { schema } from 'form-helpers/tender/schema';
import { TenderEditFormType } from 'form-helpers/tender/types';

type Props = {
    visible: boolean;
    setVisibilityModal: (value: boolean) => void;
    data: Tender;
    setEditData: (value: any) => void;
};

export const TenderEditModal = React.memo(({
    visible, setVisibilityModal, setEditData, data,
}: Props) => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const loading = useSelector(selectTendersLoading);
    const [form] = Form.useForm();
    const [commission, setCommission] = useState(1000);

    const onSubmit = () => {
        form
            .validateFields()
            .then((values: TenderEditFormType) => {
                form.resetFields();
                put(editTender(values, () => {
                    setEditData(false);
                    setVisibilityModal(false);
                }));
                // sendNewTender(orderId);
            });
    };

    const onChangeScore = (price: number | null) => {
        if (price) {
            // eslint-disable-next-line no-mixed-operators
            const proc: number = (price / 100) * 5;

            if (proc < 1000) {
                setCommission(1000);
            } else {
                setCommission(proc);
            }
        }
    };

    const validatePrice = () => {
        const price = form.getFieldValue('price');
        const proc: number = (price / 100) * 5;
        const companyPrice = user?.company?.price || 0;
        const priceUser = companyPrice < (companyPrice - proc) || (companyPrice - proc) < 0;
        const payLink = (<Link to={ ROUTES.BALANS.path }>пополнить счет</Link>);

        if (!companyPrice) {
            return Promise.reject(<div>На вашем счету: 0 руб. Вам необходимо { payLink } </div>);
        }
        if (companyPrice < 1000) {
            return Promise.reject(<div>На вашем счету: { companyPrice } руб. Вам необходимо { payLink }</div>);
        }
        if (price < 1000 && companyPrice < 1000) {
            return Promise.reject(<div>На вашем счету не достаточно денег чтобы указать такую сумму. На вашем счете: { companyPrice } руб. ({ payLink })</div>);
        }
        if (priceUser) {
            return Promise.reject(<div>На вашем счету не достаточно денег чтобы указать такую сумму. На вашем счете: { companyPrice } руб. ({ payLink })</div>);
        }

        return Promise.resolve();
    };

    return (

        <Modal
            confirmLoading={ loading.edit }
            onCancel={ () => setVisibilityModal(false) }
            onOk={ onSubmit }
            okText="Изменить"
            open={ visible }
        >
            <Spin spinning={ loading.edit }>
                <Form
                    form={ form }
                    layout="vertical"
                    name="TenderEditModal"
                    initialValues={ data }
                >

                    <Form.Item
                        hidden={ true }
                        name="id"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Предложите цену"
                        name="price"
                        extra={ true }
                        rules={ [
                            ...schema.price,
                            { validator: validatePrice },
                        ] }
                    >
                        <InputNumber
                            min={ 0 }
                            addonAfter={ `Коммисия ${commission.toLocaleString('ru-RU')} Руб.` }
                            onChange={ onChangeScore }
                        />
                    </Form.Item>

                    <Form.Item
                        label="Укажите планируемый срок оказания услуг"
                        name="termOfService"
                        extra={ true }
                        style={ { marginBottom: 0 } }
                        rules={ schema.termOfService }
                        help="Вы можете указать свой вариант оплаты"
                    >
                        <Input placeholder="Укажите планируемый срок оказания услуг" />
                    </Form.Item>

                    <Form.Item
                        label="Порядок оплаты"
                        name="payment"
                        extra={ true }
                        style={ { marginBottom: 0 } }
                        rules={ schema.payment }
                        help="Вы можете указать свой вариант оплаты"
                    >
                        <Input placeholder="Порядок оплаты" />
                    </Form.Item>

                    <Form.Item
                        label="Комментарий к предложению"
                        name="message"
                        extra={ true }
                        style={ { marginBottom: 0 } }
                        rules={ schema.message }
                        help="В данном поле укажите дополнительную информацию, например: аттестаты ОС и ИЛ, количество ПИ в СС, количество образцов необходимых для предоставления"
                    >
                        <Input.TextArea
                            placeholder="Комментарий к предложению"
                            rows={ 4 }
                            className="textarea"
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
});
