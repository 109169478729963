import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

type Props = {
    text: React.ReactNode;
    className?: string;
};

export const ErrorMessage = ({ text, className }: Props) => <div className={ cn(styles.message, className) }>{ text }</div>;
