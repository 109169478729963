import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
    loading: number;
};

export const Notification = React.memo(({ loading }: Props) => {
    const style = cn({
        [styles.notification]: true,
        [styles.waiting]: loading === 2,
        [styles.enabled]: loading === 1,
    });

    if (loading === 2) {
        return (
            <div className={ styles.notification_wrap }>
                <div className={ style }>
                    Идет соединение...
                </div>
            </div>
        );
    }

    if (loading === 1) {
        return (
            <div className={ styles.notification_wrap }>
                <div className={ style }>
                    Соединение успешно установлено
                </div>
            </div>
        );
    }

    return null;
});
