import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table,
    Pagination,
    Form,
    Typography,
    Input,
    Select,
    Button,
} from 'antd';

import { editFeedback, getFeedback } from 'ducks/feedback/actions';
import {
    selectFeedbacks,
    selectFeedbackLoading,
    selectFeedbackPagination,
} from 'ducks/feedback/selectors';

import { Title } from 'components/ui/title';
import { Feedback as FeedbackType, StatusText, TypeOfStatus } from 'types/feedback';
import { getColumns } from './columns';

import styles from './styles.module.scss';

const { Title: AntTitle } = Typography;
const { Option } = Select;

type Item = FeedbackType;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'select' | 'textarea';
    record: Item;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'select' ? (
        <Select> {
            (Object.keys(TypeOfStatus) as Array<keyof typeof TypeOfStatus>).map(
                (key) => <Option key={ key } value={ key }>{ StatusText[key] }</Option>,
            )
        }
        </Select>
    ) : <Input.TextArea />;

    return (
        <td { ...restProps }>
            { editing ? (
                <Form.Item
                    name={ dataIndex }
                    style={ { margin: 0 } }
                >
                    { inputNode }
                </Form.Item>
            ) : (
                children
            ) }
        </td>
    );
};

export const Feedback = React.memo(() => {
    const put = useDispatch();
    const loading = useSelector(selectFeedbackLoading);
    const pagination = useSelector(selectFeedbackPagination);
    const feedback = useSelector(selectFeedbacks);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState<number | null>(null);

    const getLoad = (page = 1) => put(getFeedback({ pagination: { page } }));

    useEffect(() => {
        getLoad();
    }, []);

    const isEditing = (record: Item) => record.id === editingKey;

    const edit = (record: Partial<Item> & { key: React.Key }) => {
        if (record.id) {
            form.setFieldsValue({ ...record });
            setEditingKey(record.id);
        }
    };

    const cancel = () => {
        setEditingKey(null);
    };

    const save = async (id: number) => {
        try {
            const row = (await form.validateFields()) as Item;

            const res = Object.assign(row, { id });

            put(editFeedback(res, () => setEditingKey(null)));
        } catch (errInfo) {
            // eslint-disable-next-line no-console
            console.log('Validate Failed:', errInfo);
        }
    };

    const mergedColumns = getColumns(isEditing, save, cancel, editingKey, edit).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                inputType: col.dataIndex === 'status' ? 'select' : 'textarea',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <React.Fragment>
            <Title
                className={ styles.title }
                title={ <AntTitle level={ 3 }>Обратная связь</AntTitle> }
                event={ <Button onClick={ () => getLoad() } type="ghost">Обновить</Button> }
            />
            <Form form={ form } component={ false }>
                <Table
                    size="small"
                    columns={ mergedColumns }
                    rowKey="id"
                    components={ {
                        body: {
                            cell: EditableCell,
                        },
                    } }
                    loading={ loading.get }
                    className={ styles.table }
                    dataSource={ feedback }
                    pagination={ false }
                />
            </Form>
            {
                Boolean(pagination.total > 1) && (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total }
                            pageSize={ pagination.pageSize }
                            current={ pagination.page }
                            onChange={ getLoad }
                        />
                    </div>
                )
            }
        </React.Fragment>
    );
});
