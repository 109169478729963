import React from 'react';
import { Skeleton } from 'antd';
import cn from 'classnames';
import styles from '../../../order/short/styles.module.scss';

export const OrderItemSkeleton = () => (
    <div className={ styles.wrap }>
        <div className={ styles.content }>
            <div className={ styles.content_title }>
                <Skeleton active={ true } paragraph={ { rows: 0 } } />
            </div>
            <div className={ styles.item }>
                <div className={ styles.title }>Описание</div>
                <div className={ styles.desc }>
                    <Skeleton active={ true } paragraph={ { rows: 0 } } />
                </div>
            </div>
            <div className={ styles.item }>
                <div className={ styles.title }>Компания / физ. лицо</div>
                <div className={ cn(styles.desc, styles.zakazchik) }>
                    <Skeleton active={ true } paragraph={ { rows: 0 } } />
                </div>
            </div>
        </div>
        <div className={ styles.info }>
            <div className={ styles.item }>
                <div className={ styles.title }>Категории</div>
                <div className={ styles.desc }>
                    <Skeleton active={ true } paragraph={ { rows: 0 } } />
                </div>
            </div>
            <div className={ styles.item }>
                <div className={ styles.title }>Заказчик</div>
                <div className={ cn(styles.desc, styles.acc) }>
                    <Skeleton.Avatar className={ styles.acc__ava } active={ true } />
                    <Skeleton active={ true } className={ styles.acc__name } paragraph={ { rows: 0 } } />
                </div>
            </div>
            <div className={ cn(styles.hidden, styles.item) }>
                <div className={ styles.title }>Дата создания</div>
                <div className={ styles.desc }>
                    <Skeleton active={ true } paragraph={ { rows: 1 } } />
                </div>
            </div>
        </div>
    </div>
);
