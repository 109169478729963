import React, { memo, useCallback } from 'react';
import {
    Pagination,
    Avatar,
    Empty,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Loading } from 'components/ui/loading';
import { selectMappedCategories } from 'ducks/orders/selectors';

import { ROUTES } from 'constants/routes';
import { Order, OrdersPagination } from 'types/order';
import { getAvatar, getFio } from 'utils/formatters';
import { DATE_FORMAT } from 'constants/date';

import styles from './styles.module.scss';

type Props = {
    onChange?: any;
    data: Order[];
    loading?: boolean;
    pagination?: OrdersPagination;
};

export const Orders = memo(({
    data, loading, pagination, onChange,
}: Props) => {
    const mappedCategories = useSelector(selectMappedCategories);

    const goToLink = useCallback((item: Order) => () => ROUTES.VIEW_ORDER.getPath({ id: item.id }), []);

    if (loading) {
        return (<Loading />);
    }

    if (data.length === 0) {
        return (<Empty description="Выполненных заказов нет" image={ Empty.PRESENTED_IMAGE_SIMPLE } />);
    }

    return (
        <React.Fragment>
            <div className={ styles.grid }>
                { data.map((item, i) => (
                    <Link
                        key={ i }
                        to={ goToLink(item) }
                        className={ styles.order_item }
                    >
                        <div className={ styles.order_header }>{ item.name }</div>
                        <div className={ styles.categories }>
                            <span>
                                { item.orderCategories.map((num, s, arr) => ((s + 1) < arr.length ? (`${mappedCategories[num]}, `) : mappedCategories[num])) }
                            </span>
                        </div>
                        <div className={ styles.order_body }>{ item.message }</div>
                        <div className={ styles.order_footer }>
                            <div className={ styles.account }>
                                <div className={ styles.account__img }>
                                    <Avatar shape="square" size={ 45 } src={ getAvatar(item?.account?.avatar) } icon={ <UserOutlined /> } />
                                </div>
                                <div className={ styles.account__body }>
                                    <div className={ styles.account__name }>
                                        { getFio(item?.account?.lastName, item?.account?.firstName) }
                                    </div>
                                    <div className={ styles.account__time }>
                                        { moment(item.created).format(DATE_FORMAT.DATE_TIME) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                )) }
            </div>
            { pagination && (
                Boolean(pagination.total) && (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total }
                            pageSize={ pagination.pageSize }
                            current={ pagination.page }
                            onChange={ onChange }
                        />
                    </div>
                )
            ) }
        </React.Fragment>
    );
});
