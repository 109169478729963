import React from 'react';
import { Typography } from 'antd';
// import cn from 'classnames';
import clock from 'assets/bank_clock.png';
import styles from './styles.module.scss';

const { Title } = Typography;

export const ScoreRefill = () => (
    <div className={ styles.content }>
        <div className={ styles.infoPrice }>
            <div className={ styles.info }>
                <Title className={ styles.title } level={ 4 }>Реквизиты для перечисления средств</Title>
                <p>Пополнение вашего счета необходимо осуществлять со счета компании которая зарегистрирована на площадке, идентификация платежа будет осуществлена по ИНН, если платеж отправлен со счета иной компании, деньги не будут зачислены на счет и будут возвращены отправителю. Назначение платежа &quot;Пополнение лицевого счета для участия в торгах ИНН хххххх&quot;. Реквизиты для пополнения счета указаны ниже:</p>
                <dl>
                    <dt>Получатель:</dt>
                    <dd>ООО &quot;СЕРТТОРГ&quot;</dd>
                </dl>
                <dl>
                    <dt>ИНН:</dt>
                    <dd>9721166922</dd>
                </dl>
                <dl>
                    <dt>КПП:</dt>
                    <dd>772101001</dd>
                </dl>
                <dl>
                    <dt>Банк:</dt>
                    <dd>МОСКОВСКИЙ ФИЛИАЛ АО КБ &quot;МОДУЛЬБАНК&quot;</dd>
                </dl>
                <dl>
                    <dt>БИК:</dt>
                    <dd>044525092</dd>
                </dl>
                <dl>
                    <dt>К/С №:</dt>
                    <dd>30101810645250000092</dd>
                </dl>
                <dl>
                    <dt>Счёт №:</dt>
                    <dd>40702810470010365274</dd>
                </dl>
            </div>
        </div>
        <div className={ styles.infoBlok }>
            <img src={ clock } className={ styles.infoBlok_img } alt="" />
            <span>Платежи разносятся по лицевым счетам каждый рабочий день по<br /> факту поступления средств по банковским выпискам (т.е. банковский день + рабочий день).</span>
        </div>
    </div>
);
