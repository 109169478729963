import { put, call } from 'redux-saga/effects';

import {
    saveCompany,
    saveCompanyError,
    saveCompanyFinish,
} from 'ducks/company/actions';

import { getProfile } from 'ducks/user/actions';
import { getCompanyForModeration } from 'ducks/moderation/actions';

import { fetchers } from 'api';
import { Company } from 'types/company';
import { SaveCompanyForManagerPayload } from 'types/api';

export function* saveCompanyRequest({ data, userId, callback }: ReturnType<typeof saveCompany>) {
    try {
        // callback есть при сохранении менеджером
        if (callback) {
            yield call(fetchers.saveCompanyForManager, userId, data as SaveCompanyForManagerPayload);
            yield put(getCompanyForModeration({ pagination: { page: 1 } }));
            callback();
        } else {
            yield call(fetchers.saveCompany, userId, data as Company);
            yield put(getProfile({ userId }));
            // Modal.info({
            //     title: 'Обновление',
            //     content: 'Данные вашей компании отправлены на проверку. Проверка занимает от 5 минут до 1 часа. После того как Администратор подтвердит вы сможет полностью работать с сайтом.',
            // });
        }
        yield put(saveCompanyFinish());
    } catch (error) {
        yield put(saveCompanyError());
    }
}
