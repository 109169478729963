import { message } from 'antd';
import { put, call } from 'redux-saga/effects';

import {
    sendСonfirmation,
    sendСonfirmationFinish,
    sendСonfirmationError,
} from 'ducks/user/actions';

import { fetchers } from 'api';

export function* sendConfirmationRequest({ payload }: ReturnType<typeof sendСonfirmation>) {
    try {
        yield call(fetchers.sendСonfirmation, payload);
        yield put(sendСonfirmationFinish());

        message.success('Сообщение успешно отправлено на вашу почту');
    } catch (error) {
        yield put(sendСonfirmationError());
        message.error('Ошибка отправки сообщения');
    }
}
