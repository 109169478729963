import { put, call, select } from 'redux-saga/effects';

import {
    getComments,
    getCommentsFinish,
    getCommentsError,
} from 'ducks/comments/actions';

import {
    selectCommentsSort,
    selectCommentsPagination,
} from 'ducks/comments/selectors';

import { fetchers } from 'api';

export function* getCommentsRequest({ payload }: ReturnType<typeof getComments>) {
    try {
        const { pageSize } = yield select(selectCommentsPagination);
        const sort = yield select(selectCommentsSort);
        const response = yield call(fetchers.getComments, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        yield put(getCommentsFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                page: payload.pagination.page,
            },
            sort: payload.sort || sort,
        }));
    } catch (error) {
        yield put(getCommentsError());
    }
}
