import { put, call, select } from 'redux-saga/effects';

import {
    getScores,
    getScoresError,
    getScoresFinish,
} from 'ducks/score/actions';

import {
    selectScoresPagination,
    selectScoresSort,
} from 'ducks/score/selectors';

import { fetchers } from 'api';

export function* getScoresRequest({ payload }: ReturnType<typeof getScores>) {
    try {
        const { pageSize } = yield select(selectScoresPagination);
        const sort = yield select(selectScoresSort);
        const response = yield call(fetchers.getScores, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        yield put(getScoresFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                page: payload.pagination.page,
            },
            sort: payload.sort || sort,
        }));
    } catch (error) {
        yield put(getScoresError());
    }
}
