export const CommentsActionTypes = {
    GET_COMMENTS_START: 'GET_COMMENTS_START',
    GET_COMMENTS_ERROR: 'GET_COMMENTS_ERROR',
    GET_COMMENTS_FINISH: 'GET_COMMENTS_FINISH',

    SEND_COMMENT_START: 'SEND_COMMENT_START',
    SEND_COMMENT_ERROR: 'SEND_COMMENT_ERROR',
    SEND_COMMENT_FINISH: 'SEND_COMMENT_FINISH',

    CLEAR_COMMENTS: 'CLEAR_COMMENTS',

    EDIT_COMMENT_START: 'EDIT_COMMENT_START',
    EDIT_COMMENT_ERROR: 'EDIT_COMMENT_ERROR',
    EDIT_COMMENT_FINISH: 'EDIT_COMMENT_FINISH',

    DELETE_COMMENT_START: 'DELETE_COMMENT_START',
    DELETE_COMMENT_ERROR: 'DELETE_COMMENT_ERROR',
    DELETE_COMMENT_FINISH: 'DELETE_COMMENT_FINISH',

    SET_COMMENT: 'SET_COMMENT',
    EDIT_COMMENT: 'EDIT_COMMENT',
    REMOVE_COMMENT: 'REMOVE_COMMENT',
} as const;
