import React  from 'react';
import { useSwiper } from 'swiper/react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';
import cn from 'classnames';
import cl from './styles.module.scss';

export const SliderButtons = () => {
    const swiper = useSwiper();

    if(!swiper.allowSlidePrev && !swiper.allowSlideNext) {
        return null;
    }

    return (
        <div className={ cl.video_setting }>
            <div
                className={ cn(cl.video__previous, {[cl.video_btn_disable]: !swiper.allowSlidePrev}) }
                onClick={ () => swiper.slidePrev() }
            >
                <IconChevronLeft />
            </div>
            <div
                className={ cn(cl.video__next, {[cl.video_btn_disable]: !swiper.allowSlideNext}) }

                onClick={ () => swiper.slideNext() }
            >
                <IconChevronRight />
            </div>
        </div>
    );
};