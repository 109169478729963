import * as yup from 'yup';

import { REQUIRED_FIELD, IS_EMAIL } from 'form-helpers/message';

export const schema = yup.object().shape({
    firstName: yup.string().required(REQUIRED_FIELD).default(''),
    lastName: yup.string().required(REQUIRED_FIELD).default(''),
    email: yup.string().email(IS_EMAIL).required(REQUIRED_FIELD).default(''),
    password: yup.string().required(REQUIRED_FIELD).default(''),
    confirmPassword: yup.string().required(REQUIRED_FIELD).test('isEqual', 'Пароли не совпадают', function validate(value) {
        if (this.parent.password) {
            return this.parent.password === value;
        }

        return true;
    }).default(''),
    role: yup.string().required(REQUIRED_FIELD).default(''),
    typeOfRole: yup.string().required(REQUIRED_FIELD).default(''),
});
