import React from 'react';
import { Header } from 'components/header';
import { Notification } from 'components/notification';

import styles from './styles.module.scss';

type Props = {
    children: React.ReactNode;
};

export const Layout = ({ children }: Props) => (
    <div className={ styles.container }>
        <Header />
        <Notification />
        <div className={ styles.content }> { children }</div>
    </div>
);
