import { put, call, select } from 'redux-saga/effects';
import {
    getTenderOffer,
    getTenders,
    getTendersError,
    getTendersFinish,
} from 'ducks/tenders/actions';

import {
    selectTendersPagination,
    selectTendersSort,
} from 'ducks/tenders/selectors';

import { fetchers } from 'api';

export function* getTendersRequest({ payload }: ReturnType<typeof getTenders>) {
    try {
        const { pageSize } = yield select(selectTendersPagination);
        const sort = yield select(selectTendersSort);
        const response = yield call(fetchers.getTenders, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        yield put(getTendersFinish({
            data: response.data,
            isRevoke: response.isRevoke,
            pagination: {
                total: response.pagination.total,
                page: payload.pagination.page,
            },
            sort: payload.sort || sort,
        }));

        if (response.data.length > 3) {
            yield put(getTenderOffer(Number(payload.orderId)));
        }
    } catch (error) {
        yield put(getTendersError());
    }
}
