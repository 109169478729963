import React from 'react';
import { Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

const { Title } = Typography;

export const TermsAuction = () => (
    <div>
        <Title className={ styles.title } level={ 4 }>Условия участия в торгах</Title>
        <div className={ styles.content }>
            1. Стоимость участия в торгах составляет 5% от суммы ценового предложения, но не менее 1000р. Оплата происходит путем списания средств с лицевого счета в случае победы в торгах.<br />
            2. Подача ценового предложения возможна при наличии средств на счете, необходимых для оплаты комиссии<br />
            3. После подачи ценового предложения, сумма, равная комиссии соответствующий ценовому предложению, блокируется на счету.<br />
            4. Если ваше предложение было принято, она списывается в пользу площадки<br />
            5. Если предложение не было принято то сумма разблокируется после завершения торгов<br />
            <br />
            <ul className={ styles.list }>
                <li><NavLink target="_blank" to="/Вознаграждение-Оператора-ЭП.pdf">Вознаграждение-Оператора-ЭП</NavLink></li>
            </ul>
        </div>
    </div>
);
