import { put, call, select } from 'redux-saga/effects';

import {
    getNotifications,
    getNotificationsError,
    getNotificationsFinish,
} from 'ducks/notification/actions';

import {
    selectNotificationPagination,
    selectNotificationSort,
} from 'ducks/notification/selectors';

import { fetchers } from 'api';

export function* getNotificationRequest({ payload }: ReturnType<typeof getNotifications>) {
    try {
        const { pageSize } = yield select(selectNotificationPagination);
        const sort = yield select(selectNotificationSort);
        const response = yield call(fetchers.getNotification, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        yield put(getNotificationsFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                next: response.pagination.next,
                page: payload.pagination.page,
            },
            sort: payload.sort || sort,
        }));
    } catch (error) {
        yield put(getNotificationsError());
    }
}
