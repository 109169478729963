/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { ComponentLoading } from 'components/ui/component-loading';
import { selectProfile } from 'ducks/user/selectors';
import { selectOrder, selectOrderLoading } from 'ducks/orders/selectors';
import { ROUTES } from 'constants/routes';
import styles from '../styles.module.scss';

const { Title } = Typography;

export const SuccessfullyСreated = () => {
    const profile = useSelector(selectProfile);
    const order = useSelector(selectOrder);
    const loading = useSelector(selectOrderLoading);

    return (
        <ComponentLoading loading={ loading.create }>
            <Title level={ 3 } className={ styles.success_title }>Заказ оформлен</Title>
            <div className={ styles.success_body }>
                <div className={ styles.success__text }>
                    { profile?.isVerified ? (
                        <p>Ваш заказ успешно создан. номер заказа №{ order?.id }</p>
                    ) : (
                        <p>
                            На вашу электронную почту <b>({ profile?.email })</b> отправлены ваши контактные данные для авторизации на сайте. <br /><br />
                            Вам необходимо подтвердить свою учетную запись, для этого вам нужно пройти по сылке которую мы отправили вам на почту. <br />
                            После чего вы сможете опубликовать свой заказ и он станет видимым для всех.
                        </p>
                    ) }
                </div>

                { order?.id ? (
                    <Button
                        type="primary"
                        href={ ROUTES.VIEW_ORDER.getPath({ id: order?.id }) }
                        className={ styles.button__mt30 }
                    >
                        Перейти на страницу заказа
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        href={ ROUTES.MY_ORDERS.path }
                        className={ styles.button__mt30 }
                    >
                        Перейти в мои заказы
                    </Button>
                ) }
            </div>
        </ComponentLoading>
    );
};
