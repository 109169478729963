import * as yup from 'yup';

import { REQUIRED_FIELD, IS_EMAIL } from 'form-helpers/message';
// import { isValidPhoneNumber } from 'react-phone-number-input';

export const schema = yup.object().shape({
    firstName: yup.string().required(REQUIRED_FIELD).default(''),
    lastName: yup.string().required(REQUIRED_FIELD).default(''),
    aboutMe: yup.string().nullable().default(''),
    skype: yup.string().nullable(),
    tg: yup.string().nullable(),
    viber: yup.string().nullable(),
    whatsapp: yup.string().nullable(),
    avatar: yup.mixed().test('isRequired', 'Загрузите изображение', (value) => {
        const isIssetAnotherFormat = value?.type ? !(value.type as string).includes('image') : false;

        return value ? !isIssetAnotherFormat : true;
    }).default(null),
});

export const schemaEmail = yup.object().shape({
    email: yup.string().email(IS_EMAIL).required(REQUIRED_FIELD).default(''),
});

export const schemaPhone = yup.object().shape({
    phone: yup.string().nullable(),
});

export const schemaPassword = yup.object().shape({
    password: yup.string().default(''),
    confirmPassword: yup.string().test('isEqual', 'Пароли не совпадают', function validate(value) {
        if (this.parent.password) {
            return this.parent.password === value;
        }

        return true;
    }).default(''),
});
