import axios from 'axios';
import { message } from 'antd';

import { fetchers } from 'api';

import { history } from 'App';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';

const setupInterceptors = () => {
    let isRefreshing = false;
    const subscribers: any[] = [];

    function cleanStorage() {
        localStorage.removeItem('token');
        history.push('/auth');
    }

    function subscribeTokenRefresh(cb: any) {
        subscribers.push(cb);
    }

    function onRefreshed(token: string) {
        subscribers.map((cb) => cb(token));
    }

    axios.interceptors.request.use(
        (config: any) => {
            const token = localStorage.getItem('token');
            const newConfig = config;

            if (isRefreshing) {
                delete newConfig.headers.common.Authorization;
            } else if (token) {
                newConfig.headers.common.Authorization = `Bearer ${token}`;
            }

            return newConfig;
        },
        (error: any) => Promise.reject(error),
    );
    axios.interceptors.response.use(
        (response: any) => response.data,
        (error: any) => {
            const {
                config,
                response: { status },
            } = error;

            if (
                status === 401 &&
                !config.url.includes('/refresh')
            ) {
                if (!isRefreshing) {
                    isRefreshing = true;
                    fetchers.refreshToken().then(
                        ({ jwtToken }: any) => {
                            isRefreshing = false;
                            onRefreshed(jwtToken);
                            localStorage.setItem('token', jwtToken);
                            subscribers.length = 0;
                        },
                        () => cleanStorage(),
                    );
                }

                return new Promise((resolve) => {
                    subscribeTokenRefresh((token: string) => {
                        config.headers.Authorization = `Bearer ${token}`;
                        resolve(axios(config));
                    });
                });
            }
            message.error(error.response?.data?.message || error.message);

            return Promise.reject(error.response);
        },
    );
};

export default setupInterceptors;
