import * as Api from 'types/api';
import { Message } from 'types/dialog';
import { DialogActionTypes } from './action-types';

export const getDialog = (payload: Api.GetDialogPayload) => ({
    type: DialogActionTypes.GET_DIALOG_START,
    payload,
});

export const getDialogError = () => ({
    type: DialogActionTypes.GET_DIALOG_ERROR,
});

export const getDialogFinish = (response: Api.GetDialogResponse) => ({
    type: DialogActionTypes.GET_DIALOG_FINISH,
    response,
});

export const getDialogByHash = (payload: Api.GetDialogPayload) => ({
    type: DialogActionTypes.GET_DIALOG_BY_HASH_START,
    payload,
});

export const getDialogByHashError = () => ({
    type: DialogActionTypes.GET_DIALOG_BY_HASH_ERROR,
});

export const getDialogByHashFinish = (response: Api.GetDialogResponse) => ({
    type: DialogActionTypes.GET_DIALOG_BY_HASH_FINISH,
    response,
});

export const getDialogAll = (payload: Api.GetDialogAllPayload) => ({
    type: DialogActionTypes.GET_DIALOG_ALL_START,
    payload,
});

export const getDialogAllError = () => ({
    type: DialogActionTypes.GET_DIALOG_ALL_ERROR,
});

export const getDialogAllFinish = (response: Api.GetDialogAllResponse) => ({
    type: DialogActionTypes.GET_DIALOG_ALL_FINISH,
    response,
});

export const clearDialogAll = () => ({
    type: DialogActionTypes.CLEAR_DIALOG_ALL,
});

export const clearDialog = () => ({
    type: DialogActionTypes.CLEAR_DIALOG,
});

export const toggleDialogNotify = (payload: Api.GetDialogNottifPayload, cb?: () => void) => ({
    type: DialogActionTypes.TOGGLE_SELECT_START,
    payload,
    cb,
});

export const toggleDialogNotifyFinish = (data: Api.GetDialogNottifResponse) => ({
    type: DialogActionTypes.TOGGLE_SELECT_FINISH,
    data,
});

// Сообщения

export const clearDialogMessages = () => ({
    type: DialogActionTypes.CLEAR_DIALOG_MESSAGES,
});

export const getMessages = (payload: Api.GetDiagloMessagesPayload) => ({
    type: DialogActionTypes.GET_MESSAGES_START,
    payload,
});

export const getMessagesError = () => ({
    type: DialogActionTypes.GET_MESSAGES_ERROR,
});

export const getMessagesFinish = (response: Api.GetDiagloMessagesResponse) => ({
    type: DialogActionTypes.GET_MESSAGES_FINISH,
    response,
});

export const sendMessage = (params: Api.SendDialogMessageParamsPayload, data: Api.SendDialogMessageDataPayload, callback: any) => ({
    type: DialogActionTypes.SEND_DIALOG_MESSAGE_START,
    params,
    data,
    callback,
});

export const sendMessageError = () => ({
    type: DialogActionTypes.SEND_DIALOG_MESSAGE_ERROR,
});

export const sendMessageFinish = () => ({
    type: DialogActionTypes.SEND_DIALOG_MESSAGE_FINISH,
});

export const editMessage = (payload: Api.EditDiagloMessagePayload, callback: () => void) => ({
    type: DialogActionTypes.EDIT_MESSAGES_START,
    payload,
    callback,
});

export const editMessageError = () => ({
    type: DialogActionTypes.EDIT_MESSAGES_ERROR,
});
export const editMessageFinish = () => ({
    type: DialogActionTypes.EDIT_MESSAGES_FINISH,
});

export const delteMessage = (id: number) => ({
    type: DialogActionTypes.DELETE_MESSAGES_START,
    id,
});

export const delteMessageError = () => ({
    type: DialogActionTypes.DELETE_MESSAGES_ERROR,
});

export const delteMessageFinish = (id: number) => ({
    type: DialogActionTypes.DELETE_MESSAGES_FINISH,
    id,
});

export const readMessage = (id: number) => ({
    type: DialogActionTypes.READ_DIALOG_MESSAGE,
    id,
});

export const readMessageFinish = (id: number) => ({
    type: DialogActionTypes.READ_DIALOG_MESSAGE_FINISH,
    id,
});

export const readMessageAll = (read: boolean) => ({
    type: DialogActionTypes.READ_DIALOG_MESSAGE_ALL,
    read,
});

// socket

export const socketAddMessage = (payload: Message) => ({
    type: DialogActionTypes.SOCKET_ADD_MESSAGE,
    payload,
});

export const socketEditMessage = (payload: Message) => ({
    type: DialogActionTypes.SOCKET_EDIT_MESSAGE,
    payload,
});

export const socketRemoveMessage = (id: number) => ({
    type: DialogActionTypes.SOCKET_REMOVE_MESSAGE,
    id,
});
