import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { history } from 'App';
import { Avatar } from 'components/ui/avatar';
import { Order } from 'types/order';
import { TypeOfRoleText } from 'types/account';
import {
    selectMappedCategories,
} from 'ducks/orders/selectors';
import { getFio } from 'utils/formatters';
import { ROUTES } from 'constants/routes';

import styles from './styles.module.scss';

type Props = Order;

export const OrderShort: React.FC<Props> = React.memo(({
    id,
    name,
    created,
    orderCategories,
    account,
    message,
}) => {
    const mappedCategories = useSelector(selectMappedCategories);

    const handleGoToOrder = () => history.push(ROUTES.VIEW_ORDER.getPath({ id }));

    const goToAuthor = () => history.push(ROUTES.USER.getPath({ id: account.id }));

    return (
        <div className={ styles.wrap }>
            <div className={ styles.content }>
                <div
                    className={ styles.content_title }
                    onClick={ handleGoToOrder }
                >
                    #{ id } | { name }
                </div>
                <div className={ styles.item }>
                    <div className={ styles.title }>Описание</div>
                    <div className={ styles.desc }>{ message }</div>
                </div>
                { (account.company?.fio || account.company?.organization) && (
                    <div className={ styles.item }>
                        <div className={ styles.title }>Компания / физ. лицо</div>
                        <div
                            onClick={ goToAuthor }
                            className={ cn(styles.desc, styles.zakazchik) }
                        >
                            { account.company?.fio && ('Физ.лицо') }
                            { account.company?.organization && ('Юр.лицо') } &nbsp;/&nbsp;
                            { TypeOfRoleText[account.typeOfRole] }
                        </div>
                    </div>
                ) }
            </div>
            <div className={ styles.info }>
                <div className={ styles.item }>
                    <div className={ styles.title }>Категории</div>
                    <div className={ styles.desc }>
                        {
                            orderCategories.length ?
                                orderCategories.map((num, i, arr) => ((i + 1) < arr.length
                                    ? (`${mappedCategories[num]}, `)
                                    : mappedCategories[num]))
                                : 'без категории'
                        }
                    </div>
                </div>
                <div className={ styles.item }>
                    <div className={ styles.title }>Заказчик</div>
                    <div
                        className={ cn(styles.desc, styles.acc) }
                        onClick={ goToAuthor }
                    >
                        <Avatar
                            type='round'
                            className={ styles.acc__ava }
                            src={ account.avatar }
                        />
                        <div className={ styles.acc__name }>
                            { getFio(account.lastName, account.firstName) }
                        </div>
                    </div>
                </div>
                <div className={ cn(styles.hidden, styles.item) }>
                    <div className={ styles.title }>Дата создания</div>
                    <div className={ styles.desc }>{ moment(created).fromNow() }</div>
                </div>
            </div>
        </div>
    );
});
