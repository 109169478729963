import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form, Input, Button, Space,
} from 'antd';

import { AuthLayout } from 'components/ui/auth-layout';

import { signIn } from 'ducks/app/actions';

import { selectSignInLoading } from 'ducks/app/selectors';

import { schema } from 'form-helpers/auth/schema';
import { AuthFormType } from 'form-helpers/auth/types';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { history } from 'App';

import { ROUTES } from 'constants/routes';
import { EventInit, eventYm } from 'components/ui/yandex-metrika';
import { TLoginButton, TLoginButtonSize } from 'react-telegram-auth';

import styles from './styles.module.scss';

const initialValues = schema.cast({});

export const Auth = () => {
    const loading = useSelector(selectSignInLoading);
    const put = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push(ROUTES.MAIN.path);
        }
    }, []);

    const onSubmit = (values: AuthFormType) => {
        put(signIn(values));
        eventYm('reachGoal', 'sign');
    };

    const onAuthCallback = (values: any) => {
        put(signIn({
            type: 'telegram',
            tg: values.username,
            tgUid: values.id,
        }));
        eventYm('reachGoal', 'sign');
    };

    return (
        <AuthLayout
            title="Авторизация"
            variant='login'
        >
            <div  className={ styles.formContent }>
                <Formik<AuthFormType>
                    initialValues={ initialValues }
                    onSubmit={ onSubmit }
                    validationSchema={ schema }
                >
                    { ({
                        handleSubmit,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                    }) => {
                        const errorList = {
                            email: getError(errors, touched, true, 'email'),
                            password: getError(errors, touched, true, 'password'),
                        };

                        return (
                            <Form
                                onFinish={ handleSubmit }
                                labelAlign="left"
                                layout='vertical'
                                className={ styles.form }
                            >
                                <Form.Item
                                    label="Ваш E-mail"
                                    extra={ errorList.email }
                                    validateStatus={ getErrorStatus(!!errorList.email) }
                                >
                                    <Input
                                        name="email"
                                        placeholder="Ваш E-mail"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Пароль"
                                    extra={ errorList.password }
                                    validateStatus={ getErrorStatus(!!errorList.password) }
                                >
                                    <Space>
                                        <Input
                                            name="password"
                                            placeholder="Пароль"
                                            onChange={ handleChange }
                                            onBlur={ handleBlur }
                                            type="password"
                                        />
                                        <Button
                                            className={ styles.link }
                                            type="link"
                                            onClick={ () => history.push(ROUTES.PASSWORD_RECOVERY.path) }
                                        >
                                            Забыли пароль?
                                        </Button>
                                    </Space>
                                </Form.Item>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={ loading }
                                    block={ true }
                                >
                                    Войти на сайт
                                </Button>
                            </Form>
                        );
                    } }
                </Formik>
            </div>
            <div className="hr-text">или</div>
            <div  className={ styles.formContent }>
                <TLoginButton
                    botName={ `${process.env.REACT_APP_TG_BOT}` }
                    buttonSize={ TLoginButtonSize.Medium }
                    lang="ru"
                    usePic={ false }
                    cornerRadius={ 20 }
                    onAuthCallback={ onAuthCallback }
                    requestAccess="write"
                />
            </div>
            <EventInit />
        </AuthLayout>
    );
};
