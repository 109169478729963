import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Modal,
    Radio,
    RadioChangeEvent,
    message,
    Alert,
    Typography,
} from 'antd';

import { UploadFile } from 'components/upload-file';
import { Loading } from 'components/ui/loading';
import { Feedback } from 'components/footer/form';

import { saveCompany, getCompany } from 'ducks/company/actions';
import { selectProfile } from 'ducks/user/selectors';
import { selectCompanyLoading, selectCompany } from 'ducks/company/selectors';

import { getError, getErrorStatus } from 'form-helpers/validation';
import { CompanyFormType } from 'form-helpers/company/types';
import { schema } from 'form-helpers/company/schema';

import { formatStringToUploadObj } from 'utils/formatters';
import { ROUTES } from 'constants/routes';
import { history } from 'App';

import { User } from 'types/user';
import { Role, TypeOfRole } from 'types/account';
import { CompanyByInn, CompanyType } from 'types/company';
import { fetchers } from 'api';

import { fields } from './fields';

import styles from '../styles.module.scss';

const { Title } = Typography;

const itemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

type Props = {
    user: User;
};

const getFields = (profile: User | null) => (
    values: any,
    errors: any,
    handleChange: any,
    handleBlur: any,
    onChangeFile: any,
    key: keyof typeof fields,
) => fields[key].map((item) => {
    if (profile?.role === Role.Customer || (profile?.role === Role.Executor && profile?.typeOfRole === TypeOfRole.broker)) {
        if (['certificateNumber'].includes(item.key)) {
            return false;
        }
    }

    if (item.type === 'text' || item.type === 'number') {
        return (
            <Form.Item
                key={ item.key }
                label={ item.text }
                extra={ errors[item.key] }
                validateStatus={ getErrorStatus(!!errors[item.key]) }
            >
                <Input
                    type={ item.type }
                    name={ item.key }
                    placeholder={ item.text }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    value={ values[item.key] }
                />
            </Form.Item>
        );
    }

    if (item.type === 'file') {
        return (
            <Form.Item
                key={ item.key }
                label={ item.text }
                extra={ errors[item.key] }
                validateStatus={ getErrorStatus(!!errors[item.key]) }
            >
                <UploadFile
                    onChange={ onChangeFile([item.key]) }
                    defaultValue={ [formatStringToUploadObj(values[item.key])] }
                    listType="picture"
                />
            </Form.Item>
        );
    }

    return null;
});

export const Company = React.memo(({ user }: Props) => {
    const put = useDispatch();
    const profile = useSelector(selectProfile);
    const loading = useSelector(selectCompanyLoading);
    const userCompany = useSelector(selectCompany);
    const [visible, setVisible] = useState(false);

    const [isSubmitTry, setIsSubmitTry] = React.useState(false);
    const [company, setCompany] = React.useState<CompanyByInn | null>(null);
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    useEffect(() => {
        put(getCompany(profile!.id));
    }, []);

    const toggle = (v = !visible) => {
        setVisible(v);
    };

    const returnToProfile = () => history.push(ROUTES.USER.getPath({ id: user.id }));

    const onCloseModal = () => {
        setIsModalVisible(false);
        setCompany(null);
    };

    const initialValues = schema.cast(userCompany || {});

    const onSubmitForm = (values: CompanyFormType) => {
        if (!isEqual(values, initialValues)) {
            put(saveCompany({ ...values, sendForverif: 1 }, profile!.id));
        }
    };

    if (loading.get) {
        return (
            <Loading />
        );
    }

    if (!profile?.isVerified) {
        return (
            <React.Fragment>
                <Title className={ styles.title } level={ 4 }>Компания</Title>
                <div className={ styles.content }>
                    <p>Уважаемый <b>{ profile?.firstName } { profile?.lastName }</b>, чтобы заполнить данные о своей компании, вам необходимо подтвердить свой E-mail для этого вам необходимо зайти на свою почту, открыть сообщение от нашего сервиса и пройти по сылке. После чего ваш E-mail будет подтвержден.</p>
                    <p>Если вам не пришло сообщение, обратитесь пожалуйста по <b className={ styles.link } onClick={ () => toggle() }>форме обратной связи</b> или на почту <b><a className={ styles.link } href="mailto:info@cert-torg.ru">info@cert-torg.ru</a></b>.</p>
                </div>
                {
                    visible && (
                        <Feedback
                            visible={ visible }
                            toggle={ toggle }
                        />
                    )
                }
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Title className={ styles.title } level={ 4 }>Компания</Title>
            { userCompany && userCompany.comment && <Alert message={ userCompany.comment } showIcon={ true } type="error" style={ { marginBottom: 24 } } /> }
            <div className={ styles.content }>
                <Row>
                    <Col lg={ 18 } span={ 24 }>
                        <div className={ styles.form }>
                            <Formik<CompanyFormType>
                                initialValues={ initialValues }
                                onSubmit={ onSubmitForm }
                                validationSchema={ schema }
                            >
                                { ({
                                    setFieldValue,
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    errors,
                                    touched,
                                    values,
                                    setValues,
                                }) => {
                                    const errorList = {
                                        inn: getError(errors, touched, isSubmitTry, 'inn'),
                                        organization: getError(errors, touched, isSubmitTry, 'organization'),
                                        kpp: getError(errors, touched, isSubmitTry, 'kpp'),
                                        address: getError(errors, touched, isSubmitTry, 'address'),
                                        bicBank: getError(errors, touched, isSubmitTry, 'bicBank'),
                                        scoreNumber: getError(errors, touched, isSubmitTry, 'scoreNumber'),
                                        certificateNumber: getError(errors, touched, isSubmitTry, 'certificateNumber'),
                                        fio: getError(errors, touched, isSubmitTry, 'fio'),
                                        passportNumber: getError(errors, touched, isSubmitTry, 'passportNumber'),
                                        residenceAddress: getError(errors, touched, isSubmitTry, 'residenceAddress'),
                                        appOrderDir: getError(errors, touched, isSubmitTry, 'appOrderDir'),
                                        decisionCreate: getError(errors, touched, isSubmitTry, 'decisionCreate'),
                                        pageCharter: getError(errors, touched, isSubmitTry, 'pageCharter'),
                                    };

                                    const onChangeFile = (name: string) => (file: any) => {
                                        setFieldValue(name, file || null, true);
                                    };

                                    const handleBlurInn = (e: any) => {
                                        handleBlur(e);
                                        if (e.target.value.length === 10) {
                                            fetchers.getCompanyInfoByInn({ inn: e.target.value }).then((response: any) => {
                                                if (response.type === 'error') {
                                                    message.warn(response.message);
                                                } else {
                                                    setCompany(response);
                                                    setIsModalVisible(true);
                                                }
                                            });
                                        }
                                    };

                                    const onSubmit = () => {
                                        setIsSubmitTry(true);
                                        handleSubmit();
                                    };

                                    const onCompanyOk = () => {
                                        setValues({
                                            ...values,
                                            kpp: company!.data.kpp,
                                            organization: company!.data.name.full_with_opf,
                                            address: company!.data.address.unrestricted_value,
                                        });
                                        setIsModalVisible(false);
                                        setCompany(null);
                                    };

                                    const onChangerUserType = (e: RadioChangeEvent) => {
                                        setValues(schema.cast({ typeOfOrgan: e.target.value }));
                                    };

                                    return (
                                        <React.Fragment>
                                            <Form
                                                onFinish={ handleSubmit }
                                                labelAlign="left"
                                                { ...itemLayout }
                                            >
                                                <Radio.Group
                                                    style={ { marginBottom: 16 } }
                                                    value={ values.typeOfOrgan }
                                                    onChange={ onChangerUserType }
                                                    disabled={ Boolean(initialValues.typeOfOrgan) }
                                                >
                                                    <Radio.Button value={ CompanyType.company }>Юридическое лицо</Radio.Button>
                                                    <Radio.Button value={ CompanyType.individual }>Физическое лицо</Radio.Button>
                                                </Radio.Group>
                                                {
                                                    values.typeOfOrgan === CompanyType.company ? (
                                                        <React.Fragment>
                                                            <Form.Item
                                                                label="ИНН"
                                                                extra={ errorList.inn }
                                                                validateStatus={ getErrorStatus(!!errorList.inn) }
                                                            >
                                                                <Input
                                                                    type="number"
                                                                    name="inn"
                                                                    placeholder="ИНН"
                                                                    onChange={ handleChange }
                                                                    onBlur={ handleBlurInn }
                                                                    value={ values.inn || '' }
                                                                />
                                                            </Form.Item>
                                                            { getFields(profile)(values, errorList, handleChange, handleBlur, onChangeFile, CompanyType.company) }
                                                        </React.Fragment>
                                                    ) : values.typeOfOrgan && getFields(profile)(values, errorList, handleChange, handleBlur, onChangeFile, CompanyType.individual)
                                                }
                                                {
                                                    values.typeOfOrgan && (
                                                        <div>
                                                            <Button
                                                                style={ { marginRight: 16 } }
                                                                onClick={ returnToProfile }
                                                            >
                                                                Отменить
                                                            </Button>
                                                            <Button
                                                                type="primary"
                                                                onClick={ onSubmit }
                                                                loading={ loading.save }
                                                            >
                                                                Сохранить данные компании
                                                            </Button>
                                                        </div>
                                                    )
                                                }
                                            </Form>
                                            <Modal
                                                okText="Да"
                                                cancelText="Нет"
                                                onCancel={ onCloseModal }
                                                onOk={ onCompanyOk }
                                                open={ isModalVisible }
                                            >
                                                Мы нашли компанию по ИНН. <br />
                                                <b>{ company?.value }</b> - это ваша компания?
                                            </Modal>
                                        </React.Fragment>
                                    );
                                } }
                            </Formik>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
});
