/**
 * Делаем редирект на официальный сайт
 */
export const OneClickRedirect = () => {
    document.addEventListener('click', () => {
        if (
            window.location.href.includes('cert-torg.ru') ||
            window.location.href.includes('localhost')
        ) {
            return;
        }
        window.location.href = 'https://cert-torg.ru';
    });
};
