import { put, call } from 'redux-saga/effects';
import {
    updatePermission,
    getPermissionError,
} from 'ducks/permission/actions';

import { fetchers } from 'api';
import { message } from 'antd';

export function* editPermission({ response }: ReturnType<typeof updatePermission>) {
    try {
        yield call(fetchers.savePermission, response);
    } catch (error) {
        message.error('Серверная ошибка обновление прав');
        yield put(getPermissionError());
    }
}
