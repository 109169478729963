import { put, call } from 'redux-saga/effects';
import { message } from 'antd';
import {
    sendReview,
    sendReviewError,
    sendReviewFinish,
} from 'ducks/reviews/actions';

import { fetchers } from 'api';

export function* sendReviewRequest({ payload, callback }: ReturnType<typeof sendReview>) {
    try {
        yield call(fetchers.sendReview, payload);

        message.success('Отзыв успешно оставлен');
        callback();
        yield put(sendReviewFinish());
    } catch (error) {
        yield put(sendReviewError());
    }
}
