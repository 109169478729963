/* eslint-disable no-console */
import { put, call } from 'redux-saga/effects';

import {
    toggleDialogNotify,
    toggleDialogNotifyFinish,
} from 'ducks/dialog/actions';

import { fetchers } from 'api';

export function* setDialogNotifyRequest({ payload, cb }: ReturnType<typeof toggleDialogNotify>) {
    try {
        const res = yield call(fetchers.setDialogNotify, payload);

        yield put(toggleDialogNotifyFinish(res.data));
        if (cb) {
            yield cb();
        }
    } catch (error) {
        console.log('Ошибка смены статуса уведомления');
    }
}
