import React from 'react';
import { useSelector } from 'react-redux';
import {
    Avatar, Empty, Menu, Spin,
} from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

import { selectProfile } from 'ducks/user/selectors';
import { getAvatar, getFio } from 'utils/formatters';
import { ROUTES } from 'constants/routes';
import { history } from 'App';
import { Message as MessageType } from 'types/dialog';

import { Answer } from './answer';

import styles from './styles.module.scss';

type Props = {
    data: MessageType[];
    handleEdit: (message: MessageType) => void;
    handleDelete: (number: number) => () => void;
    handleRead: (id: number) => void;
    nextMoreData: () => void;
    nextMoreLoading: boolean;
    pageSize?: number;
};

export const ListMessage = React.memo(({
    data, pageSize = 10, handleEdit, handleDelete, handleRead, nextMoreData, nextMoreLoading,
}: Props) => {
    const profile = useSelector(selectProfile);

    const goToAuthor = (id: number) => () => history.push(ROUTES.USER.getPath({ id }));

    const endMessageTxt = <div className={ styles.nomessage }>Сообщений больше нет</div>;
    const loadingMessage = <div className={ styles.loading }><Spin /></div>;

    if (data.length === 0) {
        return (<Empty className={ styles.empty } description="Сообщений нет" image={ Empty.PRESENTED_IMAGE_SIMPLE } />);
    }

    const list = data.map((item) => {
        const isEditAllow = moment().diff(item.created) < 300000;

        const actions = item.account.id === profile?.id && isEditAllow
            ? (
                <Menu key={ item.id }>
                    <Menu.Item key="edit" onClick={ () => handleEdit(item) }>Изменить</Menu.Item>
                    <Menu.Item key="delete" onClick={ handleDelete(item.id) }>Удалить</Menu.Item>
                </Menu>
            )
            : undefined;

        const author = getFio(item.account.lastName, item.account.firstName);

        if (item.account.id === profile?.id) {
            return (
                <div key={ item.id } id={ `message_${item.id}` }>
                    <Answer
                        author={ author }
                        avatar={ (
                            <div title={ getFio(item.account.lastName, item.account.firstName) } onClick={ goToAuthor(item.account.id) }>
                                <Avatar size={ 40 } src={ getAvatar(item.account.avatar) } />
                            </div>
                        ) }
                        read={ true }
                        content={ item.message }
                        datetime={ <span className={ styles.commentDate }>{ moment(item.created).calendar() }</span> }
                        actions={ actions }
                    />
                </div>
            );
        }
        if (item.account.id !== profile?.id) {
            return (
                <div key={ item.id } id={ `message_${item.id}` }>
                    <Answer
                        author={ author }
                        avatar={ (
                            <div title={ getFio(item.account.lastName, item.account.firstName) } onClick={ goToAuthor(item.account.id) }>
                                <Avatar size={ 40 } src={ getAvatar(item.account.avatar) } />
                            </div>
                        ) }
                        content={ item.message }
                        read={ item.read }
                        handleRead={ handleRead(item.id) }
                        datetime={ <span className={ styles.commentDate }>{ moment(item.created).calendar() }</span> }
                    />
                </div>
            );
        }

        return false;
    });

    return (
        <div className={ styles.wrap }>
            <div
                id="scrollableMessages"
                className={ styles.messageBodyList }
                style={ { height: '100%', overflowY: 'scroll' } }
            >
                <InfiniteScroll
                    dataLength={ data.length }
                    next={ nextMoreData }
                    style={ { display: 'flex', flexDirection: 'column-reverse' } }
                    inverse={ true }
                    hasMore={ nextMoreLoading }
                    endMessage={ data.length > pageSize && endMessageTxt }
                    loader={ loadingMessage }
                    scrollableTarget="scrollableMessages"
                >
                    { list }
                </InfiniteScroll>
            </div>
        </div>
    );
});
