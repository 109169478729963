import { CompanyType } from 'types/company';

export const fields = {
    [CompanyType.company]: [
        {
            key: 'organization',
            text: 'Наименование',
            type: 'text',
        },
        {
            key: 'kpp',
            text: 'КПП',
            type: 'number',
        },
        {
            key: 'address',
            text: 'Адрес',
            type: 'text',
        },
        {
            key: 'certificateNumber',
            text: 'Номер аттестата',
            type: 'text',
        },
        // {
        //     key: 'bicBank',
        //     text: 'БИК банка',
        //     type: 'text',
        // },
        // {
        //     key: 'scoreNumber',
        //     text: 'Номер счета',
        //     type: 'number',
        // },
        // {
        //     key: 'appOrderDir',
        //     text: 'Приказ о назначении директора',
        //     type: 'file',
        // },
        // {
        //     key: 'decisionCreate',
        //     text: 'Решение о создании',
        //     type: 'file',
        // },
        // {
        //     key: 'pageCharter',
        //     text: '1 стр устава',
        //     type: 'file',
        // },
    ],
    [CompanyType.individual]: [
        {
            key: 'fio',
            text: 'ФИО',
            type: 'text',
        },
        {
            key: 'passportNumber',
            text: 'Серия и номер паспорта',
            type: 'text',
        },
        {
            key: 'residenceAddress',
            text: 'Адрес прописки',
            type: 'text',
        },
        {
            key: 'inn',
            text: 'ИНН',
            type: 'text',
        },
        {
            key: 'ogrnip',
            text: 'ОРНИП',
            type: 'text',
        },
    ],
};
