import React, { useState } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form, Input, Button, message,
} from 'antd';
import { EventInit, eventYm } from 'components/ui/yandex-metrika';

import { signIn } from 'ducks/app/actions';
import { selectSignInLoading } from 'ducks/app/selectors';

import { schema } from 'form-helpers/auth/schema';
import { AuthFormType } from 'form-helpers/auth/types';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { ModalPasswordRecovery } from './passwordRecovery';

import styles from '../styles.module.scss';

const initialValues = schema.cast({});

type Props = {
    onNextStep: () => void;
};

export const AuthGuest = React.memo(({ onNextStep }: Props) => {
    const put = useDispatch();
    const loading = useSelector(selectSignInLoading);
    const [open, setOpen] = useState(false);

    const itemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const toggleOpen = () => {
        setOpen(!open);
    };
    const onSubmit = (values: AuthFormType) => {
        put(signIn(values, () => {
            message.success({
                content: 'Вы успешно авторизованы',
            });
            onNextStep();
        }));
        eventYm('reachGoal', 'sign');
    };

    return (
        <React.Fragment>
            <Formik<AuthFormType>
                initialValues={ initialValues }
                onSubmit={ onSubmit }
                validationSchema={ schema }
            >
                { ({
                    handleSubmit,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                }) => {
                    const errorList = {
                        email: getError(errors, touched, true, 'email'),
                        password: getError(errors, touched, true, 'password'),
                    };

                    return (
                        <Form
                            onFinish={ handleSubmit }
                            labelAlign="left"
                            { ...itemLayout }
                        >

                            <Form.Item
                                label="Ваш E-mail"
                                extra={ errorList.email }
                                validateStatus={ getErrorStatus(!!errorList.email) }
                            >
                                <Input
                                    name="email"
                                    placeholder="Ваш E-mail"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Пароль"
                                extra={ errorList.password }
                                validateStatus={ getErrorStatus(!!errorList.password) }
                            >
                                <Input
                                    name="password"
                                    placeholder="Пароль"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                    type="password"
                                />
                            </Form.Item>

                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={ loading }
                            >
                                Продолжить
                            </Button>
                        </Form>
                    );
                } }
            </Formik>
            <div className={ styles.bottomBlock }>
                <div
                    onClick={ toggleOpen }
                    className={ styles.recoveryPassword }
                >
                    Забыли пароль?
                </div>
            </div>
            { open && (
                <ModalPasswordRecovery
                    open={ open }
                    onOk={ toggleOpen }
                    onCancel={ toggleOpen }
                />
            ) }
            <EventInit />
        </React.Fragment>
    );
});
