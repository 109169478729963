import { put, call, select } from 'redux-saga/effects';

import {
    getDialogAll,
    getDialogAllError,
    getDialogAllFinish,
} from 'ducks/dialog/actions';

import {
    selectDialogAllPagination,
    selectDialogAllSort,
} from 'ducks/dialog/selectors';

import { fetchers } from 'api';

export function* getDialogAllRequest({ payload }: ReturnType<typeof getDialogAll>) {
    try {
        const { pageSize } = yield select(selectDialogAllPagination);

        const sort = yield select(selectDialogAllSort);

        const response = yield call(fetchers.getDialogAll, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        yield put(getDialogAllFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                next: response.pagination.next,
                page: payload.pagination.page,
            },
            sort: payload.sort || sort,
        }));
    } catch (error) {
        yield put(getDialogAllError());
    }
}
