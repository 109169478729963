/* eslint-disable no-nested-ternary */
import React from 'react';
import {
    Button, Space, Tooltip, Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchers } from 'api';
import { FilesList } from 'components/order-item/files';
import { selectCategoriesOptions, selectTempOrder } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';
import { clearProfile } from 'ducks/user/actions';
import { Role } from 'types/account';
import styles from './styles.module.scss';

const { Title } = Typography;

type Props = {
    onNextStep: (num: number) => any;
    onSave: () => any;
};

export const CheckOrderСreated = ({ onNextStep, onSave }: Props) => {
    const put = useDispatch();
    const profile = useSelector(selectProfile);
    const options = useSelector(selectCategoriesOptions);
    const order = useSelector(selectTempOrder);

    const getCategories = (cat: any) => {
        const categ = [];
        const level1 = cat[0]
            ? options.find((item: any) => +item.value === +cat[0])
            : [];

        categ.push(level1.title);

        if (!level1) {
            return 'Не выбрано';
        }

        const level2 = level1.children
            ? level1.children.find((item: any) => +item.value === +cat[1])
            : [];

        categ.push(level2.title);

        const level3 = level2.children
            ? level2.children.find((item: any) => +item.value === +cat[2])
            : [];

        if (level3) {
            categ.push(level3.title);
        }

        return categ.join(', ');
    };

    const onExit = async () => {
        try {
            const token = localStorage.getItem('token');

            await fetchers.exit({ token });

            localStorage.removeItem('token');
            put(clearProfile());
            onNextStep(1);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    };

    return (
        <React.Fragment>
            <Title level={ 3 } className={ styles.title }>Ваш заказ оформлен верно?</Title>
            <div className={ styles.order_body }>
                { order && (
                    <div className={ styles.order }>
                        <div className={ styles.order__label }>Название:</div>
                        <div className={ styles.order__title }>{ order.name }</div>
                        { order.orderCategories && (
                            <React.Fragment>
                                <div className={ styles.order__label }>Категории: </div>
                                <div className={ styles.order__categories }>{ getCategories(order.orderCategories) }</div>
                            </React.Fragment>
                        ) }
                        <div className={ styles.order__label }>Описание:</div>
                        <div className={ styles.order__desctiption }>{ order.message }</div>
                        { order.orderFiles && (<FilesList files={ order.orderFiles } />) }
                    </div>
                ) }

                <div className={ styles.order_btns }>
                    <Space size="middle">
                        <Button
                            type="dashed"
                            danger={ true }
                            onClick={ () => onNextStep(0) }
                            className={ styles.button__mt30 }
                        >
                            Изменить заказ
                        </Button>

                        { profile?.role === Role.Customer ? (
                            <Button
                                type="primary"
                                onClick={ onSave }
                                className={ styles.button__mt30 }
                            >
                                Верно, опубликовать
                            </Button>
                        ) : (
                            <Tooltip title="Заказы разрешено оздавать только группе заказчик">
                                <Button
                                    type="primary"
                                    onClick={ onExit }
                                    className={ styles.button__mt30 }
                                >
                                    Сменить аккаунт
                                </Button>
                            </Tooltip>
                        ) }
                    </Space>
                </div>
            </div>
        </React.Fragment>
    );
};
