import { put, call } from 'redux-saga/effects';

import {
    createBlog,
    createBlogError,
    createBlogFinish,
} from 'ducks/blog/actions';

import { fetchers } from 'api';

export function* createBlogRequest({ payload, cb }: ReturnType<typeof createBlog>) {
    try {
        const res = yield call(fetchers.createBlog, payload);

        yield put(createBlogFinish(res));
        yield cb();
    } catch (error) {
        yield put(createBlogError());
    }
}
