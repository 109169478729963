import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import {
    Form,
    Input,
    Button,
    Typography,
} from 'antd';

import { saveUser } from 'ducks/user/actions';

import { getError, getErrorStatus } from 'form-helpers/validation';
import { ProfileFormPassword } from 'form-helpers/profile/types';
import { schemaPassword } from 'form-helpers/profile/schema';

import styles from './styles.module.scss';

const { Title } = Typography;

type Props = {
    user: any;
    loading: any;
};

export const SecurityPassword = React.memo(({ user, loading }: Props) => {
    const put = useDispatch();

    const onSubmit = (values: ProfileFormPassword) => {
        put(saveUser({
            id: values.id,
            password: values.password,
            confirmPassword: values.confirmPassword,
        }, false));
    };

    return (
        <Formik<ProfileFormPassword>
            initialValues={ { id: user.id, password: '', confirmPassword: '' } }
            onSubmit={ onSubmit }
            validationSchema={ schemaPassword }
        >
            { ({
                handleSubmit,
                handleChange,
                handleBlur,
                errors,
                touched,
                values,
            }) => {
                const errorList = {
                    password: getError(errors, touched, true, 'password'),
                    confirmPassword: getError(errors, touched, true, 'confirmPassword'),
                };

                const isField = values.password.length > 0 && values.confirmPassword.length > 0;

                return (
                    <Form
                        onFinish={ handleSubmit }
                        layout="vertical"
                    >
                        <Title level={ 4 }>Сменить пароль</Title>
                        <Form.Item
                            label="Новый пароль"
                            extra={ errorList.password }
                            validateStatus={ getErrorStatus(!!errorList.password) }
                        >
                            <Input
                                name="password"
                                placeholder="Новый пароль"
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                value={ values.password }
                                type="password"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Повторите новый пароль"
                            extra={ errorList.confirmPassword }
                            validateStatus={ getErrorStatus(!!errorList.confirmPassword) }
                        >
                            <Input
                                name="confirmPassword"
                                placeholder="Повторите новый пароль"
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                value={ values.confirmPassword }
                                type="password"
                            />
                        </Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={ loading.save }
                            className={ (isField && errorList.password === errorList.confirmPassword) ? styles.visible : styles.hidden }
                        >
                            Изменить пароль
                        </Button>
                    </Form>
                );
            } }
        </Formik>
    );
});
