import { put, call } from 'redux-saga/effects';
import {
    editTender,
    editTenderError,
    editTenderFinish,
} from 'ducks/tenders/actions';
import { getPrice } from 'ducks/user/actions';

import { fetchers } from 'api';

export function* editTenderRequest(action: ReturnType<typeof editTender>) {
    try {
        const tender = yield call(fetchers.editTender, action.response);

        if (action.callback) {
            action.callback();
        }

        put(editTenderFinish(tender.data));
        yield put(getPrice());
    } catch (error) {
        put(editTenderError());
    }
}
