import * as Api from 'types/api';
import { Tender } from 'types/tender';
import {
    TendersActionTypes,
} from './action-types';

export const getTenders = (payload: Api.GetTendersPayload) => ({
    type: TendersActionTypes.GET_TENDERS_START,
    payload,
});

export const getTendersError = () => ({
    type: TendersActionTypes.GET_TENDERS_ERROR,
});

export const getTendersFinish = (response: Api.GetTendersResponse) => ({
    type: TendersActionTypes.GET_TENDERS_FINISH,
    response,
});

export const addTender = (response: Tender) => ({
    type: TendersActionTypes.SOCKET_ADD_NEW_TENDER,
    response,
});

export const editTender = (response: Api.EditTendersPayload, callback?: () => void) => ({
    type: TendersActionTypes.EDIT_TENDER,
    response,
    callback,
});

export const editTenderError = () => ({
    type: TendersActionTypes.EDIT_TENDER_ERROR,
});

export const editTenderFinish = (response: Api.EditTendersResponse) => ({
    type: TendersActionTypes.EDIT_TENDER_FINISH,
    response,
});

export const sendTender = (payload: Api.SendTenderPayload, callback: () => void) => ({
    type: TendersActionTypes.SEND_TENDER_START,
    payload,
    callback,
});

export const sendTenderError = () => ({
    type: TendersActionTypes.SEND_TENDER_ERROR,
});

export const sendTenderFinish = () => ({
    type: TendersActionTypes.SEND_TENDER_FINISH,
});

export const clearTenders = () => ({
    type: TendersActionTypes.CLEAR_TENDERS,
});

export const acceptTender = (payload: Api.AcceptTenderPayload, cb?: () => void) => ({
    type: TendersActionTypes.ACCEPT_TENDER_START,
    payload,
    cb,
});

export const acceptTenderError = () => ({
    type: TendersActionTypes.ACCEPT_TENDER_ERROR,
});

export const acceptTenderFinish = (info: Api.AcceptTenderResponse) => ({
    type: TendersActionTypes.ACCEPT_TENDER_FINISH,
    info,
});

export const getTenderOffer = (orderId: number) => ({
    type: TendersActionTypes.OFFER_TENDER_START,
    orderId,
});

export const getTendersOfferFinish = (tender: Tender) => ({
    type: TendersActionTypes.OFFER_TENDER_FINISH,
    tender,
});

export const seeTender = (payload: Api.EditTenderPayload) => ({
    type: TendersActionTypes.SEE_TENDER_START,
    payload,
});

export const seeTenderError = () => ({
    type: TendersActionTypes.SEE_TENDER_ERROR,
});

export const seeTenderFinish = (response: Api.EditTenderResponse) => ({
    type: TendersActionTypes.SEE_TENDER_FINISH,
    response,
});

export const revokeTender = (payload: Api.RevokeTenderPayload) => ({
    type: TendersActionTypes.REVOKE_TENDER,
    payload,
});

export const revokeTenderError = () => ({
    type: TendersActionTypes.REVOKE_TENDER_ERROR,
});

export const revokeTenderFinish = (response: Tender) => ({
    type: TendersActionTypes.REVOKE_TENDER_FINISH,
    response,
});
