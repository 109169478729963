import * as yup from 'yup';

import { REQUIRED_FIELD } from 'form-helpers/message';

export const schema = yup.object().shape({
    title: yup.string().required(REQUIRED_FIELD).default(''),
    desc: yup.string().required(REQUIRED_FIELD).default(''),
    fullDesc: yup.string().required(REQUIRED_FIELD).default(''),
    image: yup.mixed().test('isRequired', 'Загрузите изображение', (value) => {
        const isIssetAnotherFormat = value?.type ? !(value.type as string).includes('image') : false;

        return value ? !isIssetAnotherFormat : true;
    }).default(null),
});
