import React from 'react';
import { Formik } from 'formik';
import {
    Form,
    Input,
    Button,
    Typography,
    message,
} from 'antd';
import { useLocation } from 'react-router';
import qs from 'qs';
import { schema } from 'form-helpers/reset-password/schema';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { AuthLayout } from 'components/ui/auth-layout';

import { history } from 'App';
import { ROUTES } from 'constants/routes';
import { fetchers } from 'api';

import styles from './styles.module.scss';

const { Title } = Typography;

export const ResetPassword = React.memo(() => {
    const [loading, setLoading] = React.useState(false);
    const params = useLocation();

    const token = qs.parse(params.search.substr(1)).token as string;

    const onSubmit = (values: any) => {
        setLoading(true);
        fetchers.resetPassword({ ...values, token }).then(() => {
            message.success('Пароль успешно изменен. Теперь вы можете войти в систему');
            history.push(ROUTES.AUTH.path);
        }, () => {
            setLoading(false);
        });
    };

    return (
        <AuthLayout
            title="Восстановление пароля"
        >
            <div className={ styles.formContent }>
                <Formik
                    initialValues={ schema }
                    onSubmit={ onSubmit }
                    validationSchema={ schema }
                >
                    { ({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        errors,
                        touched,
                        values,
                    }) => {
                        const errorList = {
                            password: getError(errors, touched, true, 'password'),
                            confirmPassword: getError(errors, touched, true, 'confirmPassword'),
                        };

                        return (
                            <Form
                                onFinish={ handleSubmit }
                                labelAlign="left"
                                className={ styles.form }
                            >
                                <Title level={ 4 } style={ { marginBottom: 16 } }>Введите новый пароль</Title>
                                <Form.Item
                                    extra={ errorList.password }
                                    validateStatus={ getErrorStatus(!!errorList.password) }
                                >
                                    <Input
                                        name="password"
                                        placeholder="Новый пароль"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                        value={ values.password }
                                        type="password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    extra={ errorList.confirmPassword }
                                    validateStatus={ getErrorStatus(!!errorList.confirmPassword) }
                                >
                                    <Input
                                        name="confirmPassword"
                                        placeholder="Повторите новый пароль"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                        value={ values.confirmPassword }
                                        type="password"
                                    />
                                </Form.Item>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={ loading }
                                    block={ true }
                                >
                                    Сохранить пароль
                                </Button>
                            </Form>
                        );
                    } }
                </Formik>
            </div>
        </AuthLayout>
    );
});
