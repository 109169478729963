import { useEffect, useRef, useState } from 'react';
import { ACCOUNT } from 'constants/soccet';
import { io } from 'socket.io-client';

type TSendProps = {
    hash?: string;
    orderId?: number | string;
    message: string;
};

const useNotification = () => {
    const socketRef = useRef<any>();
    const [event, setEvent] = useState(null);
    // const router = useSelector((store: ApplicationState) => store.router);

    useEffect(() => {
        socketRef.current = io('/notification', { auth: { token: localStorage.getItem('token') } });

        // на этих страницах не отображатьуведомление
        // const viewOrder = matchPath(router.location.pathname, ROUTES.VIEW_ORDER.path);
        // const dialog = matchPath(router.location.pathname, ROUTES.DIALOG.path);
        // const dialogs = matchPath(router.location.pathname, ROUTES.DIALOGS.path);

        socketRef.current.on(ACCOUNT.READ, (data: any) => {
            setEvent(data);
        });

        return () => {
            socketRef.current.disconnect();
        };
    }, []);

    const sendNotification = ({ hash, orderId, message }: TSendProps) => {
        socketRef.current.emit(ACCOUNT.SEND, { hash, orderId, message });
    };

    const sendNewTender = (orderId: string | number) => {
        socketRef.current.emit(ACCOUNT.NEW_TENDER, { orderId });
    };

    const getNotification = () => event;

    return { getNotification, sendNewTender, sendNotification };
};

export default useNotification;
