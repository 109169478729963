import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { IconAlignRight, IconCircleX } from '@tabler/icons';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { MenuOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { selectProfile } from 'ducks/user/selectors';

import { MENU_ROUTES, ROUTES, ROUTES_KEYS } from 'constants/routes';
import { ACCESS } from 'constants/access';
import logo from 'assets/logo.svg';

import { history } from 'App';
import { Profile } from './profile';
import { Notification } from './notification';

import styles from './styles.module.scss';

const getDefaultRouteKey = () => {
    // eslint-disable-next-line no-restricted-globals
    const routeKey = ROUTES_KEYS.find((key) => ROUTES[key].path === location.pathname);

    return `/${routeKey?.toLowerCase().replace('_', '-')}` || '';
};

function getMenu(root: any, user: any) {
    const menu: ItemType[] = [];

    for (let i = 0; i < root.length; i++) {
        const el = root[i];

        if (el.showInMenu && !el.private) {
            if (el.children) {
                menu.push({
                    label: el.title,
                    key: el.path,
                    children: getMenu(el.children, user),
                });
            } else {
                menu.push({
                    label: el.title,
                    key: el.path,
                });
            }
        }

        if (el.showInMenu && el.private && user && (el.access ? user?.rights.includes(el.access as ACCESS) : true)) {
            if (el.children) {
                menu.push({
                    label: el.title,
                    key: el.path,
                    children: getMenu(el.children, user),
                });
            } else {
                menu.push({
                    label: el.title,
                    key: el.path,
                });
            }
        }
    }

    return menu;
}

export const Header = React.memo(() => {
    const [activeLink, setActiveLink] = useState(getDefaultRouteKey());
    const user = useSelector(selectProfile);
    const [open, setOpen] = useState(false);

    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    const handleClick = (e: any) => {
        setActiveLink(e.key);
        history.push(e.key);
    };

    const routes = useMemo(() => getMenu(MENU_ROUTES, user), [user]);

    const toggleMenu = () => setOpen(!open);

    const clWrapMenu = classNames({
        [styles.menu_wrap]: true,
        [styles.menu_wrap__open]: open,
    });

    if (user) {
        return (
            <div className={ styles.header }>
                <div className={ styles.content }>
                    <Link to="/">
                        <img className={ styles.logo } src={ logo } alt="logo" />
                    </Link>
                    <div className={ styles.nav }>
                        <div className={ clWrapMenu }>
                            <IconCircleX
                                className={ styles.menu_ico__close }
                                onClick={ toggleMenu }
                            />
                            <Menu
                                className={ styles.menu }
                                mode={ isMobile ? 'vertical' : 'horizontal' }
                                style={ { backgroundColor: 'transparent' } }
                                overflowedIndicator={ <MenuOutlined /> }
                                selectedKeys={ [activeLink] }
                                onClick={ (e) => handleClick(e) }
                                items={ routes }
                            />
                        </div>
                        <Notification />
                        <Profile />
                        { isMobile && (
                            <IconAlignRight
                                className={ styles.menu_ico }
                                onClick={ toggleMenu }
                            />
                        ) }
                    </div>
                </div>
            </div>
        );
    }

    const authMenu = [{
        key: 'REGISTRATION',
        label: ROUTES.REGISTRATION.title,
    },
    {
        key: 'AUTH',
        label: ROUTES.AUTH.title,
    }];

    routes.push(...authMenu);

    return (
        <div className={ styles.header }>
            <div className={ styles.content }>
                <Link to="/">
                    <img className={ styles.logo } src={ logo } alt="logo" />
                </Link>
                <div className={ styles.nav_gues }>
                    <Menu
                        className={ styles.menu }
                        mode="horizontal"
                        style={ { backgroundColor: 'transparent' } }
                        overflowedIndicator={ <IconAlignRight size={ 25 } className={ styles.menuIcon } /> }
                        selectedKeys={ [activeLink] }
                        onClick={ (e) => handleClick(e) }
                        items={ routes }
                    />
                </div>
            </div>
        </div>
    );
});
