import { put, call } from 'redux-saga/effects';
import {
    setSubscription,
    setSubscriptionError,
    setSubscriptionFinish,
} from 'ducks/subscription/actions';

import { fetchers } from 'api';

export function* setSubscriptionRequest(action: ReturnType<typeof setSubscription>) {
    try {
        const response = yield call(fetchers.setSubscription, action.payload);

        yield put(setSubscriptionFinish(response));
    } catch (error) {
        yield put(setSubscriptionError());
    }
}
