/* eslint-disable react/no-danger */
import React from 'react';
import styles from 'components/order-item/styles.module.scss';
import { Skeleton, Typography } from 'antd';

const { Title } = Typography;

export const OrderDetailSkeleton = () => (
    <div className={ styles.container }>
        <div className={ styles.name_content }>
            <Skeleton active={ true } paragraph={ { rows: 0 } } />
            <Title className={ styles.name } level={ 2 }>
                <Skeleton active={ true } paragraph={ { rows: 0 } } />
            </Title>
        </div>

        <div className={ styles.catogories }>
            <Skeleton active={ true } paragraph={ { rows: 0 } } />
        </div>

        <div className={ styles.body }>
            <Skeleton active={ true } paragraph={ true } />
        </div>

        <div className={ styles.navigate }>
            <Skeleton active={ true } paragraph={ { rows: 0 } } />
        </div>
    </div>
);
