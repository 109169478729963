import React, { useState } from 'react';
import {
    Form,
    Modal,
    Rate,
} from 'antd';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { changeStatus } from 'ducks/orders/actions';

import { selectOrder } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';
import { selectReviewsLoading } from 'ducks/reviews/selectors';

import { StatusInfo } from 'types/order';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { schema } from 'form-helpers/review/schema';
import { ReviewFormType } from 'form-helpers/review/types';

import styles from './styles.module.scss';

type Props = {
    visible: boolean;
    setVisible: (n: boolean) => void;
};

export const Review = React.memo(({ visible, setVisible }: Props) => {
    const put = useDispatch();
    const order = useSelector(selectOrder);
    const profile = useSelector(selectProfile);
    const loading = useSelector(selectReviewsLoading);

    const [isSubmitTry, setIsSubmitTry] = useState(false);

    const isExecutor = order?.orderTender?.account.id === profile?.id;

    if (!order) {
        return null;
    }

    const onSendReview = (values: ReviewFormType) => {
        put(changeStatus({
            ...values,
            status: StatusInfo.refund.key,
            orderId: order?.id,
            toAccountId: isExecutor ? order?.account.id : order?.orderTender.account.id,
        }, () => setVisible(false)));
    };

    return (
        <div className={ styles.container }>
            <Formik<ReviewFormType>
                initialValues={ schema }
                onSubmit={ onSendReview }
                validationSchema={ schema }
            >
                { ({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    touched,
                }) => {
                    const errorList = {
                        message: getError(errors, touched, isSubmitTry, 'message'),
                        rating: getError(errors, touched, isSubmitTry, 'rating'),
                    };

                    const onSubmit = () => {
                        setIsSubmitTry(true);
                        handleSubmit();
                    };

                    const onRateChange = (value: number) => {
                        setFieldValue('rating', value, true);
                    };

                    return (
                        <Modal
                            open={ visible }
                            title="Пометить заказ как невыполненный подрядчиком ?"
                            onCancel={ () => setVisible(false) }
                            okText="Отправить"
                            onOk={ onSubmit }
                            confirmLoading={ loading.send }
                        >
                            <Form
                                onFinish={ handleSubmit }
                                labelAlign="left"
                                className={ styles.form }
                                layout="vertical"
                            >
                                <Form.Item
                                    label="Рейтинг"
                                    extra={ errorList.rating }
                                    validateStatus={ getErrorStatus(!!errorList.rating) }
                                >
                                    <Rate
                                        onChange={ onRateChange }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Отзыв"
                                    extra={ errorList.message }
                                    validateStatus={ getErrorStatus(!!errorList.message) }
                                >
                                    <textarea
                                        name="message"
                                        placeholder="Описание"
                                        rows={ 4 }
                                        className="textarea"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                    />
                                </Form.Item>
                            </Form>
                            <p>Ваш отзыв позволит другим заказчикам принять верное решение о том с каким подрядчиком работать</p>
                        </Modal>
                    );
                } }
            </Formik>
        </div>
    );
});
