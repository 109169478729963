import { InferValueTypes } from 'types/common';
import { TPermissionRole, TRole } from 'types/permission';
import { PermissionActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type PermissionState = {
    loading: boolean;
    role: TRole[];
    permission: TPermissionRole[];
    access: any;
};

export const initialState: PermissionState = {
    loading: false,
    role: [],
    permission: [],
    access: [],
};

export function permissionReducer(state = initialState, action: ActionTypes): PermissionState {
    switch (action.type) {
        case PermissionActionTypes.GET_PERMISSION_START:
            return {
                ...state,
                loading: true,
            };

        case PermissionActionTypes.GET_PERMISSION_FINISH:
            return {
                ...state,
                loading: false,
                role: action.response.role,
                permission: action.response.permission,
                access: action.response.access,
            };

        case PermissionActionTypes.GET_PERMISSION_ERROR:
            return {
                ...state,
                loading: false,
            };

        default: {
            return state;
        }
    }
}
