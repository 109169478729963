import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Buttons, OrderItem } from 'components/bot';
import {
    selectOrdersGuest,
    selectOrdersGuestHide,
} from 'ducks/orders/selectors';
import { clearOrdersGuest, getOrdersGuest } from 'ducks/orders/actions';
import styles from './styles.module.scss';

export const Orders = () => {
    const put = useDispatch();
    const orders = useSelector(selectOrdersGuest);

    const hideBtn = useSelector(selectOrdersGuestHide);
    const [page, setPage] = useState(2);

    useEffect(() => {
        put(clearOrdersGuest());
        put(getOrdersGuest({ pagination: { page: 1 } }));
    }, []);

    const nexOrderGuest = () => {
        setPage((prev) => prev + 1);
        put(getOrdersGuest({ pagination: { page } }));
    };

    return (
        <React.Fragment>
            <div className={ styles.orders }>
                { orders.map((order) => (
                    <OrderItem
                        key={ `order-${order.id}` }
                        order={ order }
                    />
                )) }
            </div>
            <Buttons>
                { !hideBtn && (
                    <button
                        type="button"
                        onClick={ nexOrderGuest }
                    >
                        Подгрузить еще
                    </button>
                ) }
            </Buttons>
        </React.Fragment>
    );
};
