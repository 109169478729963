/* eslint-disable react/no-danger */
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    selectMappedCategories,
} from 'ducks/orders/selectors';

import { FullOrder } from 'types/order';

import { ChangeStatus } from 'components/change-status';
import { SendReview } from 'components/send-review';

import { history } from 'App';
import { FilesList } from './files';
import { Panel } from './panel';

import styles from './styles.module.scss';

type Props = FullOrder;

const { Title } = Typography;

export const OrderItem: React.FC<Props> = React.memo(({
    id,
    name,
    created,
    orderCategories,
    account,
    message,
    status,
    orderFiles,
}) => {
    const mappedCategories = useSelector(selectMappedCategories);
    const returnBack = () => history.goBack();

    return (
        <div className={ styles.container }>
            <Button
                className={ styles.returnBack }
                type="link"
                onClick={ returnBack }
                icon={ <ArrowLeftOutlined /> }
            >
                Вернуться назад
            </Button>
            <div className={ styles.name_content }>
                <Title className={ styles.name } level={ 2 }>
                    #{ id } | { name }
                </Title>
            </div>

            <div className={ styles.catogories }>
                <b>Категории</b>: { orderCategories.length ?  orderCategories.map((num, i, arr) => ((i + 1) < arr.length ? (`${mappedCategories[num]}, `) : mappedCategories[num])) : 'без категории' }
            </div>

            <div className={ styles.body } dangerouslySetInnerHTML={ { __html: message } } />

            <Panel
                created={ created }
                status={ status }
                account={ account }
            />
            <FilesList files={ orderFiles } />

            <div className={ styles.navigate }>
                <ChangeStatus />
                <SendReview />
            </div>
        </div>
    );
});
