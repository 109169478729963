import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tree, { DataNode } from 'antd/lib/tree';
import Typography from 'antd/lib/typography';

import { AppLoading } from 'components/ui/app-loading';

import {
    selectCategories,
    selectUserCategories,
    selectOrderLoading,
} from 'ducks/orders/selectors';
import { selectProfile, selectUserLoading } from 'ducks/user/selectors';
import { Skeleton } from 'antd';

import { subscribeOnCategory } from 'ducks/orders/actions';

import { Category } from 'types/order';

import styles from './styles.module.scss';

const formatCategories = (categories: Category[]): DataNode[] => categories.map((category) => ({
    key: category.id,
    title: category.name,
    children: formatCategories(category.child),
}));

const { Title } = Typography;

export const Subscription = React.memo(() => {
    const put = useDispatch();
    const [isSave, setSave] = useState(false);
    const user = useSelector(selectProfile);
    const loading = useSelector(selectUserLoading);
    const categories = useSelector(selectCategories);
    const userCategories = useSelector(selectUserCategories);
    const orderLoading = useSelector(selectOrderLoading);

    const handleCheckTree = (checkedKeys: any) => {
        setSave(true);
        put(subscribeOnCategory(checkedKeys));
        setTimeout(() => {
            setSave(false);
        }, 3000);
    };

    if (!user || loading.profile) {
        return <AppLoading />;
    }

    return (
        <React.Fragment>
            <div className={ styles.header }>
                <Title className={ styles.title } level={ 4 }>Подписка на категории</Title>
                { isSave && (<div className={ styles.title__info }>Изменение сохранены</div>) }
            </div>

            <div className={ styles.content }>
                {
                    orderLoading.getCategories
                        ? <Skeleton title={ false } paragraph={ { width: '100%', rows: 4 } } active={ true } />
                        : (
                            <Tree
                                checkable={ true }
                                treeData={ formatCategories(categories) }
                                defaultCheckedKeys={ userCategories }
                                defaultExpandedKeys={ userCategories }
                                defaultExpandAll={ true }
                                autoExpandParent={ true }
                                onCheck={ handleCheckTree }
                            />
                        )
                }
            </div>
        </React.Fragment>
    );
});
