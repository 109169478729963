import React from 'react';
import { LayoutStatic } from 'components/ui/layoutStatic';

export const Help = () => (
    <LayoutStatic>
        <h2 className="sp-title">Помощь</h2>
        <div className="sp-text">
            <p>Если у вас возникли вопросы, вы можете найти на них ответы в секции «Как работает сервис», где размещены обучающие видео по работе сервиса.</p>
            <p>Если ответа на свой вопрос вы по-прежнему не нашли, оставьте свой вопрос в секции «Форма обратной связи» или напишите нам на e-mail</p>
        </div>
    </LayoutStatic>
);
