import { put, call, select } from 'redux-saga/effects';

import {
    getCompanyForModeration,
    getCompanyForModerationError,
    getCompanyForModerationFinish,
} from 'ducks/moderation/actions';

import {
    selectCompaniesSort,
    selectCompaniesPagination,
} from 'ducks/moderation/selectors';

import { fetchers } from 'api';

export function* getCompaniesForModeration({ payload }: ReturnType<typeof getCompanyForModeration>) {
    try {
        const { pageSize } = yield select(selectCompaniesPagination);
        const sort = yield select(selectCompaniesSort);
        const response = yield call(fetchers.getCompaniesForModeration, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        yield put(getCompanyForModerationFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                page: payload.pagination.page,
            },
            sort: payload.sort || sort,
        }));
    } catch (error) {
        yield put(getCompanyForModerationError());
    }
}
