export const UserActionTypes = {
    GET_PRICE_START: 'GET_PRICE_START',
    GET_PRICE_FINISH: 'GET_PRICE_FINISH',
    GET_PRICE_ERROR: 'GET_PRICE_ERROR',

    GET_PROFILE_START: 'GET_PROFILE_START',
    GET_PROFILE_FINISH: 'GET_PROFILE_FINISH',
    GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',

    GET_USER_START: 'GET_USER_START',
    GET_USER_FINISH: 'GET_USER_FINISH',
    GET_USER_ERROR: 'GET_USER_ERROR',

    SAVE_USER_START: 'SAVE_USER_START',
    SAVE_USER_FINISH: 'SAVE_USER_FINISH',
    SAVE_USER_ERROR: 'SAVE_USER_ERROR',

    GET_USERS_START: 'GET_USERS_START',
    GET_USERS_FINISH: 'GET_USERS_FINISH',
    GET_USERS_ERROR: 'GET_USERS_ERROR',

    BLOCKED_USER_START: 'BLOCKED_USER_START',
    BLOCKED_USER_FINISH: 'BLOCKED_USER_FINISH',
    BLOCKED_USER_ERROR: 'BLOCKED_USER_ERROR',

    CLEAR_USERS: 'CLEAR_USERS',
    CLEAR_USER: 'CLEAR_USER',
    CLEAR_PROFILE: 'CLEAR_PROFILE',

    SEND_СONFIRMATION_START: 'SEND_СONFIRMATION_START',
    SEND_СONFIRMATION_FINISH: 'SEND_СONFIRMATION_FINISH',
    SEND_СONFIRMATION_ERROR: 'SEND_СONFIRMATION_ERROR',

    SAVE_USER_SOCIAL_NETWORK_START: 'SAVE_USER_SOCIAL_NETWORK_START',
    SAVE_USER_SOCIAL_NETWORK_FINISH: 'SAVE_USER_SOCIAL_NETWORK_FINISH',
    SAVE_USER_SOCIAL_NETWORK_ERROR: 'SAVE_USER_SOCIAL_NETWORK_ERROR',

    REMOVE_SOCIAL_NETWORK_START: 'REMOVE_SOCIAL_NETWORK_START',
    REMOVE_SOCIAL_NETWORK_FINISH: 'REMOVE_SOCIAL_NETWORK_FINISH',
    REMOVE_SOCIAL_NETWORK_ERROR: 'REMOVE_SOCIAL_NETWORK_ERROR',

} as const;
