import React, {
    memo,
    useEffect,
    useState,
} from 'react';
import { useRouteMatch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { Layout } from 'components/ui/layout';
import { OrderDetailSkeleton } from 'components/ui/skeleton';
import { OrderItem } from 'components/order-item/detail';
import { Tenders } from 'components/tenders';
import { ChatOrder } from 'components/order-chat';
import { OrderContact } from 'components/order-item/contact';

import { selectProfile } from 'ducks/user/selectors';
import { getTenders, clearTenders } from 'ducks/tenders/actions';
import { getOrder, clearOrders } from 'ducks/orders/actions';
import { selectOrder, selectOrderLoading } from 'ducks/orders/selectors';
import { StatusInfo } from 'types/order';

import styles from './styles.module.scss';

type MatchParams = {
    id: string;
};

export const ViewOrder = memo(() => {
    const put = useDispatch();
    const order = useSelector(selectOrder);
    const loading = useSelector(selectOrderLoading);
    const profile = useSelector(selectProfile);
    const [isVisibleChat, setVisibleChat] = useState(false);
    const [isHiddeTender, setHiddeTender] = useState(true);
    const { params: { id: orderId } } = useRouteMatch<MatchParams>();

    const isMe = Number(profile?.id) === Number(order?.account.id);
    const isExecutor = Number(profile?.id) === Number(order?.orderTender?.account.id);

    const getContact = (isMe && order?.orderTender) ? order.orderTender.account : order?.account;

    useEffect(() => {
        put(getOrder({ orderId }));

        return () => {
            put(clearOrders());
        };
    }, [orderId]);

    useEffect(() => {
        if (order?.status === StatusInfo.new.key) {
            setVisibleChat(true);
            setHiddeTender(false);
        }

        if (order?.status !== StatusInfo.new.key && (isMe || isExecutor)) {
            setVisibleChat(true);
        }

        if (order?.status === StatusInfo.draft.key) {
            // setHiddeTender(true);
        }

        if (!order?.orderTender && order?.status !== StatusInfo.draft.key) {
            put(getTenders({ pagination: { page: 1 }, orderId }));
        }

        return () => {
            put(clearTenders());
        };
    }, [order, isMe, isExecutor]);

    return (
        <Layout>
            <div className={ styles.container }>
                <div className={ styles.left }>
                    { !loading.get && order ? (
                        <OrderItem { ...order } />
                    ) : (
                        <OrderDetailSkeleton />
                    ) }
                    { !isHiddeTender && (
                        <Tenders orderId={ orderId } />
                    ) }
                </div>
                <div className={ styles.right }>
                    { isVisibleChat && (
                        <ChatOrder />
                    ) }
                </div>
                { isVisibleChat && (
                    <div className={ styles.full }>
                        { order?.orderTender && (
                            <OrderContact account={ getContact } />
                        ) }
                    </div>
                ) }
            </div>
        </Layout>
    );
});
