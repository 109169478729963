import React, { memo, useCallback } from 'react';
import {
    Pagination,
    Empty,
    Rate,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Avatar } from 'components/ui/avatar';
import { ROUTES } from 'constants/routes';
import { Review, ReviewPagination } from 'types/reviews';
import { Loading } from 'components/ui/loading';

import { getFio } from 'utils/formatters';
import { DATE_FORMAT } from 'constants/date';

import styles from './styles.module.scss';

type Props = {
    onChange: any;
    data: Review[];
    loading?: boolean;
    pagination?: ReviewPagination;
};

export const Reviews = memo(({
    data, loading, pagination, onChange,
}: Props) => {
    const goToLink = useCallback((id: any) => () => ROUTES.USER.getPath({ id }), []);

    if (loading) {
        return <Loading />;
    }

    if (data.length === 0) {
        return (<Empty description="Отзывов нет" image={ Empty.PRESENTED_IMAGE_SIMPLE } />);
    }

    return (
        <React.Fragment>
            <div className={ styles.grid }>
                { data.map((item) => (
                    <div key={ item.id } className={ styles.review__item }>
                        <div className={ styles.account }>
                            <div className={ styles.account__ava }>
                                <Avatar type='round' src={ item?.account?.avatar } />
                            </div>
                            <div className={ styles.account__info }>
                                <div className={ styles.account__top }>
                                    <Link
                                        to={ goToLink(item?.account?.id) }
                                        className={ styles.account__name }
                                    >
                                        { getFio(item?.account?.lastName, item?.account?.firstName) }
                                    </Link>
                                    <div className={ styles.account__time }>
                                        { moment(item.created).format(DATE_FORMAT.DATE_TIME) }
                                    </div>
                                </div>
                                <Rate
                                    disabled={ true }
                                    count={ 5 }
                                    defaultValue={ item.rating }
                                    className={ styles.account__rating }
                                />
                            </div>
                        </div>
                        { item.message && (
                            <div className={ styles.review__body }>
                                { item.message }
                            </div>
                        ) }
                    </div>
                )) }
            </div>
            { pagination && (
                Boolean(pagination.total) && (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total }
                            pageSize={ pagination.pageSize }
                            current={ pagination.page }
                            onChange={ onChange }
                        />
                    </div>
                )
            ) }
        </React.Fragment>
    );
});
