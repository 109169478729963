import { put, call } from 'redux-saga/effects';
import {
    editOrder,
    editOrderError,
    editOrderFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

import { history } from 'App';

import { ROUTES } from 'constants/routes';

export function* editOrderRequest({ data }: ReturnType<typeof editOrder>) {
    try {
        const response = yield call(fetchers.editOrder, data);

        history.push(ROUTES.VIEW_ORDER.getPath({ id: response.data.id }));
        yield put(editOrderFinish());
    } catch (error) {
        yield put(editOrderError());
    }
}
