import React from 'react';
import { Dropdown } from 'antd';

import { useSelector } from 'react-redux';
import { selectProfile } from 'ducks/user/selectors';

import { ROUTES } from 'constants/routes';
import { ACCESS } from 'constants/access';
import { logout } from 'utils/app';

import { history } from 'App';

import { Role } from 'types/account';
import { Avatar } from 'components/ui/avatar';
import styles from './styles.module.scss';

export const Profile = React.memo(() => {
    const user = useSelector(selectProfile);

    const handleClick = (e: any) => {
        history.push(ROUTES[e.key].path);
    };

    const isCustomer = user?.role === Role.Customer;
    const isVisible = (key: string) => user?.rights.includes(ROUTES[key].access as ACCESS);

    const items = [
        {
            label: ROUTES.PROFILE.title,
            key: 'PROFILE',
            onClick: handleClick,
        },
    ];

    if (isVisible('BALANS')) {
        items.push({
            label: ROUTES.BALANS.title,
            key: 'BALANS',
            onClick: handleClick,
        });
    }
    if (isVisible('MY_COMPANY')) {
        items.push({
            label: ROUTES.MY_COMPANY.title,
            key: 'MY_COMPANY',
            onClick: handleClick,
        });
    }
    if (isVisible('MY_SUBSCRIPTION')) {
        items.push({
            label: ROUTES.MY_SUBSCRIPTION.title,
            key: 'MY_SUBSCRIPTION',
            onClick: handleClick,
        });
    }
    if (isCustomer) {
        items.push({
            label: ROUTES.CREATE_ORDER.title,
            key: 'CREATE_ORDER',
            onClick: handleClick,
        });
    }

    items.push({
        label: 'Выйти',
        key: 'LOGOUT',
        onClick: logout,
    });

    if (!user) {
        return null;
    }

    return (
        <Dropdown menu={ { items } } trigger={ ['click', 'hover'] } placement="top" arrow={ { pointAtCenter: true } }>
            <div className={ styles.profile }>
                <Avatar
                    src={ user?.avatar }
                    className={ styles.profileAva }
                    type='round'
                    alt="" />
                <div className={ styles.profileName }>
                    { user?.lastName } { user?.firstName }
                </div>
            </div>
        </Dropdown>
    );
});
