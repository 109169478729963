import * as yup from 'yup';

import { REQUIRED_FIELD } from 'form-helpers/message';

export const schema = yup.object().shape({
    username: yup.string().default(''),
    phone: yup.string().default(''),
    city: yup.string().default('Не указан'),
    email: yup.string().required(REQUIRED_FIELD).default(''),
    types: yup.string().default(''),
    message: yup.string().required(REQUIRED_FIELD).default(''),
});
