/* eslint-disable no-nested-ternary */
import React, {
    useState, memo, useEffect, useCallback,
} from 'react';
import classNames from 'classnames';
import { message, Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'components/ui/layout';
import { useErrorProfile } from 'components/ui/hooks/useError';
import { selectProfile } from 'ducks/user/selectors';
import { clearTempOrderGuest, createOrder, getTempOrderGuest } from 'ducks/orders/actions';
import { selectTempOrder } from 'ducks/orders/selectors';
import { Role } from 'types/account';
import { StatusInfo } from 'types/order';
import { CreateOrderGuest, SuccessfullyСreated, CheckOrderСreated } from './order';
import { LoginAndRegisterGuest } from './auth';
import styles from './styles.module.scss';

export const CreateTempOrder = memo(() => {
    const put = useDispatch();
    const profile = useSelector(selectProfile);
    const order = useSelector(selectTempOrder);
    const [step, setStep] = useState<number>(0);
    const { isAuthConfirmed } = useErrorProfile();
    const orderId = localStorage.getItem('tempOrderId') || null;

    const items = [
        {
            title: 'Оформление заказа',
        },
        {
            title: 'Куда отправить информацию',
        },
        {
            title: 'Проверка заказа',
        },
        {
            title: 'Заказ оформлен',
        },
    ];

    useEffect(() => {
        if (orderId && orderId !== 'undefined') {
            put(getTempOrderGuest(orderId));
        }

        return () => {
            put(clearTempOrderGuest());
        };
    }, [orderId]);

    // eslint-disable-next-line no-negated-condition
    const onNextStep = (num: number) => setStep(num);

    const saveOrderToServer = useCallback(() => {
        if (!order) {
            message.error('Данные заказа не найдены...');

            Error('Ошибка отправки заказа на сервер');
        }

        if (profile && profile.role === Role.Customer && order) {
            if (isAuthConfirmed) {
                order.status = StatusInfo.draft.key;
            }

            put(createOrder({ ...order, tempOrderId: order.id }, false));
            onNextStep(3);
            localStorage.removeItem('tempOrderId');
        } else {
            message.error('Ваша учетная запись не является группой "заказчик", сменить учетную запись');
        }
    }, [order, profile]);

    const stylesStep = classNames({
        [styles.step]: true,
        [styles.visible]: step > 0,
    });

    return (
        <Layout>
            <div className={ stylesStep }>
                <Steps
                    current={ step }
                    items={ items }
                />
            </div>
            <div className={ styles.layout }>
                <div className={ styles.container }>
                    { step === 0 ? (
                        <CreateOrderGuest onNextStep={ () => onNextStep(1) } />
                    ) : step === 1 ? (
                        <LoginAndRegisterGuest onNextStep={ () => onNextStep(2) } />
                    ) : step === 2 ? (
                        <CheckOrderСreated onNextStep={ onNextStep } onSave={ saveOrderToServer } />
                    ) : (
                        <SuccessfullyСreated />
                    ) }
                </div>
            </div>
        </Layout>
    );
});
