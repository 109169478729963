/* eslint-disable no-negated-condition */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import {
    Form,
    Input,
    Button,
    Typography,
} from 'antd';

import { saveUser, sendСonfirmation } from 'ducks/user/actions';

import { getError, getErrorStatus } from 'form-helpers/validation';
import { ProfileFormEmail } from 'form-helpers/profile/types';
import { schemaEmail } from 'form-helpers/profile/schema';
import { getInitialValuesEmail } from 'form-helpers/profile/mapping';

import styles from './styles.module.scss';

const { Title } = Typography;

const initialValues = schemaEmail.cast({});

type Props = {
    user: any;
    loading: any;
};

export const SecurityEmail = React.memo(({ user, loading }: Props) => {
    const put = useDispatch();

    const [emailVerification, setEmailVerification] = useState(user.isVerified);

    const onSubmit = (values: ProfileFormEmail) => {
        put(saveUser(values, false));
    };

    const onSendVerification = () => {
        put(sendСonfirmation({
            id: user.id,
            email: user.email,
        }));
        setEmailVerification(true);
    };

    // eslint-disable-next-line no-nested-ternary
    const isMessage = user.isVerified === false && emailVerification === true ? (<div className={ styles.confirm_success }> Сообщение отправлено</div>)
        : user.isVerified === false && emailVerification === false ? (<div className={ styles.confirm }>Подтвердите e-mail пройдя по сылке в вашем сообщении. <span onClick={ onSendVerification }>Отправить повторно</span></div>) : null;

    return (
        <Formik<ProfileFormEmail>
            initialValues={ getInitialValuesEmail(initialValues, user) }
            onSubmit={ onSubmit }
            validationSchema={ schemaEmail }
        >
            { ({
                handleSubmit,
                handleChange,
                handleBlur,
                errors,
                touched,
                values,
            }) => {
                const errorList = {
                    email: getError(errors, touched, true, 'email'),
                };

                const isSubmit = values.email !== user.email;

                return (
                    <Form
                        onFinish={ handleSubmit }
                        layout="vertical"
                    >
                        <Title level={ 4 }>Email</Title>
                        <p className={ styles.info }>Почта для авторизации и восстановления доступа. <br /> На этот же адрес приходят уведомления.</p>
                        <Form.Item
                            extra={ errorList.email }
                            validateStatus={ getErrorStatus(!!errorList.email) }
                        >
                            <Input
                                name="email"
                                placeholder="Email"
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                                value={ values.email }
                            />
                        </Form.Item>

                        { !isSubmit && (isMessage) }

                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={ loading.save }
                            className={ isSubmit ? styles.visible : styles.hidden }
                        >
                            Изменить email
                        </Button>
                    </Form>
                );
            } }
        </Formik>
    );
});
