import { Company } from 'types/company';

export const getNameCompany = (item: Company) => {
    const name: any[] = [item.id];

    if (item.organization) {
        name.push(item.organization);
    }
    if (item.fio) {
        name.push(item.fio);
    }

    return name.join(' ');
};
