import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    DatePicker,
    Typography,
    TreeSelect,
    Checkbox,
} from 'antd';
import classNames from 'classnames';
import { IconArrowBarToUp, IconRotateClockwise } from '@tabler/icons';

import { Role } from 'types/account';
import { getOrders } from 'ducks/orders/actions';
import { selectCategoriesOptions } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';
import { ROUTES } from 'constants/routes';
import useSearchParam from 'components/ui/hooks/useSearchParam';

import styles from './styles.module.scss';

const { RangePicker } = DatePicker;
const { Title } = Typography;

export const OrdersFilter = () => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const options = useSelector(selectCategoriesOptions);
    const {
        params, setParams, getParams, clearParams, getSearch,
    } = useSearchParam();
    const [hidde, setHidde] = useState(false);

    const isExecutor = user?.role === Role.Executor;

    const onChecked = (check: boolean) => {
        if (check) {
            setParams('addTender')(check);
            setParams('executorId')(user?.id);
        } else {
            setParams('addTender')(null);
            setParams('executorId')(null);
        }
    };

    const handleResetFilter = useCallback(() => {
        clearParams();
        put(getOrders({ pagination: { page: 1 }, ...params }));
    }, []);

    const applyFilter = useCallback(() => {
        put(getOrders({ pagination: { page: 1 }, ...params }));

        getSearch(ROUTES.ORDERS.path);
    }, [params]);

    const toggle = () => setHidde(!hidde);

    const wrapClass = classNames({
        [styles.filter__body]: true,
        [styles.filter__hidde]: hidde,
    });

    return (
        <div className={ styles.filter }>
            <Title
                level={ 4 }
                className={ styles.filter__name }
            >
                Параметры поиска
                <IconArrowBarToUp
                    className={ classNames({ [styles.filter__ico]: true, [styles.filter__ico__active]: hidde }) }
                    onClick={ toggle }
                />
            </Title>
            <div className={ wrapClass }>
                { isExecutor && (
                    <div className={ styles.filterItem }>
                        <Checkbox
                            defaultChecked={ getParams('addTender') }
                            onChange={ (e) => onChecked(e.target.checked) }
                        >
                            Отображать заказы где оставил предложение
                        </Checkbox>
                    </div>
                ) }
                <div className={ styles.filterItem }>
                    <div className={ styles.filterName }>Категории</div>
                    <TreeSelect
                        placeholder="Категория"
                        defaultValue={ getParams('orderCategories') }
                        allowClear={ true }
                        multiple={ true }
                        onChange={ setParams('orderCategories') }
                        treeData={ options }
                        treeCheckable={ false }
                        showCheckedStrategy={ TreeSelect.SHOW_ALL }
                        className={ styles.input }
                    />
                </div>
                <div className={ styles.filterItem }>
                    <div className={ styles.filterName }>Дата</div>
                    <RangePicker
                        defaultValue={ getParams('created', 'date') }
                        placeholder={ ['Дата от', 'Дата до'] }
                        format="YYYY-MM-DD"
                        allowClear={ true }
                        onChange={ setParams('created') }
                        className={ styles.input }
                    />
                </div>
                <div className={ styles.filterItem }>
                    <button
                        type="submit"
                        onClick={ applyFilter }
                        className={ styles.button }
                    >
                        ПРИМЕНИТЬ
                    </button>
                </div>
                <div className={ styles.filterItem }>
                    <button
                        type="submit"
                        onClick={ handleResetFilter }
                        className={ styles.button_link }
                    >
                        <IconRotateClockwise size={ 15 } /> ОЧИСТИТЬ ФОРМУ
                    </button>
                </div>
            </div>
        </div>
    );
};
