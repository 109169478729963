export const AppActionTypes = {
    SIGN_UP_START: 'SIGN_UP_START',
    SIGN_UP_FINISH: 'SIGN_UP_FINISH',
    SIGN_UP_ERROR: 'SIGN_UP_ERROR',
    SIGN_IN_START: 'SIGN_IN_START',
    SIGN_IN_FINISH: 'SIGN_IN_FINISH',
    SIGN_IN_ERROR: 'SIGN_IN_ERROR',
    REGISTER_AUTO_SIGN_IN_START: 'REGISTER_AUTO_SIGN_IN_START',
    REGISTER_AUTO_SIGN_IN_FINISH: 'REGISTER_AUTO_SIGN_IN_FINISH',
    REGISTER_AUTO_SIGN_IN_ERROR: 'REGISTER_AUTO_SIGN_IN_ERROR',
    PASSWORD_RECOVERY_START: 'PASSWORD_RECOVERY_START',
    PASSWORD_RECOVERY_FINISH: 'PASSWORD_RECOVERY_FINISH',
    PASSWORD_RECOVERY_ERROR: 'PASSWORD_RECOVERY_ERROR',
} as const;
