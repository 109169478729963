import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectMappedCategories } from 'ducks/orders/selectors';
import { StatusInfo } from 'types/order';
import { getFio } from 'utils/formatters';
import styles from './styles.module.scss';
import { FilesList } from './files';

type Props = {
    order: any;
};

export const OrderDetail = ({ order }: Props) => {
    const mappedCategories = useSelector(selectMappedCategories);

    const categories = order.orderCategories.map((val: number) => mappedCategories[val]).join(', ');

    return (
        <div className={ styles.order }>
            <div className={ styles.order_title }>#{ order.id } | { order.name }</div>
            <div className={ styles.order_categories }>
                <b>Категории</b>: <span>{ categories }</span>
            </div>
            <div className={ styles.order_desc } dangerouslySetInnerHTML={ { __html: order.message } } />
            <div className={ styles.order_footer }>
                <FilesList files={ order.orderFiles } />
            </div>
            <div className={ styles.info }>
                <div className={ styles.info__title }>Инфорация о заказе</div>
                <div className={ styles.info__item }>
                    <div className={ styles.info__text }>Создано :</div>
                    <div className={ styles.info__value }> { moment(order.created).fromNow() }</div>
                </div>
                <div className={ styles.info__item }>
                    <div className={ styles.info__text }>Заказчик :</div>
                    <div className={ styles.info__value }>{ getFio(order.account.lastName, order.account.firstName) }</div>
                </div>
                <div className={ styles.info__item }>
                    <div className={ styles.info__text }>Статус :</div>
                    <div className={ styles.info__value }>
                        { order.status && (<span className={ styles.status } style={ { backgroundColor: StatusInfo[order.status].color } }>{ StatusInfo[order.status].text }</span>) }
                    </div>
                </div>
            </div>
        </div>
    );
};
