import React from 'react';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import { createBrowserHistory } from 'history';
import ru_RU from 'antd/lib/locale/ru_RU';
import 'moment/locale/ru';

import rootReducer from 'ducks';
import rootSaga from 'sagas';
import { socket, SocketContext } from 'context/socket';

import { Routing } from './routes';

import setupInterceptors from './utils/axios';

moment.locale('ru');

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer(history),
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);
setupInterceptors();

export const App = () => (
    <Provider store={ store }>
        <ConnectedRouter history={ history }>
            <ConfigProvider locale={ ru_RU }>
                <SocketContext.Provider value={ socket }>
                    <Routing />
                </SocketContext.Provider>
            </ConfigProvider>
        </ConnectedRouter>
    </Provider>
);
