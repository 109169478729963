import React from 'react';
import {
    Button,
    Form, InputNumber, Typography,
} from 'antd';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'ducks/user/selectors';
import { refundScore } from 'ducks/score/actions';
import { selectScoresLoading } from 'ducks/score/selectors';
import { RefundStatus, TypePayment } from 'types/score';

import styles from './styles.module.scss';

const { Title } = Typography;

export const ScoreRefund = () => {
    const [form] = Form.useForm();
    const put = useDispatch();
    const profil = useSelector(selectProfile);
    const loading = useSelector(selectScoresLoading);

    const onSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                const data = cloneDeep(values);

                data.typePayment = TypePayment.refund;
                data.refundStatus = RefundStatus.waiting;
                put(refundScore(data));
            });
    };

    return (
        <div className={ styles.content }>
            <Title className={ styles.title } level={ 4 }>Возврат денежных средств</Title>
            <p>Для возврата денежных средств Вам нужно указать ниже в форме сумму которая не превышает суммы вашего личного счета. Возврат денежных средств осуществляется в течение 3-7 рабочих дней</p>
            <Form
                form={ form }
                labelAlign="left"
                layout="vertical"
                onFinish={ onSubmit }
                initialValues={ { price: null } }
            >
                <Form.Item
                    label="Укажите сумму возврата (руб)"
                    name="price"
                    rules={ [{ required: true, message: 'Обязательно укажите сумму' }] }
                >
                    <InputNumber
                        min="50"
                        max={ String(profil?.price) }
                        addonAfter="Руб."
                    />
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={ loading.refund }
                    disabled={ loading.refund }
                >
                    Отправить запрос
                </Button>
            </Form>
        </div>
    );
};
