import React from 'react';
import { Layout } from 'components/ui/layout';
import { LayoutLeftMenu } from 'components/ui/layoutLeftMenu';
import { ROUTES } from './routes';

export const EditProfile = React.memo(() => (
    <Layout>
        <LayoutLeftMenu
            title="Общая информация"
            menu={ ROUTES }
        />
    </Layout>
));
