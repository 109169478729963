import React, { useState } from 'react';
import {
    Button, Popconfirm, Space, Tooltip,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { changeStatus } from 'ducks/orders/actions';
import { ROUTES } from 'constants/routes';
import { history } from 'App';
import { selectOrder, selectOrderLoading } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';
import { Status, StatusInfo, Statusfulfilleds } from 'types/order';
import { Review } from './reviews';

import styles from './styles.module.scss';

export const ChangeStatus = React.memo(() => {
    const put = useDispatch();
    const order = useSelector(selectOrder);
    const orderLoading = useSelector(selectOrderLoading);
    const profile = useSelector(selectProfile);

    const [isVisibleModal, setVisibleModal] = useState(false);

    const isButtonOrderConfirmedByExecutor = order?.orderTender?.account.id === profile?.id;
    const isButtonOrderConfirmedByCustomer = order?.account.id === profile?.id;

    const components = [];

    const handleChangeStatus = (status: Status) => () => {
        put(changeStatus({ status, orderId: order!.id }));
    };

    const goToEditOrder = (e: any, id?: number) => {
        if (id === undefined) {
            e.stopPropagation();
        } else {
            e.stopPropagation();
            history.push(ROUTES.EDIT_ORDER.getPath({ id }));
        }
    };

    if (order?.account.id === profile?.id) {
        components.push(
            <Button
                key="Редактировать"
                type="dashed"
                onClick={ (e) => goToEditOrder(e, order?.id) }
                className={ styles.navigate_edit }
            >
                Редактировать
            </Button>,
        );
    }

    if (isButtonOrderConfirmedByCustomer && Statusfulfilleds.includes(String(order?.status))) {
        components.push(
            <div key="Заказ подрядчиком не выполнен">
                <Review visible={ isVisibleModal } setVisible={ setVisibleModal } />
                <Button
                    type="primary"
                    loading={ orderLoading.changeStatus }
                    className={ styles.btn_danger }
                    onClick={ () => setVisibleModal(true) }
                >
                    Заказ подрядчиком не выполнен
                </Button>
            </div>,
        );
    }

    if (isButtonOrderConfirmedByExecutor && order?.status === StatusInfo.inOperation.key) {
        components.push(
            <Tooltip key="Завершить" placement="bottom" title="Нажмите на кнопку после того как вы готовы сообщить заказчику что вы выполнили задачу">
                <Popconfirm
                    title="Пометить заказ как завершенный ?"
                    onConfirm={ handleChangeStatus('confirmedByExecutor') }
                    okText="Да"
                    cancelText="Нет"
                >
                    <Button
                        type="primary"
                        loading={ orderLoading.changeStatus }
                        className={ styles.btn }
                    >
                        Завершить
                    </Button>
                </Popconfirm>
            </Tooltip>,
        );
    }

    if (isButtonOrderConfirmedByCustomer && order?.status === StatusInfo.confirmedByExecutor.key) {
        components.push(
            <Tooltip key="Подтвердить" placement="bottom" title="Пометить заказ как завершенный">
                <Popconfirm
                    title="Пометить заказ как завершенный ?"
                    onConfirm={ handleChangeStatus('done') }
                    okText="Да"
                    cancelText="Нет"
                >
                    <Button
                        type="primary"
                        loading={ orderLoading.changeStatus }
                        className={ styles.btn }
                    >
                        Подтвердить выполнение заказа подрядчиком
                    </Button>
                </Popconfirm>
            </Tooltip>,
        );
    }

    const Comp = components.map((item) => item);

    return <Space size="small">{ Comp }</Space>;
});
