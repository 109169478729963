import { combineReducers } from 'redux';
import { loadingReducer } from './loading';
import { categoriesReducer } from './categories';
import { ordersReducer } from './orders';
import { ordersGuestReducer } from './ordersGuest';
import { ordersBiddingReducer } from './ordersBidding';

export const orderReducer = combineReducers({
    loading: loadingReducer,
    categories: categoriesReducer,
    orders: ordersReducer,
    ordersGuest: ordersGuestReducer,
    ordersBidding: ordersBiddingReducer,
});

export type OrderState = ReturnType<typeof orderReducer>;
