import { put, call } from 'redux-saga/effects';

import {
    getScores,
    createScore,
    createScoreError,
    createScoreFinish,
} from 'ducks/score/actions';

import { fetchers } from 'api';
import { message } from 'antd';

export function* createScoreRequest({ payload, cb }: ReturnType<typeof createScore>) {
    try {
        yield call(fetchers.createScore, payload);
        yield put(createScoreFinish());
        message.success('Запись успешно добавлена');
        if (cb) {
            cb();
        }
        yield put(getScores({ pagination: { page: 1 } }));
    } catch (error) {
        yield put(createScoreError());
    }
}
