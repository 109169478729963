export type Route = {
    [key: string]: {
        path: string;
        exact: boolean;
        title: string;
        private?: boolean;
        showInMenu?: boolean;
        getPath?: any;
    };
};

export const ROUTES: Route = {
    MAIN: {
        path: '/bot',
        exact: true,
        title: 'Главная',
    },
    ORDER: {
        path: '/bot/order/:id',
        private: true,
        exact: true,
        title: 'Заказ',
        getPath: ({ id }: any) => ROUTES.ORDER.path.replace(':id', id),

    },
    TENDER: {
        path: '/bot/order/:id/tender',
        private: true,
        exact: true,
        title: 'Список предложений по заказу',
        getPath: ({ id }: any) => ROUTES.TENDER.path.replace(':id', id),
    },
    ADD_TENDER: {
        path: '/bot/order/:id/create-tender',
        private: true,
        exact: true,
        title: 'Создать предложение для заказа',
        getPath: ({ id }: any) => ROUTES.ADD_TENDER.path.replace(':id', id),
    },
    AUTH: {
        path: '/bot/auth',
        exact: true,
        title: 'Войти',
    },
    // DIALOG: {
    //     path: '/dialog/:hash',
    //     private: true,
    //     exact: true,
    //     title: 'Диалог',
    //     getPath: ({ hash }: any) => ROUTES.DIALOG.path.replace(':hash', hash),
    // },
    // ORDERS: {
    //     path: '/orders',
    //     exact: true,
    //     title: 'Заказы',
    //     showInMenu: true,
    // },
    // MY_ORDERS: {
    //     path: '/my-orders',
    //     exact: true,
    //     private: true,
    //     title: 'Мои заказы',
    //     showInMenu: true,
    // },
    // REGISTRATION: {
    //     path: '/registration',
    //     exact: true,
    //     title: 'Зарегистрироваться',
    // },
    // PASSWORD_RECOVERY: {
    //     path: '/recovery',
    //     exact: true,
    //     title: 'Забыли пароль',
    // },
    // VERIFY_ACCOUNT: {
    //     path: '/verify-account',
    //     exact: true,
    //     private: false,
    //     title: 'Подтверждение аккаунта',
    //     showInMenu: false,
    // },
    // RESET_PASSWORD: {
    //     path: '/account-reset-password',
    //     exact: true,
    //     private: false,
    //     title: 'Сброс пароля',
    //     showInMenu: false,
    // },
    // MODERATION: {
    //     path: '/moderation',
    //     exact: false,
    //     private: true,
    //     title: 'Модерация',
    //     showInMenu: true,
    //     access: ACCESS.EXPERT_VIEW,
    // },
    // USERS: {
    //     path: '/users',
    //     exact: true,
    //     private: true,
    //     title: 'Пользователи',
    //     showInMenu: true,
    //     access: ACCESS.USER_VIEWS,
    // },
    // USER: {
    //     path: '/users/:id',
    //     exact: true,
    //     private: true,
    //     title: 'Пользователи',
    //     getPath: ({ id }: any) => ROUTES.USER.path.replace(':id', id),
    // },
    // PROFILE: {
    //     path: '/profile',
    //     exact: true,
    //     private: true,
    //     title: 'Профиль',
    // },
    // EDIT_PROFILE: {
    //     path: '/edit-profile',
    //     exact: false,
    //     private: true,
    //     title: 'Редактирование профиля',
    // },
    // MY_COMPANY: {
    //     path: '/edit-profile/company',
    //     title: 'Моя компания',
    //     exact: true,
    //     private: true,
    //     access: ACCESS.COMPANY_VIEWS_EDIT_MY,
    // },
    // BALANS: {
    //     path: '/edit-profile/balans',
    //     title: 'Лицевой счет',
    //     showInMenu: true,
    //     exact: true,
    //     private: true,
    //     access: ACCESS.SCORE_VIEWS,
    // },
    // MY_SUBSCRIPTION: {
    //     path: '/edit-profile/subscription',
    //     exact: true,
    //     private: true,
    //     title: 'Мои подписки',
    //     access: ACCESS.SUBSCRIPTION_ACCESS,
    // },
    // CREATE_ORDER_GUEST: {
    //     path: '/create-order-guest',
    //     exact: true,
    //     private: true,
    //     title: 'Оформить заказ',
    // },
    // CREATE_ORDER: {
    //     path: '/create-order',
    //     exact: true,
    //     private: true,
    //     title: 'Создание заказа',
    // },
    // VIEW_ORDER: {
    //     path: '/order/:id',
    //     exact: true,
    //     private: true,
    //     title: 'Просмотр заказа',
    //     getPath: ({ id }: any) => ROUTES.VIEW_ORDER.path.replace(':id', id),
    // },
    // EDIT_ORDER: {
    //     path: '/edit-oder/:id',
    //     exact: true,
    //     private: true,
    //     title: 'Редактирование заказа',
    //     getPath: ({ id }: any) => ROUTES.EDIT_ORDER.path.replace(':id', id),
    // },
    // PERMISSION: {
    //     path: '/permission',
    //     exact: true,
    //     private: true,
    //     showInMenu: true,
    //     access: ACCESS.PERMISION,
    //     title: 'Права доступа',
    // },
    // STATIC_PARTNER: {
    //     path: '/partner',
    //     exact: true,
    //     title: 'Стать партнером',
    // },
    // STATIC_ABOUT: {
    //     path: '/about',
    //     exact: true,
    //     title: 'О сервисе',
    // },
    // STATIC_HELP: {
    //     path: '/help',
    //     exact: true,
    //     title: 'О сервисе',
    // },
    // STATIC_PAYMENT: {
    //     path: '/payment',
    //     exact: true,
    //     title: 'Оплата',
    // },
};

export const ROUTES_KEYS = Object.keys(ROUTES);
