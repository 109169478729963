import React from 'react';
import logo from 'assets/logo.svg';
import { history } from 'App';
import { ROUTES } from 'constants/routes';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type Props = {
    title?: string;
    variant?: 'none'|'rigister' | 'login';
    children: React.ReactNode;
};

export const AuthLayout = ({ title, variant = 'none', children }: Props) => (
    <div className={ styles.container }>
        <Link to="/"> <img src={ logo } className={ styles.logo } alt="" /></Link>
        <div className={  cn(styles.form, styles[variant])  }>
            { title && <h1 className={ styles.title }>{ title }</h1> }
            { children }
        </div>
        { variant === 'login' && (
            <div  className={ styles.auth_text }>
                У вас не аккаунта? &nbsp;
                <span
                    className={ styles.link }
                    onClick={ () => history.push(ROUTES.REGISTRATION.path) }
                >
                    Создать аккаунт
                </span>
            </div>
        ) }
        { variant === 'rigister' && (
            <div  className={ styles.auth_text }>
                У Вас уже есть аккаунт?  &nbsp;
                <span
                    className={ styles.link }
                    onClick={ () => history.push(ROUTES.AUTH.path) }
                >
                    Войти на сайт
                </span>
            </div>
        ) }
    </div>
);
