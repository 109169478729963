import React from 'react';
import { Dropdown } from 'antd';

import styles from './styles.module.scss';

type Props = {
    avatar: React.ReactChild;
    content: React.ReactChild | string;
    datetime: React.ReactChild;
    actions?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

export const Answer = ({
    avatar, content, datetime, actions,
}: Props) => {
    const message = (
        <div className={ styles.chat_body }>
            <div className={ styles.chat_right }>
                <div className={ styles.chat_right__avatar }>
                    { avatar }
                </div>
                <div className={ styles.chat_right__body }>
                    <div className={ styles.chat_right__content }>
                        { content }
                    </div>
                    <div className={ styles.chat_right__time }>{ datetime }</div>
                </div>
            </div>
        </div>
    );

    if (actions) {
        return (
            <Dropdown overlay={ actions } trigger={ ['contextMenu'] }>
                { message }
            </Dropdown>
        );
    }

    return message;
};
