import { put, call } from 'redux-saga/effects';
import {
    revokeTender,
    revokeTenderError,
    revokeTenderFinish,
} from 'ducks/tenders/actions';

import { fetchers } from 'api';

export function* revokeTenderRequest(action: ReturnType<typeof revokeTender>) {
    try {
        const response = yield call(fetchers.revokeTender, action.payload);

        yield put(revokeTenderFinish(response));
    } catch (error) {
        yield put(revokeTenderError());
    }
}
