import {
    EditCommentPayload, GetCommentsPayload, GetCommentsResponse, SendCommentPayload,
} from 'types/api';
import { Comment } from 'types/comments';
import { CommentsActionTypes } from './action-types';

export const getComments = (payload: GetCommentsPayload) => ({
    type: CommentsActionTypes.GET_COMMENTS_START,
    payload,
});

export const getCommentsError = () => ({
    type: CommentsActionTypes.GET_COMMENTS_ERROR,
});

export const getCommentsFinish = (response: GetCommentsResponse) => ({
    type: CommentsActionTypes.GET_COMMENTS_FINISH,
    response,
});

export const sendComment = (payload: SendCommentPayload, callback: () => void) => ({
    type: CommentsActionTypes.SEND_COMMENT_START,
    payload,
    callback,
});

export const sendCommentError = () => ({
    type: CommentsActionTypes.SEND_COMMENT_ERROR,
});

export const sendCommentFinish = () => ({
    type: CommentsActionTypes.SEND_COMMENT_FINISH,
});

export const clearComments = () => ({
    type: CommentsActionTypes.CLEAR_COMMENTS,
});

export const editComment = (payload: EditCommentPayload, callback: () => void) => ({
    type: CommentsActionTypes.EDIT_COMMENT_START,
    payload,
    callback,
});

export const editCommentError = () => ({
    type: CommentsActionTypes.EDIT_COMMENT_ERROR,
});

export const editCommentFinish = () => ({
    type: CommentsActionTypes.EDIT_COMMENT_FINISH,
});

export const deleteComment = (commentId: number) => ({
    type: CommentsActionTypes.DELETE_COMMENT_START,
    commentId,
});

export const deleteCommentError = () => ({
    type: CommentsActionTypes.DELETE_COMMENT_ERROR,
});

export const deleteCommentFinish = () => ({
    type: CommentsActionTypes.DELETE_COMMENT_FINISH,
});

// soccet
export const setComment = (message: Comment) => ({
    type: CommentsActionTypes.SET_COMMENT,
    message,
});

export const editOneComment = (comment: Comment) => ({
    type: CommentsActionTypes.EDIT_COMMENT,
    comment,
});

export const removeComment = (commentId: number) => ({
    type: CommentsActionTypes.REMOVE_COMMENT,
    commentId,
});
