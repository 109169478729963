import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

type Props = {
    title: string | React.ReactNode;
    event?: React.ReactNode;
    className?: string;
};

export const Title = ({ title, className, event }: Props) => (
    <div className={ cn(styles.title, className) }>
        { title && (
            <div>{ title }</div>
        ) }
        { event && (
            <div>{ event }</div>
        ) }
    </div>
);
