// import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    getTempOrderGuest,
    getTempOrderGuestError,
    getTempOrderGuestFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* getTempOrderGuestRequest({ orderId }: ReturnType<typeof getTempOrderGuest>) {
    try {
        const response = yield call(fetchers.getTempOrderGuest, orderId);

        yield put(getTempOrderGuestFinish(response));
    } catch (error) {
        yield put(getTempOrderGuestError());
    }
}
