import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import classNames from 'classnames';
import { IconBrandTwitch } from '@tabler/icons';
import logo from 'assets/logo.svg';
import vk from 'assets/vk_sert.svg';
import tube from 'assets/tube_sert.svg';
import telegram from 'assets/telegram_sert.svg';
import insta from 'assets/insta_sert.svg';
import { ROUTES } from 'constants/routes';

import cl from './styles.module.scss';

import { Feedback } from './form';

export const Footer = React.memo(() => {
    const [visible, setVisible] = useState(false);

    const toggle = (v = !visible) => {
        setVisible(v);
    };

    return (
        <div className={ cl.footer }>
            <div className={ cl.footer__top }>
                <div className={ cl.content }>
                    <div className={ cl.blok }>
                        <div className={ cl.blok_item }>
                            <Link to="/">
                                <img className={ cl.logo } src={ logo } alt="logo" />
                            </Link>
                            <p>
                                <b>ОГРН</b>: 1227700340053<br />
                                <b>ИНН</b>: 9721166922<br />
                                <b>Адрес</b>: 111677, город Москва, улица Маршала Ерёменко, дом 5, корпус 2, помещение 3н, комната 2
                            </p>
                            <p><b>Copyright 2022</b> <br /> ООО «СертТорг»</p>
                        </div>
                        <div className={ cl.blok_item }>
                            <div className={ cl.menu_title }>Cert-torg</div>
                            <ul className={ cl.menu }>
                                <li><NavLink to={ ROUTES.STATIC_ABOUT.path }>О сервисе</NavLink></li>
                                <li><NavLink to={ ROUTES.STATIC_PARTNER.path }>Стать партнером</NavLink></li>
                            </ul>
                        </div>
                        <div className={ cl.blok_item }>
                            <div className={ cl.menu_title }>Помощь</div>
                            <ul className={ cl.menu }>
                                <li><NavLink to={ ROUTES.STATIC_PAYMENT.path }>Варианты оплаты: сервис бесплатный</NavLink></li>
                                <li><NavLink to={ ROUTES.STATIC_HELP.path }>Помощь</NavLink></li>
                            </ul>
                        </div>
                        <div className={ cl.blok_item }>
                            <NavLink className={ cl.phone } to="tel:8-800-777-2908">8-800-777-2908</NavLink> <br />
                            <NavLink className={ cl.email } to="emailto:info@cert-torg.ru">info@cert-torg.ru</NavLink>
                            <div className={ cl.txt }>Круглосуточно</div>
                            <button
                                onClick={ () => toggle() }
                                type="button"
                                className={ cl.button }
                            >
                                <IconBrandTwitch size={ 15 } />
                                <span>Форма обратной связи</span>
                            </button>
                            { visible && (
                                <Feedback
                                    visible={ visible }
                                    toggle={ toggle }
                                />
                            ) }
                            <div className={ cl.txt }>Пишите нам свои вопросы, комментарии и предложения</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ cl.footer__bottom }>
                <div className={ cl.content }>
                    <div className={ classNames(cl.footer__row, cl.footer__row__mobile) }>
                        <ul className={ cl.menu_list }>
                            <li><NavLink target="_blank" to="/Политика конфиденциальности.pdf">Политика конфиденциальности</NavLink></li>
                            <li><NavLink target="_blank" to="/Пользовательское соглашение сайт.pdf">Пользовательское соглашение сайт</NavLink></li>
                        </ul>
                        <ul className={ cl.social }>
                            <li><NavLink to="/4"><img src={ vk } alt="" /></NavLink></li>
                            <li><NavLink to="/5"><img src={ tube } alt="" /></NavLink></li>
                            <li><NavLink to="/6"><img src={ telegram } alt="" /></NavLink></li>
                            <li><NavLink to="/6"><img src={ insta } alt="" /></NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
});
