import React, { createRef } from 'react';
import cn from 'classnames';

import cl from './styles.module.scss';

type Props = {
    id?: number;
    video: string;
    title: string;
    time: string;
    text: string;
    customer: boolean;
};

export const VideoItem = React.memo(({
    id,
    title,
    time,
    text,
    video,
    customer,
}: Props) => {
    const ref = createRef<HTMLIFrameElement>();
    const position = cn({
        [cl.video__position]: true,
        [cl.video__isp]: !customer,
    });

    const onPay = () => {
        ref.current?.requestFullscreen();
    };

    return (
        <div className={ cl.video_item } key={ id }>
            <div className={ cl.video_image }>
                <iframe
                    ref={ ref }
                    width="100%"
                    height="100%"
                    title={ title }
                    aria-hidden={ true }
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={ true }
                    srcDoc={ `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${video}/?autoplay=1><img src=https://img.youtube.com/vi/${video}/hqdefault.jpg alt='${title}'><span>▶</span></a>` }
                />
            </div>
            <div className={ cl.video_body }>
                <div className={ position }>
                    { customer ? 'ДЛЯ ЗАКАЗЧИКОВ' : 'ИСПОЛНИТЕЛЯМ' }
                </div>
                <div className={ cl.video__time }>
                    { time }
                </div>
                <div
                    className={ cl.video__name }
                    onClick={ onPay }
                >
                    { title }
                </div>
                <div className={ cl.video__text }>
                    { text }
                </div>
            </div>
        </div>
    );
});
