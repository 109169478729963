import { put, call } from 'redux-saga/effects';

import {
    removeSocialNetwork,
    removeSocialNetworkError,
    removeSocialNetworkFinish,
} from 'ducks/user/actions';

import { fetchers } from 'api';

export function* removeSocialNetworkRequest({ payload, loading }: ReturnType<typeof removeSocialNetwork>) {
    try {
        const user = yield call(fetchers.removeSocialNetwork, payload);

        yield put(removeSocialNetworkFinish(user, loading));
    } catch (error) {
        yield put(removeSocialNetworkError(loading));
    }
}
