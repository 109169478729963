/* eslint-disable react/react-in-jsx-scope */
import moment from 'moment';
import {
    Button, Popconfirm, Tag,
} from 'antd';
import { DATE_FORMAT } from 'constants/date';
import {
    Feedback, StatusInfo, TypeOfStatus,
} from 'types/feedback';
import { IconChecks, IconEditCircle, IconEditCircleOff } from '@tabler/icons';

import styles from './styles.module.scss';

type Item = Feedback;

export const getColumns = (
    isEditing: any,
    save: any,
    cancel: any,
    editingKey: any,
    edit: any,
) => {
    const columns = [{
        title: '№',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        editable: true,
        render: (value: TypeOfStatus) => (
            <Tag color={ StatusInfo[value]?.color || '#262626' }>
                { StatusInfo[value].text }
            </Tag>
        ),
    },
    {
        title: 'Тип',
        dataIndex: 'types',
        key: 'types',
    },
    {
        title: 'Контакты',
        dataIndex: 'username',
        key: 'username',
        render: (value: any, record: Feedback) => (
            <div>
                { value }<br />
                { record.email }<br />
                { record.phone }<br />
                { record.city }<br />
            </div>
        ),
    },
    {
        title: 'Сообщение',
        dataIndex: 'message',
        key: 'message',
        render: (value: any, record: Feedback) => (
            <div>
                { value } <hr />
                { moment(record.createdAt).format(DATE_FORMAT.DATE_TIME) }
            </div>
        ),
    }, {
        title: 'Комментарий',
        dataIndex: 'managerMessage',
        key: 'managerMessage',
        editable: true,
    },
    {
        title: '',
        dataIndex: 'operation',
        width: '100px',
        render: (_: any, record: Item) => {
            const editable = isEditing(record);

            return editable ? (
                <span>
                    <Button
                        type="text"
                        onClick={ () => save(record.id) }
                        className={ styles.btn }
                    >
                        <IconChecks />
                    </Button>
                    <Popconfirm title="Вы точно хотите отменить?" onConfirm={ cancel }>
                        <Button type="text" className={ styles.btn }>
                            <IconEditCircleOff />
                        </Button>
                    </Popconfirm>
                </span>
            ) : (
                <Button
                    type="text"
                    disabled={ editingKey !== null }
                    onClick={ () => edit(record) }
                    className={ styles.btn }
                >
                    <IconEditCircle />
                </Button>
            );
        },
    }];

    return columns;
};
