export enum ContentTypes {
    JSON = 'application/json',
    MPFD = 'multipart/form-data',
}

export const getBody = (data: any, type: ContentTypes) => {
    if (!data) return data;
    switch (type) {
        case ContentTypes.JSON:
            return JSON.stringify(data);
        case ContentTypes.MPFD:
            // eslint-disable-next-line no-case-declarations
            const formData = new FormData();

            Object.keys(data).forEach((key) => {
                if (typeof data[key] !== 'undefined') {
                    switch (true) {
                        case Array.isArray(data[key]):
                            data[key].forEach((item: any) => {
                                formData.append(key, item);
                            });
                            break;
                        default:
                            formData.append(key, data[key]);
                    }
                }
            });

            return formData;
        default:
    }

    return false;
};

export const getRealPath = (url: string) => `/${url}`;