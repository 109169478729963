/* eslint-disable no-console */
import React, { useRef, useState, useCallback } from 'react';
import { Input, Tooltip } from 'antd';
import { IconBackspace, IconCornerDownLeft } from '@tabler/icons';
import ErrorMessage from 'components/errorMessage';
import { EmojiPicker } from 'components/ui/emoji-picker';
import { useErrorProfile } from 'components/ui/hooks/useError';

import { Message as MessageType } from 'types/dialog';

import styles from './styles.module.scss';

type Props = {
    message: string;
    loading: {
        get: boolean;
        send: boolean;
        edit: boolean;
        delete: boolean;
    };
    handleChangeMessage: (e: any) => void;
    handleSendMessage: (e: any) => void;
    refund?: MessageType | null;
    errMessage: string | null;
    handleChangeMessageRefund: (e: any) => void;
};

export const Footer = React.memo(({
    loading,
    message,
    handleChangeMessage,
    handleSendMessage,
    refund,
    errMessage,
    handleChangeMessageRefund,
}: Props) => {
    const ref = useRef<any>();
    const { isAuthConfirmed } = useErrorProfile();
    const [position, setPosition] = useState([0, 0]);
    const view = document.getElementById('scrollableMessages');
    const onKeyDownTextArea = (e: any) => {
        const keyCode = e.which || e.keyCode;

        if (keyCode === 13 && !e.shiftKey) {
            handleSendMessage(e);

            if (view) {
                view.scrollTop = 999999;
            }
            e.preventDefault();
        }
    };

    const isSumbit = message.length > 0;

    const onChangeMessage = (e: any) => {
        handleChangeMessage(e.target.value);
        setPosition([e.target.selectionStart, e.target.selectionEnd]);
    };

    const handleChangePicker = useCallback((ico: any) => {
        if (position[0] === position[1] && position[0] !== 0) {
            const oldTxt = message.substring(0, position[0]);
            const nextTxt = message.substring(position[0]);
            const txt = `${oldTxt} ${ico}  ${nextTxt}`;

            handleChangeMessage(txt);
            // eslint-disable-next-line no-negated-condition
        } else if (position[0] !== position[1]) {
            const oldTxt = message.slice(position[0], position[1]);
            const txt = message.replace(oldTxt, ico);

            handleChangeMessage(txt);
        } else {
            handleChangeMessage(`${message} ${ico}`);
        }
    }, [message, position]);

    if (isAuthConfirmed) {
        return (
            <div className={ styles.wrap }>
                <div className={ styles.footer }>
                    <ErrorMessage type="ACCOUNT_NOT_CONFIRMED" />
                </div>
            </div>
        );
    }

    return (
        <div className={ styles.wrap }>
            <div className={ styles.footer }>
                { errMessage && (<div className={ styles.errMessage }>{ errMessage }</div>) }
                { refund && (
                    <div className={ styles.top }>
                        <div>Редактирование <span>сообщения</span></div>
                        <Tooltip title="Отменить редактирование сообщения">
                            <IconBackspace
                                size={ 18 }
                                className={ styles.refund }
                                onClick={ handleChangeMessageRefund }
                            />
                        </Tooltip>
                    </div>
                ) }
                <div className={ styles.msgBox }>
                    <Input.TextArea
                        ref={ ref }
                        className={ styles.msgBox__textarea }
                        autoSize={ { minRows: 1, maxRows: 6 } }
                        placeholder="Напишите ваше сообщение"
                        disabled={ loading.send }
                        onChange={ onChangeMessage }
                        onSelect={ onChangeMessage }
                        value={ message }
                        onKeyDown={ onKeyDownTextArea }
                    />
                    <div className={ styles.msgBox__picker }>
                        <EmojiPicker
                            picker={ handleChangePicker }
                        />
                    </div>
                    { isSumbit && (
                        <div className={ styles.msgBox__send }>
                            <IconCornerDownLeft
                                size={ 18 }
                                onClick={ (e) => handleSendMessage(e) }
                            />
                        </div>
                    ) }
                </div>
            </div>
        </div>
    );
});
