import { put, call } from 'redux-saga/effects';
import {
    subscribeOnCategory,
    subscribeOnCategoryError,
    subscribeOnCategoryFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* subscribeOnCategoryRequest({ data }: ReturnType<typeof subscribeOnCategory>) {
    try {
        yield call(fetchers.subscribeOnCategory, data);
        yield put(subscribeOnCategoryFinish());
    } catch (error) {
        yield put(subscribeOnCategoryError());
    }
}
