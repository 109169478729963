import { put, call, select } from 'redux-saga/effects';

import {
    loadNotificationsError,
    loadNotificationsFinish,
} from 'ducks/notification/actions';

import {
    selectNotificationPagination,
    selectNotificationSort,
} from 'ducks/notification/selectors';

import { fetchers } from 'api';

export function* loadNotificationRequest() {
    try {
        const token = localStorage.getItem('token');

        if (!token) return;

        const { pageSize } = yield select(selectNotificationPagination);
        const sort = yield select(selectNotificationSort);

        const response = yield call(fetchers.loadNotification, {
            pagination: {
                page: 1,
                pageSize,
            },
            sort,
        });

        yield put(loadNotificationsFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                next: response.pagination.next,
                page: 1,
            },
            sort,
        }));
    } catch (error) {
        yield put(loadNotificationsError());
    }
}
