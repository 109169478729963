export const BlogActionTypes = {
    GET_BLOG_START: 'GET_BLOG_START',
    GET_BLOG_ERROR: 'GET_BLOG_ERROR',
    GET_BLOG_FINISH: 'GET_BLOG_FINISH',

    GET_BLOG_BY_ID_START: 'GET_BLOG_BY_ID_START',
    GET_BLOG_BY_ID_ERROR: 'GET_BLOG_BY_ID_ERROR',
    GET_BLOG_BY_ID_FINISH: 'GET_BLOG_BY_ID_FINISH',

    CREATE_BLOG_START: 'CREATE_BLOG_START',
    CREATE_BLOG_ERROR: 'CREATE_BLOG_ERROR',
    CREATE_BLOG_FINISH: 'CREATE_BLOG_FINISH',

    CLEAR_BLOG: 'CLEAR_BLOG',
    CLEAR_BLOG_DETAIL: 'CLEAR_BLOG_DETAIL',

    EDIT_BLOG: 'EDIT_BLOG',
    EDIT_BLOG_ERROR: 'EDIT_BLOG_ERROR',
    EDIT_BLOG_FINISH: 'EDIT_BLOG_FINISH',

    DELETE_BLOG: 'DELETE_BLOG',
    DELETE_BLOG_ERROR: 'DELETE_BLOG_ERROR',
    DELETE_BLOG_FINISH: 'DELETE_BLOG_FINISH',
} as const;
