import React from 'react';
import moment from 'moment';
import {
    Button, Tag, Avatar, Popover,
} from 'antd';
import { IconPencil } from '@tabler/icons';
import { DATE_FORMAT } from 'constants/date';
import { ROUTES } from 'constants/routes';
import { history } from 'App';
import { Order, Status, StatusInfo } from 'types/order';
import { getAvatar, getFio } from 'utils/formatters';

import styles from './styles.module.scss';

const goToEditOrder = (id: number, e: any) => {
    e.stopPropagation();
    history.push(ROUTES.EDIT_ORDER.getPath({ id }));
};

const goToAuthor = (id: number, e: any) => {
    e.stopPropagation();
    history.push(ROUTES.USER.getPath({ id }));
};

export const getColumns = (mappedCategories: any, isCustomer: boolean) => {
    const getCompany = (account: any) => (
        <div className={ styles.company }>
            { account.company?.organization && (

                <div className={ styles.company_item }>
                    <div className={ styles.company_name }>Организация:</div>
                    <div className={ styles.company_value }>{ account.company.organization }</div>
                </div>
            ) }
            { account.email && (
                <div className={ styles.company_item }>
                    <div className={ styles.company_name }>E-mail:</div>
                    <div className={ styles.company_value }>{ account.email }</div>
                </div>
            ) }
            { account?.phone && (
                <div className={ styles.company_item }>
                    <div className={ styles.company_name }>Телефон:</div>
                    <div className={ styles.company_value }>{ account.phone }</div>
                </div>
            ) }
            { account?.skype && (
                <div className={ styles.company_item }>
                    <div className={ styles.company_name }>Skype:</div>
                    <div className={ styles.company_value }>{ account.skype }</div>
                </div>
            ) }
            { account?.tg && (
                <div className={ styles.company_item }>
                    <div className={ styles.company_name }>Телеграм:</div>
                    <div className={ styles.company_value }>{ account.tg }</div>
                </div>
            ) }
            { account?.viber && (
                <div className={ styles.company_item }>
                    <div className={ styles.company_name }>Viber:</div>
                    <div className={ styles.company_value }>{ account.viber }</div>
                </div>
            ) }
            { account?.whatsapp && (
                <div className={ styles.company_item }>
                    <div className={ styles.company_name }>Whatsapp:</div>
                    <div className={ styles.company_value }>{ account.whatsapp }</div>
                </div>
            ) }
        </div>
    );

    const colomns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (value: any, record: Order) => (
                <div>
                    <div className={ styles.ttitle }>#{ record.id }&nbsp;|&nbsp;{ value }</div>
                    <div className={ styles.tdate }>Категория:&nbsp;
                        {
                            record.orderCategories.length ?
                                record.orderCategories.map((num, i, arr) => ((i + 1) < arr.length
                                    ? (`${mappedCategories[num]}, `)
                                    : mappedCategories[num])) : 'без категории'
                        }
                    </div>
                    <div className={ styles.tdate }>Дата создания:&nbsp;{ moment(record.created).format(DATE_FORMAT.DATE_TIME) }</div>
                </div>
            ),
        },
        {
            title: 'Ценовое предложение',
            dataIndex: 'price',
            key: 'price',
            render: (value: number) => <div className={ styles.price }>{ value ? `${value}` : 0 } &#8381;</div>,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (value: Status) => (
                <Tag color={ StatusInfo[value]?.color || '#262626' }>
                    { StatusInfo[value].text }
                </Tag>
            ),
        },
    ];

    if (isCustomer) {
        const editColumn: any = {
            title: '',
            dataIndex: '',
            key: 'actions',
            render: (order: Order) => (
                <Button
                    type="text"
                    onClick={ (e) => goToEditOrder(order.id, e) }
                    disabled={ order.status !== StatusInfo.new.key && order.status !== StatusInfo.draft.key }
                >
                    <IconPencil />
                </Button>
            ),
        };

        const inspolnitel = {
            title: 'Исполинтель',
            dataIndex: 'inspolnitel',
            key: 'inspolnitel',
            render: (value: string, record: Order) => (record.orderTender ? (
                <div
                    className={ styles.account }
                    onClick={ (e) => goToAuthor(record.orderTender.account.id, e) }
                >
                    <Avatar
                        className={ styles.account_ava }
                        src={ getAvatar(record.orderTender.account.avatar) }
                        size={ 32 }
                    />
                    <div className={ styles.account_name }>
                        <Popover placement="bottom" content={ getCompany(record.orderTender.account) } title="О заказчике">
                            { getFio(record.orderTender.account.firstName, record.orderTender.account.lastName) }
                        </Popover>
                    </div>
                </div>
            ) : (
                <div className={ styles.noTender }>Исполнитель еще не выбран</div>
            )),
        };

        colomns.splice(1, 0, inspolnitel);
        colomns.push(editColumn);
    } else {
        const zakazchik = {
            title: 'Заказчик',
            dataIndex: 'zakazchik',
            key: 'zakazchik',
            render: (value: string, record: Order) => (
                <div
                    className={ styles.account }
                    onClick={ (e) => goToAuthor(record.account.id, e) }
                >
                    <Avatar
                        className={ styles.account_ava }
                        src={ getAvatar(record.account.avatar) }
                        size={ 32 }
                    />
                    <div className={ styles.account_name }>
                        <Popover placement="bottom" content={ getCompany(record.account) } title="О заказчике">
                            { getFio(record.account.firstName, record.account.lastName) }
                        </Popover>
                    </div>
                </div>
            ),
        };

        colomns.splice(1, 0, zakazchik);
    }

    return colomns;
};
