import { put, call } from 'redux-saga/effects';
import {
    editFeedback,
    ediFeedbackError,
    editFeedbackFinish,
} from 'ducks/feedback/actions';

import { fetchers } from 'api';

export function* editFeedbackRequest(action: ReturnType<typeof editFeedback>) {
    try {
        const response = yield call(fetchers.editFeedback, action.payload);

        yield put(editFeedbackFinish(response));

        if (action.callback) {
            action.callback();
        }
    } catch (error) {
        yield put(ediFeedbackError());
    }
}
