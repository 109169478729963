export const CompanyActionTypes = {
    SAVE_COMPANY_START: 'SAVE_COMPANY_START',
    SAVE_COMPANY_FINISH: 'SAVE_COMPANY_FINISH',
    SAVE_COMPANY_ERROR: 'SAVE_COMPANY_ERROR',

    GET_COMPANY_START: 'GET_COMPANY_START',
    GET_COMPANY_FINISH: 'GET_COMPANY_FINISH',
    GET_COMPANY_ERROR: 'GET_COMPANY_ERROR',

    SEARCH_COMPANY_START: 'SEARCH_COMPANY_START',
    SEARCH_COMPANY_FINISH: 'SEARCH_COMPANY_FINISH',
    SEARCH_COMPANY_ERROR: 'SEARCH_COMPANY_ERROR',

    CLEAR_COMPANY: 'CLEAR_COMPANY',
} as const;
