import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './styles.module.scss';

const antIcon = <LoadingOutlined style={ { fontSize: 100 } } spin={ true } />;

export const AppLoading = () => (
    <div className={ styles.container }>
        <div className={ styles.group }>
            <Spin indicator={ antIcon } />
            <div className={ styles.text }>Загрузка данных пользователя</div>
        </div>
    </div>
);
