import { put, call, select } from 'redux-saga/effects';

import {
    getUsers,
    getUsersError,
    getUsersFinish,
} from 'ducks/user/actions';

import {
    selectUsersSort,
    selectUsersPagination,
} from 'ducks/user/selectors';

import { fetchers } from 'api';

export function* getUsersRequest({ payload }: ReturnType<typeof getUsers>) {
    try {
        const { pageSize } = yield select(selectUsersPagination);
        const sort = yield select(selectUsersSort);
        const response = yield call(fetchers.getUsers, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        yield put(getUsersFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                page: payload.pagination.page,
            },
            sort: payload.sort || sort,
        }));
    } catch (error) {
        yield put(getUsersError());
    }
}
