import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Row,
    Col,
    Switch,
} from 'antd';

import { AppLoading } from 'components/ui/app-loading';

import { selectProfile, selectUserLoading } from 'ducks/user/selectors';
import { loadSubscription, setSubscription } from 'ducks/subscription/actions';
import { selectSubscription, selectSubscriptionLoading } from 'ducks/subscription/selectors';

import styles from './styles.module.scss';

const { Title } = Typography;

export const SubscriptionSite = React.memo(() => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const loading = useSelector(selectUserLoading);
    const subscription = useSelector(selectSubscription);
    const subscriptionLoading = useSelector(selectSubscriptionLoading);

    const sendSubscription = (key: string, type: boolean) => {
        put(setSubscription({ [key]: type }));
    };

    useEffect(() => {
        put(loadSubscription());
    }, []);

    if (!user || loading.profile) {
        return <AppLoading />;
    }

    return (
        <React.Fragment>
            <Title className={ styles.title } level={ 4 }>Подписки профиля</Title>
            <div className={ styles.content }>
                <Row>
                    <Col lg={ 24 } span={ 24 }>
                        <div className={ styles.form }>
                            <ul className={ styles.list }>
                                <li>
                                    <div className={ styles.list_content }>
                                        <div className={ styles.list__name }>Новости</div>
                                        <div className={ styles.list__desc }>Следить за новостями сайта</div>
                                    </div>
                                    <Switch
                                        loading={ subscriptionLoading }
                                        checkedChildren="Выкл"
                                        unCheckedChildren="Вкл"
                                        checked={ subscription?.news }
                                        onChange={ (type) => sendSubscription('news', type) }
                                    />
                                </li>
                                <li>
                                    <div className={ styles.list_content }>
                                        <div className={ styles.list__name }>Заказы</div>
                                        <div className={ styles.list__desc }>Следить за всеми новыми заказами сайта</div>
                                    </div>
                                    <Switch
                                        loading={ subscriptionLoading }
                                        className={ styles.list__switch }
                                        checkedChildren="Выкл"
                                        unCheckedChildren="Вкл"
                                        checked={ subscription?.orders }
                                        onChange={ (type) => sendSubscription('orders', type) }
                                    />
                                    <ul>
                                        <li>
                                            <div className={ styles.list_content }>
                                                <div className={ styles.list__name }>Мои заказы</div>
                                                <div className={ styles.list__desc }>Следить за всеми действиями заказа где я участвую</div>
                                            </div>
                                            <Switch
                                                loading={ subscriptionLoading }
                                                className={ styles.list__switch }
                                                checkedChildren="Выкл"
                                                unCheckedChildren="Вкл"
                                                checked={ subscription?.orderUpdate }
                                                onChange={ (type) => sendSubscription('orderUpdate', type) }
                                            />
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className={ styles.list_content }>
                                        <div className={ styles.list__name }>Сообщение</div>
                                        <div className={ styles.list__desc }>Уведомлять о новых сообщениях в заказе</div>
                                    </div>
                                    <Switch
                                        loading={ subscriptionLoading }
                                        className={ styles.list__switch }
                                        checkedChildren="Выкл"
                                        unCheckedChildren="Вкл"
                                        checked={ subscription?.poll }
                                        onChange={ (type) => sendSubscription('messageOrder', type) }
                                    />
                                </li>
                                <li>
                                    <div className={ styles.list_content }>
                                        <div className={ styles.list__name }>Опросы</div>
                                        <div className={ styles.list__desc }>Участвовать в опросах</div>
                                    </div>
                                    <Switch
                                        loading={ subscriptionLoading }
                                        className={ styles.list__switch }
                                        checkedChildren="Выкл"
                                        unCheckedChildren="Вкл"
                                        checked={ subscription?.poll }
                                        onChange={ (type) => sendSubscription('poll', type) }
                                    />
                                </li>
                                <li>
                                    <div className={ styles.list_content }>
                                        <div className={ styles.list__name }>Обновление сайта</div>
                                        <div className={ styles.list__desc }>Получать сообщение про новые обновления сайта</div>
                                    </div>
                                    <Switch
                                        loading={ subscriptionLoading }
                                        className={ styles.list__switch }
                                        checkedChildren="Выкл"
                                        unCheckedChildren="Вкл"
                                        checked={ subscription?.updateSite }
                                        onChange={ (type) => sendSubscription('updateSite', type) }
                                    />
                                </li>
                                <li>
                                    <div className={ styles.list_content }>
                                        <div className={ styles.list__name }>Телеграмм</div>
                                        <div className={ styles.list__desc }>Получать уведомлнеие на свой телеграм канал</div>
                                    </div>
                                    <Switch
                                        loading={ subscriptionLoading }
                                        className={ styles.list__switch }
                                        checkedChildren="Выкл"
                                        unCheckedChildren="Вкл"
                                        checked={ subscription?.orders }
                                        onChange={ (type) => sendSubscription('tg', type) }
                                    />
                                    <ul>
                                        <li>
                                            <div className={ styles.list_content }>
                                                <div className={ styles.list__name }>Заказы</div>
                                                <div className={ styles.list__desc }>Получить информацию о новых заказах</div>
                                            </div>
                                            <Switch
                                                loading={ subscriptionLoading }
                                                className={ styles.list__switch }
                                                checkedChildren="Выкл"
                                                unCheckedChildren="Вкл"
                                                checked={ subscription?.orderUpdate }
                                                onChange={ (type) => sendSubscription('tgOrderBot', type) }
                                            />
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
});
