import React from 'react';
import { Skeleton } from 'antd';

import styles from 'components/order-chat/styles.module.scss';

export const OrderChatSkeleton = () => (
    <div className={ styles.wrap }>
        <div className={ styles.chat_head }>
            <Skeleton active={ true } paragraph={ { rows: 0 } } />
        </div>
        { Array.from({ length: 3 }).map((_, i) => (
            <div key={ i } style={ { padding: '0 10px' } }>
                <Skeleton active={ true } avatar={ true } paragraph={ { rows: 2 } } />
            </div>
        )) }
        <div className={ styles.message }>
            <Skeleton active={ true } paragraph={ { rows: 1 } } />
        </div>
    </div>
);
