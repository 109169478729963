import React, { useState } from 'react';
import {
    Checkbox,
    Form, Input, Modal, Segmented,
} from 'antd';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { MaskedInput } from 'antd-mask-input';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { schema } from 'form-helpers/footer/schema';
import { sendFeedback } from 'ducks/feedback/actions';
import { AddFeedback as FeedbackFormType } from 'types/feedback';

import cl from './styles.module.scss';

const optionSegmented = [
    {
        label: (
            <div>Отзывы <br /> и предложения</div>
        ),
        value: 'Отзывы и предложения',
    },
    {
        label: (
            <div>Вопрос <br /> по контенту</div>
        ),
        value: 'Вопрос по контенту',
    },
    {
        label: (
            <div>Жалоба</div>
        ),
        value: 'Жалоба',
    },
    {
        label: (
            <div>Другое</div>
        ),
        value: 'Другое',
    }];

type Props = {
    visible: boolean;
    toggle: (v: boolean) => void;
};

export const Feedback = React.memo(({ visible, toggle }: Props) => {
    const put = useDispatch();

    const [isSubmitTry, setIsSubmitTry] = useState(false);
    const [checked, setChecked] = useState(true);

    const onSendSubmit = (values: FeedbackFormType) => {
        const newData = values;

        newData.phone = values.phone.replaceAll(' ', '');
        put(sendFeedback(newData));
        toggle(false);
        Modal.success({
            title: 'Выше обращение успешно отправлено',
        });
    };

    return (
        <React.Fragment>
            <Formik<FeedbackFormType>
                initialValues={ schema.cast({ types: 'Отзывы и предложения' }) }
                onSubmit={ onSendSubmit }
                validationSchema={ schema }
            >
                { ({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    touched,
                }) => {
                    const errorList = {
                        username: getError(errors, touched, isSubmitTry, 'username'),
                        phone: getError(errors, touched, isSubmitTry, 'phone'),
                        email: getError(errors, touched, isSubmitTry, 'email'),
                        city: getError(errors, touched, isSubmitTry, 'city'),
                        message: getError(errors, touched, isSubmitTry, 'message'),
                    };

                    const onSubmit = () => {
                        setIsSubmitTry(true);
                        handleSubmit();
                    };

                    const handleChangeSegmented = (value: string | number) => {
                        setFieldValue('types', value, true);
                    };

                    return (
                        <Modal
                            open={ visible }
                            onOk={ onSubmit }
                            onCancel={ () => toggle(false) }
                            width={ 600 }
                            footer={ null }
                        >
                            <div className={ cl.modal_title }>Обратная связь</div>
                            <p className={ cl.modal_info }>Мы всегда готовы ответить на любой вопрос, а также услышать ваши отзывы и предложения по работе нашего сервиса.</p>
                            <Form
                                name="Feedback"
                                autoComplete="off"
                                layout="vertical"
                                onFinish={ handleSubmit }
                            >
                                <div className={ cl.row }>
                                    <div className={ cl.col }>
                                        <Form.Item
                                            extra={ errorList.username }
                                            validateStatus={ getErrorStatus(!!errorList.username) }
                                        >
                                            <Input
                                                placeholder="Ваше Имя"
                                                size="large"
                                                name="username"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            extra={ errorList.phone }
                                            validateStatus={ getErrorStatus(!!errorList.phone) }
                                        >
                                            <MaskedInput
                                                mask="+7 000 000 00 00"
                                                name="phone"
                                                placeholder="Телефон"
                                                size="large"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={ cl.col }>
                                        <Form.Item
                                            extra={ errorList.email }
                                            validateStatus={ getErrorStatus(!!errorList.email) }
                                        >
                                            <Input
                                                name="email"
                                                placeholder="Адрес электронной почты"
                                                size="large"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            extra={ errorList.city }
                                            validateStatus={ getErrorStatus(!!errorList.city) }
                                            hidden={ true }
                                        >
                                            <Input
                                                name="city"
                                                placeholder="Ваш город"
                                                size="large"
                                                onChange={ handleChange }
                                                onBlur={ handleBlur }
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <Form.Item>
                                    <Segmented
                                        name="types"
                                        block={ true }
                                        className="segmented"
                                        options={ optionSegmented }
                                        onChange={ handleChangeSegmented }
                                    />
                                </Form.Item>

                                <Form.Item
                                    extra={ errorList.message }
                                    validateStatus={ getErrorStatus(!!errorList.message) }
                                >
                                    <Input.TextArea
                                        name="message"
                                        placeholder="Опишите ваш вопрос"
                                        autoSize={ { minRows: 3, maxRows: 6 } }
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                    />
                                </Form.Item>
                                <div className={ cl.row }>
                                    <div className={ cl.col }>
                                        <Checkbox
                                            checked={ checked }
                                            onChange={ () => setChecked(!checked) }
                                        >
                                            Даю согласие на обработку и хранение персональных данных
                                        </Checkbox>
                                    </div>
                                    <div className={ cl.col }>
                                        <button
                                            type="submit"
                                            className={ cl.modal_button }
                                            disabled={ !checked }
                                            onClick={ onSubmit }
                                        >
                                            Отправить
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Modal>
                    );
                } }
            </Formik>
        </React.Fragment>
    );
});
