import React from 'react';
import { useSelector } from 'react-redux';
import {
    Typography,
    Row,
    Col,
} from 'antd';

import { AppLoading } from 'components/ui/app-loading';

import { selectProfile, selectUserLoading } from 'ducks/user/selectors';

import { SecurityEmail } from './email';
import { SecurityPhone } from './phone';
import { SecurityPassword } from './password';

import styles from './styles.module.scss';

const { Title } = Typography;

export const Security = React.memo(() => {
    const user = useSelector(selectProfile);
    const loading = useSelector(selectUserLoading);

    if (!user || loading.profile) {
        return <AppLoading />;
    }

    return (
        <React.Fragment>
            <Title className={ styles.title } level={ 4 }>Безопасность и вход</Title>
            <div className={ styles.content }>
                <Row>
                    <Col lg={ { span: 11, offset: 0 } }>
                        <SecurityEmail
                            user={ user }
                            loading={ loading }
                        />
                        <SecurityPhone
                            user={ user }
                            loading={ loading }
                        />
                    </Col>
                    <Col lg={ { span: 11, offset: 1 } }>
                        <SecurityPassword
                            user={ user }
                            loading={ loading }
                        />
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
});
