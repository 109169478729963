import React, { useState } from 'react';
import { IconLogout, IconMenu2, IconX } from '@tabler/icons';
import cn from 'classnames';
import { ROUTES } from 'constants/routes';
import logo from 'assets/mobile_logo.png';
import styles from './styles.module.scss';

type ItemMenu = {
    path: string;
    title: string;
};

type Props = {
    items: ItemMenu[];
};

export const MenuMobile = React.memo(({ items }: Props) => {
    const [open, setOpen] = useState(false);

    const toggleMenu = () => setOpen(!open);

    const styleMenu = cn({
        [styles.menu]: true,
        [styles.menu__open]: open,
    });

    return (
        <div
            className={ styles.container }
        >
            <div className={ styles.open } onClick={ toggleMenu }>
                <IconMenu2 size={ 30 } />
            </div>
            <div className={ styleMenu }>
                <IconX
                    size={ 24 }
                    className={ styles.close }
                    onClick={ toggleMenu }
                />
                <img src={ logo } className={ styles.logo } alt="" />
                <nav>
                    <ul className={ styles.navigation }>
                        { items.map((item, i) => (<li key={ i }><a href={ item.path }>{ item.title }</a></li>)) }

                        <li>
                            <div><a href="tel:8-800-777-2908">8-800-777-2908</a></div>
                            <div><a href="mailto:info@cert-torg.ru">info@cert-torg.ru</a></div>
                        </li>
                        <li><a href={ ROUTES.AUTH.path } className={ styles.icon }>Войти <IconLogout size={ 16 } /></a></li>
                        <li><a href={ ROUTES.ORDERS.path }>Заказы</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    );
});
