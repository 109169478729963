import React from 'react';
import { Avatar, Rate, Typography } from 'antd';
import { parsePhoneNumber } from 'libphonenumber-js';
import { getAvatar, getFio } from 'utils/formatters';

import { TypeOfRoleText } from 'types/account';
import { AccountCompany } from 'types/order';

import { ROUTES } from 'constants/routes';
import { history } from 'App';

import styles from './styles.module.scss';

const { Paragraph } = Typography;

type Props = {
    account?: AccountCompany | null;
};

export const OrderContact = React.memo(({ account }: Props) => {
    if (!account) {
        return <div>Ошибка вывода данных исполнител или заказчика</div>;
    }
    const goToAuthor = () => history.push(ROUTES.USER.getPath({ id: account.id }));

    const phone = account.phone ? parsePhoneNumber(String(account.phone), 'RU').format('INTERNATIONAL') : null;

    return (
        <div className={ styles.body }>
            <div className={ styles.title }>Контакты заказчика</div>
            <div className={ styles.info }>
                <div className={ styles.info_account }>
                    <div className={ styles.account }>
                        <div className={ styles.account__ava } onClick={ goToAuthor }>
                            <Avatar
                                size={ 55 }
                                src={ getAvatar(account.avatar) }
                            />
                        </div>
                        <div className={ styles.account__name } onClick={ goToAuthor }>
                            { getFio(account.lastName, account.firstName) }
                        </div>
                        <div className={ styles.account__role }>
                            ({ TypeOfRoleText[account.typeOfRole] })
                        </div>
                        <div className={ styles.account__rating }>
                            <Rate disabled={ true } allowHalf={ true } defaultValue={ account.rating } style={ { fontSize: 16 } } />
                        </div>
                        <div className={ styles.account__zakaz }>
                            Заказов: { account.orderSize }
                        </div>
                    </div>
                </div>
                <div className={ styles.info_company }>
                    <div className={ styles.desc }><span>О Компании</span></div>
                    <div className={ styles.company }>
                        <div className={ styles.company__item }>
                            Тип: <span>{ account.company.typeOfOrgan === 'company' ? 'Юридическое лицо' : 'Физическое лицо' }</span>
                        </div>
                        { account.company.organization && (
                            <div className={ styles.company__item }>
                                Организация: <span>{ account.company.organization }</span>
                            </div>
                        ) }
                        { account.company.address && (
                            <div className={ styles.company__item }>
                                Адрес: <span>{ account.company.address }</span>
                            </div>
                        ) }
                        { account.company.bicBank && (
                            <div className={ styles.company__item }>
                                БИК: <span>{ account.company.bicBank }</span>
                            </div>
                        ) }
                        { account.company.inn && (
                            <div className={ styles.company__item }>
                                ИНН: <span>{ account.company.inn }</span>
                            </div>
                        ) }
                        { account.company.kpp && (
                            <div className={ styles.company__item }>
                                КПП: <span>{ account.company.kpp }</span>
                            </div>
                        ) }
                        { account.company.scoreNumber && (
                            <div className={ styles.company__item }>
                                Счет: <span>{ account.company.scoreNumber }</span>
                            </div>
                        ) }
                        { account.company.fio && (
                            <div className={ styles.company__item }>
                                ФИО: <span>{ account.company.fio }</span>
                            </div>
                        ) }
                        { account.company.certificateNumber && (
                            <div className={ styles.company__item }>
                                Номер аттестата: <span>{ account.company.certificateNumber }</span>
                            </div>
                        ) }
                    </div>
                </div>
                <div className={ styles.info_contact }>
                    <div className={ styles.desc }><span>Контакты</span></div>
                    <div className={ styles.contact }>
                        { phone && (
                            <div className={ styles.contact__item }>
                                Телефон:
                                <Paragraph className={ styles.contact__value } copyable={ { text: phone } }>{ phone }</Paragraph>
                            </div>
                        ) }
                        { account.email && (
                            <div className={ styles.contact__item }>
                                E-mail:
                                <Paragraph
                                    className={ styles.contact__value }
                                    copyable={ { text: account.email } }
                                >
                                    { account.email }
                                </Paragraph>
                            </div>
                        ) }
                        { account.skype && (
                            <div className={ styles.contact__item }>
                                Skype:
                                <Paragraph
                                    className={ styles.contact__value }
                                    copyable={ { text: account.skype } }
                                >
                                    { account.skype }
                                </Paragraph>
                            </div>
                        ) }
                        { account.viber && (
                            <div className={ styles.contact__item }>
                                Viber:
                                <Paragraph
                                    className={ styles.contact__value }
                                    copyable={ { text: account.viber } }
                                >
                                    { account.viber }
                                </Paragraph>
                            </div>
                        ) }
                        { account.whatsapp && (
                            <div className={ styles.contact__item }>
                                Whatsapp:
                                <Paragraph
                                    className={ styles.contact__value }
                                    copyable={ { text: account.whatsapp } }
                                >
                                    { account.whatsapp }
                                </Paragraph>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
        </div>
    );
});
