import { put, call } from 'redux-saga/effects';

import {
    getCompany,
    getCompanyError,
    getCompanyFinish,
} from 'ducks/company/actions';

import { fetchers } from 'api';

export function* getCompanyRequest({ userId }: ReturnType<typeof getCompany>) {
    try {
        const data = yield call(fetchers.getCompany, userId);

        yield put(getCompanyFinish(data));
    } catch (error) {
        yield put(getCompanyError());
    }
}
