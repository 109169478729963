import { InferValueTypes } from 'types/common';
import { Company } from 'types/company';
import * as actions from './actions';
import { CompanyActionTypes } from './action-types';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type CompanyState = {
    loading: {
        get: boolean;
        save: boolean;
        search: boolean;
    };
    data: Company | null;
    list: Company[] | null;
};

export const initialState: CompanyState = {
    loading: {
        save: false,
        get: false,
        search: false,
    },
    data: null,
    list: null,
};

export function companyReducer(state = initialState, action: ActionTypes): CompanyState {
    switch (action.type) {
        case CompanyActionTypes.SAVE_COMPANY_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    save: true,
                },
            };
        case CompanyActionTypes.SAVE_COMPANY_FINISH:
        case CompanyActionTypes.SAVE_COMPANY_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    save: false,
                },
            };

        case CompanyActionTypes.GET_COMPANY_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };
        case CompanyActionTypes.GET_COMPANY_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                data: action.data,
            };

        case CompanyActionTypes.GET_COMPANY_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case CompanyActionTypes.SEARCH_COMPANY_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    search: true,
                },
            };
        case CompanyActionTypes.SEARCH_COMPANY_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    search: false,
                },
                list: action.payload,
            };

        case CompanyActionTypes.SEARCH_COMPANY_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    search: false,
                },
            };

        case CompanyActionTypes.CLEAR_COMPANY:
            return {
                ...state,
                data: null,
                list: null,
            };

        default: {
            return state;
        }
    }
}
