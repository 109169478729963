import React from 'react';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TitleLine } from 'components/ui/title-line';
import { SliderButtons } from './sliderButtons';
import { VideoItem } from './item';
import 'swiper/swiper.css';
import 'swiper/modules/navigation/navigation.min.css';
import videoCustomer from './video1.json';
import videoExecutor from './video2.json';

import cl from './styles.module.scss';

export const Video = () => (
    <div className={ cl.container }>
        <TitleLine title='Как работает сервис' />
        <Swiper
            className={ cl.swiper }
            slidesPerView='auto'
            spaceBetween={ 30 }
            pagination={ {
                clickable: true,
            } }
            navigation={ false }
            grabCursor={ true }
            modules={ [Navigation, A11y] }
        >
            <SliderButtons />
            { videoCustomer.map((item) => (
                <SwiperSlide key={ item.id } className={ cl.video_slide }>
                    <VideoItem { ...item } />
                </SwiperSlide>
            )) }
        </Swiper>

        <div className={ cl.wrapExecutor }>
            <Swiper
                className={ cl.swiper }
                slidesPerView='auto'
                spaceBetween={ 30 }
                pagination={ {
                    clickable: true,
                } }
                navigation={ false }
                grabCursor={ true }
                modules={ [Navigation, A11y] }
            >
                <SliderButtons />
                { videoExecutor.map((item) => (
                    <SwiperSlide key={ item.id } className={ cl.video_slide }>
                        <VideoItem { ...item } />
                    </SwiperSlide>
                )) }
            </Swiper>
        </div>
    </div>
);
