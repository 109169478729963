import { useEffect } from 'react';

export const useTelegram = () => {
    const tg = (window as any).Telegram?.WebApp;

    useEffect(() => {
        tg.ready();
        tg.expand();
    }, []);

    const onClose = () => {
        tg.close();
    };

    return {
        tg,
        onClose,
        theme: tg.colorScheme,
        user: tg.initDataUnsafe?.user,
        queryid: tg.initDataUnsafe?.query_id,
    };
};
