import { put, call } from 'redux-saga/effects';
import {
    deleteComment,
    deleteCommentError,
    deleteCommentFinish,
    // getComments,
} from 'ducks/comments/actions';

// import { selectOrder } from 'ducks/orders/selectors';

import { fetchers } from 'api';

export function* deleteCommentRequest(action: ReturnType<typeof deleteComment>) {
    try {
        // const order = yield select(selectOrder);

        yield call(fetchers.deleteComment, action.commentId);
        yield put(deleteCommentFinish());
        // yield put(getComments({ pagination: { page: 1 }, orderId: order.id }));
    } catch (error) {
        yield put(deleteCommentError());
    }
}
