import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tree, { DataNode } from 'antd/lib/tree';
import { Skeleton } from 'antd';

import { subscribeOnCategory } from 'ducks/orders/actions';

import {
    selectCategories,
    selectUserCategories,
    selectOrderLoading,
} from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { Category } from 'types/order';
import { Role } from 'types/account';

const formatCategories = (categories: Category[]): DataNode[] => categories.map((category) => ({
    key: category.id,
    title: category.name,
    children: formatCategories(category.child),
}));

export const SubscribeCategories = React.memo(() => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const categories = useSelector(selectCategories);
    const userCategories = useSelector(selectUserCategories);
    const orderLoading = useSelector(selectOrderLoading);

    const handleCheckTree = (checkedKeys: any) => {
        put(subscribeOnCategory(checkedKeys));
    };

    const isExecutor = user?.role === Role.Executor;

    if (!isExecutor) return null;

    return (
        <div>
            <h2>Подписки на категории</h2>
            <p>Подпишитесь на категории, чтобы получать информацию о новых заказах</p>
            {
                orderLoading.getCategories
                    ? <Skeleton title={ false } paragraph={ { width: '100%', rows: 4 } } active={ true } />
                    : (
                        <Tree
                            checkable={ true }
                            treeData={ formatCategories(categories) }
                            defaultCheckedKeys={ userCategories }
                            defaultExpandedKeys={ userCategories }
                            autoExpandParent={ true }
                            style={ { backgroundColor: '#fcfcfc', marginBottom: 24 } }
                            onCheck={ handleCheckTree }
                        />
                    )
            }
        </div>
    );
});
