import { InferValueTypes } from 'types/common';
import { Review } from 'types/reviews';
import { ReviewsActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type ReviewsState = {
    loading: {
        get: boolean;
        send: boolean;
    };
    list: Review[];
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: ReviewsState = {
    loading: {
        get: false,
        send: false,
    },
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'created',
        sortOrder: 'desc',
    },
};

export function reviewsReducer(state = initialState, action: ActionTypes): ReviewsState {
    switch (action.type) {
        // СПИСОК ОТЗЫВОВ
        case ReviewsActionTypes.GET_REVIEWS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case ReviewsActionTypes.GET_REVIEWS_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case ReviewsActionTypes.GET_REVIEWS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case ReviewsActionTypes.SEND_REVIEW_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    send: true,
                },
            };

        case ReviewsActionTypes.SEND_REVIEW_ERROR:
        case ReviewsActionTypes.SEND_REVIEW_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    send: false,
                },
            };

        case ReviewsActionTypes.CLEAR_REVIEWS:
            return {
                ...initialState,
            };

        default: {
            return state;
        }
    }
}
