import React, {
    useState, useCallback, useEffect,
} from 'react';
import { useRouteMatch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { message as antMessage } from 'antd';
import ErrorMessage from 'components/errorMessage';
import useNotification from 'components/ui/hooks/useNotification';
import { useErrorProfile } from 'components/ui/hooks/useError';
import { OrderChatSkeleton } from 'components/ui/skeleton';
import {
    delteMessage,
    getMessages,
    editMessage,
    sendMessage,
    socketAddMessage,
    socketEditMessage,
    socketRemoveMessage,
    readMessage,
    getDialog,
    clearDialog,
    clearDialogMessages,
} from 'ducks/dialog/actions';
import {
    selectDialog,
    selectDialogMesages, selectDialogMesagesLoading, selectDialogMesagesNext,
} from 'ducks/dialog/selectors';

import { socket } from 'context/socket';
import { COMMENT } from 'constants/soccet';

import { Message as MessageType } from 'types/dialog';
import { forbiddenWords } from 'utils/formatters';
import { Send } from './send';
import { ListMessage } from './listMessage';
import { Notification } from './notification';
import { Title } from './title';

import styles from './styles.module.scss';

type MatchParams = {
    id: string;
};

export const ChatOrder = React.memo(() => {
    const put = useDispatch();
    const { isAuthConfirmed } = useErrorProfile();
    const loadings = useSelector(selectDialogMesagesLoading);
    const comments = useSelector(selectDialogMesages);
    const nextMoreLoading = useSelector(selectDialogMesagesNext);
    const dialog = useSelector(selectDialog);
    const { sendNotification } = useNotification();

    const { params: { id: orderId } } = useRouteMatch<MatchParams>();

    const [errMessage, setErrMessage] = useState<null | string>(null);
    const [loadingChat, setLoadingChat] = useState(2);
    const [page, setPage] = useState(2);
    const [message, setMessage] = useState('');
    const [editedComment, setEditedComment] = useState<MessageType | null>(null);

    const socketAddMess = (data: MessageType) => put(socketAddMessage(data));
    const socketEditMess = (data: MessageType) => put(socketEditMessage(data));
    const socketRemoveMess = (id: number) => put(socketRemoveMessage(id));

    useEffect(() => {
        put(getDialog({ orderId }));
        put(getMessages({ pagination: { page: 1 }, orderId }));

        return () => {
            put(clearDialog());
            put(clearDialogMessages());
        };
    }, [socket, orderId]);

    useEffect(() => {
        if (socket) {
            socket.emit(COMMENT.JOIN, { room: dialog?.id });
            socket.on(COMMENT.SEND, socketAddMess);
            socket.on(COMMENT.EDIT, socketEditMess);
            socket.on(COMMENT.REMOVE, socketRemoveMess);
            if (socket.connected) {
                setLoadingChat(1);
                setTimeout(() => setLoadingChat(3), 3000);
            }
            socket.on('connect', () => {
                setLoadingChat(1);
                setTimeout(() => setLoadingChat(3), 3000);
            });
            socket.on('connect_error', () => {
                setLoadingChat(2);
            });
        }

        return () => {
            if (socket) {
                socket.emit(COMMENT.UNSUBSCRIBE, { room: dialog?.id });
                socket.removeListener(COMMENT.SEND, socketAddMess);
                socket.removeListener(COMMENT.EDIT, socketEditMess);
                socket.removeListener(COMMENT.REMOVE, socketRemoveMess);
            }
        };
    }, [socket, dialog]);

    const handleChangeMessage = (e: any) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = useCallback(() => {
        if (forbiddenWords(message)) {
            setErrMessage('Запрещено использовать нецензурную брань, сылки, телефоны, e-mail адреса');
        } else if (message.length > 0) {
            const callback = () => setMessage('');

            put(sendMessage({ orderId }, { message }, callback));
            sendNotification({ orderId, message });
            setErrMessage(null);
        } else {
            antMessage.error('Нельзя отправить пустое сообщение');
        }
    }, [message]);

    const handleEditComment = (comment: MessageType) => {
        setEditedComment(comment);
        setMessage(comment.message);
    };

    const handleEditCommentClose = () => {
        setEditedComment(null);
        setMessage('');
        setErrMessage(null);
    };
    const nextMoreData = () => {
        setPage((num) => num + 1);
        setTimeout(() => {
            put(getMessages({ orderId, pagination: { page } }));
        }, 1);
    };

    const handleSubmitEditedComment = () => {
        if (forbiddenWords(message)) {
            setErrMessage('Запрещено использовать нецензурную брань, сылки, телефоны, e-mail адреса');
        } else if (message?.length > 0) {
            const callback = () => {
                setEditedComment(null);
                setMessage('');
                setErrMessage(null);
            };

            put(editMessage({ id: editedComment!.id, message }, callback));
        } else {
            antMessage.error('Нельзя отправить пустое сообщение');
        }
    };

    const handleDeleteComment = useCallback((id: number) => () => put(delteMessage(id)), []);
    const handleReadComment = useCallback((id: number) => () => put(readMessage(id)), []);

    if (loadings.get) {
        return <OrderChatSkeleton />;
    }

    return (
        <div className={ styles.wrap }>
            <Title />
            <Notification loading={ loadingChat } />
            <ListMessage
                data={ comments }
                nextMoreData={ nextMoreData }
                nextMoreLoading={ nextMoreLoading }
                handleEditComment={ handleEditComment }
                handleDeleteComment={ handleDeleteComment }
                handleReadComment={ handleReadComment }
            />
            { isAuthConfirmed ? (<ErrorMessage type="ACCOUNT_NOT_CONFIRMED" />) : (
                <Send
                    errMessage={ errMessage }
                    loadings={ loadings }
                    message={ message }
                    handleChangeMessage={ handleChangeMessage }
                    handleSendMessage={ editedComment ? handleSubmitEditedComment : handleSendMessage }
                    refund={ editedComment }
                    handleChangeMessageRefund={ handleEditCommentClose }
                />
            ) }
        </div>
    );
});
