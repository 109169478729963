import { put, call, select } from 'redux-saga/effects';

import {
    getFeedback,
    getFeedbackError,
    getFeedbackFinish,
} from 'ducks/feedback/actions';

import {
    selectFeedbackPagination,
    selectFeedbackSort,
} from 'ducks/feedback/selectors';

import { fetchers } from 'api';

export function* getFeedbacksRequest({ payload }: ReturnType<typeof getFeedback>) {
    try {
        const { pageSize } = yield select(selectFeedbackPagination);
        const sort = yield select(selectFeedbackSort);
        const response = yield call(fetchers.getFeedbacks, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize,
            },
            sort: payload.sort || sort,
        });

        yield put(getFeedbackFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                page: payload.pagination.page,
            },
            sort: payload.sort || sort,
        }));
    } catch (error) {
        yield put(getFeedbackError());
    }
}
