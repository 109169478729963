import React from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button, Typography,
} from 'antd';

import { acceptTender } from 'ducks/tenders/actions';
import { selectTenderOffer, selectTendersLoading } from 'ducks/tenders/selectors';
import { selectProfile } from 'ducks/user/selectors';
import { selectOrder } from 'ducks/orders/selectors';

import { getFio } from 'utils/formatters';
import { DATE_FORMAT } from 'constants/date';

import { ROUTES } from 'constants/routes';
import { history } from 'App';

import { TenderModal } from 'components/tender-modal';
import { Avatar } from 'components/ui/avatar';
import { Comment } from './comment';
import styles from './styles.module.scss';

const { Title } = Typography;

type Props = {
    orderId: string | number;
};

export const Offer = React.memo(({ orderId }: Props) => {
    const put = useDispatch();
    const loading = useSelector(selectTendersLoading);
    const tender = useSelector(selectTenderOffer);
    const profile = useSelector(selectProfile);
    const order = useSelector(selectOrder);

    const [visibilityModal, setVisibilityModal] = React.useState(false);

    const isMe = Number(profile?.id) === Number(order?.account.id);

    const onTenderAccept = (tenderId: number) => () => {
        put(acceptTender({ tenderId, orderId }));
    };

    const goToExecutor = (id: number) => () => history.push(ROUTES.USER.getPath({ id }));

    if (!tender) {
        return null;
    }

    return (
        <div className={ styles.container__offer }>
            <Title className={ styles.title_offer } level={ 5 }>Лучшеe предложение по цене</Title>
            <Comment
                active={ true }
                author={ (
                    <span className={ styles.author } onClick={ goToExecutor(tender.account.id) }>
                        { getFio(tender.account.lastName, tender.account.firstName) }
                    </span>
                ) }
                avatar={ (
                    <span onClick={ goToExecutor(tender.account.id) }>
                        <Avatar type='round' src={ tender.account.avatar } />
                    </span>
                ) }
                message={ tender.message || null }
                typeOfRole={ tender.account.typeOfRole }
                termOfService={ tender.termOfService }
                payment={ tender.payment }
                orderSize={ tender.account.orderSize }
                rating={ tender.account.rating }
                price={ tender.price }
                btn={ isMe ? (
                    <Button
                        size="small"
                        type="primary"
                        className={ styles.agree }
                        onClick={ onTenderAccept(tender.id) }
                        loading={ loading.accept }
                    >
                        Принять предложение
                    </Button>
                ) : null }
                datetime={ (
                    <span className={ styles.text }>
                        { moment(tender.created).format(DATE_FORMAT.DATE_TIME) }
                    </span>
                ) }
            />
            {
                visibilityModal && (
                    <TenderModal
                        visible={ visibilityModal }
                        setVisibilityModal={ setVisibilityModal }
                        orderId={ orderId }
                    />
                )
            }
        </div>
    );
});
