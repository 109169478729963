import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { LockFilled, UnlockFilled } from '@ant-design/icons';
import { DATE_FORMAT } from 'constants/date';
import { Role, RoleText } from 'types/account';
import { User } from 'types/user';
import { checkAccess } from 'utils/access';
import { ACCESS } from 'constants/access';
import { UserState } from 'ducks/user/reducer';

type Props = {
    profile: User | null;
    actions: {
        onBlocked: (value: number, userId: number | string, e: MouseEvent) => void;
    };
    loading: UserState['loading'];
};

export const getColumns = ({ profile, actions, loading }: Props) => [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Фамилия',
        dataIndex: 'lastName',
        key: 'lastName',
    },
    {
        title: 'Имя',
        dataIndex: 'firstName',
        key: 'firstName',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Роль',
        dataIndex: 'role',
        key: 'role',
        render: (value: Role) => RoleText[value],
    },
    {
        title: 'Дата регистрации',
        dataIndex: 'created',
        key: 'created',
        render: (value: string) => moment(value).format(DATE_FORMAT.DATE_TIME),
    },
    {
        title: '',
        dataIndex: '',
        key: 'access',
        render: (user: User) => {
            const { access, id } = user;
            const isBlockDisabled = id === profile!.id;
            const isBlockAllow = checkAccess(profile!.rights, ACCESS.USER_BLOCKING);

            if (!isBlockAllow) {
                return null;
            }

            return (
                <Button
                    icon={ access ? <UnlockFilled /> : <LockFilled /> }
                    onClick={ (e: any) => actions.onBlocked(access ? 0 : 1, user.id, e) }
                    danger={ !access }
                    disabled={ isBlockDisabled }
                    loading={ loading.blocked }
                    type="primary"
                >
                    { access ? 'Разблокировать' : 'Заблокировать' }
                </Button>
            );
        },
    },
];
