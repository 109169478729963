import { put, call } from 'redux-saga/effects';

import {
    getOrder,
    getOrderError,
    getOrderFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* getOrderRequest({ data }: ReturnType<typeof getOrder>) {
    try {
        const response = yield call(fetchers.getOrder, data);

        yield put(getOrderFinish({
            ...response,
            orderCategories: response.orderCategories.map((item: any) => item.id),
        }));
    } catch (error) {
        yield put(getOrderError());
    }
}
