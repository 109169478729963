import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Pagination, Radio, Table, Typography,
} from 'antd';
import {
    clearScore, clearScores, getScore, getScores,
} from 'ducks/score/actions';
import { selectScores, selectScoresLoading, selectScoresPagination } from 'ducks/score/selectors';
import {
    RefundStatus, Score, Status, StatusInfo,
} from 'types/score';
import { DATE_FORMAT } from 'constants/date';
import { getPrice, getStatus } from 'components/score/helper';
import { Title } from 'components/ui/title';
import { ScoreInfo } from 'components/score/info';
import { AddScore } from './add';
import { InfoScore } from './info';

import styles from './styles.module.scss';

const { Title: AntTitle } = Typography;

export const Scores = React.memo(() => {
    const put = useDispatch();
    const scores = useSelector(selectScores);
    const pagination = useSelector(selectScoresPagination);
    const loading = useSelector(selectScoresLoading);
    const [addScore, setAddScore] = useState(false);
    const [infoScore, setInfoScore] = useState<any>(false);
    const [infoDetail, setInfoDetail] = useState<any>(false);
    const [filter, setFilter] = useState<any>(null);

    const loadScores = () => put(getScores({ ...filter, pagination: { page: 1 } }));

    useEffect(() => {
        loadScores();

        return () => {
            put(clearScores());
        };
    }, [filter]);

    const onChangePagination = useCallback((page: number) => {
        put(getScores({ ...filter, pagination: { page } }));
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'ФИО / Компания',
            dataIndex: 'company',
            key: 'company',
            render: (t: string, rec: Score) => (
                <div>
                    { rec.company?.organization }
                    { rec.company?.fio }
                </div>
            ),
        },
        {
            title: 'Сумма',
            dataIndex: 'price',
            key: 'price',
            render: (t: string, rec: any) => getPrice(rec.typePayment, rec.price),
        },
        {
            title: 'Тип операции',
            dataIndex: 'typePayment',
            key: 'typePayment',
            render: (value: Status) => (
                <div className={ styles[value] }>{ StatusInfo[value].text }</div>
            ),
        },
        {
            title: 'Статус',
            dataIndex: 'refundStatus',
            key: 'refundStatus',
            render: (value: RefundStatus) => getStatus(value),
        },
        {
            title: 'Дата',
            dataIndex: 'created',
            key: 'created',
            render: (t: string) => moment(t).format(DATE_FORMAT.DATE_TIME),
        },
    ];

    const toggleInfoScore = () => {
        setInfoScore(!infoScore);
        put(clearScore());
    };

    const toggleInfoDetail = () => {
        setInfoDetail(!infoDetail);
        put(clearScore());
    };

    const goToScore = (id: number, refund: RefundStatus) => {
        put(getScore({ id }));

        if (refund === RefundStatus.waiting) {
            setInfoScore(true);
        } else {
            setInfoDetail(true);
        }
    };

    const onToggleStatus = (e: any) => {
        switch (e.target.value) {
            case 'add':
                setFilter({ typePayment: 'add' });
                break;
            case 'writeDowns':
                setFilter({ typePayment: 'writeDowns' });
                break;
            case 'refundAll':
                setFilter({ typePayment: 'refund' });
                break;
            case 'refund':
                setFilter({ refundStatus: 'waiting', typePayment: 'refund' });
                break;

            default:
                setFilter(null);
                break;
        }
    };

    return (
        <React.Fragment>
            <Title
                className={ styles.title }
                title={ <AntTitle level={ 3 }>Счета клиентов</AntTitle> }
                event={ <Button type="primary" onClick={ () => setAddScore(true) }>Управление счетом клиента</Button> }
            />
            <div className={ styles.filter }>
                <Radio.Group defaultValue="all" buttonStyle="solid">
                    <Radio.Button value="all" onClick={ (e) => onToggleStatus(e) }>История операций</Radio.Button>
                    <Radio.Button value="refund" onClick={ (e) => onToggleStatus(e) }>Запрос на возврат</Radio.Button>
                    <Radio.Button value="add" onClick={ (e) => onToggleStatus(e) }>Пополнение</Radio.Button>
                    <Radio.Button value="writeDowns" onClick={ (e) => onToggleStatus(e) }>Списание</Radio.Button>
                    <Radio.Button value="refundAll" onClick={ (e) => onToggleStatus(e) }>Возврат</Radio.Button>
                </Radio.Group>
            </div>
            <Table
                size="small"
                rowKey="id"
                pagination={ false }
                loading={ loading.gets }
                rowClassName={ styles.tableRow }
                dataSource={ scores }
                columns={ columns }
                onRow={ (record) => ({
                    onClick: () => goToScore(record.id, record.refundStatus),
                }) }
            />
            { Boolean(pagination.total) && (
                <div className={ styles.pagination }>
                    <Pagination
                        total={ pagination.total }
                        pageSize={ pagination.pageSize }
                        current={ pagination.page }
                        onChange={ onChangePagination }
                    />
                </div>
            ) }
            { addScore && (
                <AddScore
                    open={ addScore }
                    onClose={ () => setAddScore(false) }
                />
            ) }
            { infoScore && (
                <InfoScore
                    open={ infoScore }
                    onClose={ toggleInfoScore }
                />
            ) }
            { infoDetail && (
                <ScoreInfo
                    open={ infoDetail }
                    onClose={ toggleInfoDetail }
                />
            ) }
        </React.Fragment>
    );
});
