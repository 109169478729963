import React from 'react';
import classNames from 'classnames';
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TitleLine } from 'components/ui/title-line';
import 'swiper/swiper.css';
import 'swiper/modules/a11y/a11y.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { ReviewItem } from './item';
import reviews from './reviews.json';
import cl from './styles.module.scss';

export const Reviews = React.memo(() => (
    <div className={ classNames(cl.container, 'carousel') }>
        <TitleLine title='Отзывы' className={ cl.title } />
        <div className={ cl.carusel }>
            <Swiper
                modules={ [ Pagination, A11y ] }
                spaceBetween={ 25 }
                slidesPerView="auto"
                grabCursor={ true }
                pagination={ { clickable: true } }
                className="reviewsSlider"
            >
                { reviews.map((item: any, i) => (
                    <SwiperSlide key={ i } className={ cl.SwiperSlide }><ReviewItem { ...item } key={ item.id } /></SwiperSlide>
                )) }
            </Swiper>
        </div>
    </div>
));
