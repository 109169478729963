import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

type Props = {
    className?: string;
    title: string | React.ReactNode;
    sub?: string;
    extra?: React.ReactNode;
};

export const TitleLine = ({
    className, title, sub, extra,
}: Props) => (
    <div className={ cn(styles.title, className) }>
        <h2 className={ styles.title_h2 }>{ title }</h2>
        { sub && (<p className={ styles.title_p } dangerouslySetInnerHTML={ {__html: sub} } />) }
        { extra && (<div className={ styles.extra }>{ extra }</div>) }
    </div>
);
