import React from 'react';
import { history } from 'App';
import { Dialogs } from 'types/dialog';
import { ROUTES } from 'constants/routes';

import cl from './styles.module.scss';

export const Dialog = ({
    id, name, hash, order, message,
}: Dialogs) => {
    // const handleClick = (e: any) => {
    //     history.push(ROUTES[e.key].path);
    // };

    const goToLink = (url: string) => () => {
        const link = ROUTES.DIALOG.getPath({ hash: url });

        history.push(link);
    };

    return (
        <div className={ cl.item } key={ id } onClick={ goToLink(hash) }>
            <div className={ cl.num }>
                #{ id }
            </div>
            <div className={ cl.content }>
                <div className={ cl.title }>
                    { name && name }
                    { order && order.name }
                </div>
                { message && (
                    <div className={ cl.desc }>
                        { message.message }
                    </div>
                ) }
            </div>
        </div>
    );
};
