import React from 'react';
import cl from './styles.module.scss';

type Props = {
    name: string;
    text: string;
};

export const ReviewItem = React.memo(
    ({ name, text }: Props) => (
        <div className={ cl.review_item }>
            <div className={ cl.review_txt }>{ text }</div>
            <div className={ cl.author }>
                <div className={ cl.author_info }>
                    <div className={ cl.author__name }>{ name }</div>
                </div>
            </div>
        </div>
    ),
);
