import React from 'react';
import { Notification as NotificationType } from 'types/notification';
import { NewTenderOrder } from './newTenderOrder';
import { OrderRefund } from './orderRefund';
import { SendAccessCompany } from './sendAccessCompany';
import { SendEditCompany } from './sendEditCompany';
import { FillInCompanyDetails } from './fillInCompanyDetails';
import { SendErrorCompany } from './sendErrorCompany';
import { NewMessageOrder } from './newMessageOrder';
import { SendReview } from './sendReview';
import { OrderStatus } from './orderStatus';
import { UserTenderWin } from './userTenderWin';

type Props = {
    item: NotificationType;
    goToAuthorOrder: (id?: number) => void;
    goToUrl: (link: string) => void;
    onHandleVisible: (visible: boolean, id: number) => void;
};

export const Messsage = ({
    item, goToAuthorOrder, goToUrl, onHandleVisible,
}: Props) => {
    switch (item.types) {
        case 'sendErrorCompany':
            return <SendErrorCompany key={ item.id } item={ item } goToUrl={ goToUrl } onHandleVisible={ onHandleVisible } />;
        case 'newMessageOrder':
            return <NewMessageOrder key={ item.id } item={ item } goToAuthorOrder={ goToAuthorOrder } onHandleVisible={ onHandleVisible } />;
        case 'sendReview':
            return <SendReview key={ item.id } item={ item } goToAuthorOrder={ goToAuthorOrder } onHandleVisible={ onHandleVisible } />;
        case 'fillInCompanyDetails':
            return <FillInCompanyDetails key={ item.id } item={ item } goToUrl={ goToUrl } onHandleVisible={ onHandleVisible } />;
        case 'sendEditCompany':
            return <SendEditCompany key={ item.id } item={ item } onHandleVisible={ onHandleVisible } />;
        case 'sendAccessCompany':
            return <SendAccessCompany key={ item.id } item={ item } onHandleVisible={ onHandleVisible } />;
        case 'newTenderOrder':
            return <NewTenderOrder key={ item.id } item={ item } goToAuthorOrder={ goToAuthorOrder } onHandleVisible={ onHandleVisible } />;
        case 'refundOrder':
            return <OrderRefund key={ item.id } item={ item } goToAuthorOrder={ goToAuthorOrder } onHandleVisible={ onHandleVisible } />;
        case 'customerOrderDone':
        case 'executorUpdateStatus':
            return <OrderStatus key={ item.id } item={ item } goToAuthorOrder={ goToAuthorOrder } onHandleVisible={ onHandleVisible } />;
        case 'userTenderWin':
            return <UserTenderWin key={ item.id } item={ item } goToAuthorOrder={ goToAuthorOrder } onHandleVisible={ onHandleVisible } />;
        default:
            return <div />;
    }
};
