import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TitleLine } from 'components/ui/title-line';
import { selectBlogAll } from 'ducks/blog/selectors';
import { clearBlog, getBlog } from 'ducks/blog/actions';
import cls from './style.module.scss';
import { Item } from '../item';

export const BlogMain = () => {
    const put = useDispatch();
    const list = useSelector(selectBlogAll);

    useEffect(() => {
        put(getBlog({ pagination: { pageSize: 6, page: 1 } }));

        return ()=>{
            put(clearBlog());
        };
    }, []);

    return (
        <div className={ cls.blog }>
            <TitleLine
                title="Самое важно о сертификации"
                className={ cls.header }
            />

            <div className={ cls.items }>
                { list.map((item, i) => (
                    <Item { ...item } key={ i } />
                )) }
            </div>
        </div>
    );
};
