import * as Api from 'types/api';
import {
    NotificationActionTypes,
} from './action-types';

export const loadNotifications = () => ({
    type: NotificationActionTypes.LOAD_NOTIFICATION_START,
});

export const loadNotificationsError = () => ({
    type: NotificationActionTypes.LOAD_NOTIFICATION_ERROR,
});

export const loadNotificationsFinish = (response: Api.GetNotificationsResponse) => ({
    type: NotificationActionTypes.LOAD_NOTIFICATION_FINISH,
    response,
});

export const getNotifications = (payload: Api.GetNotificationsPayload) => ({
    type: NotificationActionTypes.GET_NOTIFICATION_START,
    payload,
});

export const getNotificationsError = () => ({
    type: NotificationActionTypes.GET_NOTIFICATION_ERROR,
});

export const getNotificationsFinish = (response: Api.GetNotificationsResponse) => ({
    type: NotificationActionTypes.GET_NOTIFICATION_FINISH,
    response,
});

export const getNotificationsCount = () => ({
    type: NotificationActionTypes.GET_NOTIFICATION_COUNT_START,
});

export const getNotificationsCountFinish = (size: number) => ({
    type: NotificationActionTypes.GET_NOTIFICATION_COUNT_FINISH,
    size,
});

export const setNotification = (payload: Api.SetNotificationPayload) => ({
    type: NotificationActionTypes.SET_NOTIFICATION_START,
    payload,
});

export const setNotificationError = () => ({
    type: NotificationActionTypes.SET_NOTIFICATION_ERROR,
});

export const setNotificationFinish = (response: Api.SetNotificationResponse) => ({
    type: NotificationActionTypes.SET_NOTIFICATION_FINISH,
    response,
});
