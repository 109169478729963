import React from 'react';

import styles from './styles.module.scss';

type Props = {
    avatar: React.ReactChild;
    content: React.ReactChild | string;
    datetime: React.ReactChild;
    read: boolean;
    handleRead: any;
};

export const Responder = ({
    avatar, content, datetime, read, handleRead,
}: Props) => {
    const messageBody = (
        <div className={ styles.chat_left }>
            <div className={ styles.chat_left__avatar }>
                { avatar }
            </div>
            <div className={ styles.chat_left__body }>
                <div className={ styles.chat_left__content }>
                    { !read && (<div className={ styles.chat_left__read } />) }
                    { content }
                </div>
                <div className={ styles.chat_left__time }>{ datetime }</div>
            </div>
        </div>
    );

    if (read) {
        return (
            <div className={ styles.chat_body }>{ messageBody }</div>
        );
    }

    return (<div className={ styles.chat_body } onMouseEnter={ handleRead }>{ messageBody }</div>);
};
