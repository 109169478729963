import React from 'react';
import { history } from 'App';

export const ButtonBack = () => {
    const goBack = () => {
        history.goBack();
    };

    if (history.length > 1) {
        return (
            <button
                type="button"
                onClick={ goBack }
            >
                Назад
            </button>
        );
    }

    return null;
};
