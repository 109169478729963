import * as Api from 'types/api';
import {
    CompanyActionTypes,
} from './action-types';

export const saveCompany = (
    data: Api.SaveCompanyPayload | Api.SaveCompanyForManagerPayload,
    userId: number | string,
    callback?: () => void,
) => ({
    type: CompanyActionTypes.SAVE_COMPANY_START,
    data,
    userId,
    callback,
});

export const saveCompanyError = () => ({
    type: CompanyActionTypes.SAVE_COMPANY_ERROR,
});

export const saveCompanyFinish = () => ({
    type: CompanyActionTypes.SAVE_COMPANY_FINISH,
});

export const getCompany = (userId: number | string) => ({
    type: CompanyActionTypes.GET_COMPANY_START,
    userId,
});

export const getCompanyError = () => ({
    type: CompanyActionTypes.GET_COMPANY_ERROR,
});

export const getCompanyFinish = (data: Api.GetCompanyResponse) => ({
    type: CompanyActionTypes.GET_COMPANY_FINISH,
    data,
});

export const clearCompany = () => ({
    type: CompanyActionTypes.CLEAR_COMPANY,
});

export const searchCompany = (payload: Api.GetCompanyPayload) => ({
    type: CompanyActionTypes.SEARCH_COMPANY_START,
    payload,
});

export const searchCompanyError = () => ({
    type: CompanyActionTypes.SEARCH_COMPANY_ERROR,
});

export const searchCompanyFinish = (payload: Api.GetCompanyResponseAll) => ({
    type: CompanyActionTypes.SEARCH_COMPANY_FINISH,
    payload,
});
