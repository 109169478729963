/* eslint-disable complexity */
import { InferValueTypes } from 'types/common';
import { GetCompaniesForModerationResponse } from 'types/api';
import * as actions from '../actions';
import { ModerationActionTypes } from '../action-types';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type StateType = {
    list: GetCompaniesForModerationResponse['data'];
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: StateType = {
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'created',
        sortOrder: 'desc',
    },
};

export function companyReducer(state = initialState, action: ActionTypes): StateType {
    switch (action.type) {
        case ModerationActionTypes.GET_COMPANY_FOR_MODERATION_FINISH:
            return {
                ...state,
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        default: return state;
    }
}
