import React from 'react';
import {
    DatePicker,
    Descriptions,
    Form, Input, Modal, Select, Spin,
} from 'antd';
import { Formik } from 'formik';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { DATE_FORMAT } from 'constants/date';
import { ScoreFormRefund } from 'form-helpers/score/types';
import { schemaRefundAdmin } from 'form-helpers/score/schema';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { getInitialData } from 'form-helpers/score/mapping';
import { selectScore, selectScoresLoading } from 'ducks/score/selectors';
import { refundScore } from 'ducks/score/actions';
import { RefundStatus, RefundStatusOptions, TypePaymentText } from 'types/score';
import { RefundScorePayload } from 'types/api';

import cl from './styles.module.scss';

const { Option } = Select;

const initialValues = schemaRefundAdmin.cast({});

type Props = {
    open: boolean;
    onClose: () => void;
};

export const InfoScore = ({ open, onClose }: Props) => {
    const put = useDispatch();
    const score = useSelector(selectScore);
    const loading = useSelector(selectScoresLoading);

    function onSubmit(values: RefundScorePayload) {
        const data = cloneDeep(values);

        data.id = score?.id;
        data.accountId = score?.account?.id;
        data.companyId = score?.company?.id;
        data.typePayment = score?.typePayment;
        put(refundScore(data, onClose));
    }

    return (
        <Formik<ScoreFormRefund>
            onSubmit={ onSubmit }
            initialValues={ {
                ...getInitialData(initialValues),
                refundStatus: RefundStatus.completed,
                price: score?.price,
            } }
            validationSchema={ schemaRefundAdmin }
            key="redundScoreAdmin"
            enableReinitialize={ true }
        >
            { ({
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                resetForm,
                values,
                errors,
                touched,
            }) => {
                const errorList = (name: string) => getError(errors, touched, true, name);

                const handleChangeText = (e: any) => {
                    const event = {
                        target: {
                            name: e.target.name,
                            value: e.target.value,
                            id: e.target.id,
                        },
                    };

                    handleChange(event);
                };

                const onChangeSelect = (name: string) => (value: string) => {
                    resetForm();
                    setFieldValue(name, value || null, true);
                    setFieldTouched(name, true);
                };

                const handleChangeDate = (v: any) => {
                    const value = moment(v).unix();

                    setFieldValue('datePp', value || null, true);
                    setFieldTouched('datePp', true);
                };

                const isCompany = score?.company?.organization;

                return (
                    <Modal
                        title="Информация о счете"
                        open={ open }
                        onCancel={ onClose }
                        onOk={ () => handleSubmit() }
                        okText="Отправить"
                        confirmLoading={ loading.refund }
                        width={ 800 }
                    >
                        <Spin spinning={ loading.get }>
                            <Form
                                labelAlign="left"
                                layout="vertical"
                                initialValues={ { refundStatus: RefundStatus.completed } }
                            >
                                <div className={ cl.flex }>
                                    <div className={ cl.col }>
                                        <Descriptions column={ 1 }>
                                            { (score?.company?.organization || score?.company?.fio) && (
                                                <Descriptions.Item label={ isCompany ? 'Организация' : 'Физ. лицо' }>{ isCompany ? score?.company?.organization : score?.company?.fio }</Descriptions.Item>
                                            ) }
                                            <Descriptions.Item label="ИНН">{ score?.inn || '-' }</Descriptions.Item>
                                            <Descriptions.Item label="Типа заявки">{ score?.typePayment ? TypePaymentText[score?.typePayment] : '-' }</Descriptions.Item>
                                            <Descriptions.Item label="Сумма (руб)">{ `${score?.price} руб.` || '-' }</Descriptions.Item>
                                            <Descriptions.Item label="Дата создания">{ score?.created ? moment(score?.created).format(DATE_FORMAT.DATE_TIME) : '-' }</Descriptions.Item>
                                            <Descriptions.Item label="Сообщение">{ score?.message || '-' }</Descriptions.Item>
                                        </Descriptions>
                                    </div>
                                    <div className={ cl.col }>
                                        <Form.Item
                                            label="Статус"
                                            name="refundStatus"
                                            extra={ errorList('refundStatus') }
                                            validateStatus={ getErrorStatus(!!errorList('refundStatus')) }
                                        >
                                            <Select
                                                placeholder="Выберите операцию"
                                                onChange={ onChangeSelect('refundStatus') }
                                            >
                                                { RefundStatusOptions.map(
                                                    (item) => (<Option key={ item.value } value={ item.value }>{ item.name }</Option>),
                                                ) }
                                            </Select>
                                        </Form.Item>
                                        { values.refundStatus === RefundStatus.error ? (
                                            <Form.Item
                                                label="Комментарий"
                                                extra={ errorList('refundMessage') }
                                                validateStatus={ getErrorStatus(!!errorList('refundMessage')) }
                                            >
                                                <Input.TextArea
                                                    name="refundMessage"
                                                    placeholder="Комментарий к счету"
                                                    onChange={ handleChangeText }
                                                    onBlur={ handleChangeText }
                                                    value={ values.refundMessage }
                                                    autoSize={ { minRows: 2, maxRows: 6 } }
                                                />
                                            </Form.Item>
                                        ) : (
                                            <div>
                                                <Form.Item
                                                    label="№ ПП"
                                                    extra={ errorList('pp') }
                                                    validateStatus={ getErrorStatus(!!errorList('pp')) }
                                                >
                                                    <Input
                                                        name="pp"
                                                        placeholder="№ ПП"
                                                        onChange={ handleChangeText }
                                                        onBlur={ handleChangeText }
                                                        value={ values.pp }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Дата ПП"
                                                    extra={ errorList('pp') }
                                                    validateStatus={ getErrorStatus(!!errorList('pp')) }
                                                >
                                                    <DatePicker
                                                        name="datePp"
                                                        style={ { width: '100%' } }
                                                        placeholder="Дата ПП"
                                                        format="YYYY-MM-DD H:mm"
                                                        onChange={ handleChangeDate }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Сумма к возврату (руб)"
                                                    tooltip="Укажите сумму которую вы возвращаете клиенту. Эта сумма спишится с личного кабинета"
                                                    extra={ errorList('price') }
                                                    validateStatus={ getErrorStatus(!!errorList('price')) }
                                                >
                                                    <Input
                                                        type="number"
                                                        name="price"
                                                        placeholder="Сумма"
                                                        onChange={ handleChangeText }
                                                        onBlur={ handleChangeText }
                                                        value={ values.price }
                                                    />
                                                </Form.Item>
                                            </div>
                                        ) }
                                    </div>
                                </div>
                            </Form>
                        </Spin>
                    </Modal>
                );
            } }
        </Formik>
    );
};
