import { TypeOfRole } from './account';

export type Category = {
    id: number;
    name: string;
    child: Category[];
};

export interface IStatusInfo {
    [key: string]: {
        key: string;
        text: string;
        color: string;
    };
}

export const StatusInfo: IStatusInfo = {
    new: {
        key: 'new',
        text: 'Новый',
        color: '#e96055',
    },
    inOperation: {
        key: 'inOperation',
        text: 'В работе',
        color: '#5fc5ea',
    },
    confirmedByExecutor: {
        key: 'confirmedByExecutor',
        text: 'На проверке',
        color: '#ffc800',
    },
    confirmedByCustomer: {
        key: 'confirmedByCustomer',
        text: 'Подтвержден заказчиком',
        color: '#ffc800',
    },
    draft: {
        key: 'draft',
        text: 'Черновик',
        color: '#CA6B2F',
    },
    refund: {
        key: 'refund',
        text: 'Отказ от подрядчика',
        color: '#CA6B2F',
    },
    done: {
        key: 'done',
        text: 'Завершен',
        color: '#1DA57A',
    },
};

export const Statusfulfilleds = [StatusInfo.inOperation.key, StatusInfo.confirmedByExecutor.key, StatusInfo.confirmedByCustomer.key];

export type Status = keyof typeof StatusInfo;

export type Order = {
    id: number;
    name: string;
    message: string;
    status: Status;
    created: string;
    orderCategories: number[];
    account: {
        avatar: string;
        firstName: string;
        id: number;
        lastName: string;
        email: string;
        typeOfRole: TypeOfRole;
        phone: string;
        skype: string;
        tg: string;
        viber: string;
        whatsapp: string;
        company: {
            fio: string | null;
            organization: string | null;
        };
    };
    orderTender: {
        id: number;
        account: AccountCompany;
    };
};

export type Account = {
    avatar: string;
    firstName: string;
    id: number;
    lastName: string;
};

export type OrderGuest = {
    id: number;
    name: string;
    message: string;
    created: string;
    orderCategories: [{
        id: number;
        name: string;
    }] | [];
    account: Account;
    orderTenders: [{ id: number }] | [];
    test?: boolean;
};

export type TempOrderGuest = {
    status?: Status;
    id?: number;
    orderCategories: number[];
    name: string;
    message: string;
    orderFiles?: Array<{ id: number; path: string }>;
};

export type OrderBidding = {
    id: number;
    name: string;
    created: Date;
    message: string;
    account: Account;
    orderCategories: [{
        id: number;
        name: string;
    }];
    tender: {
        min: number;
        idMin: number;
        max: number;
        idMax: number;
        procent: number;
    };
};

export type OrderFile = {
    id: number; path: string;
};

export type AccountCompany = {
    avatar: null | string;
    company: {
        typeOfOrgan: string;
        fio: string;
        organization: string;
        inn: string;
        kpp: string;
        bicBank: string;
        address: string;
        scoreNumber: string;
        certificateNumber: string;
    };
    email: string;
    firstName: string;
    typeOfRole: TypeOfRole;
    rating: number;
    id: number;
    lastName: string;
    phone: string;
    skype: string;
    tg: string;
    viber: string;
    whatsapp: string;
    orderSize: number;
};

export type FullOrder = Order & {
    orderFiles: Array<{ id: number; path: string }>;
    account: AccountCompany;
    orderTender: {
        created: string;
        id: number;
        message: string;
        price: number;
        account: AccountCompany;
    };
};

export type OrdersPagination = {
    pageSize: number;
    total: number;
    page: number;
};

export const allowedFileTypes = ['rar', 'zip', 'jpeg', 'jpg', 'png', 'xlsx', 'xls', 'pdf', 'doc', 'docx'];
