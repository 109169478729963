import { put, call } from 'redux-saga/effects';

import {
    refundScore,
    refundScoreError,
    refundScoreFinish,
} from 'ducks/score/actions';
import { getPrice } from 'ducks/user/actions';

import { fetchers } from 'api';
import { message } from 'antd';

export function* refundScoreRequest({ payload, cb }: ReturnType<typeof refundScore>) {
    try {
        const response = yield call(fetchers.refundScore, payload);

        yield put(refundScoreFinish(payload.id));

        if (response.type === 'success') {
            message.success(response.message);
        }
        if (cb) {
            cb();
        }
        yield put(getPrice());
    } catch (error) {
        yield put(refundScoreError());
    }
}
