import React from 'react';

import cl from './styles.module.scss';

const vatiant = {
    ACCOUNT_NOT_CONFIRMED: 'ACCOUNT_NOT_CONFIRMED',
    ACCOUNT_NOT_COMPANY: 'ACCOUNT_NOT_COMPANY',
};

type Props = {
    type: keyof typeof vatiant;
};

const ErrorMessage = ({ type }: Props) => {
    switch (type) {
        case vatiant.ACCOUNT_NOT_CONFIRMED:
            return (
                <div className={ cl.container }>
                    Для участия в торгах, необходимо подтвердить e-mail. Для этого нужно перейти по ссылке, которую мы вам отправили на e-mail указанный при регистрации
                </div>
            );

        case vatiant.ACCOUNT_NOT_COMPANY:
            return (
                <div className={ cl.container }>
                    Вам временно ограничен доступ
                </div>
            );

        default:
            return (
                <div className={ cl.container }>
                    Доступ запрещен
                </div>
            );
    }
};

export default ErrorMessage;
