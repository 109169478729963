import { put, call } from 'redux-saga/effects';

import {
    registerAutoSignIn,
    registerAutoSignInError,
    registerAutoSignInFinish,
} from 'ducks/app/actions';

import { SignInResponse } from 'types/api';

import { fetchers } from 'api';

export function* rigisterAutoSignInRequest({ values, cb }: ReturnType<typeof registerAutoSignIn>) {
    try {
        yield call(fetchers.signUp, values);

        const { jwtToken, ...restProps }: SignInResponse = yield call(fetchers.signIn, { email: values.email, password: values.password });

        localStorage.setItem('token', jwtToken);
        yield put(registerAutoSignInFinish(restProps));
        if (cb) cb();
    } catch (error) {
        yield put(registerAutoSignInError());
    }
}
