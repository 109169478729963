import { Score } from 'types/score';

// export const getInitialData = (initialData: any, score: FullOrder | null) => ({
export const getInitialData = (initialData: any) => ({
    ...initialData,
});
export const getInitialDataValue = (initialData: any, score: Score | null) => ({
    ...initialData,
    ...score,
});
