import { put, call } from 'redux-saga/effects';

import {
    getDialogByHash,
    getDialogByHashError,
    getDialogByHashFinish,
} from 'ducks/dialog/actions';

import { fetchers } from 'api';
import { history } from 'App';

export function* getDialogByHashRequest({ payload }: ReturnType<typeof getDialogByHash>) {
    try {
        const response = yield call(fetchers.getDialogByHash, payload);

        if (response.redirect) {
            history.push(response.redirect);
        }

        yield put(getDialogByHashFinish(response));
    } catch (error) {
        yield put(getDialogByHashError());
    }
}
