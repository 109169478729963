import React, { useState } from 'react';
import {
    Form,
    Button,
    Modal,
    Rate,
} from 'antd';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { sendReview } from 'ducks/reviews/actions';

import { selectOrder } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';
import { selectReviewsLoading } from 'ducks/reviews/selectors';

import { getError, getErrorStatus } from 'form-helpers/validation';
import { schema } from 'form-helpers/review/schema';
import { ReviewFormType } from 'form-helpers/review/types';
import { history } from 'App';
import { ROUTES } from 'constants/routes';

import { StatusInfo } from 'types/order';

import styles from './styles.module.scss';

const itemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
};

export const SendReview = React.memo(() => {
    const put = useDispatch();
    const order = useSelector(selectOrder);
    const profile = useSelector(selectProfile);
    const loading = useSelector(selectReviewsLoading);

    const [isVisibleModal, setVisibleModal] = useState(false);
    const [isSubmitTry, setIsSubmitTry] = useState(false);

    const isExecutor = order?.orderTender?.account.id === profile?.id;
    const isCustomer = order?.account.id === profile?.id;

    if (order?.status === StatusInfo.done.key && !(isExecutor || isCustomer)) {
        history.replace(ROUTES.ORDERS.path);
    }

    if (
        order?.status !== StatusInfo.done.key
        || !(isExecutor || isCustomer)
    ) {
        return null;
    }

    const onSendReview = (values: ReviewFormType) => {
        put(sendReview({
            ...values,
            orderId: order.id,
            toAccountId: isExecutor ? order.account.id : order.orderTender.account.id,
        }, () => setVisibleModal(false)));
    };

    return (
        <div className={ styles.container }>
            <Button
                type="text"
                onClick={ () => setVisibleModal(true) }
            >
                Оставить отзыв
            </Button>
            <Formik<ReviewFormType>
                initialValues={ schema }
                onSubmit={ onSendReview }
                validationSchema={ schema }
            >
                { ({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    touched,
                }) => {
                    const errorList = {
                        message: getError(errors, touched, isSubmitTry, 'message'),
                        rating: getError(errors, touched, isSubmitTry, 'rating'),
                    };

                    const onSubmit = () => {
                        setIsSubmitTry(true);
                        handleSubmit();
                    };

                    const onRateChange = (value: number) => {
                        setFieldValue('rating', value, true);
                    };

                    return (
                        <Modal
                            open={ isVisibleModal }
                            title="Оставьте отзыв"
                            onCancel={ () => setVisibleModal(false) }
                            okText="Отправить"
                            onOk={ onSubmit }
                            confirmLoading={ loading.send }
                        >
                            <Form
                                onFinish={ handleSubmit }
                                labelAlign="left"
                                className={ styles.form }
                                { ...itemLayout }
                            >
                                <Form.Item
                                    label="Рейтинг"
                                    extra={ errorList.rating }
                                    validateStatus={ getErrorStatus(!!errorList.rating) }
                                >
                                    <Rate
                                        onChange={ onRateChange }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Отзыв"
                                    extra={ errorList.message }
                                    validateStatus={ getErrorStatus(!!errorList.message) }
                                >
                                    <textarea
                                        name="message"
                                        placeholder="Описание"
                                        rows={ 4 }
                                        className="textarea"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                    />
                                </Form.Item>
                            </Form>
                        </Modal>
                    );
                } }
            </Formik>
        </div>
    );
});
