import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography,
    Pagination,
    List,
    Button,
    Empty,
    Space,
} from 'antd';
import { SocketContext } from 'context/socket';

import {
    getTenders, acceptTender, seeTender, addTender, revokeTender,
} from 'ducks/tenders/actions';

import {
    selectTenderRevoke,
    selectTenders,
    selectTendersLoading,
    selectTendersPagination,
} from 'ducks/tenders/selectors';
import { useHistory } from 'react-router';
import { selectProfile } from 'ducks/user/selectors';
import { selectOrder } from 'ducks/orders/selectors';

import { getFio, timeDiff } from 'utils/formatters';
import { DATE_FORMAT } from 'constants/date';

import { ROUTES } from 'constants/routes';
import { history } from 'App';
import { TENDER } from 'constants/soccet';

import { Tender } from 'types/tender';
import { TenderModal, TenderEditModal } from 'components/tender-modal';
import { Role } from 'types/account';
import { Avatar } from 'components/ui/avatar';
import empty from '../../assets/empty.svg';
import { Comment } from './comment';
import { Offer } from './offer';

import styles from './styles.module.scss';

const { Title } = Typography;

type Props = {
    orderId: string | number;
};

export const Tenders = React.memo(({ orderId }: Props) => {
    const put = useDispatch();
    const navigate = useHistory();
    const socket = useContext(SocketContext);
    const loading = useSelector(selectTendersLoading);
    const isRevoke = useSelector(selectTenderRevoke);
    const pagination = useSelector(selectTendersPagination);
    const tenders = useSelector(selectTenders);
    const profile = useSelector(selectProfile);
    const order = useSelector(selectOrder);

    const [visibilityModal, setVisibilityModal] = useState(false);
    const [visibilityEditModal, setVisibilityEditModal] = useState(false);
    const [editData, setEditData] = useState<any>(false);

    const isEdit = (userId: number, created: string) => Number(profile?.id) === userId && timeDiff(created, 30000);

    const isMe = Number(profile?.id) === Number(order?.account.id);
    const isExecutor = profile?.role === Role.Executor;
    const isCustomer = profile?.role === Role.Customer;

    useEffect(() => {
        const addSocketTender = (data: Tender) => put(addTender(data));
        const reloadPage = () => navigate.go(0);

        if (socket) {
            socket.emit(TENDER.JOIN, { room: orderId });
            socket.on(TENDER.SEND, addSocketTender);
            socket.on(TENDER.CHOOSE, reloadPage);
        }

        return () => {
            if (socket) {
                socket.emit(TENDER.UNSUBSCRIBE, { room: orderId });
                socket.removeListener(TENDER.SEND, addSocketTender);
                socket.removeListener(TENDER.CHOOSE, reloadPage);
            }
        };
    }, [socket, orderId]);

    const onChangePagination = (page: number) => {
        put(getTenders({ orderId, pagination: { page } }));
    };

    const onTenderAccept = (tenderId: number) => () => {
        put(acceptTender({ tenderId, orderId }));
    };

    const goToExecutor = (id: number) => () => history.push(ROUTES.USER.getPath({ id }));

    const onHandleSee = useCallback((tenderId: number) => {
        put(seeTender({ tenderId, orderId, see: true }));
    }, []);

    const onTenderEdit = useCallback((id: number) => {
        const el = tenders.map((tender) => tender.id === id && tender);

        setEditData(el[0]);
        setVisibilityEditModal(true);
    }, [tenders]);

    const tenderButton = !isMe && isExecutor ? (
        <React.Fragment>
            <Space size="large">
                { isRevoke && (
                    <Button
                        key="1"
                        type="primary"
                        className={ styles.btn_withdraw }
                        onClick={ () => put(revokeTender({ orderId })) }
                    >
                        Отозвать
                    </Button>
                ) }
                <Button
                    key="2"
                    type="primary"
                    className={ styles.btn }
                    onClick={ () => setVisibilityModal(true) }
                >
                    Оставить свое
                </Button>
            </Space>
            {
                visibilityModal && (
                    <TenderModal
                        visible={ visibilityModal }
                        setVisibilityModal={ setVisibilityModal }
                        orderId={ orderId }
                    />
                )
            }
        </React.Fragment>
    ) : null;

    if (!tenders.length) {
        return (
            <div className={ styles.wrapNoTender }>
                <Empty
                    image={ empty }
                    imageStyle={ {
                        height: 60,
                    } }
                    description={
                        <Title className={ styles.title } level={ 5 }>Предложений к заказу не поступило</Title>
                    }
                >
                    { tenderButton }
                </Empty>
            </div>
        );
    }

    return (
        <div className={ styles.container }>
            <Offer orderId={ orderId } />
            <div className={ styles.container__header }>
                <Title level={ 4 }>Предложения к заказу</Title>
                { tenderButton }
            </div>
            <List
                loading={ loading.get }
                className={ styles.list }
                itemLayout="horizontal"
                dataSource={ tenders }
                renderItem={ (item) => (
                    <li className={ styles.item }>
                        <Comment
                            see={ isMe ? item.see : undefined }
                            onHandleSee={ (isMe && isCustomer && !item.see) ? () => onHandleSee(item.id) : null }
                            author={ (
                                <span className={ styles.author } onClick={ goToExecutor(item.account.id) }>
                                    { getFio(item.account.lastName, item.account.firstName) }
                                </span>
                            ) }
                            avatar={ (
                                <span onClick={ goToExecutor(item.account.id) }>
                                    <Avatar type='round' src={ item.account.avatar } />
                                </span>
                            ) }
                            typeOfRole={ item.account.typeOfRole }
                            termOfService={ item.termOfService }
                            payment={ item.payment }
                            orderSize={ item.account.orderSize }
                            rating={ item.account.rating }
                            message={ item.message || null }
                            price={ item.price }
                            btn={ isMe ? (
                                <Button
                                    size="small"
                                    type="primary"
                                    className={ styles.agree }
                                    onClick={ onTenderAccept(item.id) }
                                    loading={ loading.accept }
                                >
                                    Принять предложение
                                </Button>
                            ) : null }
                            datetime={ (
                                <span className={ styles.text }>
                                    { moment(item.created).format(DATE_FORMAT.DATE_TIME) }
                                </span>
                            ) }
                            edit={ {
                                isEdit: isEdit(item.account.id, item.created),
                                fEdit: () => onTenderEdit(item.id),
                            } }
                        />
                    </li>
                ) }
            />
            <div className={ styles.pagination }>
                <Pagination
                    total={ pagination.total }
                    pageSize={ pagination.pageSize }
                    current={ pagination.page }
                    onChange={ onChangePagination }
                />
            </div>
            {
                visibilityEditModal && (
                    <TenderEditModal
                        visible={ visibilityEditModal }
                        data={ editData }
                        setVisibilityModal={ setVisibilityEditModal }
                        setEditData={ setEditData }
                    />
                )
            }
        </div>
    );
});
