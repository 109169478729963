import { message } from 'antd';
import { put, call } from 'redux-saga/effects';

import {
    saveUser,
    saveUserError,
    saveUserFinish,
} from 'ducks/user/actions';

import { fetchers } from 'api';

import { ROUTES } from 'constants/routes';
import { history } from 'App';

export function* saveUserRequest({ payload, reload }: ReturnType<typeof saveUser>) {
    try {
        const user = yield call(fetchers.saveUser, payload);

        yield put(saveUserFinish(user));

        if (reload) {
            history.push(ROUTES.PROFILE.path);
        } else {
            message.success('Данные успешно обновлены');
        }
    } catch (error) {
        yield put(saveUserError());
    }
}
