import { combineReducers } from 'redux';
import { dialogReducer } from '../dialogReducer';
import { messageReducer } from './message';

export const dialogMessageReducer = combineReducers({
    dialog: dialogReducer,
    message: messageReducer,
});

export type DialogMessageState = ReturnType<typeof dialogMessageReducer>;
