import { put, call } from 'redux-saga/effects';
import {
    sendMessage,
    sendMessageFinish,
    sendMessageError,
} from 'ducks/dialog/actions';

import { fetchers } from 'api';
import { message } from 'antd';

export function* sendDialogMessageRequest(action: ReturnType<typeof sendMessage>) {
    try {
        const res = yield call(fetchers.sendDialogMessage, action.params, action.data);

        action.callback();
        if (res.type === 'error') {
            message.error(res.message);
        }
        yield put(sendMessageFinish());
    } catch (error) {
        yield put(sendMessageError());
    }
}
