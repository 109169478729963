export enum RoleOptions {
    'Customer' = 'Customer',
    'Executor' = 'Executor',
}

export enum Role {
    'Customer' = 'Customer',
    'Executor' = 'Executor',
    'Admin' = 'Admin',
    'Manager' = 'Manager',
}

export const RoleText = {
    [Role.Customer]: 'Заказчик',
    [Role.Executor]: 'Исполнитель',
    [Role.Manager]: 'Менеджер',
    [Role.Admin]: 'Админ',
};

export type TypeOfRoleItem = {
    value: string;
    name: string;
};

export enum TypeOfRole {
    'broker' = 'broker',
    'delegate' = 'delegate',
    'accreditedOS' = 'accreditedOS',
    'accreditedOr' = 'accreditedOr',
    // 'notAccreditedOr' = 'notAccreditedOr',
    'applicant' = 'applicant',
}

export const TypeOfRoleText = {
    [TypeOfRole.broker]: 'Посредник',
    [TypeOfRole.delegate]: 'Уполномоченное изготовителем лицо',
    [TypeOfRole.accreditedOS]: 'Аккредитованный ОС',
    [TypeOfRole.accreditedOr]: 'Аккредитованная ИЛ',
    // [TypeOfRole.notAccreditedOr]: 'Не аккредитованная ИЛ',
    [TypeOfRole.applicant]: 'Изготовитель',
};

export const TypeOfRoleOptions = [
    {
        value: TypeOfRole.broker,
        name: TypeOfRoleText[TypeOfRole.broker],
        type: [Role.Executor],
    },
    {
        value: TypeOfRole.accreditedOS,
        name: TypeOfRoleText[TypeOfRole.accreditedOS],
        type: [Role.Executor],
    },
    {
        value: TypeOfRole.accreditedOr,
        name: TypeOfRoleText[TypeOfRole.accreditedOr],
        type: [Role.Executor],
    },
    // {
    //     value: TypeOfRole.notAccreditedOr,
    //     name: TypeOfRoleText[TypeOfRole.notAccreditedOr],
    //     type: [Role.Executor],
    // },
    {
        value: TypeOfRole.broker,
        name: TypeOfRoleText[TypeOfRole.broker],
        type: [Role.Customer],
    },
    {
        value: TypeOfRole.delegate,
        name: TypeOfRoleText[TypeOfRole.delegate],
        type: [Role.Customer],
    },
    {
        value: TypeOfRole.applicant,
        name: TypeOfRoleText[TypeOfRole.applicant],
        type: [Role.Customer],
    },
];

export type AccountInfo = {
    isVerified?: boolean;
    id: number;
    firstName: string;
    lastName: string;
    avatar: string;
};
