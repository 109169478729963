export type Blog = {
    id: number;
    title: string;
    image: string;
    created: string;
    desc?: string;
    fullDesc?: string;
    account: {
        avatar: string;
        firstName: string;
        id: number;
        lastName: string;
    };
};

export type BlogDetail = {
    id: number;
    title: string;
    desc: string;
    fullDesc: string;
    image: any;
    created: string;
    account: {
        avatar: string;
        firstName: string;
        id: number;
        lastName: string;
    };
};

export const allowedFileTypes = ['jpeg', 'jpg', 'png'];

export type CreateBlog = {
    title: string;
    desc: string;
    fullDesc: string;
    image: any;
}

export type EditBlog = {
    id: number;
    title: string;
    desc: string;
    fullDesc: string;
    image: any;
}