import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import cl from './styles.module.scss';

type Props = {
    size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';
    container?: 'center' | 'fluid';
    className?: string;
    children?: ReactNode | undefined;
};

export const Container: FC<Props> = ({
    size = 'xxl',
    container = 'center',
    className,
    children,
}) => {
    const styleRoot = classNames({
        [cl[`container_${container}`]]: true,
        [cl[`size_${size}`]]: true,
    });

    return <div className={ classNames(styleRoot, className) }>{ children }</div>;
};
