/* eslint-disable no-console */
import { fetchers } from 'api';

export const logout = async () => {
    try {
        const token = localStorage.getItem('token');

        await fetchers.exit({ token });

        localStorage.removeItem('token');
        window.location.replace('/');
    } catch (e) {
        console.log(e);
    }
};
