export const ReviewsActionTypes = {
    GET_REVIEWS_START: 'GET_REVIEWS_START',
    GET_REVIEWS_FINISH: 'GET_REVIEWS_FINISH',
    GET_REVIEWS_ERROR: 'GET_REVIEWS_ERROR',

    SEND_REVIEW_START: 'SEND_REVIEW_START',
    SEND_REVIEW_FINISH: 'SEND_REVIEW_FINISH',
    SEND_REVIEW_ERROR: 'SEND_REVIEW_ERROR',

    CLEAR_REVIEWS: 'CLEAR_REVIEWS',
} as const;
