import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import qs from 'qs';

import { history } from 'App';
import { ROUTES } from 'constants/routes';
import { fetchers } from 'api';

import styles from './styles.module.scss';

const antIcon = <LoadingOutlined style={ { fontSize: 100 } } spin={ true } />;

export const VerifyAccount = React.memo(() => {
    const [isError, setIsError] = useState(false);
    const params = useLocation();

    const token = qs.parse(params.search.substr(1)).token as string;

    useEffect(() => {
        fetchers.verifyEmail({ token }).then(() => {
            message.success('Email успешно подтвержден. Теперь вы можете успешно войти в личный кабинет');
            history.push(ROUTES.AUTH.path);
        }, () => {
            setIsError(true);
        });
    }, []);

    return (
        <div className={ styles.container }>
            <div className={ styles.group }>
                {
                    isError ? (
                        <div className={ styles.text }>Ошибка подтверждения Email. Попробуйте обновить страницу</div>
                    ) : (
                        <React.Fragment>

                            <Spin indicator={ antIcon } />
                            <div className={ styles.text }>Подтверждение аккаунта</div>
                        </React.Fragment>
                    )
                }

            </div>
        </div>
    );
});
