import moment from 'moment';
import avatar from 'assets/avatar.png';
import imgWord from 'assets/word.svg';
import imgExcel from 'assets/excel.svg';
import imgPicture from 'assets/picture.svg';
import imgPdf from 'assets/pdf.png';
import imgArchive from 'assets/archive.webp';

export function validateMessage(message: string) {
    const email = !hasSpam('@gmail.com', message)
        || !hasSpam('@gmail.ru', message)
        || !hasSpam('@yandex.com', message)
        || !hasSpam('@yandex.ru', message)
        || !hasSpam('@ya.ru', message)
        || !hasSpam('@mail.ru', message)
        || !hasSpam('.com', message)
        || !hasSpam('.net', message)
        || !hasSpam('.ru', message);

    if (email) {
        return true;
    }

    return false;
}

export function declOfNum(number = 1, words: string[]) {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
}

export function timeDiff(time1: string, time2 = 300000) {
    const time = moment().diff(time1) < time2;

    return time;
}

export function hasSpam(blacklist: string, phrase: string) {
    const p = phrase.toLowerCase();
    const b = blacklist.toLowerCase();

    for (let i = 0; i < b.length; i++) {
        if (p.indexOf(b[i]) !== -1) {
            return false;
        }
    }

    return true;
}

const badPatterns = [
    '^(о|а)н(о|а)нист.*',
    '^лошар.*',
    '^к(а|о)злина$',
    '^к(о|а)зел$',
    '^сволоч(ь|ъ|и|уга|ам|ами).*',
    '^лох[уеыаоэяию].*',
    '.*урод(ы|у|ам|ина|ины).*',
    '.*бля(т|д).*', '.*гандо.*',
    '^м(а|о)нд(а|о).*',
    '.*сперма.*',
    '.*[уеыаоэяию]еб$',
    '^сучк(а|у|и|е|ой|ай).*',
    '^придур(ок|ки).*',
    '^д(е|и)би(л|лы).*',
    '^сос(ать|и|ешь|у)$',
    '^залуп.*',
    '^твар(ь|и)$',
    '^муд(е|ил|о|а|я|еб).*',
    '.*шалав(а|ы|ам|е|ами).*',
    '.*пр(а|о)ст(и|е)т(у|е)тк(а|и|ам|е|ами).*',
    '.*шлюх(а|и|ам|е|ами).*',
    '.*ху(й|и|я|е|л(и|е)).*',
    '.*п(и|е|ы)зд.*',
    '^бл(я|т|д).*',
    '(с|сц)ук(а|о|и|у).*',
    '^еб.*',
    '.*(д(о|а)лб(о|а)|разъ|разь|за|вы|по)ебы*.*',
    '.*пид(а|о|е)р.*',
    '.*хер.*',
    // eslint-disable-next-line no-useless-escape
    '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$',
    // eslint-disable-next-line no-useless-escape
    '^(http|https)',
    // eslint-disable-next-line no-useless-escape
    '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})',
    // eslint-disable-next-line no-useless-escape
    '^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$',
];

export function forbiddenWords(mess: string) {
    for (let s = 0; s < badPatterns.length; s++) {
        const reg = new RegExp(badPatterns[s], 'gi');

        if (reg.test(mess)) {
            return true;
        }
    }

    return false;
}

export function pluralize(
    number: number | string,
    declensions: [string, string, string],
) {
    const [one, two, many] = declensions;
    const parsedNumber =
        typeof number === 'string' ? parseInt(number, 10) : number;
    let result;
    let rest = Math.abs(parsedNumber) % 100;

    if (rest > 10 && rest < 20) {
        result = many;
    } else {
        rest %= 10;
        if (rest > 1 && rest < 5) {
            result = two;
        } else if (rest === 1) {
            result = one;
        } else {
            result = many;
        }
    }

    return result.replace(/%n/g, parsedNumber.toString(10));
}

export const getFio = (lastName: string, firstName: string) => `${lastName} ${firstName}`;

export const formatStringToUploadObj = (value: string | null, uid?: string) => (value ? ({
    uid: uid || 'old',
    size: 0,
    url: value,
    name: value,
}) : null);

export const getAvatar = (src: string | null): string => (src ? (process.env.REACT_APP_URL_FILES + src) : avatar);

export const getPath = (url: string | null): string => (url ? (process.env.REACT_APP_URL_FILES + url) : `/${url}`);

export const getImages = (type: string | null | undefined) => {
    switch (type) {
        case 'zip':
        case 'rar':
            return imgArchive;
        case 'xlsx':
        case 'xls':
            return imgExcel;
        case 'pdf':
            return imgPdf;
        case 'doc':
        case 'docx':
            return imgWord;
        case 'png':
        case 'jpeg':
        case 'gif':
        case 'jpg':
            return imgPicture;

        default: return imgPicture;
    }
};
