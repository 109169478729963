import qs from 'qs';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { history } from 'App';
import moment from 'moment';

function getDate(date: any) {
    if (!date) {
        return null;
    }
    if (date.length > 1) {
        return [
            moment(date[0]),
            moment(date[1]),
        ] as any;
    }

    return moment(date);
}

const useSearchParam = () => {
    const { search } = useLocation();
    const [params, setParam] = useState(qs.parse(search,
        {
            ignoreQueryPrefix: true,
            interpretNumericEntities: true,
            delimiter: ';',
            comma: true,
        }) || '');

    const setParams = (name: string) => (value: any) => {
        setParam((prev) => ({ ...prev, [name]: value }));
    };

    const getParams = (name: string, type = 'any') => {
        switch (type) {
            case 'string':
                return params[name];

            case 'number':
                return params[name];

            case 'date':
                return getDate(params[name]);

            default:
                return params[name];
        }
    };

    const getSearch = (pathname: string) => {
        if (params) {
            history.push({
                pathname,
                search: qs.stringify(params, {
                    arrayFormat: 'comma',
                    encode: false,
                    delimiter: ';',
                    skipNulls: true,
                }),
            });
        }
    };

    const goSearch = (pathname: string, param: any) => {
        if (param) {
            history.push({
                pathname,
                search: qs.stringify(param, {
                    arrayFormat: 'comma',
                    encode: false,
                    delimiter: ';',
                    skipNulls: true,
                }),
            });
        }
    };

    const clearParams = () => setParam({});

    return {
        params, setParams, getParams, clearParams, getSearch, goSearch,
    };
};

export default useSearchParam;
