import React from 'react';
import { FrownTwoTone } from '@ant-design/icons';

import styles from './styles.module.scss';

export const NotFound = () => (
    <div className={ styles.container }>
        <div className={ styles.group }>
            <FrownTwoTone twoToneColor="#e64c4c" style={ { fontSize: 100 } } />
            <div className={ styles.text }>Страница не найдена <br /> или у вас нет доступа к ней</div>
        </div>
    </div>
);
