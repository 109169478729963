import React from 'react';
import { LayoutStatic } from 'components/ui/layoutStatic';

export const About = () => (
    <LayoutStatic>
        <h2 className="sp-title">О сервисе</h2>
        <div className="sp-text">
            <p><b>Кто мы такие</b> - команда энтузиастов, проработавших более 15 лет на рынке сертификации, изучивших проблемы участников рынка. </p>
            <p><b>Основная цель сервиса</b> – повышение эффективности процесса осуществления заказа услуг по сертификации.</p>

            <p><b>Основные принципы, положенные в основу сервиса:</b></p>
            <ul className="sp-list number bold">
                <li><b>простота</b> – Заказчик размещает заказ в открытом доступе Подрядчики дают предложения </li>
                <li><b>рыночные цены</b> – все подрядчики видят предложения конкурентов, что стимулирует снижение цен до оптимального уровня </li>
                <li><b>прозрачность</b> – система рейтингов, открытая история выполненных ранее заказов, отзывы, открытое общение между сторонами позволяют лучше понимать с кем имеешь дело </li>
            </ul>

            <p><b>При использовании сервиса СертТорг все стороны получают выгоду:</b></p>

            <p><b>Заказчики смогут:</b></p>
            <ul className="sp-list circle">
                <li>в разы снизить затраты на сертификацию своих товаров сервис использует систему открытых аукционов, аналогично госзакупкам, это означает, что все Подрядчики, претендующие на заказ, видят ценовые предложения конкурентов, что стимулирует их к снижению цены до реальной рыночной с приемлемым уровнем наценки</li>
                <li>сократить время на поиск подрядчика, способного выполнить работу не нужно звонить в разные компании, подрядчики сами найдут ваш заказ и сделают предложения, нужно только разместить его на площадке</li>
                <li>снизить риски недобросовестного поведения подрядчиков история выполненных заказов по каждому подрядчику и система рейтингов, аналогично системам бронирования отелей позволит выбрать благонадежного подрядчика</li>
            </ul>

            <p><b>Исполнители (органы по сертификации и лаборатории) смогут:</b></p>
            <ul className="sp-list circle">
                <li>получить возможность находить прямых Заказчиками без затрат на рекламу </li>
                <li>снизить зависимость от крупных посредников, которые диктуют свои условия располагая объемам заказов </li>
                <li>повысить качество работ за счет улучшения коммуникаций с прямым Заказчиком</li>
            </ul>
            <p><b>Посредники смогут:</b></p>
            <ul className="sp-list circle">
                <li>сократить бюджеты на рекламу, огромные отделы продаж, кол-центры за счет прямого бесплатного доступа к заказам</li>
                <li>перевести конкуренцию из плоскости бюджетов на рекламу в плоскость качества обслуживания клиентов и рейтингов, это стоит дешевле и способствует выстраиванию долгосрочных отношений с Заказчиками и конечными Исполнителями</li>
            </ul>
        </div>
    </LayoutStatic>
);
