import { ApplicationState } from 'ducks/application-state';

export const selectCategories = (state: ApplicationState) =>
    state.orders.categories.all;
export const selectMappedCategories = (state: ApplicationState) =>
    state.orders.categories.mapped;
export const selectCategoriesOptions = (state: ApplicationState) =>
    state.orders.categories.options;
export const selectUserCategories = (state: ApplicationState) =>
    state.orders.categories.user;
export const selectOrderLoading = (state: ApplicationState) =>
    state.orders.loading;
export const selectOrder = (state: ApplicationState) =>
    state.orders.orders.order;
export const selectOrders = (state: ApplicationState) =>
    state.orders.orders.list;
export const selectOrdersSort = (state: ApplicationState) =>
    state.orders.orders.sort;
export const selectOrdersPagination = (state: ApplicationState) =>
    state.orders.orders.pagination;

export const selectOrdersGuest = (state: ApplicationState) =>
    state.orders.ordersGuest.list;
export const selectTempOrder = (state: ApplicationState) =>
    state.orders.ordersGuest.order;
export const selectOrdersGuestSort = (state: ApplicationState) =>
    state.orders.ordersGuest.sort;
export const selectOrdersGuestPagination = (state: ApplicationState) =>
    state.orders.ordersGuest.pagination;
export const selectOrdersGuestLoading = (state: ApplicationState) =>
    state.orders.ordersGuest.loading;
export const selectOrdersGuestHide = (state: ApplicationState) =>
    state.orders.ordersGuest.hide;

export const selectOrdersBidding = (state: ApplicationState) =>
    state.orders.ordersBidding.list;
export const selectOrdersBiddingSort = (state: ApplicationState) =>
    state.orders.ordersBidding.sort;
export const selectOrdersBiddingPagination = (state: ApplicationState) =>
    state.orders.ordersBidding.pagination;
export const selectOrdersBiddingLoading = (state: ApplicationState) =>
    state.orders.ordersBidding.loading;
export const selectOrdersBiddingHide = (state: ApplicationState) =>
    state.orders.ordersBidding.hide;
