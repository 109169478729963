import * as yup from 'yup';

import { REQUIRED_FIELD } from 'form-helpers/message';
import { allowedFileTypes } from 'types/order';

export const schema = yup.object().shape({
    orderCategories: yup.array().default([]),
    name: yup.string().min(5, 'Название заказа должен состоять мин из 5 символов').required(REQUIRED_FIELD).default(''),
    message: yup.string().min(3, 'Описание заказа должен состоять минимум из 3 символа').required(REQUIRED_FIELD).default(''),
    files: yup.array().test('isRequired', 'Некоректный формат', (value) => {
        const isIssetAnotherFormat = value && value.some((item) => {
            const isFile = typeof item === 'object';

            if (!isFile) return false;

            const format = item.name.split('.').pop();

            return !allowedFileTypes.includes(format);
        });

        return value ? !isIssetAnotherFormat : true;
    }).test('isRequired', 'Размер файла привышает 50 мб', (value) => {
        const isIssetAnotherFormat = value && value.some((item) => {
            const isFile = typeof item === 'object';

            if (!isFile) return false;

            if (item.size >= 52428800) {
                return true;
            }

            return false;
        });

        return value ? !isIssetAnotherFormat : true;
    }).default(null),
});
