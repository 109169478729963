import React from 'react';
import { Link } from 'react-router-dom';
import Alert from 'antd/lib/alert';

import { useSelector } from 'react-redux';
import { selectProfile } from 'ducks/user/selectors';
import { ROUTES } from 'constants/routes';

export const useErrorProfile = () => {
    const user = useSelector(selectProfile);

    const IsAuthConfirmedTxt = () => {
        if (!user?.isVerified) {
            return (
                <Alert
                    type="info"
                    message={ (<div>Для участия в торгах, необходимо подтвердить e-mail. Для этого нужно перейти по ссылке, которую мы вам отправили на e-mail указанный при регистрации</div>) }
                />
            );
        }

        return null;
    };

    const isAuthConfirmed = !user?.isVerified;

    const ErrorCompany = () => {
        if (user?.company?.sendForverif === 1) {
            const link = <Link to={ ROUTES.MY_COMPANY.path }>компания</Link>;
            const txt = <div>Ваша { link } находится на проверке у Администрации сайта. После проверки вам будут сняты ограничения</div>;

            return (
                <Alert
                    type="info"
                    message={ txt }
                />
            );
        }

        const link = <Link to={ ROUTES.MY_COMPANY.path }>данные компании</Link>;
        const txt = <div>Пожалуйста заполните { link } для того чтобы вы смогли работать с заказами</div>;

        if (user?.error?.company) {
            return (
                <Alert
                    type="warning"
                    message={ txt }
                />
            );
        }

        return null;
    };

    const ErrorTender = () => {
        if (user?.company?.sendForverif === 1) {
            const link = <Link to={ ROUTES.MY_COMPANY.path }>компания</Link>;
            const txt = <div>Ваша { link } находится на проверке у Администрации сайта. После проверки вам будут сняты ограничения</div>;

            return (
                <Alert
                    type="info"
                    message={ txt }
                />
            );
        }

        const link = <Link to={ ROUTES.MY_COMPANY.path }>данные компании</Link>;
        const txt = <div>Пожалуйста заполните { link } для того чтобы вы смогли работать с предложениями</div>;

        if (user?.error?.company) {
            return (
                <Alert
                    type="warning"
                    message={ txt }
                />
            );
        }

        return null;
    };

    const isCreateOrder = Boolean(user?.error?.company);
    const isErrorCompany = Boolean(user?.error?.company);

    return {
        isCreateOrder, isErrorCompany, ErrorCompany, ErrorTender, IsAuthConfirmedTxt, isAuthConfirmed,
    };
};
