import { put, call } from 'redux-saga/effects';

import {
    getScore,
    getScoreError,
    getScoreFinish,
} from 'ducks/score/actions';

import { fetchers } from 'api';

export function* getScoreRequest({ payload }: ReturnType<typeof getScore>) {
    try {
        const response = yield call(fetchers.getScore, payload);

        yield put(getScoreFinish(response));
    } catch (error) {
        yield put(getScoreError());
    }
}
