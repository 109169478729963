import { put, call } from 'redux-saga/effects';

import {
    searchCompany,
    searchCompanyError,
    searchCompanyFinish,
} from 'ducks/company/actions';

import { fetchers } from 'api';

export function* searchCompanyRequest({ payload }: ReturnType<typeof searchCompany>) {
    try {
        const data = yield call(fetchers.searchCompany, payload);

        yield put(searchCompanyFinish(data));
    } catch (error) {
        yield put(searchCompanyError());
    }
}
