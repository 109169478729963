import { Blog, BlogDetail, CreateBlog, EditBlog } from 'types/blog';
import { formatStringToUploadObj } from 'utils/formatters';

export const getInitialData = (initialData: any, blog: Blog | CreateBlog | EditBlog | BlogDetail | null) => {
    const toImage = (image: any) => {
        if (Array.isArray(image)) {
            return image.map((item: any) => formatStringToUploadObj(item.path, item.id));
        }

        return [formatStringToUploadObj(`/${image}`, '1')];
    };

    return {
        image: blog?.image
            ? toImage(blog.image)
            : [],
        title: blog?.title || initialData.title,
        desc: blog?.desc || initialData.desc,
        fullDesc: blog?.fullDesc || initialData.fullDesc,
    };
};