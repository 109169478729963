import { InferValueTypes } from 'types/common';
import { Category } from 'types/order';
import * as actions from '../actions';
import { OrderActionTypes } from '../action-types';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type StateType = {
    all: Category[];
    user: number[];
    mapped: { [key: number]: string };
    options: any;
};

export const initialState: StateType = {
    all: [],
    user: [],
    mapped: {},
    options: [],
};

export function categoriesReducer(state = initialState, action: ActionTypes): StateType {
    switch (action.type) {
        case OrderActionTypes.GET_CATEGORIES_FINISH:
            return {
                ...state,
                all: action.response,
                mapped: action.mappedObj,
                options: action.options,
            };

        case OrderActionTypes.SAVE_USER_CATEGORIES:
            return {
                ...state,
                user: action.response,
            };

        default: return state;
    }
}
