import { put, call } from 'redux-saga/effects';
import {
    sendTender,
    sendTenderError,
    sendTenderFinish,
} from 'ducks/tenders/actions';
import { getPrice } from 'ducks/user/actions';

import { fetchers } from 'api';

export function* sendTenderRequest(action: ReturnType<typeof sendTender>) {
    try {
        yield call(fetchers.sendTender, action.payload);

        action.callback();
        yield put(sendTenderFinish());
        yield put(getPrice());
    } catch (error) {
        yield put(sendTenderError());
    }
}
