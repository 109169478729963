import { Modal } from 'antd';
import { fetchers } from 'api';
import { schema } from 'form-helpers/subscribe/schema';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import cl from './styles.module.scss';

export const Subscribe = React.memo(() => (
    <div className={ cl.subscribe }>
        <div className={ cl.subscribe__h2 }>
            Подписаться на поступление новых заказов
        </div>
        <div className={ cl.subscribe__desc }>
            Информация о новых заказах будет поступать 4 раза в день
        </div>
        <Formik
            initialValues={ schema }
            validationSchema={ schema }
            onSubmit={ (values: any, actions) => {
                fetchers
                    .anonymousSubscription(values)
                    .then((res: any) => {
                        if (res?.type === 'info') {
                            Modal.info({
                                content: res?.message,
                            });
                        } else {
                            Modal.success({
                                content: res?.message,
                            });
                        }

                        actions.setSubmitting(false);
                    })
                    .catch(() => {
                        Modal.error({
                            content: 'Ошибка сервера..',
                        });
                    });
            } }
        >
            { () => (
                <Form className={ cl.form }>
                    <Field
                        type='email'
                        name='email'
                        placeholder='Введите email'
                        className={ cl.form__input }
                    />
                    <button type='submit' className={ cl.form__btn }>
                        Попробовать
                    </button>
                </Form>
            ) }
        </Formik>
    </div>
));
