import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form,
    Input,
    Button,
    Select,
} from 'antd';
import { NavLink } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { AuthLayout } from 'components/ui/auth-layout';

import { signUp } from 'ducks/app/actions';

import { selectSignUpLoading, selectIsRegistrationFinish } from 'ducks/app/selectors';

import { schema } from 'form-helpers/registration/schema';
import { RegistrationFormType } from 'form-helpers/registration/types';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { ROUTES } from 'constants/routes';
import { history } from 'App';
import {
    RoleOptions, TypeOfRoleOptions, RoleText, Role,
} from 'types/account';
import { EventInit, eventYm } from 'components/ui/yandex-metrika';

import styles from './styles.module.scss';

const initialValues = schema.cast({});

const { Option } = Select;

export const Registration = React.memo(() => {
    const loading = useSelector(selectSignUpLoading);
    const isRegistrationFinish = useSelector(selectIsRegistrationFinish);
    const put = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push(ROUTES.MAIN.path);
        }
    }, []);

    const onSubmit = (values: RegistrationFormType) => {
        put(signUp(values));
        eventYm('reachGoal', 'registration');
    };

    if (isRegistrationFinish) {
        return (
            <AuthLayout title="Регистрация прошла успешно">
                <div  className={ styles.formContent }>
                    <div>Для участия в торгах, необходимо подтвердить e-mail. Для этого нужно перейти по ссылке, которую мы вам отправили на e-mail указанный при регистрации</div>
                    <br />
                    <Button
                        type="primary"
                        onClick={ () => history.push(ROUTES.MAIN.path) }
                    >
                        Вернуться на главную
                    </Button>
                </div>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout
            title="Создание аккаунта"
            variant='rigister'
        >
            <div  className={ styles.formContent }>
                <Formik<RegistrationFormType>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                    initialValues={ initialValues }
                    onSubmit={ () => {} }
                    validationSchema={ schema }
                >
                    { ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        setSubmitting,
                        validateForm,
                        setFieldValue,
                        setValues,
                    }) => {
                        const errorList = {
                            firstName: getError(errors, touched, isSubmitting, 'firstName'),
                            lastName: getError(errors, touched, isSubmitting, 'lastName'),
                            phone: getError(errors, touched, isSubmitting, 'phone'),
                            email: getError(errors, touched, isSubmitting, 'email'),
                            password: getError(errors, touched, isSubmitting, 'password'),
                            confirmPassword: getError(errors, touched, isSubmitting, 'confirmPassword'),
                            role: getError(errors, touched, isSubmitting, 'role'),
                            typeOfRole: getError(errors, touched, isSubmitting, 'typeOfRole'),
                        };

                        const handleSubmit = async () => {
                            setSubmitting(true);
                            const result = await validateForm();

                            if (isEmpty(result)) {
                                onSubmit(values);
                            }
                        };

                        const handleChangeSelect = (name: string) => (value: string) => {
                            if (name === 'role') {
                                setValues({
                                    ...values,
                                    [name]: value as Role,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    typeOfRole: '',
                                });
                            } else {
                                setFieldValue(name, value);
                            }
                        };

                        return (
                            <Form
                                onFinish={ handleSubmit }
                                labelAlign="left"
                                layout='vertical'
                                className={ styles.form }
                            >
                                <Form.Item
                                    label="Ваше имя"
                                    extra={ errorList.firstName }
                                    validateStatus={ getErrorStatus(!!errorList.firstName) }
                                >
                                    <Input
                                        name="firstName"
                                        placeholder="Ваше имя"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Ваша фамилия"
                                    extra={ errorList.lastName }
                                    validateStatus={ getErrorStatus(!!errorList.lastName) }
                                >
                                    <Input
                                        name="lastName"
                                        placeholder="Ваша фамилия"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Ваш E-mail"
                                    extra={ errorList.email }
                                    validateStatus={ getErrorStatus(!!errorList.email) }
                                >
                                    <Input
                                        name="email"
                                        placeholder="Ваш E-mail"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                    />
                                </Form.Item>
                                <div className={ styles.flex }>

                                    <Form.Item
                                        label="Пароль"
                                        extra={ errorList.password }
                                        validateStatus={ getErrorStatus(!!errorList.password) }
                                        className={ styles.input }
                                    >
                                        <Input
                                            name="password"
                                            placeholder="Пароль"
                                            onChange={ handleChange }
                                            onBlur={ handleBlur }
                                            type="password"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Повторите пароль"
                                        extra={ errorList.confirmPassword }
                                        validateStatus={ getErrorStatus(!!errorList.confirmPassword) }
                                        className={ styles.input }
                                    >
                                        <Input
                                            name="confirmPassword"
                                            placeholder="Повторите пароль"
                                            onChange={ handleChange }
                                            onBlur={ handleBlur }
                                            type="password"
                                        />
                                    </Form.Item>

                                </div>
                                <Form.Item
                                    label="Заказчик или исполнитель"
                                    extra={ errorList.role }
                                    validateStatus={ getErrorStatus(!!errorList.role) }
                                >
                                    <Select
                                        placeholder="Выберите"
                                        onChange={ handleChangeSelect('role') }
                                    >
                                        {
                                            (Object.keys(RoleOptions) as Array<keyof typeof RoleOptions>).map(
                                                (key) => <Option key={ key } value={ key }>{ RoleText[key] }</Option>,
                                            )
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Тип роли"
                                    extra={ errorList.typeOfRole }
                                    validateStatus={ getErrorStatus(!!errorList.typeOfRole) }
                                >
                                    <Select
                                        placeholder="Выберите"
                                        disabled={ !values.role }
                                        onChange={ handleChangeSelect('typeOfRole') }
                                        value={ values.typeOfRole }
                                    >
                                        { TypeOfRoleOptions.map(
                                            (item) => (
                                                item.type.includes(values.role) && (
                                                    <Option key={ item.value } value={ item.value }>{ item.name }</Option>
                                                )
                                            ),
                                        ) }
                                    </Select>
                                </Form.Item>

                                <Button
                                    type="primary"
                                    loading={ loading }
                                    onClick={ handleSubmit }
                                    block={ true }
                                >
                                    Создать аккаунт на сайте
                                </Button>
                                <p style={ { marginTop: 24, fontSize: 11 } }>
                                    Регистрируясь на сайте вы соглашаетесь с <NavLink target="_blank" to="/Политика конфиденциальности.pdf">Политикой конфиденциальности</NavLink>
                                </p>
                            </Form>
                        );
                    } }
                </Formik>
                <EventInit />
            </div>
        </AuthLayout>
    );
});
