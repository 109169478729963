import { Comment } from 'types/comments';
import { InferValueTypes } from 'types/common';
import { CommentsActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type CommentsState = {
    loading: {
        get: boolean;
        send: boolean;
        edit: boolean;
        delete: boolean;
    };
    list: Comment[];
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: CommentsState = {
    loading: {
        get: false,
        send: false,
        edit: false,
        delete: false,
    },
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'created',
        sortOrder: 'desc',
    },
};

export function commentsReducer(state = initialState, action: ActionTypes): CommentsState {
    switch (action.type) {
        case CommentsActionTypes.GET_COMMENTS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case CommentsActionTypes.GET_COMMENTS_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: [...action.response.data, ...state.list],
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case CommentsActionTypes.GET_COMMENTS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        // ОТПРАВКА КОММЕНТАРИЯ
        case CommentsActionTypes.SEND_COMMENT_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    send: true,
                },
            };

        case CommentsActionTypes.SEND_COMMENT_ERROR:
        case CommentsActionTypes.SEND_COMMENT_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    send: false,
                },
            };

        // РЕДАКТИРОВАНИЕ КОММЕНТА
        case CommentsActionTypes.EDIT_COMMENT_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };

        case CommentsActionTypes.EDIT_COMMENT_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
                // list: state.list.map((item) => (item.id === action.comment.id ? action.comment : item)),
            };

        case CommentsActionTypes.EDIT_COMMENT_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };

        // УДАЛЕНИЕ КОММЕНТАРИЯ
        case CommentsActionTypes.DELETE_COMMENT_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    delete: true,
                },
            };

        case CommentsActionTypes.DELETE_COMMENT_ERROR:
        case CommentsActionTypes.DELETE_COMMENT_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    delete: false,
                },
            };

        // ОЧИСТКА ДАННЫХ
        case CommentsActionTypes.CLEAR_COMMENTS:
            return {
                ...state,
                list: [],
                pagination: initialState.pagination,
            };

        case CommentsActionTypes.SET_COMMENT:
            return {
                ...state,
                list: [...state.list, action.message],
            };

        case CommentsActionTypes.EDIT_COMMENT:
            return {
                ...state,
                list: state.list.map((item) => (item.id === action.comment.id ? action.comment : item)),
            };

        case CommentsActionTypes.REMOVE_COMMENT:
            return {
                ...state,
                list: state.list.filter((item) => item.id !== Number(action.commentId)),
            };

        default: {
            return state;
        }
    }
}
