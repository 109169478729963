import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import jwt_decode from 'jwt-decode';

import { getProfile } from 'ducks/user/actions';
import { getCategories } from 'ducks/orders/actions';

import { selectProfile, selectUserLoading } from 'ducks/user/selectors';

import { Orders } from 'pages/orders';
import { Partner } from 'pages/static/partner';
import { Help } from 'pages/static/help';
import { About } from 'pages/static/about';
import { Payment } from 'pages/static/payment';
import { CreateTempOrder } from 'pages/create-order-guest';
import { Сontractor } from 'pages/contractor';
import { BotPage } from 'pages/bot';
import { AboutCompany } from 'pages/aboutCompany';
import { StaticPageVideo } from 'pages/static/video';
import { Certificate } from 'pages/static/certificate';
import { PageBlog } from 'pages/blog';
import { PageBlogDetail } from 'pages/blog/detail';
import { Cookie } from 'pages/static/cookie';
import { Person } from 'pages/static/person';
import { Main } from './pages/main';
import { Auth } from './pages/auth';
import { Registration } from './pages/registration';
import { PasswordRecovery } from './pages/password-recovery';
import { PageNotFound } from './pages/page-not-found';
import { Profile } from './pages/profile';
import { EditProfile } from './pages/edit-profile';
import { VerifyAccount } from './pages/verify-account';
import { Users } from './pages/users';
import { User } from './pages/user';
import { ResetPassword } from './pages/reset-password';
import { MyOrders } from './pages/my-orders';
import { CreateOrder } from './pages/create-order';
import { ViewOrder } from './pages/view-order';
import { Dialogs } from './pages/dialog';
import { Moderation } from './pages/moderation';
import { Permission } from './pages/permission';

import { PrivateRoute } from './components/private-route';

import { ROUTES } from './constants/routes';

export const Routing = () => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const loading = useSelector(selectUserLoading);
    const token = localStorage.getItem('token');

    const loadingProfile = loading.profile;

    useEffect(() => {
        // const loadNotification = () => put(getNotifications({ pagination: { page: 1 } }));

        put(getCategories());

        if (token) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { id } = jwt_decode(token);

            if (!user) {
                put(getProfile({ userId: id }));
            }
        }
    }, []);

    return (
        <Switch>
            <Route { ...ROUTES.MAIN } component={ Main } />
            <Route { ...ROUTES.COOKIE } component={ Cookie } />
            <Route { ...ROUTES.PERSON } component={ Person } />
            <Route { ...ROUTES.СONTRACTOR } component={ Сontractor } />
            <Route { ...ROUTES.ABOUT_THE_COMPANY } component={ AboutCompany } />
            <Route { ...ROUTES.STATIC_PAGE_VIDEO } component={ StaticPageVideo } />
            <Route { ...ROUTES.BOT } component={ BotPage } />
            <Route { ...ROUTES.ORDERS } component={ Orders } />
            <Route { ...ROUTES.CREATE_ORDER_GUEST } component={ CreateTempOrder } />
            <Route { ...ROUTES.REGISTRATION } component={ Registration } />
            <Route { ...ROUTES.AUTH } component={ Auth } />
            <Route { ...ROUTES.PASSWORD_RECOVERY } component={ PasswordRecovery } />
            <Route { ...ROUTES.VERIFY_ACCOUNT } component={ VerifyAccount } />
            <Route { ...ROUTES.RESET_PASSWORD } component={ ResetPassword } />
            <Route { ...ROUTES.STATIC_PARTNER } component={ Partner } />
            <Route { ...ROUTES.STATIC_ABOUT } component={ About } />
            <Route { ...ROUTES.STATIC_HELP } component={ Help } />
            <Route { ...ROUTES.STATIC_PAYMENT } component={ Payment } />
            <Route { ...ROUTES.STATIC_CERTIFICATE } component={ Certificate } />
            <Route { ...ROUTES.BLOG } component={ PageBlog } />
            <Route { ...ROUTES.BLOGS } component={ PageBlogDetail } />
            <PrivateRoute
                { ...ROUTES.EDIT_PROFILE }
                user={ user }
                loading={ loadingProfile }
                component={ EditProfile }
            />
            <PrivateRoute
                { ...ROUTES.PROFILE }
                user={ user }
                loading={ loadingProfile }
                component={ Profile }
            />
            <PrivateRoute
                { ...ROUTES.USERS }
                user={ user }
                loading={ loadingProfile }
                component={ Users }
            />
            <PrivateRoute
                { ...ROUTES.USER }
                user={ user }
                loading={ loadingProfile }
                component={ User }
            />
            <PrivateRoute
                { ...ROUTES.MY_ORDERS }
                user={ user }
                loading={ loadingProfile }
                component={ MyOrders }
            />
            <PrivateRoute
                { ...ROUTES.CREATE_ORDER }
                user={ user }
                loading={ loadingProfile }
                component={ CreateOrder }
            />
            <PrivateRoute
                { ...ROUTES.VIEW_ORDER }
                user={ user }
                loading={ loadingProfile }
                component={ ViewOrder }
            />
            <PrivateRoute
                { ...ROUTES.EDIT_ORDER }
                user={ user }
                loading={ loadingProfile }
                component={ CreateOrder }
            />
            <PrivateRoute
                { ...ROUTES.DIALOGS }
                user={ user }
                loading={ loadingProfile }
                component={ Dialogs }
            />
            <PrivateRoute
                { ...ROUTES.DIALOG }
                user={ user }
                loading={ loadingProfile }
                component={ Dialogs }
            />
            <PrivateRoute
                { ...ROUTES.MODERATION }
                user={ user }
                loading={ loadingProfile }
                component={ Moderation }
            />
            <PrivateRoute
                { ...ROUTES.PERMISSION }
                user={ user }
                loading={ loadingProfile }
                component={ Permission }
            />
            <Route component={ PageNotFound } />
        </Switch>
    );
};
